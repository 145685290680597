import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectCourseParticipationObjectForSelectedNew } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course/selectors';
import {
    selectCourseSlug,
    selectCustomer,
    selectCustomersCourses,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/customer/selectors';
import { Observable } from 'rxjs/Observable';
import { CourseParticipation } from '@cna/projects/ak-jura/shared/interfaces';
import { setCourseParticipation } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course/actions';
import { setBreadcrumbs } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';
import { MsalService } from '@azure/msal-angular';
import { EnvironmentService } from '../../../../../../../../angular/services/src/lib/environment/environment.service';
import { MeinAkJuraEnvironment } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/environments/environment.interface';
import {
    distinctUntilChanged,
    map,
    mergeMap,
    skipWhile,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { selectCourseParticipationId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { combineLatest, Subject, timer } from 'rxjs';
import { CustomerBaseData } from '@cna/projects/ak-jura/shared/interfaces';
import { CourseType } from '@cna/projects/ak-jura/shared/interfaces';
import type { UUID } from '@cna/shared/generic-types';
import { Warnings } from '@cna/projects/ak-jura/angular/my-ak-jura/shared-components';
import { environment } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/environments/environment';

@Component({
    selector: 'cna-dashboard-view',
    templateUrl: './dashboard-view.component.html',
    styleUrls: ['./dashboard-view.component.scss'],
})
export class DashboardViewComponent implements OnInit, OnDestroy {
    destroy$ = new Subject();
    customer$: Observable<CustomerBaseData> = this.store.select(selectCustomer);
    customersCourse$ = this.store.select(selectCustomersCourses).pipe(
        tap(courses => {
            if (!courses || courses.length === 0) {
                this.warning = Warnings.NO_COURSE_BOOKED;
            }
        })
    );
    selectedCourseName;
    selectedCourseId$: Observable<UUID> = this.store.select(
        selectCourseParticipationId
    );
    selectedCourseSlug$: Observable<string> = this.selectedCourseId$.pipe(
        mergeMap(courseParticipationId =>
            this.store.select(selectCourseSlug(courseParticipationId))
        )
    );
    courseSelected = false;
    courseDaysLeft: number;
    courseLocked = false;
    courseType;
    isTest = false;
    showWarningTile = false;
    warning: Warnings = undefined;
    millisecondsLeft$: Observable<Date>;

    CourseType = CourseType;
    Warnings = Warnings;
    frontendHost = environment.frontendHost;
    bookExtensionUrl$: Observable<UrlTree> = this.selectedCourseId$.pipe(
        map(cpId => this.router.createUrlTree([cpId, 'bookextension']))
    );

    private environment: MeinAkJuraEnvironment;

    constructor(
        private store: Store,
        private authService: MsalService,
        private environmentService: EnvironmentService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.environment = this.environmentService.getEnvironment();
    }

    setBreadcrumbs() {
        this.store.dispatch(
            setBreadcrumbs({
                breadcrumbs: [{ label: 'Mein AK JURA', routerLink: '' }],
            })
        );
    }

    setBreadcrumbWithCurrentInfo() {
        this.store
            .select(selectCourseParticipationId)
            .pipe(
                takeUntil(this.destroy$),
                skipWhile(x => !x)
            )
            .subscribe(cpid => {
                this.store.dispatch(
                    setBreadcrumbs({
                        breadcrumbs: [
                            { label: 'Mein AK JURA', routerLink: cpid },
                        ],
                    })
                );
            });
    }

    onCourseSelected({
        courseParticipationId,
    }: {
        courseParticipationId: UUID;
    }) {
        this.store.dispatch(setCourseParticipation({ courseParticipationId }));
    }

    /**
     * Checks if the validUntil Date is within the next 14 days
     */
    participationDaysValid(courseParticipation: CourseParticipation): number {
        if (courseParticipation) {
            const daysLeft = Math.ceil(
                (Date.parse(courseParticipation.validUntil) - Date.now()) /
                    (1000 * 3600 * 24)
            );
            this.courseDaysLeft = daysLeft;
            return daysLeft;
        } else {
            return -1;
        }
    }

    /**
     * Checks if user paid and course time period still not expired
     */
    private isCourseLocked(courseParticipation: CourseParticipation): boolean {
        return !(
            !courseParticipation.isBlocked &&
            this.participationDaysValid(courseParticipation) > 0
        );
    }

    /**
     * Logout - delete saved store in localstorage //TODO - What has to be! saved?
     */
    logout = () => {
        localStorage.removeItem('state1');
        localStorage.removeItem('state3');
        this.authService.logout({
            postLogoutRedirectUri:
                this.environment.msalConfiguration.auth.postLogoutRedirectUri,
        });
    };

    ngOnInit(): void {
        combineLatest([
            this.store.select(selectCourseParticipationId),
            this.store.select(selectCustomersCourses),
        ])
            .pipe(takeUntil(this.destroy$), distinctUntilChanged())
            .subscribe(([actualCpId, courseParticipations]) => {
                console.log(actualCpId, courseParticipations);
                if (
                    (!actualCpId && courseParticipations) ||
                    (courseParticipations &&
                        !courseParticipations?.find(cp => cp.id === actualCpId))
                ) {
                    this.store.dispatch(
                        setCourseParticipation({
                            courseParticipationId: courseParticipations[0].id,
                        })
                    );
                }
            });

        this.setBreadcrumbWithCurrentInfo();

        this.store
            .select(selectCourseParticipationObjectForSelectedNew)
            .pipe(
                skipWhile(x => {
                    return !x;
                })
            )
            .subscribe(courseParticipation => {
                if (courseParticipation) {
                    const expirationTime = new Date(
                        courseParticipation.validUntil
                    ).getTime();
                    this.millisecondsLeft$ = undefined;
                    if (courseParticipation.isBlocked) {
                        this.warning = Warnings.COURSE_BLOCKED;
                    } else if (expirationTime < Date.now()) {
                        if (courseParticipation.isTest) {
                            this.warning = Warnings.TEST_COURSE_EXPIRED;
                        } else {
                            this.warning = Warnings.COURSE_EXPIRED;
                        }
                    } else if (
                        expirationTime <
                        Date.now() + 14 * 24 * 60 * 60 * 1000
                    ) {
                        if (courseParticipation.isTest) {
                            this.warning = Warnings.TEST_COURSE_NEARLY_EXPIRED;
                            this.millisecondsLeft$ = timer(0, 60 * 1000).pipe(
                                takeUntil(this.destroy$),
                                map(() => {
                                    const date = new Date(0, 0, 0);
                                    date.setMilliseconds(
                                        expirationTime - Date.now()
                                    );
                                    return date;
                                })
                            );
                        } else {
                            this.warning = Warnings.COURSE_NEARLY_EXPIRED;
                        }
                    } else {
                        this.warning = undefined;
                    }
                    this.courseType = courseParticipation.bookedCourse.type;
                    this.courseSelected = true;
                    this.showWarningTile =
                        this.participationDaysValid(courseParticipation) < 14;
                    this.courseLocked =
                        this.isCourseLocked(courseParticipation);
                    this.isTest = courseParticipation.isTest;
                    if (
                        typeof courseParticipation.bookedCourse
                            .associatedLegalArea !== 'string'
                    ) {
                        this.selectedCourseName =
                            courseParticipation.bookedCourse.description +
                            ' – ' +
                            courseParticipation.bookedCourse.associatedLegalArea
                                .name;
                    }
                } else {
                    this.courseSelected = false;
                }
            });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
