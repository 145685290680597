import { Component, Input } from '@angular/core';

@Component({
    selector: 'cna-bibliography-accordion',
    templateUrl: './bibliography-accordion.component.html',
    styleUrls: ['./bibliography-accordion.component.scss'],
})
export class BibliographyAccordionComponent {
    @Input() isOpen = false;
    @Input() bibliography: string;

    async toggle(): Promise<void> {
        this.isOpen = !this.isOpen;
    }
}
