import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';

export enum NetworkStatus {
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
}

@Injectable({ providedIn: 'root' })
export class NetworkStatusService implements OnDestroy {
    private statusSubject = new BehaviorSubject<NetworkStatus>(this.status);
    private destroy$ = new Subject<void>();

    status$: Observable<NetworkStatus> = this.statusSubject.asObservable();
    get status(): NetworkStatus {
        return navigator.onLine ? NetworkStatus.ONLINE : NetworkStatus.OFFLINE;
    }
    get isOnline(): boolean {
        return navigator.onLine;
    }
    get isOffline(): boolean {
        return !navigator.onLine;
    }

    constructor() {
        merge(
            fromEvent(window, 'online').pipe(map(() => NetworkStatus.ONLINE)),
            fromEvent(window, 'offline').pipe(map(() => NetworkStatus.OFFLINE))
        )
            .pipe(distinctUntilChanged(), takeUntil(this.destroy$))
            .subscribe(this.statusSubject);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
