import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BREADCRUMB_STORE_NAME, BreadcrumbReducer } from './reducer';

export const selectGlobal = createFeatureSelector<BreadcrumbReducer>(
    BREADCRUMB_STORE_NAME
);

export const selectBreadcrumbs = createSelector(
    selectGlobal,
    breadcrumb => breadcrumb?.breadcrumbs
);
