import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectSidenavActiveState } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/selectors';
import { SidenavContentType } from 'libs/projects/ak-jura/angular/my-ak-jura/shared-components/src/lib/sidenav/sidenav.component';
import {
    selectCourseParticipationId,
    selectRouteDataProp,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { Router } from '@angular/router';

@Component({
    selector: 'cna-header-navigation-row',
    templateUrl: './header-navigation-row.component.html',
    styleUrls: ['./header-navigation-row.component.scss'],
})
export class HeaderNavigationRowComponent {
    constructor(private store: Store, private router: Router) {}

    sidenavTypeContentOverview = SidenavContentType.CONTENT_OVERVIEW;
    sidenavTypeCustomization = SidenavContentType.CUSTOMIZATION;
    sidenavTypeNotesOverview = SidenavContentType.NOTES_OVERVIEW;

    isLearningViewActive$ = this.store.select(
        selectRouteDataProp<boolean>('isLearningViewActive')
    );
    isDashboardActive$ = this.store.select(
        selectRouteDataProp<boolean>('isDashboardActive')
    );

    sidenavIsActive$ = combineLatest([
        this.store.select(selectSidenavActiveState),
        this.isLearningViewActive$,
    ]).pipe(map(([active, learningView]) => active && learningView));
    courseParticipationId$ = this.store.select(selectCourseParticipationId);

    async routeToDashboard() {
        const cpId = await this.courseParticipationId$.toPromise();
        if (cpId) {
            await this.router.navigate([cpId]);
        }
    }
}
