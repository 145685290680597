<div
    *ngIf="!sidebar"
    class="cap-circle gray adelle-sans-12 lh-1 font-weight-700"
    [ngClass]="done ? 'green' : ''"
>
    <span class="chapter-text">{{ chapter }}</span>
</div>
<!-- style="margin-top: 4px;" -->

<div
    *ngIf="sidebar"
    class="cap-circle-sidebar gray adelle-sans-8 font-weight-700"
    [ngClass]="done ? 'green' : ''"
>
    <span>{{ chapter }}</span>
</div>
<!-- style="margin-top: 4px;" -->
