import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class EnvironmentService {
    environment;

    constructor() {}

    /**
     * saves the environment data to this service
     * Best do this in the App (for example in constructor of apps/.../.../app.component.ts)
     * @param environment
     */
    setEnvironment(environment) {
        this.environment = environment;
    }

    /**
     * use this method in the components in /libs/.../
     */
    getEnvironment() {
        if (!this.environment) {
            throw new Error(
                'You have to Set a environment File to the EnvironmentService before use!'
            );
        }
        return this.environment;
    }
}
