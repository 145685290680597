/**
 * Calculates the sum of an array of numbers
 * @param arrValues
 */
export const sum = (arrValues: number[]) => {
    if (arrValues.length < 1) {
        throw new Error(
            'Must provide Numbers to calculate Sum. Empty Array was given!'
        );
    }
    return arrValues.reduce((a, b) => a + b);
};
/**
 * Calculates the weighted Average of an Array of values and an array of weights
 * @param arrValues
 * @param arrWeights
 */
export const weightedMean = (arrValues: number[], arrWeights: number[]) => {
    if (!arrValues || !arrWeights) return null;
    if (arrValues.length !== arrWeights.length)
        throw new Error('Number of weigths does not match number of values!');
    const result = arrValues
        .map((value, i) => {
            const weight = arrWeights[i];
            const tempSum = value * weight;
            return [tempSum, weight];
        })
        .reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0]);

    return result[0] / result[1];
};
/**
 * Calculates average/mean of an array of numbers
 * @param arrValues
 */
export const mean = (arrValues: number[]) => {
    return sum(arrValues) / arrValues.length;
};

export const roundSecondsToTheMinute = (
    duration: number,
    rounding: 'up' | 'down' | 'precise' = 'up'
): number => {
    switch (rounding) {
        case 'up':
            return duration + (60 - (duration % 60));
        case 'down':
            return duration - (duration % 60);
        case 'precise':
            return duration;
    }
};
