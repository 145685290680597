export const getAllValuesEnum = (enumType: any) => {
    const keysAndValues = Object.values(enumType);
    const values = [];
    keysAndValues.forEach((keyOrValue: any) => {
        if (isNaN(Number(keyOrValue))) {
            values.push(enumType[keyOrValue] || keyOrValue);
        }
    });
    return values;
};
