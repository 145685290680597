import { createAction, props } from '@ngrx/store';
import { Repetitorium } from '@cna/projects/ak-jura/shared/interfaces';
import type { UUID } from '@cna/shared/generic-types';

/**
 Load actual Repetitiorium
 */
export const loadRepetitoriumForActualCourse = createAction(
    '[global] load Repetitorium of Course'
);
export const loadRepetitoriumForActualCourseSuccess = createAction(
    '[global] load Repetitorium of Course success',
    props<{ repetitorium: Repetitorium }>()
);
export const loadRepetitoriumForActualCourseFailure = createAction(
    '[global] load Repetitorium of Course failure',
    props<{ error: Error }>()
);
export const setRepetitoriumUnlockedState = createAction(
    '[global] set Repetitorium unlocked state',
    props<{ unlockedState: boolean }>()
);

/** Repetitorium */
export const setReadStatusForRepetitorium = createAction(
    '[global] set read status for Repetitorium'
);
export const updateReadStatusForRepetitorium = createAction(
    '[global] update read status for Repetitorium',
    props<{ progressId: UUID; workedOut: boolean }>()
);
export const setReadStatusForRepetitoriumSuccess = createAction(
    '[global] set read status for Repetitorium success',
    props<{ readStatus: any }>()
);
export const setOrUpdateReadStatusForRepetitoriumFailure = createAction(
    '[global] set read status for Repetitorium failure',
    props<{ error: Error }>()
);
