import { Component, OnInit } from '@angular/core';
import { CourseType } from '@cna/projects/ak-jura/shared/interfaces';
import { loadCourseParticipationInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';
import { selectCurrentCourseType } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';
import { selectCourseParticipationId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { setBreadcrumbs } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';
import { Store } from '@ngrx/store';

@Component({
    selector: 'cna-questions-and-support-view',
    templateUrl: './questions-and-support-view.component.html',
    styleUrls: ['./questions-and-support-view.component.css'],
})
export class QuestionsAndSupportViewComponent implements OnInit {
    currentCourseType$ = this.store.select(selectCurrentCourseType);

    courseType = CourseType;

    constructor(private store: Store) {}

    setBreadcrumbs() {
        this.store
            .select(selectCourseParticipationId)
            .subscribe(courseParticipationId => {
                if (courseParticipationId) {
                    this.store.dispatch(
                        setBreadcrumbs({
                            breadcrumbs: [
                                {
                                    label: 'Mein AK JURA',
                                    routerLink: courseParticipationId,
                                },
                                {
                                    label: 'Fragen & Support',
                                    routerLink: courseParticipationId
                                        ? courseParticipationId
                                        : '' + '/questionsAndSupport',
                                },
                            ],
                        })
                    );
                } else {
                    this.store.dispatch(
                        setBreadcrumbs({
                            breadcrumbs: [
                                {
                                    label: 'Mein AK JURA',
                                    routerLink: '',
                                },
                                {
                                    label: 'Fragen & Support',
                                    routerLink: courseParticipationId
                                        ? courseParticipationId
                                        : '' + '/questionsAndSupport',
                                },
                            ],
                        })
                    );
                }
            })
            .unsubscribe();
    }

    ngOnInit(): void {
        this.store.dispatch(loadCourseParticipationInfos());
        this.setBreadcrumbs();
    }
}
