import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as controlTestsActions from './actions';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';
import { ToastType } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/toast-type.enum';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ToasterService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/toaster.service';
import { Injectable } from '@angular/core';
import { EntityServices } from '@ngrx/data';
import {
    selectControlTestId,
    selectModuleInfo,
} from '../module_infos/selectors';
import { ControlTest } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/control-test/control-test';
import * as errorDescribtionActions from '../error_describtion/actions';
import {
    selectActualModuleDocumentInfo,
    selectControlTestIdOfActualModuleDocumentInfo,
} from '../selectors';
import { selectCourseParticipationId } from '../router.selector';
import { ControlTestResult } from '@cna/projects/ak-jura/shared/interfaces';
import { LearningModuleDocumentInfo } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/learning-module-document-info';

@Injectable()
export class ControlTestsEffects {
    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private store: Store,
        private toasterService: ToasterService
    ) {}

    /**
     * Load Control Test for actual Course
     */
    loadControlTestForActualCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(controlTestsActions.loadControlTestForActualCourse),
            withLatestFrom(this.store.select(selectControlTestId)),
            mergeMap(([action, controlTestId]) => {
                console.log('controlTestId', controlTestId);

                return this.http.get<ControlTest>(
                    environment.apiHost + '/control-test/' + controlTestId
                );
            }),
            map(controlTest => {
                return controlTestsActions.loadControlTestForActualCourseSuccess(
                    {
                        controlTest: controlTest,
                    }
                );
            }),
            catchError(error => {
                return of(
                    controlTestsActions.loadControlTestForActualCourseFailure(
                        error
                    )
                );
            })
        );
    });

    loadControlTestForActualCourseFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    controlTestsActions.loadControlTestForActualCourseFailure
                ),
                tap(error => {
                    this.store.dispatch(
                        errorDescribtionActions.setErrorDescription({
                            errorDescription:
                                'Die Daten zum Repetitorium des ausgewählten Kurses konnten nicht vom Server geladen werden.',
                        })
                    );
                })
            );
        },
        { dispatch: false }
    );

    /**
     * Save Control Test attempt
     */
    saveControlTestAttempt$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(controlTestsActions.validateAndSaveControlTestAttempt),
            withLatestFrom(
                this.store.select(selectControlTestId),
                this.store.select(selectCourseParticipationId)
            ),
            mergeMap(([action, controlTestId, courseParticipationId]) => {
                console.log('test', controlTestId, courseParticipationId);
                return this.http.post(
                    environment.apiHost +
                        '/control-test/' +
                        controlTestId +
                        '/course-participation/' +
                        courseParticipationId,
                    { answers: action.answers }
                );
            }),
            withLatestFrom(this.store.select(selectModuleInfo)),
            map(
                ([validationResult, moduleInfo]: [
                    ControlTestResult,
                    LearningModuleDocumentInfo
                ]) =>
                    controlTestsActions.saveControlTestAttemptSuccess({
                        result: validationResult,
                        moduleId: moduleInfo.moduleId,
                    })
            ),
            catchError(error => {
                return of(
                    controlTestsActions.saveControlTestAttemptFailure(error)
                );
            })
        );
    });

    saveControlTestAttemptSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(controlTestsActions.saveControlTestAttemptSuccess),
                tap(result => {
                    this.toasterService.show(
                        'Testergebnis erfolgreich gespeichert.',
                        ToastType.success
                    );
                })
            );
        },
        { dispatch: false }
    );
    saveControlTestAttemptFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(controlTestsActions.saveControlTestAttemptFailure),
                tap(error => {
                    if (error['status'] === 409) {
                        this.store.dispatch(
                            errorDescribtionActions.setErrorDescription({
                                errorDescription:
                                    'Das Ergebnis des Tests wurde nicht gespeichert, anscheinend wurde der Test schon bestanden und kann nicht wiederholt werden.',
                            })
                        );
                    } else {
                        this.store.dispatch(
                            errorDescribtionActions.setErrorDescription({
                                errorDescription:
                                    'Beim speichern des Testergebnis ist etwas schief gelaufen.',
                            })
                        );
                    }
                })
            );
        },
        { dispatch: false }
    );
}
