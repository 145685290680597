<ng-container *ngIf="selectedCourse">
    <span *ngIf="courses.length === 1">
        {{ selectedCourse.bookedCourse.description }}
        -
        {{ selectedCourse.bookedCourse.associatedLegalArea.name }}
    </span>
    <ng-container *ngIf="courses.length > 1">
        <p>Bitte wählen Sie einen Ihrer gebuchten Kurse</p>
        <div>
            <div ngbDropdown>
                <button
                    class="btn"
                    id="selectCourseParticipation"
                    ngbDropdownToggle
                >
                    <span>
                        {{ selectedCourse.bookedCourse.description }}
                    </span>
                </button>
                <div
                    ngbDropdownMenu
                    aria-labelledby="selectCourseParticipation"
                >
                    <div *ngFor="let participation of courses">
                        <button
                            ngbDropdownItem
                            (click)="onSelectCourse(participation.id)"
                        >
                            <span>
                                {{ participation.bookedCourse.description }}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</ng-container>
