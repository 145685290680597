<div class="row" *ngIf="!this.isRepetitoriumMode">
    <div id="moduleSideNav" class="col-16">
        <cna-learning-module-accordion-sidenav
            [moduleDocumentInfo]="moduleInfo$ | async"
            [sidebar]="true"
        ></cna-learning-module-accordion-sidenav>
    </div>
</div>

<div class="row" *ngIf="this.isRepetitoriumMode">
    <div id="repetitoriumSideNav" class="col-16">
        <cna-repetitorium-accordion
            [sidebar]="true"
        ></cna-repetitorium-accordion>
    </div>
</div>
