<div class="pb-5 row justify-content-center mt-5">
    <div class="col-15 col-sm-13">
        <cna-page-heading
            pageHeadingTitle="Fragen & Support"
        ></cna-page-heading>
    </div>
</div>
<div class="pt-5 pb-5 row justify-content-center">
    <div class="col-15 col-sm-13">
        <div class="row">
            <div class="col-16 col-md-8">
                <cna-questions-and-support-education-accordion
                    *ngIf="
                        (currentCourseType$ | async) ===
                        courseType.EducationCourse
                    "
                ></cna-questions-and-support-education-accordion>
                <cna-questions-and-support-training-accordion
                    *ngIf="
                        (currentCourseType$ | async) ===
                        courseType.TrainingCourse
                    "
                ></cna-questions-and-support-training-accordion>
            </div>
        </div>
    </div>
</div>
