import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    CentsToEuroStringPipe,
    CurrencyValuePipe,
} from './currency-value.pipe';

@NgModule({
    imports: [CommonModule],
    exports: [CurrencyValuePipe, CentsToEuroStringPipe],
    declarations: [CurrencyValuePipe, CentsToEuroStringPipe],
})
export class MoneyModule {}
