<div
    class="pb-5 row justify-content-center mt-5"
    *ngLet="courseParticipation$ | async as courseParticipation"
>
    <div class="col-15 col-sm-13">
        <cna-page-heading pageHeadingTitle="Meine Notizen"></cna-page-heading>
        <div
            class="adelle-sans-17 font-weight-300 dark-gray"
            style="margin-top: 12px"
        >
            {{ courseParticipation?.bookedCourse?.description }}
        </div>
    </div>
    <div class="col-15 col-sm-13 pt-3">
        <div *ngIf="courseParticipation">
            <div *ngFor="let moduleNotes of notesPerModules$ | async">
                <div *ngIf="moduleNotes.notes.length > 0">
                    <cna-notes-accordion
                        [moduleName]="moduleNotes.moduleNumber"
                        [notes]="moduleNotes.notes"
                        [moduleId]="moduleNotes.moduleId"
                        [notesOverview]="true"
                    ></cna-notes-accordion>
                </div>
            </div>
            <div *ngIf="(repNotes$ | async).length > 0">
                <cna-notes-accordion
                    moduleName="Repetitorium"
                    [notes]="repNotes$ | async"
                    [moduleId]="null"
                    [notesOverview]="true"
                ></cna-notes-accordion>
            </div>
            <div *ngIf="!(hasNotes$ | async)">
                <p class="adelle-sans-17 dark-gray">
                    Es wurden noch keine Notizen angelegt.
                </p>
            </div>
        </div>
    </div>
</div>
