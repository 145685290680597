/* eslint-disable */
/**
 * Really fast deepclone. Kudos: https://github.com/davidmarkclements/rfdc
 * ******************************************************************************************************************
 * Copyright 2019 "David Mark Clements <david.mark.clements@gmail.com>"
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated
 * documentation files (the "Software"), to deal in the Software without restriction, including without limitation
 * the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and
 * to permit persons to whom the Software is furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions
 * of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED
 * TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL
 * THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF
 * CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS
 * IN THE SOFTWARE.
 * *******************************************************************************************************************
 * @param opts
 */

export function rfdc(opts) {
    opts = opts || {};

    if (opts.circles) return rfdcCircles(opts);
    return opts.proto ? cloneProto : clone;

    function cloneArray(a, fn) {
        var keys = Object.keys(a);
        var a2 = new Array(keys.length);
        for (var i = 0; i < keys.length; i++) {
            var k = keys[i];
            var cur = a[k];
            if (typeof cur !== 'object' || cur === null) {
                a2[k] = cur;
            } else if (cur instanceof Date) {
                a2[k] = new Date(cur);
            } else {
                a2[k] = fn(cur);
            }
        }
        return a2;
    }

    function clone(o) {
        if (typeof o !== 'object' || o === null) return o;
        if (o instanceof Date) return new Date(o);
        if (Array.isArray(o)) return cloneArray(o, clone);
        var o2 = {};
        for (var k in o) {
            if (Object.hasOwnProperty.call(o, k) === false) continue;
            var cur = o[k];
            if (typeof cur !== 'object' || cur === null) {
                o2[k] = cur;
            } else if (cur instanceof Date) {
                o2[k] = new Date(cur);
            } else {
                o2[k] = clone(cur);
            }
        }
        return o2;
    }

    function cloneProto(o) {
        if (typeof o !== 'object' || o === null) return o;
        if (o instanceof Date) return new Date(o);
        if (Array.isArray(o)) return cloneArray(o, cloneProto);
        var o2 = {};
        for (var k in o) {
            var cur = o[k];
            if (typeof cur !== 'object' || cur === null) {
                o2[k] = cur;
            } else if (cur instanceof Date) {
                o2[k] = new Date(cur);
            } else {
                o2[k] = cloneProto(cur);
            }
        }
        return o2;
    }
}

function rfdcCircles(opts) {
    var refs = [];
    var refsNew = [];

    return opts.proto ? cloneProto : clone;

    function cloneArray(a, fn) {
        var keys = Object.keys(a);
        var a2 = new Array(keys.length);
        for (var i = 0; i < keys.length; i++) {
            var k = keys[i];
            var cur = a[k];
            if (typeof cur !== 'object' || cur === null) {
                a2[k] = cur;
            } else if (cur instanceof Date) {
                a2[k] = new Date(cur);
            } else {
                var index = refs.indexOf(cur);
                if (index !== -1) {
                    a2[k] = refsNew[index];
                } else {
                    a2[k] = fn(cur);
                }
            }
        }
        return a2;
    }

    function clone(o) {
        if (typeof o !== 'object' || o === null) return o;
        if (o instanceof Date) return new Date(o);
        if (Array.isArray(o)) return cloneArray(o, clone);
        var o2 = {};
        refs.push(o);
        refsNew.push(o2);
        for (var k in o) {
            if (Object.hasOwnProperty.call(o, k) === false) continue;
            var cur = o[k];
            if (typeof cur !== 'object' || cur === null) {
                o2[k] = cur;
            } else if (cur instanceof Date) {
                o2[k] = new Date(cur);
            } else {
                var i = refs.indexOf(cur);
                if (i !== -1) {
                    o2[k] = refsNew[i];
                } else {
                    o2[k] = clone(cur);
                }
            }
        }
        refs.pop();
        refsNew.pop();
        return o2;
    }

    function cloneProto(o) {
        if (typeof o !== 'object' || o === null) return o;
        if (o instanceof Date) return new Date(o);
        if (Array.isArray(o)) return cloneArray(o, cloneProto);
        var o2 = {};
        refs.push(o);
        refsNew.push(o2);
        for (var k in o) {
            var cur = o[k];
            if (typeof cur !== 'object' || cur === null) {
                o2[k] = cur;
            } else if (cur instanceof Date) {
                o2[k] = new Date(cur);
            } else {
                var i = refs.indexOf(cur);
                if (i !== -1) {
                    o2[k] = refsNew[i];
                } else {
                    o2[k] = cloneProto(cur);
                }
            }
        }
        refs.pop();
        refsNew.pop();
        return o2;
    }
}
