import { Injectable, OnDestroy } from '@angular/core';
import { takeUntilFirst } from '@cna/angular/utils';
import { filterNil } from '@cna/shared/helper';
import { combineLatest, merge, Subject, Subscription } from 'rxjs';
import {
    selectCourseParticipationId,
    selectModuleId,
    selectReadingId,
} from '../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { map, skip, take, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { DateTrackingService } from './date-tracking.service';
import { InactivityService } from './inactivity.service';
import { StopWatch } from './stopwatch.class';
import { TimeTrackingClientService } from './time-tracking-client.service';
import { TrackingSession } from './tracking-session';
import { roundSecondsToTheMinute } from '@cna/shared/utils/math';

@Injectable({
    providedIn: 'root',
})
export class TimeTrackingService implements OnDestroy {
    private destroyed$ = new Subject<void>();
    private trackingSession?: TrackingSession;
    public inactiveMessage$ = new Subject<string>();

    private sectionId$ = this.store.select(selectReadingId);
    private stopWatch = new StopWatch();

    activityLostSubscription?: Subscription;
    dateTrackingSubscription?: Subscription;

    constructor(
        private timeTrackingClientService: TimeTrackingClientService,
        private store: Store,
        private inactivityService: InactivityService,
        private dateTrackingService: DateTrackingService
    ) {}

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    async startTracking(): Promise<void> {
        await this.initTrackingSessionIfNotExistent();
        this.stopWatch.start();
        this.startActivityEventWatcher();
        this.startDateInterval();
    }

    async stopTracking(persist = false): Promise<void> {
        this.stopActivityEventWatcher();
        this.stopDateInterval();
        await this.submitTrackingSession(persist);
    }

    private async initTrackingSessionIfNotExistent(): Promise<void> {
        if (!this.trackingSession) {
            return combineLatest([
                this.store.select(selectReadingId).pipe(filterNil()),
                this.store.select(selectModuleId).pipe(filterNil()),
                this.store
                    .select(selectCourseParticipationId)
                    .pipe(filterNil()),
            ])
                .pipe(
                    map(([sectionId, moduleId, courseParticipationId]) => ({
                        moduleId,
                        sectionId,
                        courseParticipationId,
                        duration: 0,
                        lastRead: new Date(),
                    })),
                    take(1)
                )
                .toPromise()
                .then(session => {
                    this.trackingSession = session;
                });
        }
    }

    private async submitTrackingSession(persist: boolean): Promise<void> {
        if (this.trackingSession) {
            const stopWatchData = this.stopWatch.flush();
            await this.timeTrackingClientService.submit(
                {
                    ...this.trackingSession,
                    duration: roundSecondsToTheMinute(stopWatchData.duration),
                    lastRead: stopWatchData.lastRead,
                },
                persist
            );
            this.trackingSession = null;
        }
    }

    private startActivityEventWatcher(): void {
        this.activityLostSubscription ??= this.inactivityService.inactiveTimer$
            .pipe(
                tap(() =>
                    this.inactiveMessage$.next(
                        'Sie waren zu lange inaktiv. Die letzten Minuten werden nicht zu Ihrer aktiven Zeit gebucht.'
                    )
                ),
                takeUntilFirst(
                    this.destroyed$,
                    this.sectionId$.pipe(skip(1)) // navigated to different section
                ),
                take(1)
            )
            .subscribe((inactiveSeconds: number) => {
                this.stopActivityEventWatcher();
                this.stopDateInterval();
                this.stopWatch.pause(inactiveSeconds);
            });
    }

    private stopActivityEventWatcher(): void {
        if (this.activityLostSubscription) {
            this.activityLostSubscription.unsubscribe();
            this.activityLostSubscription = undefined;
        }
    }

    private startDateInterval(): void {
        this.dateTrackingSubscription ??= this.dateTrackingService.dateTracking$
            .pipe(
                takeUntilFirst(
                    this.destroyed$,
                    this.sectionId$.pipe(skip(1)) // navigated to different section
                ),
                take(1)
            )
            .subscribe(async (inactiveSeconds: number) => {
                this.stopActivityEventWatcher();
                this.stopDateInterval();
                this.stopWatch.pause(inactiveSeconds);
                await this.stopTracking();
                await this.startTracking();
            });
    }

    private stopDateInterval(): void {
        if (this.dateTrackingSubscription) {
            this.dateTrackingSubscription.unsubscribe();
            this.dateTrackingSubscription = undefined;
        }
    }
}
