import { Subject } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { selectCourseParticipationId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { Store } from '@ngrx/store';
import { loadTimeExpendituresForActualCourse } from 'apps/ak-jura/frontends/my-ak-jura/src/app/+state/actions';
import { setBreadcrumbs } from 'apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';
import { skipWhile, takeUntil } from 'rxjs/operators';
import { selectCustomersCourseParticipation } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';
import { loadCourseParticipationInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';

@Component({
    selector: 'cna-learnstate-view',
    templateUrl: './learnstate-view.component.html',
    styleUrls: ['./learnstate-view.component.scss'],
})
export class LearnstateViewComponent implements OnInit, OnDestroy {
    _destroy$ = new Subject();

    // selectedCategory$: Observable<string> = of('state');

    courseParticipation$ = this.store
        .select(selectCustomersCourseParticipation)
        .pipe(
            //selectActualCourseObject
            skipWhile(x => {
                return x === undefined;
            }),
            takeUntil(this._destroy$)
        );

    constructor(private store: Store) {}

    setBreadcrumbs() {
        this.store
            .select(selectCourseParticipationId)
            .subscribe(courseParticipationId => {
                if (courseParticipationId) {
                    this.store.dispatch(
                        setBreadcrumbs({
                            breadcrumbs: [
                                {
                                    label: 'Mein AK JURA',
                                    routerLink: courseParticipationId,
                                },
                                {
                                    label: 'Lernstatus',
                                    routerLink:
                                        courseParticipationId + '/learnState',
                                },
                            ],
                        })
                    );
                }
            })
            .unsubscribe();
    }
    ngOnInit(): void {
        this.store.dispatch(loadCourseParticipationInfos());

        this.store.dispatch(loadTimeExpendituresForActualCourse());

        this.setBreadcrumbs();
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    /**
    changeItem(newItem: string) {
        this.selectedCategory$ = of(newItem);
    } */
}
