<div class="d-flex justify-content-center">
    <div class="learnstate-page-heading">
        <cna-page-heading pageHeadingTitle="Lernstatus"></cna-page-heading>
        <div
            class="adelle-sans-17 font-weight-300 dark-gray"
            style="margin-top: 12px"
        >
            {{ (courseParticipation$ | async)?.bookedCourse.description }}
        </div>
    </div>
</div>

<!--  *ngIf="selectedCategory$ | async as selectedCategory" -->
<div class="d-flex justify-content-center pb-5">
    <div class="learnstate-accordion">
        <cna-learnstate-accordion></cna-learnstate-accordion>
        <!-- [selectedCategory]="selectedCategory" -->
    </div>
</div>

<div class="d-flex justify-content-center pb-5">
    <div class="learntime-wrapper">
        <div class="adelle-sans-17 font-weight-600 blue-color">Lernzeiten</div>
        <cna-learn-time-accordion></cna-learn-time-accordion>
    </div>
</div>
