import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
} from '@angular/core';
import { Course, CourseType } from '@cna/projects/ak-jura/shared/interfaces';
import { CourseParticipation } from '@cna/projects/ak-jura/shared/interfaces';
import type { UUID } from '@cna/shared/generic-types';
import { LearningModuleDocumentInfo } from '../../../../../../shared/interfaces/src/lib/learning-module-document-info';

@Component({
    selector: 'cna-module-overview',
    templateUrl: './module-overview.component.html',
    styleUrls: ['./module-overview.component.scss'],
})
export class ModuleOverviewComponent implements OnChanges {
    @Input()
    public courseParticipation: CourseParticipation & {
        bookedCourse: Course & {
            modules: LearningModuleDocumentInfo[];
            repetitorium: { unlocked: boolean } | undefined;
        };
    };

    @Output()
    public selectModule = new EventEmitter<{ moduleId: UUID }>();

    @Output()
    public selectRepetitorium = new EventEmitter<void>();

    @Output()
    public printModule = new EventEmitter<{ moduleId: UUID }>();

    @Output()
    public printRepetitorium = new EventEmitter<{ courseId: UUID }>();

    public learnTimeInSeconds: number;
    public targetTimeInHours: number;

    public CourseType = CourseType;

    ngOnChanges(): void {
        this.learnTimeInSeconds = this.courseParticipation?.bookedCourse.modules
            .map(m => (<any>m).totalTimeRead)
            .reduce((sum, moduleTime) => sum + moduleTime);
        this.targetTimeInHours =
            this.courseParticipation?.bookedCourse.targetReadingTime;
    }

    navigateToModule(event: { moduleId: UUID }): void {
        this.selectModule.emit(event);
    }

    async navigateToRepetitorium(): Promise<void> {
        this.selectRepetitorium.emit();
    }

    async onPrintModule(event: { moduleId: UUID }): Promise<void> {
        this.printModule.emit(event);
    }

    async onPrintRepetitorium(): Promise<void> {
        this.printRepetitorium.emit({
            courseId: this.courseParticipation.bookedCourse.id,
        });
    }
}
