import { createReducer, on } from '@ngrx/store';
import * as fromActions from './actions';
export const CONTENT_SECTION_INFO_STORE_NAME = 'content_section';

export interface ContentSectionState {
    section: any;
    loading: boolean;
}

const initialState = {
    section: [],
    loading: false,
};

const _contentSectionReducer = createReducer<ContentSectionState>(
    initialState,

    /**
     * load content section //
     */
    on(fromActions.loadContentSection, state => ({
        ...state,
        loading: true,
    })),

    on(fromActions.loadContentSectionSuccess, (state, action) => ({
        ...state,
        section: action.section.sections,
        loading: false,
    })),

    on(fromActions.loadContentSectionFailure, state => ({
        ...state,
        loading: false,
    }))
);

export function contentSectionReducer(state, action) {
    return _contentSectionReducer(state, action);
}
