import { createAction, props } from '@ngrx/store';
import type { UUID } from '@cna/shared/generic-types';

/** the info to one course participation */
export const loadCourseParticipationInfos = createAction(
    '[global] load course participation infos'
);

export const loadCourseParticipationInfosSuccess = createAction(
    '[global] load course participation infos success',
    props<{
        courseParticipation: any; // TODO Interface
    }>()
);

export const loadCourseParticipationInfosFailure = createAction(
    '[global] load course participation failure',
    props<{ error: Error }>()
);

/** the info of all my course participations */

export const loadAllCourseParticipationInfo = createAction(
    '[global] load course participations info'
);

export const loadAllCourseParticipationInfoFailure = createAction(
    '[global] load course participations failure',
    props<{ error: Error }>()
);

export const loadAllCourseParticipationInfoDetails = createAction(
    '[global] load course participations info details',
    props<{
        courseParticipations: any;
    }>()
);

export const loadAllCourseParticipationInfoDetailsSuccess = createAction(
    '[global] load course participations info details success',
    props<{
        courseParticipations: any;
    }>()
);

export const loadAllCourseParticipationInfoDetailsFailure = createAction(
    '[global] load course participations info details failure',
    props<{ error: Error }>()
);

export const updateLastLogin = createAction(
    '[CourseParticipation] Update last login'
);

export const updateLastLoginSuccess = createAction(
    '[CourseParticipation] Update last login success',
    props<{ courseParticipationId: UUID }>()
);

export const updateLastLoginFailure = createAction(
    '[CourseParticipation] Update last login failure',
    props<{ error: string }>()
);
