import { createAction, props } from '@ngrx/store';
import { ControlTestResult } from '@cna/projects/ak-jura/shared/interfaces';
import { ControlTest } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/control-test/control-test';
import { UUID } from '@cna/shared/generic-types';

/**
 Load actual ControlTest
 */
export const loadControlTestForActualCourse = createAction(
    '[global] load ControlTest of Course'
);
export const loadControlTestForActualCourseSuccess = createAction(
    '[global] load ControlTest of Course success',
    props<{ controlTest: ControlTest }>()
);
export const loadControlTestForActualCourseFailure = createAction(
    '[global] load ControlTest of Course failure',
    props<{ error: Error }>()
);

/**
 Save ControlTest attempt
 */
export const validateAndSaveControlTestAttempt = createAction(
    '[global] save ControlTest attempt',
    props<{ answers: UUID[] }>()
);
export const saveControlTestAttemptSuccess = createAction(
    '[global] save ControlTest attempt success',
    props<{ result: ControlTestResult; moduleId: UUID }>()
);
export const saveControlTestAttemptFailure = createAction(
    '[global] save ControlTest attempt failure',
    props<{ error: Error }>()
);

export const resetControlTestValidationResult = createAction(
    '[global] reset ControlTest validation result'
);
