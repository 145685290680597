import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SIDENAV_STORE_NAME, SidenavState } from './reducers';

export const selectSidenav =
    createFeatureSelector<SidenavState>(SIDENAV_STORE_NAME);

export const selectSidenavActiveState = createSelector(
    selectSidenav,
    sidenav => sidenav?.sidenavIsActive
);
export const selectSidenavContentType = createSelector(
    selectSidenav,
    sidenav => sidenav?.sidenavContentType
);
export const selectNavInsideSidenav = createSelector(
    selectSidenav,
    sidenav => sidenav?.navInsideSidenav
);
