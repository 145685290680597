import { Injectable } from '@angular/core';
import { Exam } from '../../../../../shared/interfaces/src/lib/data-management/exam/exam';
import { ExaminationPeriod } from '../../../../../shared/interfaces/src/lib/data-management/exam/examination-period';
import { rfdc } from '@cna/shared/utils/deepclone';
import { CourseType } from '../../../../../shared/interfaces/src/lib/data-management/course/course';
import { DatePipe } from '@angular/common';
import { ExamParticipation } from '../../../../../shared/interfaces/src/lib/data-management/exam/exam-participation';
const clone = rfdc({});

@Injectable({
    providedIn: 'root',
})
export class ExamHelperService {
    constructor(public datepipe: DatePipe) {}

    translateCourseType(type: string): string {
        return type === CourseType.EducationCourse
            ? 'Ausbildung'
            : 'Weiterbildung';
    }

    getSortedPeriods(exam: Exam): ExaminationPeriod[] {
        if (exam) {
            return exam.examinationPeriods.sort(function (a, b): any {
                return Date.parse(a.date) - Date.parse(b.date);
            });
        }
    }

    getFirstDayOfExamParticipation(
        examParticipation: ExamParticipation
    ): string {
        const sortedPeriods = this.getSortedPeriods(
            clone(examParticipation.exam)
        );
        return sortedPeriods[0].date;
    }
    getFirstDayOfExam(exam: Exam): string {
        const sortedPeriods = this.getSortedPeriods(clone(exam));
        return sortedPeriods[0].date;
    }

    getLastDayOfExamParticipation(
        examParticipation: ExamParticipation
    ): string {
        const sortedPeriods = this.getSortedPeriods(
            clone(examParticipation.exam)
        );
        return sortedPeriods[sortedPeriods.length - 1].date;
    }

    getLastDayOfExam(exam: Exam): string {
        const sortedPeriods = this.getSortedPeriods(clone(exam));
        return sortedPeriods[sortedPeriods.length - 1].date;
    }

    //https://angular.io/api/common/DatePipe
    getFromToDateString(exam: Exam): string {
        if (exam) {
            return (
                this.datepipe.transform(
                    this.getFirstDayOfExam(exam),
                    'd. LLLL'
                ) +
                '–' +
                this.datepipe.transform(
                    this.getLastDayOfExam(exam),
                    'd. LLLL yyyy'
                )
            );
        }
    }

    getDayName(dateString: string): string {
        return new Date(dateString).toLocaleString('de', {
            weekday: 'long',
        });
    }

    getFormattedTime(timeString: string): string {
        return timeString.substr(0, 5);
    }
}
