import { InjectionToken } from '@angular/core';

export interface TimeTrackingConfig {
    maxInactiveTime: number;
    dateTrackingIntervalSeconds: number;
    dateTrackingToleranceSeconds: number;
    apiHost: string;
}

export const TIME_TRACKING_CONFIG = new InjectionToken<TimeTrackingConfig>(
    'time.tracking.config'
);
