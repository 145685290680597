<!-- A user HAS NOT an exam booked related to this legalArea / course  -->
<div *ngIf="courseParticipation$ | async">
    <div
        *ngIf="
            (courseParticipation$ | async).bookedCourse.type ===
            'EDUCATION_COURSE'
        "
    >
        <div *ngIf="!(examOfCourseParticipation$ | async)">
            <div class="pb-5 row justify-content-center mt-5">
                <div class="col-15 col-sm-13">
                    <cna-page-heading
                        pageHeadingTitle="Prüfungstermine"
                    ></cna-page-heading>
                    <div
                        class="adelle-sans-17 font-weight-300 dark-gray mb-5"
                        style="margin-top: 12px"
                    >
                        {{
                            (courseParticipation$ | async).bookedCourse
                                ?.description
                        }}
                    </div>
                    <p
                        *ngIf="
                            (availableExams$ | async).length;
                            else noExamsAvailable
                        "
                    >
                        Wählen Sie einen Prüfungstermin.
                    </p>
                    <ng-template #noExamsAvailable>
                        <p>
                            Leider stehen derzeit keine Prüfungstermine zur
                            Auswahl.<br />
                            Sobald aktuelle Prüfungstermine verfügbar sind,
                            informieren wir Sie per E-Mail.
                        </p>
                    </ng-template>
                </div>
            </div>

            <div class="row justify-content-center mb-5">
                <div class="col-15 col-sm-13 mb-5">
                    <cna-exam-dates>
                        <!-- [exams]="availableExams$ | async" -->
                    </cna-exam-dates>
                </div>
            </div>

            <div class="row justify-content-center gray-background">
                <div class="col-15 col-sm-13 mt-5 mb-5">
                    <div class="row">
                        <div class="col-13 col-md-8">
                            <p class="adelle-pe-17 font-weight-600 blue-color">
                                Informationen zur Abschlussprüfung
                            </p>
                            <div
                                class="adelle-sans-17 font-weight-300 dark-gray"
                            >
                                <p class="mb-0">Prüfungsvorbereitung</p>
                                <ul>
                                    <li>
                                        Als Vorbereitung empfehlen wir die
                                        Bearbeitung des Repetitoriums
                                    </li>
                                    <li>
                                        Das Repetitorium bereitet Sie
                                        vollumfänglich auf die Prüfung vor
                                    </li>
                                    <li>
                                        Prüfungsrelevant sind ausschließlich die
                                        im Repetitorium besprochenen Inhalte
                                    </li>
                                </ul>
                                <p class="mb-0">Zugelassene Hilfsmittel</p>
                                <ul>
                                    <li>
                                        Zugelassene Hilfsmittel sind sämtliche
                                        Gesetze und Normen
                                    </li>
                                    <li>
                                        Markierungen und Kommentierungen sind
                                        zulässig
                                    </li>
                                    <li>
                                        Sie können die Normen auch in digitaler
                                        Form (offline, z.B. Tablet) oder auch
                                        ausgedruckt mitbringen
                                    </li>
                                    <li>
                                        Sie benötigen ausschließlich Normen
                                        welche Sie auch für die Bearbeitung des
                                        Repetitoriums benötigen
                                    </li>
                                </ul>
                                <p class="mb-0">Prüfungsablauf</p>
                                <ul>
                                    <li>
                                        Jede Prüfung besteht aus drei jeweils
                                        5-stündigen Klausuren
                                    </li>
                                    <li>
                                        Jede Klausur besteht aus etwa 30 offenen
                                        Fragen (s. Repetitorium)
                                    </li>
                                    <li>
                                        Es sind weder Fälle zu lösen noch sind
                                        Gutachten zu fertigen
                                    </li>
                                </ul>
                            </div>
                            <p class="adelle-pe-17 font-weight-600 blue-color">
                                Anmeldung zur Prüfung
                            </p>
                            <div
                                class="adelle-sans-17 font-weight-300 dark-gray"
                            >
                                <p class="mb-0">
                                    Sie können sich jederzeit zur
                                    Abschlussprüfung anmelden. Eine Teilnahme an
                                    der Prüfung ist jedoch erst möglich, wenn
                                    folgende Voraussetzungen erfüllt sind:
                                </p>
                                <ul>
                                    <li>
                                        alle 4 Lernmodule sind vollständig
                                        bearbeitet
                                    </li>
                                    <li>
                                        alle 4 Lernkontrollen sind bestanden und
                                    </li>
                                    <li>
                                        die protokollierte Lernzeit beträgt
                                        mindestens 120 (bei Insolvenzrecht 180)
                                        Zeitstunden
                                    </li>
                                </ul>
                            </div>
                            <p class="adelle-pe-17 font-weight-600 blue-color">
                                Prüfungsgebühr
                            </p>
                            <p class="adelle-sans-17 font-weight-300 dark-gray">
                                Die Prüfungsgebühr wird erst mit Ihrer Teilnahme
                                an der Prüfung erhoben.
                            </p>
                            <p class="adelle-pe-17 font-weight-600 blue-color">
                                Teilnahme an der Prüfung nach Ablauf der
                                Vertragslaufzeit
                            </p>
                            <p class="adelle-sans-17 font-weight-300 dark-gray">
                                Sie können an der Prüfung teilnehmen, auch wenn
                                Ihre Vertragszeit abgelaufen ist.
                            </p>
                            <p class="adelle-pe-17 font-weight-600 blue-color">
                                Individuelle Prüfungstermine
                            </p>
                            <div class="adelle-sans-17 font-weight-300 dark-gray">
                                <ul class="mb-0">
                                    <li>
                                        notarielle oder anwaltliche Prüfungsaufsicht bei Ihnen vor Ort
                                    </li>
                                    <li>
                                        zusätzlicher Unkostenbeitrag zur Prüfungsgebühr für die Durchführung
                                    </li>
                                </ul>
                                <p class="mb-0">
                                    Für Fragen nehmen sie bitte Kontakt mit uns auf unter
                                    <a
                                        href="mailto:kontakt@ak-jura.de"
                                        class="dark-gray"
                                        style="text-decoration: underline"
                                    >kontakt@ak-jura.de</a
                                    >
                                    oder telefonisch
                                    <a href="tel:+4973160319939"
                                       class="dark-gray"
                                       style="text-decoration: underline"
                                    >+49&nbsp;731&nbsp;60319939</a
                                    >.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- A user HAS an exam booked related to this legalArea / course -->
    <div *ngIf="examOfCourseParticipation$ | async">
        <div class="pb-5 row justify-content-center mt-5">
            <div class="col-15 col-sm-13">
                <cna-page-heading
                    pageHeadingTitle="Meine Prüfungen"
                ></cna-page-heading>
                <div
                    class="adelle-sans-17 font-weight-300 dark-gray"
                    style="margin-top: 12px"
                >
                    {{
                        (courseParticipation$ | async).bookedCourse?.description
                    }}
                </div>
            </div>
        </div>

        <div class="row justify-content-center gray-background">
            <div class="col-15 col-sm-13 mt-5 mb-5">
                <div class="d-flex flex-column flex-md-row">
                    <div class="mr-md-5">
                        <p
                            class="adelle-sans-17 font-weight-600 dark-gray mb-0"
                        >
                            {{
                                (examOfCourseParticipation$ | async).exam
                                    .address.city
                            }}
                            <br />
                            <span
                                *ngIf="
                                    (examOfCourseParticipation$ | async).exam
                                        .examinationPeriods.length > 0
                                "
                            >
                                {{
                                    examHelperService.getFromToDateString(
                                        (examOfCourseParticipation$ | async)
                                            .exam
                                    )
                                }}
                            </span>
                        </p>
                        <p class="adelle-sans-17 font-weight-300 dark-gray">
                            {{
                                (examOfCourseParticipation$ | async).exam
                                    .address.locationName
                            }}
                            <span
                                *ngIf="
                                    (examOfCourseParticipation$ | async).exam
                                        .address.locationName
                                "
                            >
                                <br />
                            </span>
                            {{
                                (examOfCourseParticipation$ | async).exam
                                    .address.addressLine1
                            }}
                            <span
                                *ngIf="
                                    (examOfCourseParticipation$ | async).exam
                                        .address.addressLine1
                                "
                            >
                                <br />
                            </span>
                            {{
                                (examOfCourseParticipation$ | async).exam
                                    .address.addressLine2
                            }}
                            <span
                                *ngIf="
                                    (examOfCourseParticipation$ | async).exam
                                        .address.addressLine2
                                "
                            >
                                <br />
                            </span>
                            {{
                                (examOfCourseParticipation$ | async).exam
                                    .address.postCode
                            }}
                            {{
                                (examOfCourseParticipation$ | async).exam
                                    .address.city
                            }}
                            <span
                                *ngIf="
                                    (examOfCourseParticipation$ | async).exam
                                        .address.city
                                "
                            >
                                <br />
                            </span>
                            {{
                                (examOfCourseParticipation$ | async).exam
                                    .address.stateOrProvince
                            }}
                        </p>
                    </div>
                    <div class="d-flex flex-column ml-md-5">
                        <p
                            class="adelle-sans-17 font-weight-600 mb-0 dark-gray"
                        >
                            Prüfungszeiten
                        </p>
                        <div
                            class="d-flex"
                            *ngFor="
                                let examinationPeriods of (
                                    examOfCourseParticipation$ | async
                                ).exam.examinationPeriods
                            "
                        >
                            <div
                                class="pl-0 adelle-sans-17 font-weight-300 dark-gray"
                            >
                                {{
                                    examHelperService.getDayName(
                                        examinationPeriods.date
                                    )
                                }}
                            </div>
                            <div
                                class="adelle-sans-17 font-weight-300 dark-gray ml-2"
                            >
                                {{
                                    examHelperService.getFormattedTime(
                                        examinationPeriods.startTime
                                    )
                                }}
                                -
                                {{
                                    examHelperService.getFormattedTime(
                                        examinationPeriods.endTime
                                    )
                                }}
                                Uhr
                            </div>
                        </div>
                    </div>
                </div>

                <div class="d-flex flex-column mt-5">
                    <div
                        *ngIf="
                            (examOfCourseParticipation$ | async).exam as exam
                        "
                        class="download-link d-flex flex-row align-items-center"
                    >
                        <a
                            *ngIf="exam.infoSheetText"
                            (click)="downloadExamDetails(exam)"
                        >
                            <img
                                width=""
                                height="auto"
                                src="assets/mehr_grey_2.svg"
                            />
                            <span
                                class="font-weight-600 adelle-sans-11 dark-gray"
                                style="margin-left: 8px"
                                >DOWNLOAD PRÜFUNGSDETAILS</span
                            >
                        </a>
                    </div>

                    <div class="mt-2 d-flex flex-row align-items-center">
                        <a
                            [routerLink]="['../questionsAndSupport']"
                            [queryParams]="{ panel: 'p4' }"
                        >
                            <img
                                width=""
                                height="auto"
                                src="assets/mehr_grey_2.svg"
                            />
                            <span
                                class="font-weight-600 adelle-sans-11 dark-gray letterSpacing-073"
                                style="margin-left: 8px"
                                >FAQ PRÜFUNGEN</span
                            >
                        </a>
                    </div>
                </div>
            </div>
        </div>


        <div
            class="row justify-content-center gray-background"
            *ngLet="(examOfCourseParticipation$ | async) as examParticipation"
        >
            <div
                *ngIf="examIsCancellable$ | async"
                class="col-15 col-sm-13 mb-5"
            >
                <cna-gradient-button
                    (akClick)="cancelExamParticipation(examParticipation.id)"
                    buttonTitle="Prüfung stornieren"
                ></cna-gradient-button>
            </div>
        </div>
    </div>

    <div
        *ngIf="
            (courseParticipation$ | async).bookedCourse.type ===
            'TRAINING_COURSE'
        "
    >
        <div class="d-flex flex-row justify-content-center">
            <div class="d-flex flex-column">
                <p>Weiterbildungen haben keine Prüfungen</p>
                <a [routerLink]="['../']">zurück</a>
            </div>
        </div>
    </div>
</div>
