import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/environments/environment';
import { Store } from '@ngrx/store';
import { of, Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map, skipWhile, takeUntil, withLatestFrom } from 'rxjs/operators';

import { setBreadcrumbs } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';
import { loadCourseParticipationInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';
import { selectCustomersCourseParticipation } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';
import {
    loadExamParticipations,
    loadExams,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/exam/actions';
import {
    selectExamParticipations,
    selectVisibleExams,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/exam/selectors';

import { selectCourseParticipationId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { Exam } from '../../../../../../shared/interfaces/src/lib/data-management/exam/exam';
import { ExamParticipation } from '../../../../../../shared/interfaces/src/lib/data-management/exam/exam-participation';
import { ToastType } from '../../../../../../shared/interfaces/src/lib/toast-type.enum';
import { PdfCreatorService } from '../../../../../file-creation/src/lib/pdf-creator.service';
import { ExamHelperService } from '../../../../../shared-components/src/lib/services/exam-helper.service';
import { ToasterService } from '../../../../../shared-components/src/lib/services/toaster.service';
import { ExamsService } from './exams.service';

@Component({
    selector: 'cna-exams-view',
    templateUrl: './exams-view.component.html',
    styleUrls: ['./exams-view.component.scss'],
})
export class ExamsViewComponent implements OnInit, OnDestroy {
    constructor(
        private store: Store,
        public examHelperService: ExamHelperService,
        private pdfCreatorService: PdfCreatorService,
        private examsService: ExamsService,
        private toaster: ToasterService,
        private router: Router
    ) {}

    private destroy$: Subject<any> = new Subject<any>();

    public availableExams$: Observable<Exam[]> = this.store
        .select(selectVisibleExams)
        .pipe(
            skipWhile(sVE => sVE === undefined || sVE === null),
            takeUntil(this.destroy$),
            map(exams =>
                exams.sort((exam1, exam2) => {
                    function getMinDateOfExam(exam: Exam): Date {
                        if(!exam.examinationPeriods?.length) {
                            return new Date(Number.MAX_SAFE_INTEGER);
                        }

                        return exam.examinationPeriods.reduce(
                            (minStart, period) => {
                                const start = new Date(
                                    `${period.date} ${period.startTime}`
                                );
                                return start.getTime() < minStart.getTime()
                                    ? start
                                    : minStart;
                            },
                            new Date(
                                `${exam.examinationPeriods[0].date} ${exam.examinationPeriods[0].startTime}`
                            )
                        );
                    }
                    return (
                        getMinDateOfExam(exam1).getTime() -
                        getMinDateOfExam(exam2).getTime()
                    );
                })
            )
        );

    public examParticipations$: Observable<ExamParticipation[]> = this.store
        .select(selectExamParticipations)
        .pipe(
            skipWhile(ep => !ep),
            takeUntil(this.destroy$)
        );

    public courseParticipation$ = this.store
        .select(selectCustomersCourseParticipation)
        .pipe(
            skipWhile(x => x === undefined || x === null),
            takeUntil(this.destroy$)
        );

    examOfCourseParticipation$: Observable<any> = this.examParticipations$.pipe(
        skipWhile(ep => !ep),
        withLatestFrom(
            this.store.select(selectCustomersCourseParticipation).pipe(
                skipWhile(x => x === undefined || x === null),
                takeUntil(this.destroy$)
            )
        ),
        map(([eps, cp]) => {
            return eps.find(ep => {
                if (typeof cp.bookedCourse.associatedLegalArea !== 'string') {
                    return (
                        ep.legalAreaId ===
                        cp.bookedCourse.associatedLegalArea.id
                    );
                } else {
                    return undefined;
                }
            });
        })
    );

    examIsCancellable$ = of(true);
    // examIsCancellable$ = this.examOfCourseParticipation$.pipe(
    //     map((examParticipation) => {
    //         if(!examParticipation ) {
    //             return false;
    //         }
    //
    //         const firstDate = examParticipation.exam.examinationPeriods.map(p => new Date(`${p.date} ${p.startTime}`).getTime()).sort((p1,p2) => {
    //             return p1 - p2;
    //         })[0];
    //
    //         // Exam starts in more than 28 days / 4 weeks
    //         return firstDate > Date.now() + 1000 * 60 * 60 * 24 * 28;
    //     })
    // )

    setBreadcrumbs() {
        this.store
            .select(selectCourseParticipationId)
            .subscribe(courseParticipationId => {
                if (courseParticipationId) {
                    this.store.dispatch(
                        setBreadcrumbs({
                            breadcrumbs: [
                                {
                                    label: 'Mein AK JURA',
                                    routerLink: courseParticipationId,
                                },
                                {
                                    label: 'Prüfungen',
                                    routerLink:
                                        courseParticipationId + '/exams',
                                },
                            ],
                        })
                    );
                }
            })
            .unsubscribe();
    }

    ngOnInit(): void {
        this.store.dispatch(loadCourseParticipationInfos());
        this.store.dispatch(loadExamParticipations());
        this.store.dispatch(loadExams());

        this.setBreadcrumbs();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    downloadExamDetails(exam: Exam): void {
        this.pdfCreatorService.createExamDetailsPdf(exam.infoSheetText);
    }

    async cancelExamParticipation(examParticipationId: string): Promise<void> {
        try {
            await this.examsService.cancelExamParticipation(examParticipationId).toPromise();
            this.toaster.show(
                'Ihre Stornierungsanfrage wurde erfolgreich übermittelt und wird in Kürze von uns bearbeitet.',
                ToastType.success,
            );
            await this.router.navigate(['/']);
        } catch(err) {
            this.toaster.show(
                `Beim Versuch, Ihre Prüfung zu stornieren, ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal, oder wenden Sie sich an ${environment.authorNoteMailAddress}.`,
                ToastType.error
            )
        }
    }
}
