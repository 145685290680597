import {
    Component,
    EventEmitter,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'cna-loading-screen',
    templateUrl: './loading-screen.component.html',
    styleUrls: ['./loading-screen.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class LoadingScreenComponent {
    @Output() logout = new EventEmitter<void>();

    async onLogout(): Promise<void> {
        this.logout.emit();
    }
}
