<cna-loading-content
    [loading]="loading$ | async"
    [loadingInfo]="
        (showPdfLoadingInfo$ | async)
            ? 'Ihr PDF wird erstellt. Bitte haben Sie etwas Geduld.'
            : ''
    "
>
    <div class="course-list-container">
        <div *ngIf="courseParticipation$ | async as courseParticipation">
            <cna-page-heading
                [pageHeadingTitle]="
                    courseParticipation.bookedCourse.type ===
                    CourseType.EducationCourse
                        ? 'Ausbildung zum Fachanwalt'
                        : courseParticipation.bookedCourse.targetReadingTime +
                          '-stündige Fortbildung im Selbststudium'
                "
                [pageHeadingSubTitle]="
                    courseParticipation.bookedCourse.description
                "
            ></cna-page-heading>
            <cna-module-overview
                [courseParticipation]="courseParticipation"
                (selectModule)="navigateToModule($event)"
                (selectRepetitorium)="navigateToRepetitorium()"
                (printModule)="printModule($event)"
                (printRepetitorium)="printRepetitorium($event)"
            ></cna-module-overview>
            <cna-bibliography-accordion
                *ngIf="courseParticipation.bookedCourse.bibliography"
                [bibliography]="courseParticipation.bookedCourse.bibliography"
            ></cna-bibliography-accordion>
        </div>
    </div>
</cna-loading-content>
