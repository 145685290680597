import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadCourseParticipationInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';
import { selectCustomersCourseParticipation } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';
import { selectCourseParticipationId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { addBreadcrumbsUnique } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';

@Component({
    selector: 'cna-book-course-extension-success-view',
    templateUrl: './book-course-extension-success-view.component.html',
    styleUrls: ['./book-course-extension-success-view.component.css'],
})
export class BookCourseExtensionSuccessViewComponent implements OnInit {
    validUntil;

    constructor(private store: Store) {}

    setBreadcrumbs() {
        this.store
            .select(selectCourseParticipationId)
            .subscribe(cpId => {
                if (cpId) {
                    this.store.dispatch(
                        addBreadcrumbsUnique({
                            breadcrumbs: [
                                {
                                    label: 'Anmeldungsergebnis',
                                    routerLink: cpId + '/bookextension/success',
                                },
                            ],
                        })
                    );
                }
            })
            .unsubscribe();
    }

    ngOnInit(): void {
        this.setBreadcrumbs();
        this.store.dispatch(loadCourseParticipationInfos());

        this.store
            .select(selectCustomersCourseParticipation)
            .subscribe(x => {
                this.validUntil = x.validUntil;
            })
            .unsubscribe();
    }
}
