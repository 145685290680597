import { createAction, props } from '@ngrx/store';
import {
    ModuleDocument,
    ReadStatus,
    TimeExpenditure,
} from '@cna/projects/ak-jura/shared/interfaces';
import type { UUID } from '@cna/shared/generic-types';
import { LearningModuleDocumentInfo } from '../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/learning-module-document-info';

export const skipped = createAction('[global] skipped');

export const setVersion = createAction(
    '[global] set version',
    props<{ version: number }>()
);

/**
 * Module Info Actions
 */
export const loadModuleInfos = createAction('[global] load Module Infos');

export const loadModuleInfosSuccess = createAction(
    '[global] load Module Infos success',
    props<{
        moduleInfos: LearningModuleDocumentInfo[];
        updated: boolean;
    }>()
);
export const loadModuleInfosFailure = createAction(
    '[global] load Module Infos Failure'
);

/**
 * Load Module Content
 */
export const loadModuleDocument = createAction('[global] load Module Document');

export const loadModuleDocumentSuccess = createAction(
    '[global] load Module Document success',
    props<{ moduleDocument: ModuleDocument | undefined }>()
);
export const loadModuleDocumentFailure = createAction(
    '[global] load Module Document failure',
    props<{ error: Error }>()
);
export const loadModuleDocumentSkipped = createAction(
    '[global] load Module Document skipped'
);

/**
 Load TimeExpenditures
 */
export const loadTimeExpendituresForActualCourse = createAction(
    '[global] load timeExpenditures of Course'
);
export const loadTimeExpendituresForActualCourseSuccess = createAction(
    '[global] load timeExpenditures of Course success',
    props<{ timeExpenditures: TimeExpenditure[] }>()
);
export const loadTimeExpendituresForActualCourseFailure = createAction(
    '[global] load timeExpenditures of Course failure',
    props<{ error: Error }>()
);

/**
 Set Read Status
 */
export const setReadStatusForSection = createAction(
    '[global] set read status for Section',
    props<{ sectionId: UUID; readStatus: boolean }>()
);

export const setReadStatusForSectionsSuccess = createAction(
    '[global] set read status success',
    props<{ readStatus: ReadStatus[] }>()
);
export const setReadStatusForSectionsFailure = createAction(
    '[global] set read status failure',
    props<{ error: Error }>()
);

export const closeRunningReadingSession = createAction(
    '[global] close running reading Session'
);
export const closeRunningReadingSessionSuccess = createAction(
    '[global] close running reading Session success'
);
export const closeRunningReadingSessionFailure = createAction(
    '[global] close running reading Session failure',
    props<{ error: Error }>()
);

/**
 * TimeExpenditure
 */
export const saveTimeExpenditureToLocalStore = createAction(
    '[global] save TimeExpenditure to local store',
    props<{ timeExpenditure: TimeExpenditure }>()
);
