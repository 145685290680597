import { ActionReducer, INIT, UPDATE } from '@ngrx/store';

export const MetaReducerLocalStorage = (
    reducer: ActionReducer<any>
): ActionReducer<any> => {
    return (state, action) => {
        if (action.type === INIT || action.type === UPDATE) {
            //const storageValue = localStorage.getItem('state');

            let storageValue1: any = localStorage.getItem('state1');
            //let storageValue2: any = localStorage.getItem('state2');
            let storageValue3: any = localStorage.getItem('state3');

            storageValue1 = JSON.parse(storageValue1);
            //storageValue2 = JSON.parse(storageValue2);
            storageValue3 = JSON.parse(storageValue3);

            const storageValueG = {
                breadcrumb: storageValue1?.breadcrumb,
                customer: storageValue1?.customer,
                entityCache: storageValue1?.entityCache,
                global: undefined, // storageValue2?.global,
                learningView: storageValue1?.learningView,
                notes: storageValue1?.notes,
                readingStyles: storageValue1?.readingStyles,
                router: storageValue3?.router,
                sidenav: storageValue1?.sidenav,
                exams: storageValue3?.exams,
                examinables: storageValue1?.examinables,
            };

            if (storageValueG) {
                try {
                    return storageValueG;
                } catch {
                    localStorage.removeItem('state1');
                    //localStorage.removeItem('state2');
                    localStorage.removeItem('state3');
                }
            }
        }
        const nextState = reducer(state, action);
        //localStorage.setItem('state', JSON.stringify(nextState));

        const state1 = {
            breadcrumb: nextState.breadcrumb,
            customer: nextState.customer,
            entityCache: nextState.entityCache,
            learningView: nextState.learningView,
            notes: nextState.notes,
            readingStyles: nextState.readingStyles,
            sidenav: nextState.sidenav,
            examinables: nextState.examinables,
        };

        /** const state2 = {
            global: nextState.global,
        } */

        // console.log('state', JSON.stringify(state2));

        const state3 = {
            // router: nextState.router,
            exams: nextState.exams,
        };

        localStorage.setItem('state1', JSON.stringify(state1));
        // localStorage.setItem('state2', JSON.stringify(state2))
        localStorage.setItem('state3', JSON.stringify(state3));

        return nextState;
    };
};
