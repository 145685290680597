<div>
    <div
        *ngIf="notesOverview"
        style="margin-top: 50px"
        class="adelle-pe-25 font-weight-400 blue-color"
    >
        <span
            *ngIf="
                (courseParticipation$ | async).bookedCourse.type !==
                'TRAINING_COURSE'
            "
        >
            <span *ngIf="moduleName !== 'Repetitorium'">
                MODUL
                {{ moduleName | numberToNumbering: 'upperRomanNumerals' }}
            </span>
            <span *ngIf="moduleName === 'Repetitorium'">
                {{ moduleName }}
            </span>
        </span>

        <span
            *ngIf="
                (courseParticipation$ | async).bookedCourse.type ===
                'TRAINING_COURSE'
            "
        >
            KURS</span
        >
    </div>

    <div>
        <p
            *ngIf="notes?.length == 0"
            class="adelle-sans-17 dark-gray font-weight-300 m-0 py-3"
        >
            Noch keine Notizen für {{ moduleName }} vorhanden
        </p>
    </div>

    <div *ngIf="notes">
        <div style="margin-bottom: 90px">
            <div
                class="d-flex flex-row justify-content-end"
                *ngIf="notes?.length != 0"
            >
                <p
                    class="adelle-sans-12 letterSpacing-08 font-weight-600 text-uppercase dark-gray"
                    style="width: 325px"
                >
                    <span *ngIf="moduleName !== 'Repetitorium'">Kapitel</span>
                    <span *ngIf="moduleName === 'Repetitorium'">Frage</span>
                </p>
            </div>

            <div>
                <!--accordion-->
                <ngb-accordion
                    activeIds="ngb-panel-0"
                    id="notes-accordion"
                    class="note"
                >
                    <!--chapters-->
                    <ngb-panel *ngFor="let note of notes">
                        <ng-template ngbPanelTitle>
                            <div
                                class="d-flex flex-row justify-content-between"
                                style="min-height: 60px; gap: 15px"
                            >
                                <div class="d-flex flex-row" style="flex: auto">
                                    <div class="d-flex align-items-center">
                                        <img
                                            src="assets/Pfeil_Accordion-unten.svg"
                                            alt="acc-arrow"
                                            style="
                                                width: 20px;
                                                height: 10.91px;
                                                opacity: 50%;
                                            "
                                            class="acc-arrow"
                                        />
                                    </div>
                                    <p
                                        class="ebene1-title adelle-sans-17 font-weight-600 dark-gray"
                                    >
                                        {{ note.title }}
                                    </p>
                                </div>
                                <div
                                    class="d-flex flex-row justify-content-between"
                                    style="width: 325.17px; flex: none"
                                >
                                    <div
                                        class="text-left adelle-sans-14 font-weight-300 dark-gray d-flex flex-row align-items-baseline"
                                        *ngIf="
                                            note.chapterNumbering &&
                                            note.chapterNumbering.length >= 2
                                        "
                                        [routerLink]="[
                                            '/',
                                            note.courseParticipationId,
                                            'learning',
                                            'moduleOverview',
                                            note.moduleId,
                                            'readingView',
                                            note.sectionId
                                        ]"
                                    >
                                        <span>
                                            {{ note.chapterNumbering[0] }}
                                        </span>
                                        <div style="margin: 15px 6px">
                                            <cna-chapter-circle
                                                chapter="{{
                                                    note.chapterNumbering[1]
                                                }}"
                                            ></cna-chapter-circle>
                                        </div>
                                        <span>
                                            {{ note.chapterNumbering[2] }}
                                        </span>
                                    </div>
                                    <div
                                        class="text-left adelle-sans-14 font-weight-300 dark-gray d-flex flex-row align-items-baseline"
                                        *ngIf="
                                            note.chapterNumbering &&
                                            note.chapterNumbering.length < 2 &&
                                            note.chapterNumbering.length > 0
                                        "
                                        [routerLink]="[
                                            '/',
                                            note.courseParticipationId,
                                            'learning',
                                            'repetitorium',
                                            note.favoriteGroupId
                                        ]"
                                    >
                                        <div style="margin: 15px 6px">
                                            <cna-chapter-circle
                                                chapter="{{
                                                    note.chapterNumbering[0]
                                                }}"
                                            ></cna-chapter-circle>
                                        </div>
                                    </div>
                                    <div
                                        class="text-right d-flex flex-row justify-content-around align-items-center"
                                        style="margin-right: 13px"
                                    >
                                        <div (click)="open($event, note)">
                                            <img
                                                id="edit"
                                                alt="edit"
                                                src="assets/bearbeiten.svg"
                                                style="
                                                    width: 20px;
                                                    height: 20px;
                                                "
                                            />
                                        </div>
                                        <div (click)="open($event, note)">
                                            <img
                                                id="close"
                                                alt="close"
                                                src="assets/trash.svg"
                                                style="
                                                    width: 20px;
                                                    height: 20px;
                                                    margin-left: 13px;
                                                "
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="d-flex flex-row">
                                <p
                                    class="adelle-sans-17 font-weight-300 dark-gray notes-modul-text"
                                >
                                    {{ note.description }}
                                </p>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
            <div style="margin-top: 53px" *ngIf="notes.length !== 0">
                <cna-gradient-button
                    [notgradient]="true"
                    [disabled]="false"
                    buttonTitle="Notizen exportieren"
                    (click)="exportNotes(notes)"
                ></cna-gradient-button>
            </div>
        </div>
    </div>
</div>
