import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Examinable } from '@cna/projects/ak-jura/shared/interfaces';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import {
    selectCourseParticipationId,
    selectCurrentRoute,
    selectReadingId,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { LearningModuleDocumentInfo } from '../../../../../../shared/interfaces/src/lib/learning-module-document-info';
import { ModuleDocumentHelperService } from '../../../../../shared-components/src/lib/services/module-document-helper.service';
import { selectModuleExaminables } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/examinables/selectors';

import { takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { setNavInsideSidenav } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/actions';
import type { UUID } from '@cna/shared/generic-types';

@Component({
    selector: 'cna-learning-module-accordion-sidenav',
    templateUrl: './learning-module-accordion-sidenav.component.html',
    styleUrls: ['./learning-module-accordion-sidenav.component.scss'],
})
export class LearningModuleAccordionSidenavComponent
    implements OnInit, OnDestroy, OnChanges
{
    @Input() moduleDocumentInfo: LearningModuleDocumentInfo;
    @Input() sidebar: boolean;

    public examinables: Examinable[];
    destroy$ = new Subject<void>();
    info = {};
    openChapterPanelNames = '';
    openSectionPanel = '';

    currentReadingId: UUID;
    readingId$ = this.store.pipe(
        select(selectReadingId),
        takeUntil(this.destroy$),
        tap(readingId => {
            this.currentReadingId = readingId;
            this.openAccordionsByReadingId(readingId);
        })
    );

    constructor(
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        public moduleDocumentHelperService: ModuleDocumentHelperService
    ) {}

    ngOnInit(): void {
        this.store.dispatch(setNavInsideSidenav({ activeState: false }));

        this.store
            .select(selectModuleExaminables)
            .pipe(takeUntil(this.destroy$))
            .subscribe(examinables => {
                this.examinables = examinables;
                this.enhanceModuleDocumentInfo();
            });
    }

    ngOnChanges() {
        this.enhanceModuleDocumentInfo();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    enhanceModuleDocumentInfo() {
        if (this.moduleDocumentInfo) {
            this.moduleDocumentInfo = {
                ...this.moduleDocumentInfo,
                progress:
                    this.moduleDocumentHelperService.getPercentageReadForNodeModul(
                        this.moduleDocumentInfo
                    ),
                chapters: this.moduleDocumentInfo.chapters.map(chapter => ({
                    ...chapter,
                    progress:
                        this.moduleDocumentHelperService.getPercentageReadForNodeChapter(
                            chapter
                        ),
                    sections: chapter.sections.map(section => ({
                        ...section,
                        progress: section.sections
                            ? this.moduleDocumentHelperService.getPercentageReadForNodeSection(
                                  section
                              )
                            : this.moduleDocumentHelperService.getPercentageReadForNode(
                                  section
                              ),
                        isExaminable:
                            this.moduleDocumentHelperService.getNodeIncludesExaminables(
                                section,
                                this.examinables
                            ),
                        sections: section.sections
                            ? section.sections.map(subSection => ({
                                  ...subSection,
                                  progress:
                                      this.moduleDocumentHelperService.getPercentageReadForNodeSection(
                                          subSection
                                      ),
                                  isExaminable:
                                      this.moduleDocumentHelperService.getNodeIncludesExaminables(
                                          subSection,
                                          this.examinables
                                      ),
                              }))
                            : undefined,
                    })),
                })),
            };

            if (this.currentReadingId) {
                this.openAccordionsByReadingId(this.currentReadingId);
            }
        }
    }

    openedAccordionByUnread() {
        if (this.moduleDocumentInfo) {
            for (const i in this.moduleDocumentInfo.chapters) {
                if (this.moduleDocumentInfo.chapters[i].progress !== 100) {
                    this.openChapterPanelNames = `chapterPanel-${i}`;

                    for (const k in this.moduleDocumentInfo.chapters[i]
                        .sections) {
                        if (
                            this.moduleDocumentInfo.chapters[i].sections[k]
                                .progress !== 100
                        ) {
                            this.openSectionPanel = `section-panel-${i}-${k}`;
                            return;
                        }
                    }
                }
            }
        }
    }

    openAccordionsByReadingId(readingId: UUID) {
        if (this.moduleDocumentInfo) {
            for (const chapterIndex in this.moduleDocumentInfo.chapters) {
                if (this.moduleDocumentInfo.chapters) {
                    for (const sectionIndex in this.moduleDocumentInfo.chapters[
                        chapterIndex
                    ].sections) {
                        if (
                            this.moduleDocumentInfo.chapters[chapterIndex]
                                .sections
                        ) {
                            if (
                                this.moduleDocumentInfo.chapters[chapterIndex]
                                    .sections[sectionIndex].id === readingId
                            ) {
                                this.openChapterPanelNames = `chapterPanel-${chapterIndex}`;
                                this.openSectionPanel = `section-panel-${chapterIndex}-${sectionIndex}`;
                                return;
                            }

                            for (const subSectionIndex in this
                                .moduleDocumentInfo.chapters[chapterIndex]
                                .sections[sectionIndex].sections) {
                                if (
                                    this.moduleDocumentInfo.chapters[
                                        chapterIndex
                                    ].sections[sectionIndex].sections[
                                        subSectionIndex
                                    ].id === readingId
                                ) {
                                    this.openChapterPanelNames = `chapterPanel-${chapterIndex}`;
                                    this.openSectionPanel = `section-panel-${chapterIndex}-${sectionIndex}`;
                                    return;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    navigate(node) {
        if (this.sidebar) {
            this.store.dispatch(setNavInsideSidenav({ activeState: true }));
            this.store
                .select(selectCurrentRoute)
                .pipe(
                    withLatestFrom(
                        this.store.select(selectCourseParticipationId)
                    )
                )
                .subscribe(async ([route, cpId]) => {
                    //relativeTo did not work...
                    await this.router.navigate([
                        cpId,
                        'learning',
                        route.url[0].path,
                        route.url[1].path,
                        route.url[2].path,
                        node.id,
                    ]);
                })
                .unsubscribe();
        } else {
            this.router.navigate(['readingView', node.id], {
                relativeTo: this.route,
            });
        }
    }
}
