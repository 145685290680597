import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { Store } from '@ngrx/store';

import { resetErrorDescription } from './+state/error_describtion/actions';

import { setCourseParticipation } from './+state/course/actions';
import { selectSidenavActiveState } from './+state/sidenav/selectors';

import { EnvironmentService } from '../../../../../../libs/angular/services/src/lib/environment/environment.service';
import { selectErrorDescription } from './+state/selectors';

import { loadCourse, loadCustomer } from './+state/customer/actions';
import { selectCustomersCourses } from './+state/customer/selectors';

import { combineLatest, Observable, of, Subject } from 'rxjs';
import { InteractionStatus } from '@azure/msal-browser';
import { distinctUntilChanged, filter, take, takeUntil } from 'rxjs/operators';
import { selectCourseParticipationId } from './+state/router.selector';
import { ToasterService } from '../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/toaster.service';
import {
    OnScroll,
    ScrollService,
} from '@cna/projects/ak-jura/angular/my-ak-jura/scroll-module';

@Component({
    selector: 'cna-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent
    implements OnInit, AfterViewInit, OnDestroy, OnScroll
{
    @ViewChild('containerFluid') container: ElementRef;

    title = 'Mein AK JURA';
    _opened = false;

    // TODO show loading screen if data is not completely fetched
    storeLoadingState$: Observable<boolean> = of(false);

    errorDescription$: Observable<string> = this.store.select(
        selectErrorDescription
    );

    private readonly _destroying$ = new Subject<void>();

    constructor(
        private authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private router: Router,
        private store: Store,
        private environmentService: EnvironmentService,
        private toasterService: ToasterService,
        private scrollService: ScrollService
    ) {
        this.scrollService.setOnScrollListener(this);
    }

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        const activeAccount = this.authService.instance.getActiveAccount();
        if (
            !activeAccount &&
            this.authService.instance.getAllAccounts().length > 0
        ) {
            const accounts = this.authService.instance.getAllAccounts();
            this.authService.instance.setActiveAccount(accounts[0]);
        }
    }

    ngOnInit() {
        this.store.dispatch(loadCustomer());
        this.store.dispatch(loadCourse());

        this.environmentService.setEnvironment(environment);

        this.store
            .select(selectSidenavActiveState)
            .subscribe(val => (this._opened = val));

        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) =>
                        status === InteractionStatus.None
                ),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.checkAndSetActiveAccount();
            });

        this.router.events
            .pipe(takeUntil(this._destroying$))
            .subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.scrollService.scrollToTop();
                }
            });
    }

    ngAfterViewInit(): void {
        this.container.nativeElement.addEventListener('scroll', event =>
            document.dispatchEvent(new Event('scroll'))
        );
    }

    ngOnDestroy(): void {
        this._destroying$.next(null);
        this._destroying$.complete();
    }

    async hardReloadPage() {
        this.store.dispatch(resetErrorDescription());
        localStorage.removeItem('state1');
        localStorage.removeItem('state3');
        window.location.reload();
        window.location.assign('');
    }

    async logout() {
        localStorage.removeItem('state1');
        // localStorage.removeItem('state2');
        localStorage.removeItem('state3');
        this.authService.logoutRedirect();
    }

    async onScroll(x: number, y: number): Promise<void> {
        this.container.nativeElement.scroll(x, y);
    }
}
