import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { selectCustomer } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/customer/selectors';
import {
    editCustomer,
    loadCustomer,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/customer/actions';
import { CustomerBaseData } from '@cna/projects/ak-jura/shared/interfaces';
import { Validators as CustomValidators } from '@cna/projects/ak-jura/angular/shared-components';

@Component({
    selector: 'cna-edit-customer-popup',
    templateUrl: './edit-customer-popup.component.html',
    styleUrls: ['./edit-customer-popup.component.scss'],
})
export class EditCustomerPopupComponent implements OnInit {
    customer$: Observable<CustomerBaseData> = this.store.select(selectCustomer);

    salutations = ['', 'Frau', 'Herr'];

    customerForm = new FormGroup({
        title: new FormControl(''),
        salutation: new FormControl(
            this.salutations[0],
            CustomValidators.oneOf(this.salutations)
        ),
        firstName: new FormControl('', Validators.required),
        lastName: new FormControl('', Validators.required),
        address: new FormGroup({
            addressLine1: new FormControl('', Validators.required),
            postCode: new FormControl('', Validators.required),
            city: new FormControl('', Validators.required),
        }),
        phone: new FormControl(''),
        newsletter: new FormControl(false, Validators.required),
    });

    constructor(private store: Store, public activeModal: NgbActiveModal) {
        this.customer$.subscribe(customer => {
            if (customer) {
                this.customerForm.patchValue(customer);
            }
        });
    }
    onSaveClicked() {
        this.store.dispatch(
            editCustomer({ userData: this.customerForm.getRawValue() })
        );
        this.activeModal.close();
    }

    ngOnInit(): void {
        this.store.dispatch(loadCustomer());
    }
}
