import { createAction, props } from '@ngrx/store';
import { UUID } from '@cna/shared/generic-types';

export const loadContentSection = createAction(
    '[global] load section content'
    /** props<{
        moduleId: UUID,
        courseParticipationId: UUID,
        sectionId: UUID
    }>() */
);

export const loadContentSectionSuccess = createAction(
    '[global] load section content success ',
    props<{
        section: any;
    }>()
);

export const loadContentSectionFailure = createAction(
    '[global] load section content fail',
    props<{
        error: Error;
    }>()
);
