<nav
    aria-label="breadcrumb"
    class="fixed-breadcrumb"
    *ngIf="breadcrumbs$ | async as breadcrumbs"
>
    <ol class="breadcrumb">
        <ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
            <li
                *ngIf="i === breadcrumbs.length - 1"
                class="breadcrumb-item active"
                aria-current="page"
            >
                <a
                    [style]="{ 'line-height': '1.333' }"
                    class="adelle-sans-12 font-weight-300 dark-gray"
                    [routerLink]="breadcrumb.routerLink"
                    >{{ breadcrumb.label }}</a
                >
            </li>
            <li *ngIf="i !== breadcrumbs.length - 1" class="breadcrumb-item">
                <a
                    class="adelle-sans-12 font-weight-300 dark-gray"
                    [style]="{ 'line-height': '1.333' }"
                    [routerLink]="breadcrumb.routerLink"
                    >{{ breadcrumb.label }}</a
                >
            </li>
        </ng-container>
    </ol>
</nav>
