<div class="accordion-header" (click)="toggle()">
    <cna-collapse-icon [class.open]="isOpen"></cna-collapse-icon>
    <span> Quelle & Urheber </span>
</div>
<div
    class="accordion-content"
    [class.open]="isOpen"
>
    {{ bibliography }}
</div>
