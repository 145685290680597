import { fromEvent, interval, merge, of, Subject } from 'rxjs';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
    TIME_TRACKING_CONFIG,
    TimeTrackingConfig,
} from './time-tracking.config';
import {
    skipWhile,
    switchMap,
    take,
    takeUntil,
    tap,
    throttleTime,
} from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class InactivityService implements OnDestroy {
    private destroy$ = new Subject<void>();

    /**
     * An observable to track browser inactivity time.
     * It starts counting seconds on subscribe and resets the counter on a browser event.
     * @readonly
     */
    readonly inactiveTimer$ = merge(
        // Initial value to start counter on subscribe
        of(undefined),
        // Listen for activity events
        fromEvent(document, 'keypress'),
        fromEvent(document, 'click'),
        fromEvent(document, 'scroll'),
        fromEvent(document, 'mousemove')
    ).pipe(
        // Emit a maximum of one event each second to reduce load
        throttleTime(1000, undefined, { leading: true, trailing: true }),
        // On each received event start a new infinity counter
        switchMap(() => interval(1000)),
        // Every output is caught until the max inactive time is reached
        skipWhile(
            inactiveSeconds =>
                inactiveSeconds < this.timeTrackingConfig.maxInactiveTime
        ),
        // Complete the observable if the service is destroyed to prevent memory leaks
        takeUntil(this.destroy$)
    );

    constructor(
        @Inject(TIME_TRACKING_CONFIG)
        private timeTrackingConfig: TimeTrackingConfig
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
