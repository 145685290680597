import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import {
    selectCourseParticipationId,
    selectFavoriteGroupId,
    selectModuleId,
    selectReadingId,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { addNote } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/notes/actions';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import { Note } from '../../../../../../shared/interfaces/src';
import { Subject } from 'rxjs';

@Component({
    selector: 'cna-sidenav-notes',
    templateUrl: './sidenav-notes.component.html',
    styleUrls: ['./sidenav-notes.component.scss'],
})
export class SidenavNotesComponent implements OnInit, OnDestroy {
    addNoticeActive = false;
    moduleId: string;
    sectionId: string;
    courseParticipationId: string;
    favoriteGroupId: string;

    private _destroy$ = new Subject<void>();

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store
            .select(selectModuleId)
            .pipe(
                skipWhile(x => !x),
                takeUntil(this._destroy$)
            )
            .subscribe(moduleId => {
                this.moduleId = moduleId;
                this.favoriteGroupId = undefined;
            });
        this.store
            .select(selectReadingId)
            .pipe(skipWhile(x => !x))
            .subscribe(sectionId => {
                this.sectionId = sectionId;
                this.favoriteGroupId = undefined;
            });
        this.store
            .select(selectCourseParticipationId)
            .pipe(
                skipWhile(x => !x),
                takeUntil(this._destroy$)
            )
            .subscribe(
                courseParticipationId =>
                    (this.courseParticipationId = courseParticipationId)
            );
        this.store
            .select(selectFavoriteGroupId)
            .pipe(
                skipWhile(x => !x),
                takeUntil(this._destroy$)
            )
            .subscribe(favoriteGroupId => {
                this.favoriteGroupId = favoriteGroupId;
                this.moduleId = undefined;
                this.sectionId = undefined;
            });
    }

    createNotice(form: NgForm) {
        if (form.valid) {
            const newNote: Note = {
                title: form.value.noticeHeader,
                description: form.value.noticeContent,
                moduleId: this.moduleId,
                sectionId: this.sectionId,
                courseParticipationId: this.courseParticipationId,
                favoriteGroupId: this.favoriteGroupId,
            };

            this.store.dispatch(addNote({ note: newNote }));

            form.resetForm();
            this.changeAddNoticeState();
        }
    }

    changeAddNoticeState() {
        this.addNoticeActive = !this.addNoticeActive;
    }

    ngOnDestroy() {
        this._destroy$.next(undefined);
        this._destroy$.complete();
    }
}
