import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { selectRepetitorium } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/repetitorium/selectors';

import { selectSidenavActiveState } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/selectors';

export const TILES_AMOUNT = 10;

@Component({
    selector: 'cna-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.css'],
})
export class ProgressBarComponent implements OnInit, OnChanges {
    @Input() progressPercentage: number | boolean;

    sidebar$ = this.store.select(selectSidenavActiveState);

    tilesFilled = Array.from({ length: TILES_AMOUNT }).map(() => 0);

    constructor(private store: Store) {}

    calcFilledTiles() {
        if (typeof this.progressPercentage === 'boolean') {
            if (this.progressPercentage === true) {
                for (let i = 0; i < TILES_AMOUNT; i++) {
                    this.tilesFilled[i] = 10;
                }
            } else {
                for (let i = 0; i < TILES_AMOUNT; i++) {
                    this.tilesFilled[i] = 0;
                }
            }
        }
        if (typeof this.progressPercentage === 'number') {
            for (let i = 0; i < TILES_AMOUNT; i++) {
                if (this.progressPercentage - i * 10 >= 10) {
                    this.tilesFilled[i] = 10;
                } else if (
                    this.progressPercentage - i * 10 > 0 &&
                    this.progressPercentage - i * 10 < 10
                ) {
                    this.tilesFilled[i] = this.progressPercentage - i * 10;
                } else {
                    this.tilesFilled[i] = 0;
                }
            }
        }
    }

    ngOnInit(): void {
        this.calcFilledTiles();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.calcFilledTiles();
    }
}
