import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoneyModule } from '@cna/angular/commerce/money';
import { DashboardViewComponent } from './dashboard-view/dashboard-view.component';
import { SharedComponentsModule } from './../../../shared-components/src/lib/shared-components.module';
import { ExamsService } from './exams-view/exams.service';
import { LoginViewComponent } from './login-view/login-view.component';
import { ExamsViewComponent } from './exams-view/exams-view.component';
import { ExamSigninViewComponent } from './exam-signin-view/exam-signin-view.component';
import { ExamSigninSuccessViewComponent } from './exam-signin-success-view/exam-signin-success-view.component';
import { QuestionsAndSupportViewComponent } from './questions-and-support-view/questions-and-support-view.component';
import { LearnstateViewComponent } from '../../../learning/src/lib/learnstate-view/learnstate-view.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgLetModule } from '@ngrx-utils/store';
import { NotesViewComponent } from './notes-view/notes-view.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { BookCourseExtensionViewComponent } from './book-course-extension-view/book-course-extension-view.component';
import { BookCourseExtensionSuccessViewComponent } from './book-course-extension-success-view/book-course-extension-success-view.component';

@NgModule({
  imports: [
    CommonModule,
    SharedComponentsModule,
    NgbDropdownModule,
    NgLetModule,
    RouterModule,
    ReactiveFormsModule,
    MoneyModule,
  ],
    providers: [ExamsService],
    declarations: [
        DashboardViewComponent,
        LoginViewComponent,
        ExamsViewComponent,
        ExamSigninViewComponent,
        ExamSigninSuccessViewComponent,
        BookCourseExtensionViewComponent,
        BookCourseExtensionSuccessViewComponent,
        QuestionsAndSupportViewComponent,
        LearnstateViewComponent,
        NotesViewComponent,
    ],
    exports: [
        DashboardViewComponent,
        LoginViewComponent,
        ExamsViewComponent,
        ExamSigninViewComponent,
        BookCourseExtensionViewComponent,
        BookCourseExtensionSuccessViewComponent,
        ExamSigninSuccessViewComponent,
        QuestionsAndSupportViewComponent,
        LearnstateViewComponent,
    ],
})
export class PageViewsModule {}
