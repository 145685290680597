import { WoltersKluwerContent } from './wolters-kluwer-content';
import { ExternalContent } from '../external-content';
import { UUID } from '@cna/shared/generic-types';
import { Note } from '../note';
import { ReadStatus } from '../read-status';
import { OwnContent } from '../own-content';
import { TimeExpenditure } from '../time-expenditure';
import { Bibliography } from './bibliography';

/**
 * Represents a (sub) section inside a chapter
 */
export interface Section {
    /**
     * Universally unique identifier
     */
    id: UUID;
    /**
     * Title of a section
     */
    title: string;
    /**
     * Sections can have an arbitrary nesting depth of sub sections
     */
    sections?: Section[];
    /**
     * Optional: content associated within a section
     */
    content?: (WoltersKluwerContent | ExternalContent | OwnContent)[];
    /**
     * Optional: sections with content track the read status
     */
    readStatus?: ReadStatus;
    /**
     * Optional: notes associated with this section
     */
    notes?: Note[];
    /**
     * Optional: represents the time spent reading a section
     */
    timeExpenditure?: TimeExpenditure;
    /**
     * Optional: bibliography of a section
     */
    bibliographies?: Bibliography[];
    /**
     * Percentage of subNodes which are read
     */
    progress?: number;

    /**
     * time in Seconds that has been read, accumulated over the children
     */
    timeRead?: number;

    readable?: boolean;

    isExaminable?: boolean;
}

export function isSection(object: any): object is Section {
    return 'content' in object || 'sections' in object;
}
