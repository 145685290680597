import { Address } from '@cna/shared/interfaces';

export interface InvoiceAddress {
    /**
     * The addressee's salutation
     * max length 10 chars
     */
    salutation?: string | null;
    /**
     * The addressee's title
     * max length 25 chars
     */
    title?: string | null;
    /**
     * The addressee's company name
     * max length 50 chars
     */
    company?: string | null;
    /**
     * The addressee's first name
     * max length 50 chars
     */
    firstName?: string;
    /**
     * The addressee's last name
     * max length 50 chars
     */
    lastName?: string;
    /**
     * The addressee's mail address
     * max length 255 chars
     */
    mail: string;
    address: Address;
}

export function isInvoiceAddress(
    invoiceAddress: any
): invoiceAddress is InvoiceAddress {
    if (typeof invoiceAddress !== 'object') {
        return false;
    }
    return (
        'address' in invoiceAddress &&
        'addressLine1' in invoiceAddress.address &&
        'city' in invoiceAddress.address &&
        'postCode' in invoiceAddress.address &&
        'firstName' in invoiceAddress &&
        'lastName' in invoiceAddress &&
        'mail' in invoiceAddress
    );
}
