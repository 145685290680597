import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { updateNote } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/notes/actions';

@Component({
    selector: 'cna-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
    @Input() id;
    @Input() title;
    @Input() content;

    constructor(private store: Store, public activeModal: NgbActiveModal) {}

    ngOnInit(): void {}

    updateNote(noticeForm: NgForm) {
        const title = noticeForm.value.noteHeader;
        const content = noticeForm.value.noteContent;

        this.store.dispatch(
            updateNote({ id: this.id, title: title, content: content })
        );

        noticeForm.resetForm();
        this.activeModal.close();
    }
}
