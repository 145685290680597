import { ModuleDocumentHelperService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/module-document-helper.service';
import { SidenavContentType } from '../../../../../../../../libs/projects/ak-jura/angular/my-ak-jura/shared-components/src/lib/sidenav/sidenav.component';
import { ExamParticipation } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam-participation';
import { Exam } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam';
import { LearningModuleDocumentInfo } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/learning-module-document-info';
import {
    Examinable,
    ModuleDocument,
    Repetitorium,
    TimeExpenditure,
} from '@cna/projects/ak-jura/shared/interfaces';
import { ControlTest } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/control-test/control-test';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './actions';

export const EXAMS_STORE_NAME = 'exams';
const moduleDocumentHelperService = new ModuleDocumentHelperService();

export interface ExamState {
    examParticipations: ExamParticipation[];
    exams: Exam[];
    loading: boolean;
    errorDescription: string;
}

const initialState = {
    examParticipations: undefined,
    exams: undefined,
    loading: true,
    errorDescription: undefined,
};

const _examReducer = createReducer<ExamState>(
    initialState,

    /**
     * Load Exams
     */
    on(fromActions.loadExamParticipations, state => ({
        ...state,
        loading: true,
    })),
    on(fromActions.loadExamParticipationsSuccess, (state, action) => ({
        ...state,
        loading: false,
        examParticipations: action.examParticipations,
    })),
    on(fromActions.loadExamParticipationsFailure, state => ({
        ...state,
        loading: false,
    })),

    /**
     * Load Exams
     */
    on(fromActions.loadExams, state => ({
        ...state,
        loading: true,
    })),
    on(fromActions.loadExamsSuccess, (state, action) => ({
        ...state,
        loading: false,
        exams: action.exams,
    })),
    on(fromActions.loadExamsFailure, state => ({
        ...state,
        loading: false,
    })),

    on(fromActions.saveBookingExamSuccess, (state, action) => ({
        ...state,
        examParticipations: state.examParticipations.concat([
            action.examParticipation,
        ]),
    }))
);

export function examReducer(state, action) {
    return _examReducer(state, action);
}
