<div class="row exam-wrapper" style="column-gap: 118px">
    <div
        class="exam-date"
        *ngFor="let exam of availableExams$ | async; index as i"
    >
        <!-- <cna-exam-date [exam]="exam" [index]="i"></cna-exam-date> -->
        <div>
            <!-- <div *ngIf="alreadyBookedOnce(exam)"> TODO !! -->
            <div class="exam-container" style="width: 275px">
                <div class="col-16 mt-2" style="min-height: 400px">
                    <p class="adelle-pe-17 font-weight-600 mb-1 blue-color">
                        {{ exam.address.city }}
                        <br />
                        <span *ngIf="exam.examinationPeriods.length > 0; else description">
                            {{ examHelperService.getFromToDateString(exam) }}
                        </span>
                        <ng-template #description>
                            <span>{{ exam.description }}</span>
                        </ng-template>
                    </p>
                    <div>
                        <p
                            class="adelle-sans-17 font-weight-300 dark-gray"
                            style="margin-bottom: 40px"
                        >
                            {{ exam.address.locationName }}<br />
                            {{ exam.address.addressLine1 }}<br />
                            <span *ngIf="exam.address.addressLine2">
                                {{ exam.address.addressLine2 }}<br /> </span
                            >{{ exam.address.postCode }} {{ exam.address.city
                            }}<br />
                            {{ exam.address.stateOrProvince }}
                        </p>
                        <ng-container *ngIf="exam.examinationPeriods.length > 0">
                            <p
                                class="adelle-sans-17 font-weight-600 mb-0 dark-gray"
                            >
                                Prüfungszeiten
                            </p>
                            <div
                                *ngFor="
                                    let examinationPeriods of exam.examinationPeriods
                                "
                            >
                                <div class="d-flex">
                                    <div
                                        class="pl-0 adelle-sans-17 font-weight-300 dark-gray"
                                    >
                                        {{
                                            examHelperService.getDayName(
                                                examinationPeriods.date
                                            )
                                        }}
                                    </div>
                                    <div
                                        class="col-12 adelle-sans-17 font-weight-300 dark-gray px-2"
                                    >
                                        {{
                                            examHelperService.getFormattedTime(
                                                examinationPeriods.startTime
                                            )
                                        }}
                                        –
                                        {{
                                            examHelperService.getFormattedTime(
                                                examinationPeriods.endTime
                                            )
                                        }}
                                        Uhr
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div
                        class="mt-5"
                        *ngIf="exam.bookable"
                    >
                        <!-- *ngIf="alreadyBookedOnce(exam)" -->
                        <!-- *ngIf="passedCourse$ | async" -->
                        <a
                            class="d-flex flex-row align-items-center"
                            [routerLink]="['../examsignin', exam.id]"
                        >
                            <img
                                width=""
                                height="auto"
                                src="assets/mehr_grey_2.svg"
                            />
                            <span
                                class="font-weight-600 adelle-sans-11 text-uppercase dark-gray ml-2"
                                style="letter-spacing: 0.73px"
                                ><span>Buchen</span></span
                            >
                        </a>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>
</div>
