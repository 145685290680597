import { Injectable } from '@angular/core';
import { ToastType } from '../../../../../shared/interfaces/src/lib/toast-type.enum';

@Injectable({
    providedIn: 'root',
})
export class ToasterService {
    toasts: any[] = [];

    show(text: string, type: ToastType, delay?: number) {
        this.toasts.push({ text, type, delay });
    }

    remove(toast) {
        this.toasts = this.toasts.filter((t) => t !== toast);
    }
}
