import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'cna-loading-content',
    templateUrl: './loading-content.component.html',
    styleUrls: ['./loading-content.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class LoadingContentComponent {
    @Input() loading: boolean;
    @Input() loadingInfo?: string;
}
