import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
    CustomerBaseData,
    InvoiceAddress,
    isInvoiceAddress,
} from '@cna/projects/ak-jura/shared/interfaces';
import type { UUID } from '@cna/shared/generic-types';
import { filterNil } from '@cna/shared/helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { map, take, takeUntil } from 'rxjs/operators';
import { loadModuleInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/actions';
import { setBreadcrumbs } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';
import { saveCourseExtension } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course/actions';
import { loadCourseParticipationInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';
import {
    selectCustomersCourseParticipation,
    selectExtensionLoading,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';
import {
    loadCourse,
    loadCustomer,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/customer/actions';
import { selectCustomer } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/customer/selectors';
import { selectCourseParticipationId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { environment } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/environments/environment';
import { ToastType } from '../../../../../../shared/interfaces/src/lib/toast-type.enum';
import { ExamHelperService } from '../../../../../shared-components/src/lib/services/exam-helper.service';
import { ToasterService } from '../../../../../shared-components/src/lib/services/toaster.service';
import { EditCustomerPopupComponent } from '../../../../shared-components/src/lib/edit-customer-popup/edit-customer-popup.component';
import { EditInvoiceAddressModalComponent } from '../../../../shared-components/src/lib/edit-invoice-address-modal/edit-invoice-address-modal.component';

@Component({
    selector: 'cna-book-course-extension-view',
    templateUrl: './book-course-extension-view.component.html',
    styleUrls: ['./book-course-extension-view.component.scss'],
})
export class BookCourseExtensionViewComponent implements OnInit, OnDestroy {
    private _destroy$ = new Subject();
    private courseId: UUID;
    customer$: Observable<CustomerBaseData> = this.store
        .select(selectCustomer)
        .pipe(takeUntil(this._destroy$));

    customerValid$ = this.customer$.pipe(
        map(customer => {
            return (
                customer.salutation !== null &&
                customer.salutation !== undefined &&
                customer.firstName !== null &&
                customer.firstName !== undefined &&
                customer.lastName !== null &&
                customer.lastName !== undefined &&
                customer.email !== null &&
                customer.email !== undefined &&
                customer.address !== null &&
                customer.address !== undefined &&
                customer.address.addressLine1 !== null &&
                customer.address.addressLine1 !== undefined &&
                customer.address.postCode !== null &&
                customer.address.postCode !== undefined &&
                customer.address.city !== null &&
                customer.address.city !== undefined
            );
        })
    );

    /** moi */
    public extensionMonths;
    public netPrice;
    private taxRate = 19;
    private taxExemption = true;
    public grossPrice;
    public newOperationalTime;
    readonly price = 35;
    /** */

    invoiceAddress: InvoiceAddress;
    invoiceAddressChanged = false;

    public today = new Date();
    public courseHasEnded = false;
    public websiteHost = environment.frontendHost;

    course$ = this.store
        .select(selectCustomersCourseParticipation)
        .pipe(filterNil());

    checkCourseExtensionForm = new FormGroup({
        checkReadAGB: new FormControl(false, Validators.requiredTrue),
    });

    ExtensionMonthsForm = new FormGroup({
        em: new FormControl(1, Validators.requiredTrue),
    });

    loading$ = this.store.select(selectExtensionLoading);

    constructor(
        private store: Store,
        public examHelperService: ExamHelperService,
        private route: ActivatedRoute,
        private router: Router,
        public modalService: NgbModal,
        private http: HttpClient,
        private toasterService: ToasterService
    ) {
        this.route.params
            .subscribe(params => {
                if ('courseParticipationId' in params) {
                    this.courseId = params.courseParticipationId;
                }
            })
            .unsubscribe();
    }

    setBreadcrumbs() {
        this.store
            .select(selectCourseParticipationId)
            .subscribe(cpId => {
                if (cpId) {
                    this.store.dispatch(
                        setBreadcrumbs({
                            breadcrumbs: [
                                { label: 'Mein AK JURA', routerLink: cpId },
                                {
                                    label: 'Laufzeit verlängern',
                                    routerLink: cpId + '/bookextension',
                                },
                            ],
                        })
                    );
                }
            })
            .unsubscribe();
    }

    ngOnInit(): void {
        this.store.dispatch(loadModuleInfos());
        this.store.dispatch(loadCustomer());
        this.store.dispatch(loadCourse());
        this.store.dispatch(loadCourseParticipationInfos());

        this.extensionMonths = 1;
        this.calcPrices();
        this.calcNewOperationalTime();
        this.setBreadcrumbs();

        this.customer$.subscribe(
            customer =>
                (this.invoiceAddress = this.invoiceAddressChanged && this.invoiceAddress ? this.invoiceAddress : {
                    title: customer.title,
                    salutation: customer.salutation,
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    address: {
                        addressLine1: customer.address.addressLine1,
                        postCode: customer.address.postCode,
                        city: customer.address.city,
                    },
                    mail: customer.email,
                })
        );

        this.course$.subscribe(course => {
            this.courseHasEnded =
                Date.parse(course.validUntil) < this.today.getTime();
        });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    async openInvoiceAddressModal() {
        const modalRef = this.modalService.open(
            EditInvoiceAddressModalComponent,
            {
                backdrop: true,
                size: 'lg',
                keyboard: true,
                centered: true,
                windowClass: 'custom-modal',
            }
        );
        modalRef.componentInstance.invoiceAddress = this.invoiceAddress;
        try {
            const result = await modalRef.result;
            if (result && isInvoiceAddress(result)) {
                this.invoiceAddress = result;
                this.invoiceAddressChanged = true;
            }
        } catch (err) {
            // do not update invoice address on modal dismiss
            // everything stays like before
        }
    }
    // [routerLink]="['./../account']" [queryParams]="{ updateCustomer: true }"
    openCustomerAddressModal(event) {
        event?.stopPropagation();
        this.modalService.open(EditCustomerPopupComponent, {
            backdrop: true,
            size: 'lg',
            keyboard: true,
            centered: true,
            windowClass: 'custom-modal',
        });
    }

    invoiceAddressValid(): boolean {
        return (
            this.invoiceAddress.salutation !== null &&
            this.invoiceAddress.salutation !== undefined &&
            this.invoiceAddress.firstName !== null &&
            this.invoiceAddress.firstName !== undefined &&
            this.invoiceAddress.lastName !== null &&
            this.invoiceAddress.lastName !== undefined &&
            this.invoiceAddress.address !== null &&
            this.invoiceAddress.address !== undefined &&
            this.invoiceAddress.address.addressLine1 !== null &&
            this.invoiceAddress.address.addressLine1 !== undefined &&
            this.invoiceAddress.address.postCode !== null &&
            this.invoiceAddress.address.postCode !== undefined &&
            this.invoiceAddress.address.city !== null &&
            this.invoiceAddress.address.city !== undefined
        );
    }

    changeMonthValue(x: '+' | '-' | 'input') {
        switch (x) {
            case '+':
                if (this.extensionMonths < 12) {
                    this.extensionMonths++;
                }
                break;
            case '-':
                if (this.extensionMonths > 1) {
                    this.extensionMonths--;
                }
                break;
            case 'input':
                this.extensionMonths = Math.round(
                    this.ExtensionMonthsForm.value.em
                );
                break;
        }
        this.calcPrices();
        this.calcNewOperationalTime();
    }

    calcPrices() {
        this.netPrice = this.extensionMonths * this.price;
        if (this.taxExemption === false) {
            this.grossPrice =
                this.netPrice + this.netPrice * (this.taxRate / 100);
        } else if (this.taxExemption === true) {
            this.grossPrice = this.netPrice;
        }
    }

    calcNewOperationalTime() {
        this.course$.subscribe(x => {
            const performancePeriodFrom = new Date(
                Math.max(Date.now(), new Date(x.validUntil).getTime())
            );

            this.newOperationalTime = new Date(
                performancePeriodFrom.setMonth(
                    performancePeriodFrom.getMonth() + this.extensionMonths
                )
            );
        });
    }

    async extendCourse() {
        if (!(await this.customerValid$.pipe(take(1)).toPromise())) {
            this.toasterService.show(
                'Kontaktdaten vervollständigen',
                ToastType.error
            );
        } else if (!this.invoiceAddressValid()) {
            this.toasterService.show(
                'Bitte füllen Sie eine Rechnungsadresse aus',
                ToastType.error
            );
        } else if (!this.checkCourseExtensionForm.value.checkReadAGB) {
            this.toasterService.show(
                'Vertragsbestimmungen akzeptieren',
                ToastType.error
            );
        } else {
            this.store.dispatch(
                saveCourseExtension({
                    courseParticipationId: this.courseId,
                    extensionMonthsNumber: this.extensionMonths,
                    invoiceAddress: this.invoiceAddress,
                    costPerMonth: this.price,
                    vat: this.taxRate,
                })
            );
        }
    }
}
