import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { combineLatest, Subject } from 'rxjs';
import { map, skipWhile, takeUntil } from 'rxjs/operators';
import { log } from 'winston';
import { selectModuleNotesByModuleIdFromRouter } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/notes/selectors';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute, Router } from '@angular/router';
import { setBreadcrumbs } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';
import { ModuleDocumentHelperService } from '../../../../../shared-components/src/lib/services/module-document-helper.service';
import {
    selectCourseParticipationId,
    selectModuleId,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { NumberingService } from '../../../../../../../../angular/services/src/lib/numbering/numbering.service';
import {
    selectCurrentCourseType,
    selectCustomersCourseParticipation,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';
import { loadModuleInfosForModuleId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/module_infos/actions';
import {
    selectControlTestPassed,
    selectCurrentModuleSequentialNumber,
    selectModuleInfo,
    selectModuleLoading,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/module_infos/selectors';
import { loadCourseParticipationInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';
import {
    loadControlTestForActualCourse,
    resetControlTestValidationResult,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/controlTests/actions';
import { LearningModuleDocumentInfo } from '../../../../../../shared/interfaces/src/lib/learning-module-document-info';
import {
    CourseParticipation,
    CourseType,
} from '@cna/projects/ak-jura/shared/interfaces';
import { NumberToNumberingPipe } from '../../../../../../../../angular/ui/pipes/src/lib/number-to-numbering.pipe';
import { filterNil } from '@cna/shared/helper';

@Component({
    selector: 'cna-learning-module-overview-view',
    templateUrl: './learning-module-overview-view.component.html',
    styleUrls: ['./learning-module-overview-view.component.scss'],
})
export class LearningModuleOverviewViewComponent implements OnInit, OnDestroy {
    private _destroy$ = new Subject<void>();

    public loading$ = this.store.select(selectModuleLoading);
    public module$: Observable<LearningModuleDocumentInfo> = this.store
        .select(selectModuleInfo)
        .pipe(
            takeUntil(this._destroy$),
            skipWhile(v => !v)
        );
    public moduleName$: Observable<string> = this.module$.pipe(
        map(
            module =>
                'MODUL ' +
                this.numberingService.toUppercaseRomanNumbers(
                    module.sequentialNumber
                )
        )
    );
    public courseParticipation$: Observable<CourseParticipation> = this.store
        .select(selectCustomersCourseParticipation)
        .pipe(
            takeUntil(this._destroy$),
            skipWhile(v => !v)
        );
    public courseType$ = this.courseParticipation$.pipe(
        map(courseParticipation => courseParticipation?.bookedCourse?.type)
    );
    public courseName$ = this.courseParticipation$.pipe(
        map(courseParticipation => courseParticipation.bookedCourse.description)
    );

    public CourseType = CourseType;

    moduleDocumentInfo$: Observable<any> = this.store
        .select(selectModuleInfo)
        .pipe(
            skipWhile(mI => !mI),
            map(mI => {
                return mI;
            })
        );

    controlTestPassed$: Observable<boolean> = this.store
        .select(selectControlTestPassed)
        .pipe(takeUntil(this._destroy$));

    notes$ = this.store
        .select(selectModuleNotesByModuleIdFromRouter)
        .pipe(takeUntil(this._destroy$));

    skipLearning = false;

    @ViewChild('skipLearnTimeModal') skipLearnTimeModal: TemplateRef<any>;

    constructor(
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private moduleDocumentHelperService: ModuleDocumentHelperService,
        private numberingService: NumberingService,
        private numberToNumberingPipe: NumberToNumberingPipe,
        private modalService: NgbModal,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(loadCourseParticipationInfos());
        this.store.dispatch(loadModuleInfosForModuleId());
        this.setBreadcrumbs();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    setBreadcrumbs() {
        combineLatest([
            this.store.select(selectCourseParticipationId).pipe(filterNil()),
            this.store.select(selectModuleId).pipe(filterNil()),
            this.store.select(selectCurrentCourseType).pipe(filterNil()),
            this.store
                .select(selectCurrentModuleSequentialNumber)
                .pipe(filterNil()),
        ])
            .pipe(takeUntil(this._destroy$))
            .subscribe(
                ([
                    courseParticipationId,
                    moduleId,
                    courseType,
                    sequentialNumber,
                ]) => {
                    this.store.dispatch(
                        setBreadcrumbs({
                            breadcrumbs: [
                                {
                                    label: 'Mein AK JURA',
                                    routerLink: courseParticipationId,
                                },
                                {
                                    label: 'Lernstatus',
                                    routerLink:
                                        courseParticipationId +
                                        '/learning/courseOverview',
                                },
                                {
                                    label:
                                        courseType === CourseType.TrainingCourse
                                            ? 'Inhalt'
                                            : `MODUL ${this.numberToNumberingPipe.transform(
                                                  sequentialNumber,
                                                  'upperRomanNumerals'
                                              )}`,
                                    routerLink:
                                        courseParticipationId +
                                        '/learning/moduleOverview/' +
                                        moduleId,
                                },
                            ],
                        })
                    );
                }
            );
    }

    async startLearnControl(): Promise<void> {
        this.store.dispatch(resetControlTestValidationResult());
        this.store.dispatch(loadControlTestForActualCourse());
        await this.router.navigate(['learningControl'], {
            relativeTo: this.route,
        });
    }

    /** readStatus only in level section without subsection  */
    isModuleReadAndMarkedDone(): Observable<boolean> {
        return this.moduleDocumentInfo$.pipe(
            takeUntil(this._destroy$),
            map(mi => {
                let readChapter = 0;
                let allRelevantNodes = 0;
                for (let i = 0; i < mi.chapters.length; i++) {
                    for (let k = 0; k < mi.chapters[i].sections?.length; k++) {
                        if (mi.chapters[i].sections[k]?.readStatus?.read) {
                            readChapter++;
                            allRelevantNodes++;
                        } else {
                            for (
                                let j = 0;
                                j <
                                mi.chapters[i].sections[k]?.sections?.length;
                                j++
                            ) {
                                if (
                                    mi.chapters[i].sections[k]?.sections[j]
                                        .readStatus?.read
                                ) {
                                    readChapter++;
                                    allRelevantNodes++;
                                } else {
                                    allRelevantNodes++;
                                }
                            }
                        }
                    }
                }

                if (
                    readChapter === allRelevantNodes &&
                    mi.totalTimeRead >= mi.targetReadingTime
                ) {
                    return true;
                }
                if (
                    readChapter !== allRelevantNodes ||
                    mi.totalTimeRead >= mi.targetReadingTime
                ) {
                    return false;
                }
            })
        );
    }
}
