<div class="d-flex justify-content-start">
    <div *ngFor="let tileFilled of tilesFilled" class="text-center firstchild">
        <div *ngIf="tileFilled === 10">
            <div
                [ngClass]="
                    (sidebar$ | async)
                        ? 'progress-tile-sidebar'
                        : 'progress-tile'
                "
                class="progress-tile-filled"
            ></div>
        </div>
        <div *ngIf="tileFilled > 5 && tileFilled < 10">
            <div
                [ngClass]="
                    (sidebar$ | async)
                        ? 'progress-tile-sidebar'
                        : 'progress-tile'
                "
                class="progress-tile-half-filled"
            ></div>
        </div>
        <div *ngIf="tileFilled < 5">
            <div
                [ngClass]="
                    (sidebar$ | async)
                        ? 'progress-tile-sidebar'
                        : 'progress-tile'
                "
                class="progress-tile-not-filled"
            ></div>
        </div>
    </div>
</div>
