<a class="arrow-container" (click)="onClick()">
    <svg
        class="arrow"
        width="27px"
        height="15px"
        viewBox="0 0 27 15"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g stroke-width="1" fill-rule="evenodd">
            <g id="Elemente" transform="translate(-133.000000, -124.000000)">
                <g id="Group-7" transform="translate(133.000000, 124.000000)">
                    <polygon
                        id="Path-3"
                        transform="translate(22.500000, 7.500000) scale(-1, 1) translate(-22.500000, -7.500000)"
                        points="26.5 0 26.5 15 18.5 7.14530283"
                    ></polygon>
                    <line
                        x1="0.5"
                        y1="8"
                        x2="18.5"
                        y2="8"
                        id="Line-2"
                        stroke-linecap="square"
                    ></line>
                </g>
            </g>
        </g>
    </svg>
    <span class="label">
        <ng-container *ngIf="label; else content">{{ label }}</ng-container>
        <ng-template #content><ng-content></ng-content></ng-template>
    </span>
</a>
