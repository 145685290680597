import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LearningModuleDocumentInfo } from '../../../../../../shared/interfaces/src/lib/learning-module-document-info';
import { ModuleDocumentHelperService } from '../../../../../shared-components/src/lib/services/module-document-helper.service';
import { CourseType } from '@cna/projects/ak-jura/shared/interfaces';
import { UUID } from '@cna/shared/generic-types';

@Component({
    selector: 'cna-module-tile',
    templateUrl: './module-tile.component.html',
    styleUrls: ['./module-tile.component.scss'],
})
export class ModuleTileComponent {
    @Input()
    public learningModuleInfo: LearningModuleDocumentInfo;
    @Input()
    public courseType: CourseType;

    @Output()
    private selectModule = new EventEmitter<{ moduleId: UUID }>();
    @Output()
    private printModule = new EventEmitter<{ moduleId: UUID }>();

    CourseType = CourseType;

    constructor(
        public moduleDocumentHelperService: ModuleDocumentHelperService
    ) {}

    navigateOverview() {
        this.selectModule.emit({ moduleId: this.learningModuleInfo.id });
    }

    onPrintModule(event) {
        event.stopPropagation();
        this.printModule.emit({ moduleId: this.learningModuleInfo.id });
    }
}
