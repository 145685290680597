import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCurrentRoute } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { Observable } from 'rxjs/Observable';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'cna-questions-and-support-education-accordion',
    templateUrl: './questions-and-support-education-accordion.component.html',
    styleUrls: ['./questions-and-support-education-accordion.component.css'],
})
export class QuestionsAndSupportEducationAccordionComponent
    implements OnInit, OnDestroy
{
    private destroy$: Subject<any> = new Subject<any>();
    openSectionPanel = '';
    openAccordionDueParam$: Observable<string> = this.store
        .select(selectCurrentRoute)
        .pipe(
            takeUntil(this.destroy$),
            map(x => {
                this.openSectionPanel = x.queryParams.panel;
                return x;
            })
        );

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.openAccordionDueParam$.subscribe();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
