<div>
    <div class="d-flex justify-content-center" style="padding-top: 6rem"></div>
    <div class="d-flex justify-content-center pt-5">
        <div
            style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            "
        >
            <p class="adelle-sans-20 font-weight-400">
                Daten werden vom Server geladen, bitte warten...
            </p>
            <div class="btn-arr-wrapper" (click)="onLogout()">
                <div class="arrow">
                    <svg
                        width="27px"
                        height="15px"
                        viewBox="0 0 27 15"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                    >
                        <title>mehr_grey 2</title>
                        <g
                            id="Basics-Programmierung"
                            stroke="none"
                            stroke-width="1"
                            fill="none"
                            fill-rule="evenodd"
                        >
                            <g
                                id="Elemente"
                                transform="translate(-133.000000, -124.000000)"
                            >
                                <g
                                    id="Group-7"
                                    transform="translate(133.000000, 124.000000)"
                                >
                                    <polygon
                                        id="Path-3"
                                        fill="#FFFFFF"
                                        transform="translate(22.500000, 7.500000) scale(-1, 1) translate(-22.500000, -7.500000)"
                                        points="26.5 0 26.5 15 18.5 7.14530283"
                                    ></polygon>
                                    <line
                                        x1="0.5"
                                        y1="8"
                                        x2="18.5"
                                        y2="8"
                                        id="Line-2"
                                        stroke="#FFFFFF"
                                        stroke-linecap="square"
                                    ></line>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <a
                    style="margin-right: 12px"
                    class="font-weight-600 letterSpacing-073 adelle-sans-11 dark-gray text-uppercase text"
                    >Logout</a
                >
            </div>
        </div>
    </div>
</div>
