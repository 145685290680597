import { Component, Input, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { selectAllTimeExpendituresForActualCourseByMonth } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/selectors';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { TimeExpenditure } from '@cna/projects/ak-jura/shared/interfaces';
import * as _ from 'lodash';

@Component({
    selector: 'cna-learn-time-accordion',
    templateUrl: './learn-time-accordion.component.html',
    styleUrls: ['./learn-time-accordion.component.scss'],
})
export class LearnTimeAccordionComponent implements OnInit {
    @Input() selectedCategory: string;
    timeExpendituresByMonth$ = this.store
        .select(selectAllTimeExpendituresForActualCourseByMonth)
        .pipe(
            map(timeExpendituresByMonth => {
                const groupedTimeExpendituresByMonth = new Map<
                    string,
                    { date: string; duration: number }[]
                >();
                timeExpendituresByMonth.forEach((value, key) => {
                    groupedTimeExpendituresByMonth.set(
                        key,
                        value
                            .reduce<{ date: string; duration: number }[]>(
                                (grouped, te) => {
                                    const index = new Date(
                                        te.lastRead
                                    ).getDate();
                                    const previous = grouped[index] ?? {
                                        date: new Date(te.lastRead)
                                            .toISOString()
                                            .substr(0, 10),
                                        duration: 0,
                                    };

                                    grouped[index] = {
                                        ...previous,
                                        duration:
                                            previous.duration + te.duration,
                                    };
                                    return grouped;
                                },
                                []
                            )
                            .filter(v => !!v)
                            .sort(
                                (d1, d2) =>
                                    new Date(d1.date).getTime() -
                                    new Date(d2.date).getTime()
                            )
                    );
                });
                return groupedTimeExpendituresByMonth;
            })
        );

    constructor(private store: Store) {}

    ngOnInit(): void {}

    getProgressObservable(number): Observable<number> {
        return of(number);
    }

    getTotalTimeRead(monthTimeExpenditures: TimeExpenditure[]): number {
        return monthTimeExpenditures
            .map(te => te.duration)
            .reduce((a, b) => a + b);
    }
    public mapCompareFnc(a, b) {
        if (
            a &&
            a.length > 1 &&
            a[1].length > 1 &&
            b &&
            b.length > 1 &&
            b[1].length > 1
        ) {
            return Date.parse(a[1][0]) - Date.parse(b[1][0]);
        }
        return 0;
    }
}
