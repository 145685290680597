import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTrackingService } from './date-tracking.service';
import { TimeTrackingClientService } from './time-tracking-client.service';
import { TimeTrackingService } from './time-tracking.service';
import {
    TIME_TRACKING_CONFIG,
    TimeTrackingConfig,
} from './time-tracking.config';
import { InactivityService } from './inactivity.service';

@NgModule({
    imports: [CommonModule],
    providers: [
        TimeTrackingService,
        InactivityService,
        TimeTrackingClientService,
        DateTrackingService,
    ],
})
export class ActiveTimeTrackingModule {
    static forRoot(
        timeTrackingConfig: TimeTrackingConfig
    ): ModuleWithProviders<ActiveTimeTrackingModule> {
        return {
            ngModule: ActiveTimeTrackingModule,
            providers: [
                {
                    provide: TIME_TRACKING_CONFIG,
                    useValue: timeTrackingConfig,
                },
            ],
        };
    }
}
