<cna-app-toasts aria-live="polite" aria-atomic="true"></cna-app-toasts>
<ng-sidebar-container style="height: 100vh">
    <!-- A sidebar -->
    <ng-sidebar
        class="d-none d-lg-block color"
        mode="push"
        position="right"
        [(opened)]="_opened"
    >
        <cna-sidenav></cna-sidenav>
    </ng-sidebar>
    <ng-sidebar
        class="d-block d-lg-none"
        mode="push"
        position="right"
        [(opened)]="_opened"
    >
        <cna-sidenav></cna-sidenav>
    </ng-sidebar>

    <!-- Page content -->
    <div ng-sidebar-content>
        <div class="container-fluid" #containerFluid>
            <cna-header-navigation-row></cna-header-navigation-row>
            <cna-my-ak-jura-breadcrumbs></cna-my-ak-jura-breadcrumbs>
            <div *ngIf="!(errorDescription$ | async)" class="content">
                <cna-loading-screen
                    *ngIf="storeLoadingState$ | async; else page"
                    (logout)="logout()"
                ></cna-loading-screen>
                <ng-template #page>
                    <router-outlet></router-outlet>
                </ng-template>
            </div>
            <div *ngIf="errorDescription$ | async">
                <div
                    class="arrow-container justify-content-center col col-md-8"
                    style="padding-top: 6rem"
                >
                    <div class="row">
                        <div class="fs-2 mb-3 col-auto pl-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="5rem"
                                height="5rem"
                                fill="currentColor"
                                class="bi bi-bug"
                                viewBox="0 0 16 16"
                            >
                                <path
                                    d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z"
                                ></path>
                            </svg>
                        </div>

                        <div class="col">
                            <div class="adelle-sans-20 font-weight-600 pt-3">
                                Leider ist ein <br />
                                Fehler aufgetreten.
                            </div>
                        </div>
                    </div>
                    <div class="row pt-3">
                        <div class="adelle-sans-16 sans font-weight-600">
                            Fehlerbeschreibung: &nbsp;
                        </div>
                        <div class="adelle-sans-16 sans font-weight-300">
                            {{ errorDescription$ | async }}
                        </div>
                    </div>
                    <div class="row pt-4">
                        <cna-gradient-button
                            buttonTitle="Neu Laden"
                            (click)="hardReloadPage()"
                        ></cna-gradient-button>
                    </div>

                    <div class="row pt-5">
                        <span (click)="logout()">
                            <img
                                height="auto"
                                src="assets/mehr_grey 2.svg"
                                class="mr-2 mb-1"
                            />
                            <a
                                class="font-weight-600 letterSpacing-073 adelle-sans-11 dark-gray text-uppercase"
                                >Logout</a
                            >
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-sidebar-container>
