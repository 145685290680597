<ng-container *ngLet="readingStyles$ | async as readingStyles">
    <ng-container *ngLet="renderedText$ | async as renderedText">
        <div
            class="text-container"
            [ngClass]="
                readingStyles
                    ? [readingStyles.textWidth, readingStyles?.backgroundColor]
                    : []
            "
        >
            <div class="content">
                <cna-loading-content [loading]="loading$ | async">
                    <cna-reading-content
                        [content]="renderedText"
                        [readingStyles$]="readingStyles$"
                    ></cna-reading-content>
                </cna-loading-content>
            </div>
            <ng-container *ngLet="readingNode$ | async as readingNode">
                <ng-container *ngLet="nextReadingId$ | async as nextReadingId">
                    <cna-learning-footer
                        *ngLet="readStatus$ | async as readStatus"
                        [readStatus]="readStatus || false"
                        [isReadStatusDisabled]="isCourseDone$ | async"
                        [isRouteNextDisabled]="!(nextReadingId$ | async)"
                        [contactMailLink]="environment.authorNoteMailAddress"
                        (changeReadStatus)="setReadStatus(readingNode, $event)"
                        (routeNext)="routeToId(nextReadingId)"
                        (routeOverview)="routeToOverview()"
                        (print)="printContent(renderedText)"
                    ></cna-learning-footer>
                </ng-container>
            </ng-container>
        </div>
    </ng-container>
</ng-container>
