import { createAction, props } from '@ngrx/store';
import { ModuleNotes, Note } from '@cna/projects/ak-jura/shared/interfaces';
import { UUID } from '@cna/shared/generic-types';

/**
 Extract notes
 */
export const extractNotesForCourse = createAction(
    '[notes] extract notes of Course'
);
export const extractNotesForCourseSuccess = createAction(
    '[notes] extract notes of Course success',
    props<{
        notes: ModuleNotes[];
    }>()
);
export const extractNotesForCourseFailure = createAction(
    '[notes] extract notes of Course failure',
    props<{ error: Error }>()
);

/**
 * Add Note
 */
export const addNote = createAction(
    '[notes] add new Note',
    props<{ note: Note }>()
);
export const addNoteSuccess = createAction(
    '[notes] add new Note successful',
    props<{ note: Note; courseParticipationId: UUID }>()
);
export const addNoteFailure = createAction(
    '[notes] add new Note failed',
    props<{ err: Error }>()
);

export const setActualNumberingArray = createAction(
    '[notes] set actual numbering array',
    props<{ numbering: string[] }>()
);

/**
 * Update Note
 */
export const updateNote = createAction(
    '[notes] update Note',
    props<{ id: string; title: string; content: string }>()
);
export const updateNoteSuccess = createAction(
    '[notes] update Note successful',
    props<{ note: Note }>()
);
export const updateNoteFailure = createAction(
    '[notes] update Note failed',
    props<{ err: Error }>()
);

/**
 * delete Note
 */
export const deleteNote = createAction(
    '[notes] delete Note',
    props<{ noteId: string }>()
);
export const deleteNoteSuccess = createAction(
    '[notes] delete Note successful',
    props<{ noteId: any }>()
);
export const deleteNoteFailure = createAction(
    '[notes] delete Note failed',
    props<{ err: Error }>()
);

/**
 * Select nodes
 */
export const loadNotes = createAction(
    '[notes] load notes for current course participation'
);

export const loadNotesSuccess = createAction(
    '[notes] load notes for current course participation success',
    props<{ moduleNotes: ModuleNotes[]; repNotes: Note[] }>()
);

export const loadNotesFailure = createAction(
    '[notes] load notes for current course participation failure',
    props<{ error: Error }>()
);
