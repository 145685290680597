<div id="header" *ngIf="!sidebar">
    <div class="repetitorium-header">
        <div class="adelle-sans-12 letterSpacing-08 font-weight-400">
            LERNFORTSCHRITT
        </div>
    </div>
</div>

<div class="row" id="" *ngIf="!sidebar">
    <div
        class="repetitorium-progress"
        style="min-height: 52px; background-color: #f5f5f5"
    >
        <div
            class="d-flex justify-content align-items-center adelle-sans-17 font-weight-600"
            style="margin-left: 14px"
        >
            Repetitorium
        </div>
        <div class="d-flex justify-content align-items-center">
            <span *ngIf="!sidebar">
                <cna-progress-bar [progressPercentage]="progress$ | async">
                </cna-progress-bar>
            </span>
            <span class="adelle-sans-14 dark-gray font-weight-300 ml-2">
                <!-- {{
                moduleDocumentHelperService.getPercentageReadForNode(
                    moduleDocumentInfo
                ) | number: '1.0-0'
                }}%-->
            </span>
        </div>
        <!-- <div *ngIf="!sidebar" class="col-1 fake-element"></div> -->
    </div>
</div>

<!-- -->

<div class="row" id="" *ngIf="sidebar">
    <div class="col-16 d-flex flex-row" style="min-height: 52px">
        Repetitorium
    </div>
</div>

<!-- -->

<ngb-accordion class="rep-accordion" animation="true">
    <ngb-panel
        *ngFor="let favoriteGroup of favoriteGroups$ | async; index as i"
    >
        <!--<ng-template ngbPanelHeader>{{ moduleName }}</ng-template>-->
        <ng-template ngbPanelHeader>
            <!--            <div class="d-md-block">-->
            <!--                <div-->
            <!--                    class="d-flex flex-row justify-content-between"-->
            <!--                    (click)="navigate(favoriteGroup)"-->
            <!--                >-->
            <!--                    <div class="d-flex flex-row">-->
            <!--                        <cna-chapter-circle-->
            <!--                            [chapter]="'' + (i + 1)"-->
            <!--                            [done]="favoriteGroup.workedOut?.workedOut"-->
            <!--                            style="margin-top: 8px; margin-bottom: 12px"-->
            <!--                        ></cna-chapter-circle>-->
            <!--                        <div-->
            <!--                            class="adelle-sans-17 black-color font-weight-600 mb-0"-->
            <!--                            style="-->
            <!--                                margin-left: 12px;-->
            <!--                                margin-top: 12px;-->
            <!--                                margin-bottom: 12px;-->
            <!--                            "-->
            <!--                        >-->
            <!--                            <span> {{ favoriteGroup.title }}a </span>-->
            <!--                        </div>-->
            <!--                    </div>-->

            <!--                    <div-->
            <!--                        (click)="navigate(favoriteGroup)"-->
            <!--                        style="-->
            <!--                            z-index: 1;-->
            <!--                            position: relative;-->
            <!--                            margin-top: 12px;-->
            <!--                            margin-bottom: 12px;-->
            <!--                        "-->
            <!--                    >-->
            <!--                        <div class="img-container">-->
            <!--                            <img src="assets/start.svg" class="img-before" />-->
            <!--                            <img-->
            <!--                                src="assets/start-hover.svg"-->
            <!--                                class="img-after"-->
            <!--                            />-->
            <!--                        </div>-->
            <!--                    </div>-->
            <!--                </div>-->
            <!--            </div>-->

            <div class="rep-row" (click)="navigate(favoriteGroup)">
                <cna-chapter-circle
                    [chapter]="'' + (i + 1)"
                    [done]="favoriteGroup.workedOut?.workedOut"
                ></cna-chapter-circle>
                <p>{{ favoriteGroup.title }}</p>
                <cna-start-icon></cna-start-icon>
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
