<div class="footer-container">
    <cna-checkbox-input
        label="Ich habe die Lerneinheit gelesen und erarbeitet."
        [checked]="readStatus"
        [disabled]="isReadStatusDisabled"
        (changeEvent)="onChange($event)"
    ></cna-checkbox-input>

    <div class="button-bar">
        <cna-gradient-button
            notgradient="true"
            buttonTitle="Nächster Lerninhalt"
            (click)="onRouteNext()"
            [disabled]="isRouteNextDisabled"
        ></cna-gradient-button>
        <cna-gradient-button
            notgradient="true"
            buttonTitle="Zur Übersicht"
            (click)="onRouteOverview()"
            [disabled]="isRouteOverviewDisabled"
        ></cna-gradient-button>
    </div>
</div>
<div class="link-bar">
    <a (click)="onPrint()">Drucken</a>
    <a [href]="contactMailLink">Hinweis an Autor</a>
</div>
