import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as globalActions from './actions';
import {
    catchError,
    filter,
    map,
    mergeMap,
    skipWhile,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { selectActualCourseId } from '../course/selectors';
import {
    selectCourseParticipationId,
    selectFavoriteGroupId,
} from '../router.selector';
import { forkJoin, of, zip } from 'rxjs';
import { Note, Repetitorium } from '@cna/projects/ak-jura/shared/interfaces';
import { environment } from '../../../environments/environment';
import { FavoriteGroupProgress } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/favorite-group-progress';
import * as errorDescribtionActions from '../error_describtion/actions';
import { ToastType } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/toast-type.enum';
import { Injectable } from '@angular/core';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { LearningModuleDocumentInfo } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/learning-module-document-info';
import { Exam } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleDocumentHelperService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/module-document-helper.service';
import { ToasterService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/toaster.service';
import {
    selectCourseParticipationState,
    selectCustomersCourseParticipationBookedCourseId,
} from '../course_participation/selectors';

@Injectable()
export class RepetitoriumEffects {
    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private entityServices: EntityServices,
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private moduleDocumentHelperService: ModuleDocumentHelperService,
        private toasterService: ToasterService
    ) {}

    /**
     * Load Repetitorium for actual Course
     */
    loadRepetitoriumForActualCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(globalActions.loadRepetitoriumForActualCourse),
            mergeMap(action => {
                return zip(
                    of(action),
                    this.store
                        .select(
                            selectCustomersCourseParticipationBookedCourseId
                        )
                        .pipe(skipWhile(c => !c)),
                    this.store
                        .select(selectCourseParticipationId)
                        .pipe(skipWhile(cpId => !cpId))
                );
            }),
            mergeMap(([action, actualCourseId, courseParticipationId]) => {
                if (actualCourseId) {
                    return forkJoin({
                        repetitorium: this.http.get<Repetitorium>(
                            environment.apiHost + '/repetitorium',
                            {
                                params: {
                                    courseId: actualCourseId,
                                },
                            }
                        ),
                        workedOutStats: this.http.get<FavoriteGroupProgress[]>(
                            environment.apiHost +
                                '/favorite-group/course-participation/' +
                                courseParticipationId
                        ),
                    }).pipe(
                        map(values => {
                            return {
                                ...values.repetitorium,
                                favoriteGroups:
                                    values.repetitorium.favoriteGroups
                                        .map(favoriteGroup => {
                                            const temp =
                                                values.workedOutStats.find(
                                                    workedOutState =>
                                                        workedOutState.favoriteGroupId ===
                                                        favoriteGroup.id
                                                );
                                            return {
                                                ...favoriteGroup,
                                                sort: Number(
                                                    favoriteGroup.title.split(
                                                        '.'
                                                    )[0]
                                                ),
                                                workedOut: temp
                                                    ? temp
                                                    : undefined,
                                            };
                                        })
                                        .sort(
                                            (fg1, fg2) => fg1.sort - fg2.sort
                                        ),
                            };
                        })
                    );
                }
            }),
            map(repetitorium =>
                globalActions.loadRepetitoriumForActualCourseSuccess({
                    repetitorium: repetitorium,
                })
            ),
            catchError(error => {
                return of(
                    globalActions.loadRepetitoriumForActualCourseFailure(error)
                );
            })
        );
    });

    loadRepetitoriumForActualCourseFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.loadRepetitoriumForActualCourseFailure),
                tap(error => {
                    this.store.dispatch(
                        errorDescribtionActions.setErrorDescription({
                            errorDescription:
                                'Die Daten zum Repetitorium des ausgewählten Kurses konnten nicht vom Server geladen werden.',
                        })
                    );
                })
            );
        },
        { dispatch: false }
    );

    /**
     * Set / Update Read Status for Repetitorium Favorite Group
     */
    setReadStatusForRepetitorium$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(globalActions.setReadStatusForRepetitorium),
            withLatestFrom(
                this.store.select(selectFavoriteGroupId),
                this.store.select(selectCourseParticipationId)
            ),
            switchMap(([action, favoriteGroupId, courseParticipationId]) =>
                this.http.post(
                    environment.apiHost +
                        '/favorite-group/' +
                        favoriteGroupId +
                        '/course-participation/' +
                        courseParticipationId,
                    {
                        workedOut: true,
                    }
                )
            ),
            map(rs =>
                globalActions.setReadStatusForRepetitoriumSuccess({
                    readStatus: rs,
                })
            ),
            catchError(error =>
                of(
                    globalActions.setOrUpdateReadStatusForRepetitoriumFailure({
                        error,
                    })
                )
            )
        );
    });

    updateReadStatusForRepetitorium$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(globalActions.updateReadStatusForRepetitorium),
            switchMap(action =>
                this.http.patch(
                    environment.apiHost +
                        '/favorite-group/progress/' +
                        action.progressId,
                    {
                        workedOut: action.workedOut,
                    }
                )
            ),
            map(rs =>
                globalActions.setReadStatusForRepetitoriumSuccess({
                    readStatus: rs,
                })
            ),
            catchError(error =>
                of(
                    globalActions.setOrUpdateReadStatusForRepetitoriumFailure({
                        error,
                    })
                )
            )
        );
    });

    setReadStatusForRepetitoriumSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.setReadStatusForRepetitoriumSuccess),
                tap(action => {
                    this.toasterService.show(
                        'Status erfolgreich aktualisiert',
                        ToastType.success
                    );
                })
            );
        },
        { dispatch: false }
    );

    setReadStatusForRepetitoriumFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    globalActions.setOrUpdateReadStatusForRepetitoriumFailure
                ),
                tap(() => {
                    this.toasterService.show(
                        'Der Status für das aktuelle Kapitel konnte nicht gespeichert werden, bitte versuchen Sie es später noch einmal!',
                        ToastType.error
                    );
                })
            ),
        { dispatch: false }
    );
}
