import { createReducer, on } from '@ngrx/store';
import * as fromCourseActions from '../course/actions';
import * as fromActions from './actions';

export const COURSE_PARTICIPATION_STORE_NAME = 'course_participation';

export interface CourseParticipationState {
    loading: boolean;
    extensionLoading: boolean;
    error: any;
    course_participation: any;
    course_participations: any;
}

const initialState = {
    loading: false,
    extensionLoading: false,
    error: undefined,
    course_participation: undefined,
    course_participations: undefined,
};

const _courseParticipationReducer = createReducer<CourseParticipationState>(
    initialState,

    /** only one */
    on(fromActions.loadCourseParticipationInfos, state => ({
        ...state,
        loading: true,
    })),

    on(fromActions.loadCourseParticipationInfosSuccess, (state, action) => ({
        ...state,
        course_participation: action.courseParticipation,
        loading: false,
    })),

    on(fromActions.loadCourseParticipationInfosFailure, state => ({
        ...state,
        loading: false,
    })),

    /** all */
    on(fromActions.loadAllCourseParticipationInfo, state => ({
        ...state,
        loading: true,
    })),

    on(fromActions.loadAllCourseParticipationInfoDetails, state => ({
        ...state,
        loading: true,
    })),

    on(
        fromActions.loadAllCourseParticipationInfoDetailsSuccess,
        (state, action) => ({
            ...state,
            course_participations: action.courseParticipations,
            loading: false,
        })
    ),

    on(fromActions.loadAllCourseParticipationInfoDetailsFailure, state => ({
        ...state,
        loading: false,
    })),

    on(fromActions.loadAllCourseParticipationInfoFailure, state => ({
        ...state,
        loading: false,
    })),

    on(fromCourseActions.saveCourseExtension, state => ({
        ...state,
        extensionLoading: true,
    })),

    on(fromCourseActions.saveCourseExtensionSuccess, (state, action) => ({
        ...state,
        course_participations: state.course_participations?.map(cp =>
            cp.id === action.courseParticipationId
                ? {
                      ...cp,
                      validUntil: action.newValidUntil,
                  }
                : cp
        ),
        course_participation:
            state.course_participation.id === action.courseParticipationId
                ? {
                      ...state.course_participation,
                      validUntil: action.newValidUntil,
                  }
                : state.course_participation,
        extensionLoading: false,
    })),

    on(fromCourseActions.saveCourseExtensionFailure, state => ({
        ...state,
        extensionLoading: false,
    }))
);

export function courseParticipationReducer(state, action) {
    return _courseParticipationReducer(state, action);
}
