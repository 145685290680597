<div *ngLet="readingId$ | async as readingId">
    <div
        class="d-flex flex-row justify-content-between align-items-baseline"
        style="min-height: 69px; width: 100%"
    >
        <div
            class="dark-gray d-flex flex-row font-weight-600 align-items-center adelle-sans-14"
            style="margin-top: 26px; margin-bottom: 18px"
        >
            <span *ngIf="sidebar" style="color: #454f54"
                >MODUL
                {{
                    moduleDocumentInfo?.sequentialNumber
                        | numberToNumbering: 'upperRomanNumerals'
                }}</span
            >
        </div>
        <div class="d-flex flex-row justify-content-start align-items-baseline">
            <span
                *ngIf="sidebar"
                class="adelle-sans-14 dark-gray font-weight-300"
            >
                Lernfortschritt
            </span>
            <span
                class="adelle-sans-14 dark-gray font-weight-300"
                style="margin-left: 10px"
            >
                {{ moduleDocumentInfo?.progress | number: '1.0-0' }}
                %
            </span>
        </div>
    </div>

    <hr style="margin-top: 0px; margin-bottom: 0px" />

    <ngb-accordion
        id="chapter-accordion"
        class="chapter-accordion"
        animation="true"
        [activeIds]="openChapterPanelNames"
    >
        <!-- Ebene 1 -->
        <ngb-panel
            *ngFor="
                let chapter of moduleDocumentInfo?.chapters;
                let chapterIndex = index
            "
            id="{{ 'chapterPanel-' + chapterIndex }}"
        >
            <ng-template ngbPanelTitle>
                <div
                    class="justify-content-between"
                    style="
                        display: flex;
                        flex-direction: row;
                        min-height: 40px;
                        width: 100%;
                    "
                >
                    <div
                        class="d-flex justify-content-start"
                        style="margin-top: 7.5px; margin-bottom: 7.5px"
                    >
                        <div
                            class="d-flex align-items-center"
                            style="
                                display: flex;
                                margin-right: 11px;
                                height: 25px;
                            "
                        >
                            <img
                                src="assets/Pfeil_Accordion-unten.svg"
                                style="
                                    width: 20px;
                                    height: 10.91px;
                                    opacity: 50%;
                                "
                                class="acc-arrow"
                            />
                        </div>
                    </div>

                    <div
                        class="d-flex flex-row flex-grow-1 justify-content-between align-items-baseline"
                        style="min-width: 100px"
                    >
                        <div
                            class="adelle-sans-12 font-weight-600 umb"
                            style="
                                display: flex;
                                align-items: center;
                                line-height: 1.471;
                                color: #454f54;
                                margin-top: 11.365px;
                                margin-bottom: 11.365px;
                            "
                        >
                            {{ chapter.title }}
                        </div>

                        <div style="margin-left: 11px">
                            <cna-progress-bar
                                [progressPercentage]="chapter.progress"
                            ></cna-progress-bar>
                        </div>
                    </div>
                </div>
            </ng-template>

            <!-- Ebene 2 -->
            <ng-template ngbPanelContent>
                <div
                    *ngFor="
                        let section of chapter.sections;
                        let sectionIndex = index
                    "
                >
                    <ngb-accordion
                        activeIds="{{ openSectionPanel }}"
                        class="section-accordion"
                        id="{{
                            'section-accordion-' +
                                chapterIndex +
                                '-' +
                                sectionIndex
                        }}"
                        animation="true"
                        [ngClass]="!sidebar ? 'main' : ''"
                    >
                        <ngb-panel
                            id="{{
                                'section-panel-' +
                                    chapterIndex +
                                    '-' +
                                    sectionIndex
                            }}"
                            *ngIf="
                                section.sections?.length > 0;
                                else noSubsections
                            "
                        >
                            <ng-template ngbPanelTitle>
                                <div
                                    class="d-flex flex-row justify-content-start"
                                    style="min-height: 40px; width: 100%"
                                    [ngClass]="{
                                        active: section.id == readingId
                                    }"
                                >
                                    <div
                                        style="
                                            margin-top: 7.5px;
                                            margin-bottom: 7.5px;
                                            max-height: 25px;
                                        "
                                        class="d-flex flex-row justify-content-start"
                                    >
                                        <div
                                            class="d-flex align-items-center"
                                            style="
                                                display: flex;
                                                margin-left: 23px;
                                                margin-right: 11px;
                                                height: 25px;
                                            "
                                        >
                                            <img
                                                src="assets/Pfeil_Accordion-unten.svg"
                                                style="
                                                    width: 20px;
                                                    height: 10.91px;
                                                    opacity: 50%;
                                                "
                                                class="acc-arrow"
                                            />
                                        </div>

                                        <cna-chapter-circle
                                            chapter="{{
                                                section.title | sectionNumbering
                                            }}"
                                            [done]="section.progress === 100"
                                            sidebar="{{ true }}"
                                        ></cna-chapter-circle>
                                    </div>

                                    <div
                                        class="d-flex flex-row flex-grow-1 justify-content-between align-items-baseline"
                                    >
                                        <div
                                            class="d-flex flex-row flex-grow-1 align-items-baseline justify-content-between"
                                        >
                                            <div
                                                style="
                                                    margin-left: 10px;
                                                    line-height: 1.333;
                                                    margin-top: 12.275px;
                                                    margin-bottom: 12.275px;
                                                    color: #454f54;
                                                "
                                                class="adelle-sans-12 font-weight-300 umb"
                                            >
                                                {{
                                                    section.title | sectionTitle
                                                }}
                                            </div>

                                            <div class="d-flex flex-row">
                                                <cna-exam-relevant
                                                    class="d-flex align-items-baseline"
                                                    *ngIf="section.isExaminable"
                                                ></cna-exam-relevant>

                                                <div
                                                    class="d-flex align-items-baseline"
                                                    style="margin-left: 11px"
                                                >
                                                    <cna-progress-bar
                                                        [progressPercentage]="
                                                            section.progress
                                                        "
                                                    ></cna-progress-bar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                            <!-- Ebene 3 -->
                            <ng-template ngbPanelContent>
                                <ngb-accordion
                                    id="{{
                                        'subsection-accordion-' +
                                            chapterIndex +
                                            '-' +
                                            sectionIndex
                                    }}"
                                    animation="true"
                                >
                                    <ngb-panel
                                        *ngFor="
                                            let subsection of section.sections;
                                            let subsectionIndex = index
                                        "
                                        id="{{
                                            'subsection-panel-' +
                                                chapterIndex +
                                                '-' +
                                                sectionIndex +
                                                '-' +
                                                subsectionIndex
                                        }}"
                                    >
                                        <ng-template ngbPanelHeader>
                                            <div
                                                class="justify-content-start"
                                                style="
                                                    display: flex;
                                                    flex-direction: row;
                                                    min-height: 40px;
                                                "
                                                [ngClass]="{
                                                    active:
                                                        subsection.id ==
                                                        readingId
                                                }"
                                            >
                                                <div
                                                    style="
                                                        content: '';
                                                        margin-left: 88px;
                                                    "
                                                ></div>

                                                <div
                                                    class="d-flex flex-row flex-grow-1 justify-content-between align-items-baseline"
                                                >
                                                    <div
                                                        style="
                                                            line-height: 1.333;
                                                            max-width: 9vw;
                                                            margin-top: 12.275px;
                                                            margin-bottom: 12.275px;
                                                            color: #454f54;
                                                        "
                                                        class="adelle-sans-12 font-weight-300 umb"
                                                        (click)="
                                                            navigate(subsection)
                                                        "
                                                    >
                                                        {{ subsection.title }}
                                                    </div>

                                                    <div
                                                        class="d-flex justify-content-between align-items-baseline"
                                                        style="
                                                            margin-left: 42px;
                                                        "
                                                    >
                                                        <div
                                                            class="d-flex flex-row justify-content-start align-items-baseline"
                                                        >
                                                            <div>
                                                                <cna-exam-relevant
                                                                    *ngIf="
                                                                        subsection.isExaminable
                                                                    "
                                                                ></cna-exam-relevant>
                                                            </div>

                                                            <div
                                                                class="d-flex align-items-baseline"
                                                                style="
                                                                    margin-left: 11px;
                                                                "
                                                            >
                                                                <cna-progress-bar
                                                                    [progressPercentage]="
                                                                        subsection
                                                                            ?.readStatus
                                                                            ?.read
                                                                    "
                                                                ></cna-progress-bar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </ng-template>
                        </ngb-panel>

                        <!-- Ebene 2 (ohne Subsektionen) -->
                        <ng-template #noSubsections>
                            <ngb-panel>
                                <ng-template ngbPanelHeader>
                                    <div
                                        class="d-flex flex-row justify-content-start"
                                        style="min-height: 40px; width: 100%"
                                        [ngClass]="{
                                            active: section.id == readingId
                                        }"
                                    >
                                        <div
                                            class="d-flex justify-content-start align-items-baseline"
                                            style="
                                                margin-top: 7.5px;
                                                margin-bottom: 7.5px;
                                            "
                                        >
                                            <div
                                                style="margin-left: 54px"
                                                class="align-items-baseline adelle-sans-17 dark-gray font-weight-300 d-flex flex-row justify-content-start"
                                            >
                                                <cna-chapter-circle
                                                    chapter="{{
                                                        section.title
                                                            | sectionNumbering
                                                    }}"
                                                    [done]="
                                                        section.progress === 100
                                                    "
                                                    sidebar="{{ true }}"
                                                ></cna-chapter-circle>
                                            </div>
                                        </div>

                                        <div
                                            class="d-flex flex-row flex-grow-1 justify-content-between align-items-baseline"
                                        >
                                            <span
                                                (click)="navigate(section)"
                                                style="
                                                    margin-left: 11px;
                                                    line-height: 1.333;
                                                    margin-top: 12.275px;
                                                    margin-bottom: 12.275px;
                                                    max-width: 11vw;
                                                    color: #454f54;
                                                "
                                                class="adelle-sans-12 font-weight-300 umb line16"
                                            >
                                                {{
                                                    section.title | sectionTitle
                                                }}
                                            </span>

                                            <div
                                                class="d-flex flex-row justify-content-between align-items-baseline"
                                            >
                                                <cna-exam-relevant
                                                    *ngIf="section.isExaminable"
                                                ></cna-exam-relevant>

                                                <div
                                                    class="d-flex flex-row align-items-baseline"
                                                    style="margin-left: 11px"
                                                >
                                                    <cna-progress-bar
                                                        [progressPercentage]="
                                                            section?.readStatus
                                                                ?.read
                                                        "
                                                    ></cna-progress-bar>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                        </ng-template>
                    </ngb-accordion>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>

    <div
        class="d-flex flex-row justify-content-end align-items-baseline pt-4 border-top"
    >
        <img
            style="height: 10px; width: auto; margin-right: 3px"
            src="assets/stern.svg"
        />
        <div
            class="adelle-sans-10 font-weight-600 letterSpacing-08"
            style="color: #b4b8ba"
        >
            <!-- pl-1 -->
            PRÜFUNGSRELEVANT
        </div>
    </div>
</div>
