import { Routes } from '@angular/router';
import { DashboardViewComponent } from './../../../../../../libs/projects/ak-jura/angular/my-ak-jura/page-views/src/lib/dashboard-view/dashboard-view.component';
import { ExamsViewComponent } from './../../../../../../libs/projects/ak-jura/angular/my-ak-jura/page-views/src/lib/exams-view/exams-view.component';
import { ExamSigninViewComponent } from './../../../../../../libs/projects/ak-jura/angular/my-ak-jura/page-views/src/lib/exam-signin-view/exam-signin-view.component';
import { ExamSigninSuccessViewComponent } from './../../../../../../libs/projects/ak-jura/angular/my-ak-jura/page-views/src/lib/exam-signin-success-view/exam-signin-success-view.component';
import { QuestionsAndSupportViewComponent } from './../../../../../../libs/projects/ak-jura/angular/my-ak-jura/page-views/src/lib/questions-and-support-view/questions-and-support-view.component';
import { MsalGuard } from '@azure/msal-angular';
import { NotesViewComponent } from '../../../../../../libs/projects/ak-jura/angular/my-ak-jura/page-views/src/lib/notes-view/notes-view.component';
import { LearnstateViewComponent } from '../../../../../../libs/projects/ak-jura/angular/my-ak-jura/learning/src/lib/learnstate-view/learnstate-view.component';
import { BookCourseExtensionSuccessViewComponent } from '../../../../../../libs/projects/ak-jura/angular/my-ak-jura/page-views/src/lib/book-course-extension-success-view/book-course-extension-success-view.component';
import { BookCourseExtensionViewComponent } from '../../../../../../libs/projects/ak-jura/angular/my-ak-jura/page-views/src/lib/book-course-extension-view/book-course-extension-view.component';

// Add Path here, if wanna react on change in Effect
export const enum GlobalRoutePaths {
    SETTED_COURSE_PARTICIPATION = ':courseParticipationId',
    EXAMS = ':courseParticipationId/exams',
    NOTES = ':courseParticipationId/notes',
    LEARN_STATE = ':courseParticipationId/learnState',
    LEARNING = ':courseParticipationId/learning',
}

export const routes: Routes = [
    {
        path: ':courseParticipationId/questionsAndSupport',
        component: QuestionsAndSupportViewComponent,
        canActivate: [MsalGuard],
    },
    {
        path: ':courseParticipationId/account',
        loadChildren: () =>
            import(
                '../../../../../../libs/projects/ak-jura/angular/my-ak-jura/my-account/src'
            ).then(module => module.MyAccountModule),
        canActivate: [MsalGuard],
    },
    {
        path: '',
        component: DashboardViewComponent,
        canActivate: [MsalGuard],
        data: { isDashboardActive: true },
    },
    {
        path: GlobalRoutePaths.SETTED_COURSE_PARTICIPATION,
        component: DashboardViewComponent,
        canActivate: [MsalGuard],
        data: { isDashboardActive: true },
    },
    {
        path: GlobalRoutePaths.EXAMS,
        component: ExamsViewComponent,
        canActivate: [MsalGuard],
    },
    {
        path: GlobalRoutePaths.NOTES,
        component: NotesViewComponent,
        canActivate: [MsalGuard],
    },
    {
        path: ':courseParticipationId/examsignin/success',
        component: ExamSigninSuccessViewComponent,
        canActivate: [MsalGuard],
    },
    {
        path: ':courseParticipationId/examsignin/:examId',
        component: ExamSigninViewComponent,
        canActivate: [MsalGuard],
    },

    {
        path: ':courseParticipationId/bookextension/success',
        component: BookCourseExtensionSuccessViewComponent,
        canActivate: [MsalGuard],
    },
    {
        path: ':courseParticipationId/bookextension',
        component: BookCourseExtensionViewComponent,
        canActivate: [MsalGuard],
    },
    {
        path: GlobalRoutePaths.LEARNING,
        loadChildren: () =>
            import(
                '../../../../../../libs/projects/ak-jura/angular/my-ak-jura/learning/src/lib/learn.module'
            ).then(m => m.LearnModule),
        canActivate: [
            MsalGuard, //LearningGuard
        ] /** LearningGuard BUG - Refresh does not work when active */,
        data: { breadcrumb: 'Lernen' },
    },
    {
        path: GlobalRoutePaths.LEARN_STATE,
        component: LearnstateViewComponent,
        canActivate: [MsalGuard],
        data: { breadcrumb: 'Lernstatus' },
    },
];
