import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HtmlPipe } from './pipes/htmlPipe';
import {
    DateTimeToString,
    DateToString,
    TimeObjToString,
} from './pipes/date-object-to-String';
import {
    PrettifyDateString,
    PrettifyDateStringWithDayName,
    PrettifyDateTimeString,
    PrettifyTimeString,
} from './pipes/pretify-date-time-strings';
import { SortPipe } from './pipes/sort-pipes';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { NumberToNumberingPipe } from './number-to-numbering.pipe';
import { BooleanToAscii } from './boolean.pipe';
import { SecondsToHoursPipe } from './seconds-to-hours.pipe';
import { SectionTitlePipe } from './section-title.pipe';
import { SectionNumberingPipe } from './section-numbering.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [
        HtmlPipe,
        DateToString,
        DateTimeToString,
        TimeObjToString,
        PrettifyDateTimeString,
        PrettifyDateString,
        PrettifyDateStringWithDayName,
        PrettifyTimeString,
        SortPipe,
        SafeHtmlPipe,
        NumberToNumberingPipe,
        BooleanToAscii,
        SecondsToHoursPipe,
        SectionTitlePipe,
        SectionNumberingPipe,
        SanitizeHtmlPipe,
    ],
    exports: [
        HtmlPipe,
        DateToString,
        DateTimeToString,
        TimeObjToString,
        PrettifyDateTimeString,
        PrettifyDateString,
        PrettifyDateStringWithDayName,
        PrettifyTimeString,
        SortPipe,
        SafeHtmlPipe,
        NumberToNumberingPipe,
        BooleanToAscii,
        SecondsToHoursPipe,
        SectionTitlePipe,
        SectionNumberingPipe,
        SanitizeHtmlPipe,
    ],
})
export class AngularUiPipesModule {}
