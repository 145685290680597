import { CanDeactivateGuard } from '../../../../../../../angular/ui/confirm-before-leave/can-deactivate-guard.service';
import { CourseListComponent } from './course-list/course-list.component';
import { Routes } from '@angular/router';
import { LearningViewComponent } from './learning-view/learning-view.component';
import { LearningModuleOverviewViewComponent } from './learning-module-overview-view/learning-module-overview-view.component';
import { RepetitoriumOverviewComponent } from '../../../shared-components/src/lib/repetitorium-overview/repetitorium-overview.component';
import { LearningControlViewComponent } from './learning-control-view/learning-control-view.component';
import { RepetitoriumViewComponent } from './repetitorium-view/repetitorium-view.component';

// Add Path here, if wanna react on change in Effect
export const enum LearningRoutePaths {
    COURSE_OVERVIEW = 'courseOverview',
    LEARNING_CONTROL = 'moduleOverview/:moduleId/learningControl',
    MODULE_OVERVIEW = 'moduleOverview/:moduleId',
    LEARNING_VIEW = 'moduleOverview/:moduleId/readingView/:readingId',
    REPETITORIUM_OVERVIEW = 'repetitorium',
    LEARNING_VIEW_REPETITORIUM = 'repetitorium/:favoriteGroupId',
}

export const routes: Routes = [
    {
        path: LearningRoutePaths.COURSE_OVERVIEW,
        pathMatch: 'full',
        component: CourseListComponent, // Includes ModuleOverview || Sketch 19
    },
    {
        path: LearningRoutePaths.MODULE_OVERVIEW,
        component: LearningModuleOverviewViewComponent, // Module Detail Overview || Sketch 20
    },
    {
        path: LearningRoutePaths.LEARNING_VIEW,
        component: LearningViewComponent, //Content reading view || Sketch 21
        canDeactivate: [CanDeactivateGuard],
        data: {
            isLearningViewActive: true,
        },
    },
    {
        path: LearningRoutePaths.REPETITORIUM_OVERVIEW,
        component: RepetitoriumOverviewComponent,
    },
    {
        path: LearningRoutePaths.LEARNING_VIEW_REPETITORIUM,
        component: RepetitoriumViewComponent,
        data: {
            isLearningViewActive: true,
        },
    },
    {
        path: LearningRoutePaths.LEARNING_CONTROL,
        component: LearningControlViewComponent,
    },
];
