<div class="row">
    <div class="offset-sm-2 col-sm-8 col-16">
        <div class="mb-5">
            <div
                class="adelle-sans-31 sans font-weight-400 dark-gray mb-3"
                style="padding-top: 5vw"
            >
                Lernkontrolle

                <span *ngIf="(courseType$ | async) === 'EDUCATION_COURSE'"
                    >MODUL
                    {{
                        (selectModuleId$ | async)?.sequentialNumber
                            | numberToNumbering: 'upperRomanNumerals'
                    }}</span
                >

                <span *ngIf="(courseType$ | async) === 'TRAINING_COURSE'">
                    Weiterbildung
                </span>
            </div>
            <div *ngIf="(controlTestResult$ | async) === undefined">
                <p class="adelle-sans-17 font-weight-300 dark-gray">
                    Insgesamt gibt es
                    {{ (this.controlTest$ | async)?.controlQuestions?.length }}
                    Fragen. Bitte kreuzen Sie jeweils die richtige Antwort an.
                    Pro Frage gibt es nur eine richtige Möglichkeit. <br />
                    Die Lernkontrolle kann beliebig of wiederholt werden. <br />
                    Eine bestandene Lernkontrolle kann nicht wiederholt werden.
                </p>
            </div>
            <div *ngIf="(controlTestResult$ | async) !== undefined">
                <div class="mt-3">
                    <!-- Test ist nicht Weiterbildung und Test bestanden -->
                    <div
                        *ngIf="
                            (controlTestResult$ | async) &&
                            (courseType$ | async) === 'TRAINING_COURSE'
                        "
                    >
                        <div class="d-flex flex-row pt-2">
                            <div>
                                <img
                                    src="assets/bestanden.svg"
                                    alt="yes_green"
                                    style="width: 27px; height: 29px"
                                />
                            </div>
                            <div class="pl-2">
                                <p
                                    class="adelle-pe-25 font-weight-400 green-color"
                                >
                                    Glückwunsch! Sie haben die Lernkontrolle in
                                    <br />
                                    diesem
                                    <span class="adelle-pe-25">Kurs</span>
                                    erfolgreich bestanden.
                                </p>
                                <p
                                    class="adelle-sans-17 font-weight-300 pt-4 dark-gray"
                                >
                                    Das Testergebnis können Sie in Ihrem
                                    <a
                                        [routerLink]="[
                                            '../../../../learnState'
                                        ]"
                                        class="dark-gray"
                                        style="
                                            text-decoration: underline #454f54;
                                        "
                                        >Lernstatus</a
                                    >
                                    downloaden.
                                </p>
                                <div class="pt-4">
                                    <cna-gradient-button
                                        notgradient="true"
                                        buttonTitle="Zurück zur Modulübersicht"
                                        buttonRouterLink="../../../courseOverview/"
                                    ></cna-gradient-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Test ist eine Weiterbildung und Test bestanden -->
                    <div
                        *ngIf="
                            (controlTestResult$ | async) &&
                            (courseType$ | async) === 'EDUCATION_COURSE'
                        "
                    >
                        <div class="d-flex flex-row pt-2">
                            <div>
                                <img
                                    src="assets/bestanden.svg"
                                    alt="yes_green"
                                    style="width: 27px; height: 29px"
                                />
                            </div>
                            <div class="pl-2">
                                <p
                                    class="adelle-pe-25 font-weight-400 green-color"
                                >
                                    Glückwunsch! Sie haben die Lernkontrolle in
                                    <br />
                                    diesem
                                    <span class="adelle-pe-25">MODUL</span>
                                    erfolgreich bestanden.
                                </p>
                                <p
                                    class="adelle-sans-17 font-weight-300 pt-4 dark-gray"
                                >
                                    Das Testergebnis können Sie in Ihrem
                                    <a
                                        [routerLink]="[
                                            '../../../../learnState'
                                        ]"
                                        class="dark-gray"
                                        style="
                                            text-decoration: underline #454f54;
                                        "
                                        >Lernstatus</a
                                    >
                                    downloaden.
                                </p>
                                <ng-container *ngIf="(selectModuleId$ | async)?.sequentialNumber === 4">
                                    <p class="adelle-sans-17 font-weight-300 pt-1 dark-gray">
                                        Sie haben die erforderliche Stundenzahl im Rahmen des theoretischen Teils Ihrer Fachanwaltsausbildung erbracht!
                                    </p>
                                    <p class="adelle-sans-17 font-weight-300 pt-1 dark-gray">
                                        Als nächstes melden Sie sich entweder zu einem unserer Prüfungstermine an oder vereinbaren einen individuellen Prüfungstermin bei Ihnen vor Ort.
                                    </p>
                                    <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                                        Ablauf eines individuellen Prüfungstermins bei Ihnen vor Ort:
                                    </span>
                                    <ul class="adelle-sans-17 font-weight-300 dark-gray">
                                        <li>Nennen Sie uns eine Aufsichtsperson (Rechtsanwältin/Rechtsanwalt), welche bereits ist, die Prüfungsaufsicht zu übernehmen.</li>
                                        <li>Wir schicken an die Aufsichtsperson sämtliche für die Prüfung notwendigen Unterlagen (Informationen zum Ablauf, Klausurfragen, Deckblatt, ...).</li>
                                        <li>Wir benötigen Name und E-Mail-Adresse der Aufsichtsperson.</li>
                                        <li>Uns genügt ein Vorlauf von etwa 10 Tagen bis zur 1. Klausur.</li>
                                        <li>Die Aufsichtsperson schickt dann die drei Klausuren per Post an uns zurück.</li>
                                        <li>Für den Mehraufwand einer Individualprüfung verlangen wir 200,- EUR zzgl. zur Prüfungsgebühr.</li>
                                    </ul>
                                    <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                                        Hinweis Prüfungsvorbereitung:
                                    </span>
                                    <ul class="adelle-sans-17 font-weight-300 dark-gray">
                                        <li>Jede der drei Klausuren besteht aus etwa 30 offenen Fragen. Es sind somit weder Fälle zu lösen noch sind Gutachten zu fertigen.</li>
                                        <li>Als Prüfungsvorbereitung genügt die aufmerksame Bearbeitung des Repetitoriums. Die dort besprochenen Inhalte entsprechen den Prüfungsfragen (in der entsprechenden Reihenfolge).</li>
                                        <li>Zugelassene Hilfsmittel sind sämtliche Gesetzestexte. Anstreichungen oder handschriftliche Kommentierungen in den Hilfsmitteln sind zulässig.</li>
                                        <li>Sie können die nötigen Normen auch in digitaler Form (z.B. Tablet) mitbringen.</li>
                                    </ul>
                                    <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                                        Hinweis zur Prüfungsgebühr:
                                    </span>
                                    <ul class="adelle-sans-17 font-weight-300 dark-gray">
                                        <li>Unsere Rechnung für die Prüfungsgebühr erhalten Sie erst mit der tatsächlichen Teilnahme an der Prüfung.</li>
                                    </ul>
                                    <p class="adelle-sans-17 font-weight-300 dark-gray pt-1">
                                        Bei Fragen stehen wir Ihnen jederzeit - gerne auch telefonisch - zur Verfügung.
                                    </p>
                                    <p class="adelle-sans-17 font-weight-600 dark-gray pt-1">
                                        Ihr AK-JURA Team
                                    </p>
                                </ng-container>
                                <div class="pt-4">
                                    <cna-gradient-button
                                        notgradient="true"
                                        buttonTitle="Zurück zur Modulübersicht"
                                        buttonRouterLink="../../../courseOverview/"
                                    ></cna-gradient-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Test nicht bestanden -->
                    <div *ngIf="!(controlTestResult$ | async)">
                        <div class="d-flex flex-row pt-2">
                            <div>
                                <img
                                    src="assets/falsch.svg"
                                    alt="red-cross"
                                    style="width: 28px; height: 28px"
                                />
                            </div>
                            <div class="pl-2">
                                <p
                                    class="adelle-pe-25 font-weight-400 red-color"
                                >
                                    Sie haben die Lernkontrolle leider nicht
                                    bestanden. <br />
                                    Bitte wiederholen Sie die Lernkontrolle.
                                </p>
                                <p
                                    class="adelle-sans-17 font-weight-300 pt-4 dark-gray"
                                >
                                    Mit den Lernkontrollen überprüfen Sie
                                    eigenverantwortlich Ihren <br />
                                    aktuellen Wissensstand. Sie können die nicht
                                    bestandene <br />
                                    Lernkontrolle beliebig oft wiederholen.
                                </p>
                                <div style="padding-top: 29px">
                                    <cna-gradient-button
                                        notgradient="true"
                                        buttonTitle="Lernkontrolle wiederholen"
                                        (click)="retryTest()"
                                    ></cna-gradient-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="(controlTestResult$ | async) === undefined">
    <div *ngIf="(controlQuestions$ | async) !== undefined">
        <cna-test-form
            [controlQuestions]="controlQuestions$ | async"
        ></cna-test-form>
    </div>
</div>
