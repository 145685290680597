import {
    CourseParticipation,
    Section,
} from '@cna/projects/ak-jura/shared/interfaces';
import { UUID } from '@cna/shared/generic-types';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { skipWhile } from 'rxjs/operators';
import { selectCourseParticipationObjectForSelectedNew } from '../course/selectors';
import { loadCourseParticipationInfos } from '../course_participation/actions';
import { MODULE_INFO_STORE_NAME, ModuleInfoState } from './reducer';
import { selectModuleId, selectReadingId } from '../router.selector';
import { selectGlobal } from '../selectors';

/**
 * Feature Selectors
 */
const selectState = createFeatureSelector<ModuleInfoState>(
    MODULE_INFO_STORE_NAME
);

export const selectModuleLoading = createSelector(
    selectState,
    state => state.loading
);

export const selectModuleInfo = createSelector(selectState, module => {
    return module?.moduleInfo[0];
});

export const selectModuleInfoID = createSelector(selectState, module => {
    if (module?.moduleInfo[0]) {
        return module.moduleInfo[0].id;
    }
});

export const selectControlTestId = createSelector(selectState, module => {
    return module?.moduleInfo[0]?.controlTestId;
});

export const selectControlTestPassed = createSelector(selectState, module => {
    return module?.moduleInfo[0]?.controlTestResults.reduce((prev, result) => {
        return prev || result.passed;
    }, false);
});

export const selectCurrentModuleSequentialNumber = createSelector(
    selectModuleInfo,
    module => module?.sequentialNumber
);

export const selectCurrentModuleSectionByCurrentReadingId = createSelector(
    selectModuleInfo,
    selectReadingId,
    (module, readingId) => {
        if (!module || !readingId) {
            return undefined;
        }
        for (const chapter of module.chapters) {
            for (const section of chapter.sections) {
                const hit = findSectionById(section, readingId);
                if (hit) {
                    return hit;
                }
            }
        }
        return undefined;
    }
);

export const selectCurrentSectionTitle = createSelector(
    selectCurrentModuleSectionByCurrentReadingId,
    section => section?.title
);

function findSectionById(section: Section, id: UUID): Section | undefined {
    if (section.id === id) {
        return section;
    } else if (!section.sections || section.sections.length === 0) {
        return undefined;
    }
    for (const child of section.sections) {
        const hit = findSectionById(child, id);
        if (hit) {
            return hit;
        }
    }
    return undefined;
}

export const selectSectionContactMailSubject = createSelector(
    selectCourseParticipationObjectForSelectedNew,
    selectCurrentModuleSequentialNumber,
    selectCurrentSectionTitle,
    (courseParticipation: CourseParticipation, moduleNumber, title) => {
        return `Hinweis zu ${courseParticipation.bookedCourse.description} | Modul ${moduleNumber} | ${title}`;
    }
);

export const selectSectionContactMailData = createSelector(
    selectSectionContactMailSubject,
    subject => ({ subject, body: '' })
);

/**
export const selectControlTest = createSelector(
    selectModuleInfo,
    module => {
        return module?.moduleInfo[0]?.controlTestId
    }
);

export const selectControlTestResult = createSelector(
    selectModuleInfo,
    module => {
        console.log('module', module)
        return module
    }
); */
