import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Subject, timer } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import {
    TIME_TRACKING_CONFIG,
    TimeTrackingConfig,
} from './time-tracking.config';

@Injectable({
    providedIn: 'root',
})
export class DateTrackingService implements OnDestroy {
    private readonly localStorageKey = 'last-read-date';

    private destroy$ = new Subject<void>();

    dateTracking$ = timer(
        0,
        this.config.dateTrackingIntervalSeconds * 1000
    ).pipe(
        map(() => {
            const last = this.readLastDate();
            const now = this.writeLastDate();
            return (
                (now - last) / 1000 - this.config.dateTrackingIntervalSeconds
            );
        }),
        filter(diff => diff > this.config.dateTrackingToleranceSeconds),
        takeUntil(this.destroy$)
    );

    constructor(
        @Inject(TIME_TRACKING_CONFIG)
        private readonly config: TimeTrackingConfig
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private writeLastDate(): number {
        const now = new Date();
        localStorage.setItem(this.localStorageKey, now.toISOString());
        return now.getTime();
    }

    private readLastDate(): number {
        const lastDate = localStorage.getItem(this.localStorageKey);
        return lastDate ? new Date(lastDate).getTime() : Date.now();
    }
}
