import { ReadingStyles } from '@cna/projects/ak-jura/shared/interfaces';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './actions';

export const READINGSTYLE_STORE_NAME = 'readingstyle';

export interface ReadingStyleState {
    readingStyles: ReadingStyles;
}

const initialState = {
    readingStyles: {
        fontStyle: 'adelle-sans',
        fontSize: 'size-17',
        textWidth: 'medium-container',
        textColor: 'dark-gray',
        backgroundColor: 'white-background',
    },
};

const _readingstyleReducer = createReducer<ReadingStyleState>(
    initialState,
    /**
     * Reading State
     */
    on(fromActions.setReadingStyles, (state, { readingStyles }) => ({
        ...state,
        readingStyles: readingStyles,
    }))
);

export function readingstyleReducer(state, action) {
    return _readingstyleReducer(state, action);
}
