import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GLOBAL_STORE_NAME, GlobalState } from './reducer';
import { ENTITY_CACHE_NAME, EntityCache } from '@ngrx/data';
import { selectCourseParticipationId, selectModuleId } from './router.selector';
import { CourseParticipation } from '../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/course/course-participation';
import { TimeExpenditure } from '@cna/projects/ak-jura/shared/interfaces';
import { DatePipe } from '@angular/common';
import { LearningModuleDocumentInfo } from '../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/learning-module-document-info';

import {
    selectActualCourseObject,
    selectCourseParticipationObjectForSelected,
} from './course/selectors';

/**
 * Feature Selectors
 */
export const selectGlobal =
    createFeatureSelector<GlobalState>(GLOBAL_STORE_NAME);
export const selectEntityCache =
    createFeatureSelector<EntityCache>(ENTITY_CACHE_NAME);

export const selectLoadingState = createSelector(
    selectGlobal,
    global => global?.loading
);

export const selectErrorDescription = createSelector(
    selectGlobal,
    global => global?.errorDescription
);

export const selectActualReloadModuleInfo = createSelector(
    selectCourseParticipationObjectForSelected,
    (courseParticipation: CourseParticipation) =>
        courseParticipation?.reloadModuleInfo
);
export const selectActualReloadModuleDocument = createSelector(
    selectCourseParticipationObjectForSelected,
    (courseParticipation: CourseParticipation) =>
        courseParticipation?.reloadModuleDocument
);

export const selectAllModuleInfos = createSelector(
    selectGlobal,
    global => global?.moduleInfos
);

export const selectModuleInfosForActualCourseParticipation = createSelector(
    selectAllModuleInfos,
    selectCourseParticipationId,
    (moduleInfos, courseParticipationId) => {
        const mod = moduleInfos?.filter(
            moduleInfo =>
                moduleInfo.courseParticipationId === courseParticipationId
        );
        return mod;
    }
);

export const selectActualModuleDocumentInfo = createSelector(
    selectModuleInfosForActualCourseParticipation,
    selectModuleId,
    (moduleInfos, moduleId) =>
        moduleInfos?.find(mi => mi.learnModuleId === moduleId)
);

export const selectControlTestIdOfActualModuleDocumentInfo = createSelector(
    selectActualModuleDocumentInfo,
    (moduleDocumentInfo: LearningModuleDocumentInfo) => {
        console.log('moduleDocumentInfo', moduleDocumentInfo);
        return moduleDocumentInfo?.controlTestId;
    }
);

export const selectModuleDocuments = createSelector(
    selectGlobal,
    global => global?.moduleDocuments
);

export const selectModuleDocument = createSelector(
    selectModuleDocuments,
    selectModuleId,
    (moduleDocuments, actualModuleId) =>
        moduleDocuments?.find(md => md?.moduleId === actualModuleId)
);

export const selectAllTimeExpendituresForActualCourse = createSelector(
    selectGlobal,
    globalState => globalState.timeExpenditures
);

// Iterate over all TimeExpenditures and sort the into a Map with Key 'MMM y', new Key is added on first occurrence
export const selectAllTimeExpendituresForActualCourseByMonth = createSelector(
    selectAllTimeExpendituresForActualCourse,
    (timeExpenditures: TimeExpenditure[]) => {
        if (!timeExpenditures) {
            return undefined;
        }
        const datePipe = new DatePipe('de');
        const monthMap = new Map<string, TimeExpenditure[]>();
        timeExpenditures
            .sort(
                (a, b) =>
                    new Date(b.lastRead).getTime() -
                    new Date(a.lastRead).getTime()
            )
            .forEach(te => {
                const monthCode = datePipe
                    .transform(Date.parse(te.lastRead), 'yyyy MMMM')
                    .replace('.', '');
                if (monthMap.has(monthCode)) {
                    monthMap.set(
                        monthCode,
                        monthMap.get(monthCode).concat([te])
                    );
                } else {
                    monthMap.set(monthCode, [te]);
                }
            });

        return monthMap;
    }
);

export const selectTimeExpendituresPerModuleForActualCourse = createSelector(
    selectAllTimeExpendituresForActualCourse,
    selectActualCourseObject,
    (timeExpenditures, course) => {
        if (!timeExpenditures || !course) {
            return undefined;
        }
        const timesByModule: {
            moduleNumber: number;
            timeExpenditures: TimeExpenditure[];
        }[] = [];
        course?.modules.forEach(learnModule => {
            timesByModule.push({
                moduleNumber: learnModule.sequentialNumber,
                timeExpenditures: timeExpenditures.filter(
                    timeExpenditure =>
                        timeExpenditure.moduleId === learnModule.id
                ),
            });
        });
        return timesByModule.sort((x, y) => x.moduleNumber - y.moduleNumber);
    }
);
