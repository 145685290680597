<cna-icon-tile
    tileTitle="Lernen"
    tileImage="assets/Lernen.svg"
    routeLink="learning/courseOverview"
    [isDeactivated]="!courseSelected || courseLocked"
></cna-icon-tile>
<cna-icon-tile
    tileTitle="Notizen"
    tileImage="assets/Notizen.svg"
    routeLink="notes"
    [isDeactivated]="!courseSelected"
></cna-icon-tile>
<cna-icon-tile
    tileTitle="Prüfung"
    tileImage="assets/pruefungen.svg"
    routeLink="./exams"
    [isDeactivated]="
        !courseSelected || courseType === CourseType.TrainingCourse || isTest
    "
></cna-icon-tile>
<cna-icon-tile
    tileTitle="Lernstatus"
    tileImage="assets/Lernstatus.svg"
    routeLink="learnState"
    [isDeactivated]="!courseSelected"
></cna-icon-tile>
<cna-icon-tile
    tileTitle="Fragen & Support"
    tileImage="assets/Fragen.svg"
    routeLink="questionsAndSupport"
    [isDeactivated]="!courseSelected"
></cna-icon-tile>
<cna-icon-tile
    tileTitle="Mein Konto"
    tileImage="assets/MeinKonto.svg"
    routeLink="account"
></cna-icon-tile>
