<div class="d-flex flex-row" style="margin-top: 137px; margin-left: 142px">
    <cna-page-heading pageHeadingTitle=""></cna-page-heading>
    <div class="d-flex flex-row">
        <div class="d-flex flex-column">
            <img src="assets/bestanden.svg" />
        </div>

        <div class="d-flex flex-column ml-3">
            <p class="green-color adelle-pe-25 font-weight-400">
                Die Prüfung wurde erfolgreich gebucht
            </p>
            <p class="adelle-sans-17 font-weight-300">
                Die Anmeldung ist in Ihrem Kundenbereich noch inaktiv.<br />
                Sie erhalten in Kürze eine E-Mail von uns – wir bitten Sie um
                etwas Geduld.
            </p>

            <div class="pt-4">
                <cna-gradient-button
                    notgradient="true"
                    buttonTitle="Zurück zur Kursübersicht"
                    buttonRouterLink="../../"
                ></cna-gradient-button>
            </div>
            <div></div>
        </div>
    </div>
</div>
