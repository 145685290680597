<label class="checkbox-label" [class.disabled]="disabled">
    <input
        type="checkbox"
        [checked]="checked"
        [disabled]="disabled"
        (change)="onChange($event)"
    />
    <span class="checkbox-icon"></span>
    <span>
        {{ label }}
    </span>
</label>
