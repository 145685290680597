import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sectionTitle',
})
export class SectionTitlePipe implements PipeTransform {
    transform(value: string, seperator = '. '): string {
        const split = value.split(seperator, 2);
        return split.length === 1 ? split[0] : split[1];
    }
}
