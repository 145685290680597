import { EntityMetadataMap } from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {
    Exam: {},
    Customer: {},
    LegalArea: {},
    Book: { selectId: model => model.documentRef },
    Course: {},
    Examinable: {},
    Note: {},
    Repetitorium: {},
    'Module-document-template': {},
    'Module-document': {},
    'Module-document-info': {},
    'Control-test': {},
    'Course-Participation': {},
    'Discount-code': {},
    Payment: {},
    'time-expenditure': {},
};

const pluralNames = {
    Exam: 'exam',
    Customer: 'customer',
    LegalArea: 'legalArea',
    Book: 'book',
    Course: 'course',
    Examinable: 'examinable',
    Note: 'note',
    Repetitorium: 'repetitorium',
    'Module-document-template': 'module-document-templates',
    'Module-document': 'module-document',
    'Module-document-info': 'module-document-info',
    'Course-Participation': 'course-Participation',
    'Control-test': 'control-tests',
    'Discount-code': 'discount-code',
    Payment: 'payment',
    'time-expenditure': 'time-expenditure',
};

export const entityConfig = {
    entityMetadata,
    pluralNames,
};
