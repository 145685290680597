<ng-container *ngIf="isLearningViewActive$ | async; else smallMenu">
    <div class="header-left wide-menu" (click)="routeToDashboard()">
        <img src="assets/AkJuraLogo.svg" />
        <span class="separator"></span>
        <img src="assets/Wolters_Kluwer.svg" />
    </div>
    <div class="header-right" *ngIf="!(sidenavIsActive$ | async)">
        <cna-header-sidenav-icon-tile
            tileTitle="Inhalt"
            tileImage="assets/icon-inhalt.svg"
            [sidenavType]="sidenavTypeContentOverview"
        ></cna-header-sidenav-icon-tile>
        <cna-header-sidenav-icon-tile
            tileTitle="Notizen"
            tileImage="assets/Notizen.svg"
            [sidenavType]="sidenavTypeNotesOverview"
        ></cna-header-sidenav-icon-tile>
        <cna-header-sidenav-icon-tile
            tileTitle="Anpassen"
            tileImage="assets/icon-anpassen.svg"
            [sidenavType]="sidenavTypeCustomization"
        ></cna-header-sidenav-icon-tile>
        <cna-header-sidenav-icon-tile
            tileTitle="MEIN AK JURA"
            tileImage="assets/Dashboard.svg"
            [sidenavType]="4"
            [routerLinks]="courseParticipationId$ | async"
        ></cna-header-sidenav-icon-tile>
    </div>
</ng-container>
<ng-template #smallMenu>
    <ng-container
        *ngIf="
            !(isDashboardActive$ | async) && !(isLearningViewActive$ | async);
            else noMenu
        "
    >
        <div class="header-left" (click)="routeToDashboard()">
            <img src="assets/AkJuraLogo.svg" />
            <span class="separator"></span>
            <img src="assets/Wolters_Kluwer.svg" />
        </div>
        <div class="header-right" *ngIf="!(sidenavIsActive$ | async)">
            <cna-header-sidenav-icon-tile
                tileTitle="MEIN AK JURA"
                tileImage="assets/Dashboard.svg"
                [sidenavType]="4"
                [routerLinks]="courseParticipationId$ | async"
            ></cna-header-sidenav-icon-tile>
        </div>
    </ng-container>

    <ng-template #noMenu>
        <div class="header-left" (click)="routeToDashboard()">
            <img src="assets/AkJuraLogo.svg" />
            <span class="separator"></span>
            <img src="assets/Wolters_Kluwer.svg" />
        </div>
    </ng-template>
</ng-template>
