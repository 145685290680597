<div>
    <div class="modal-header">
        <h4 class="modal-title adelle-sans-17 font-weight-600">
            Notiz bearbeiten
        </h4>
        <button
            type="button"
            class="closeButton"
            aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"
        >
            <img
                alt="close"
                src="assets/x-close.svg"
                height="20px"
                width="20px"
            />
        </button>
    </div>
    <div class="modal-body">
        <form
            #updateNoteForm="ngForm"
            (submit)="updateNote(updateNoteForm)"
            class="formnote"
        >
            <div class="form-group form-el">
                <label
                    for="header"
                    class="adelle-sans-17 font-weight-600 dark-gray"
                >
                    Titel
                </label>
                <input
                    id="header"
                    type="text"
                    class="form-control formtitle adelle-sans-17 font-weight-300 dark-gray"
                    name="noteHeader"
                    [ngModel]="title"
                    required
                />
            </div>
            <div class="form-group form-el">
                <label
                    for="header"
                    class="adelle-sans-17 font-weight-600 dark-gray pt-3"
                    >Notiz
                </label>
                <textarea
                    id="content"
                    class="form-control note adelle-sans-17 font-weight-300 dark-gray"
                    name="noteContent"
                    [ngModel]="content"
                    required
                ></textarea>
            </div>
            <div class="form-el d-flex flex-row">
                <cna-gradient-button
                    notgradient="true"
                    buttonTitle="Speichern"
                ></cna-gradient-button>
            </div>
            <div>
                <p
                    *ngIf="updateNoteForm.submitted && updateNoteForm.invalid"
                    class="red-color adelle-sans-12"
                >
                    Bitte füllen Sie alle Felder aus!
                </p>
            </div>
        </form>
    </div>
</div>
