import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ReadingStyles } from '@cna/projects/ak-jura/shared/interfaces';
import { setReadingStyles } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/readingstyles/actions';
import { selectReadingStyles } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/readingstyles/selectors';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'cna-sidenav-customization',
    templateUrl: './sidenav-customization.component.html',
    styleUrls: ['./sidenav-customization.component.scss'],
})
export class SidenavCustomizationComponent implements OnInit, OnDestroy {
    @ViewChild('fontSizes') fontsizes: ElementRef;
    @ViewChild('fontStyles') fontStyles: ElementRef;
    @ViewChild('textWidths') textWidths: ElementRef;
    @ViewChild('textColors') textColors: ElementRef;
    @ViewChild('backgroundColors') backgroundColors: ElementRef;

    destroy$ = new Subject<void>();
    changedStyle$ = this.store.pipe(
        select(selectReadingStyles),
        takeUntil(this.destroy$)
    );

    currentStyle: ReadingStyles;
    constructor(
        private store: Store,
        private renderer: Renderer2,
        private el: ElementRef
    ) {}

    ngOnInit(): void {
        this.changedStyle$.subscribe(value => (this.currentStyle = value));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    handleChange(type: string, value: string) {
        this.store.dispatch(
            setReadingStyles({
                readingStyles: { ...this.currentStyle, [type]: value },
            })
        );
    }
}
