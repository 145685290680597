import { createFeatureSelector, createSelector } from '@ngrx/store';
import { COURSE_STORE_NAME, CourseState } from './reducer';
import {
    selectAllCourseParticipations,
    selectCustomersCourses,
} from '../customer/selectors';
import { selectCourseParticipationId } from '../router.selector';
import { CourseParticipation } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/course/course-participation';
import { UUID } from '@cna/shared/generic-types';

export const selectCourse =
    createFeatureSelector<CourseState>(COURSE_STORE_NAME);

/** old */
export const selectCourseParticipationObjectForSelected = createSelector(
    selectAllCourseParticipations,
    /** selectGlobal, */
    selectCourseParticipationId,
    (courseParticipations, courseParticipationId) => {
        const v = courseParticipations?.find(
            cp => cp?.id === courseParticipationId
        );
        if (v !== undefined) {
            return v;
        }
    }
);
/** old */

/** test */
export const selectCourseParticipationObjectForSelectedNew = createSelector(
    selectCustomersCourses,
    /** selectGlobal, */
    selectCourseParticipationId,
    (course, courseParticipationId) => {
        const v = course?.find(cp => cp?.id === courseParticipationId);
        if (v !== undefined) {
            return v;
        }
    }
);
/** test */

export const selectActualCourseObject = createSelector(
    selectCourseParticipationObjectForSelectedNew,
    (courseParticipation: CourseParticipation) => {
        return courseParticipation?.bookedCourse;
    }
);

export const selectActualCourseId = createSelector(
    selectActualCourseObject,
    (course: any) => course?.id as UUID
);

export const selectActualCourseLegalArea = createSelector(
    selectActualCourseObject,
    courseObject => courseObject?.associatedLegalArea
);

export const selectLoadingStateCourse = createSelector(
    selectCourse,
    global => global?.loading
);
