<div [style]="getWidthForStyling()">
    <div *ngIf="currentSidenavContentType == 0">
        <div class="row justify-content-between mr-0 sticky-top">
            <div class="col-4 col-sm-3 col-md-3 col-lg-6 col-xl-4">
                <cna-header-sidenav-icon-tile
                    tileTitle="Inhalt"
                    tileImage="assets/icon-zuklappen.svg"
                    [sidenavType]="3"
                ></cna-header-sidenav-icon-tile>
            </div>
            <div
                class="col-8 pt-2 pt-md-3 pt-lg-5 text-right to-model-overview"
            >
                <!-- Zur Repetitorium-Übersicht -->
                <a
                    *ngIf="this.currentMode ? 'display-none' : ''"
                    (click)="routeToOverview()"
                    class="pr-3 adelle-sans-14 font-weight-300 link-grey"
                    style="text-decoration: underline; cursor: pointer"
                    >Zur Repetitorium-Übersicht</a
                >
                <!-- zur Modul-Übersicht -->
                <a
                    *ngIf="this.currentMode ? '' : 'display-none'"
                    (click)="routeToOverview()"
                    class="adelle-sans-14 font-weight-300 link-grey"
                    style="text-decoration: underline; cursor: pointer"
                    >Zur Modulübersicht</a
                >
            </div>
        </div>
        <div class="row mx-0 mt-4">
            <div class="col-16">
                <cna-sidenav-content-overview></cna-sidenav-content-overview>
            </div>
        </div>
    </div>
    <div *ngIf="currentSidenavContentType == 1">
        <div class="row justify-content-between mr-0 sticky-top">
            <div class="col-4 col-sm-3 col-md-3 col-lg-6 col-xl-4">
                <cna-header-sidenav-icon-tile
                    tileTitle="Anpassen"
                    tileImage="assets/icon-zuklappen.svg"
                    [sidenavType]="3"
                ></cna-header-sidenav-icon-tile>
            </div>
            <div class="col-8 pt-2 pt-md-3 pt-lg-5 text-right"></div>
        </div>

        <cna-sidenav-customization></cna-sidenav-customization>
    </div>
    <div *ngIf="currentSidenavContentType == 2">
        <div class="row justify-content-between mr-0 sticky-top">
            <div class="col-4 col-sm-3 col-md-3 col-lg-6 col-xl-4">
                <cna-header-sidenav-icon-tile
                    tileTitle="Notizen"
                    tileImage="assets/icon-zuklappen.svg"
                    [sidenavType]="3"
                ></cna-header-sidenav-icon-tile>
            </div>
            <div
                class="col-8 pt-2 pt-md-3 pt-lg-5 text-right to-model-overview"
            >
                <a
                    (click)="routeToNotes()"
                    class="pr-3 adelle-sans-14 font-weight-300 link-grey"
                    style="text-decoration: underline; cursor: pointer"
                    >Zu den Notizen</a
                >
            </div>
        </div>
        <div class="row mx-0 mt-4">
            <div class="col-16" style="padding-left: 15px; padding-right: 15px">
                <cna-sidenav-notes></cna-sidenav-notes>
            </div>
        </div>
    </div>
</div>
