import { HttpClient } from '@angular/common/http';
import {
    Component,
    ElementRef,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    Bibliography,
    CourseType,
    FavoriteGroup,
    isSection,
    ReadingStyles,
    ReadStatus,
    Section,
} from '@cna/projects/ak-jura/shared/interfaces';
import type { UUID } from '@cna/shared/generic-types';
import { filterNil } from '@cna/shared/helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import {
    finalize,
    map,
    mergeMap,
    skipWhile,
    take,
    takeUntil,
    tap,
} from 'rxjs/operators';
import { closeRunningReadingSession } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/actions';
import { setBreadcrumbs } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';
import { selectActualCourseObject } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course/selectors';
import {
    loadCourseParticipationInfos,
    updateLastLogin,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';
import { selectCurrentCourseType } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';
import { loadModuleExaminables } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/examinables/actions';
import { selectModuleExaminables } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/examinables/selectors';
import { loadContentSection } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/load_content/actions';
import {
    selectContentSection,
    selectContentSectionLoading,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/load_content/selectors';
import { loadModuleInfosForModuleId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/module_infos/actions';
import {
    selectCurrentModuleSequentialNumber,
    selectCurrentSectionTitle,
    selectModuleInfo,
    selectSectionContactMailData,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/module_infos/selectors';
import { setActualNumberingArray } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/notes/actions';
import {
    createReadStatusForCurrent,
    loadCurrentReadStatus,
    updateReadStatus,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/read_status/actions';
import { selectCurrentReadStatus } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/read_status/selectors';
import { selectReadingStyles } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/readingstyles/selectors';
import {
    selectCourseParticipationId,
    selectModuleId,
    selectReadingId,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import {
    setNavInsideSidenav,
    setSidenavActiveState,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/actions';
import { selectNavInsideSidenav } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/selectors';
import { environment } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/environments/environment';
import { MeinAkJuraEnvironment } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/environments/environment.interface';
import { EnvironmentService } from '../../../../../../../../angular/services/src/lib/environment/environment.service';
import { CanComponentDeactivate } from '../../../../../../../../angular/ui/confirm-before-leave/can-deactivate-guard.service';
import { NumberToNumberingPipe } from '../../../../../../../../angular/ui/pipes/src/lib/number-to-numbering.pipe';
import { LearningModuleDocumentInfo } from '../../../../../../shared/interfaces/src/lib/learning-module-document-info';
import { ToastType } from '../../../../../../shared/interfaces/src/lib/toast-type.enum';
import { ModuleDocumentHelperService } from '../../../../../shared-components/src/lib/services/module-document-helper.service';
import { ToasterService } from '../../../../../shared-components/src/lib/services/toaster.service';
import {
    TIME_TRACKING_CONFIG,
    TimeTrackingConfig,
} from '../../../../active-time-tracking/src/lib/time-tracking.config';
import { TimeTrackingService } from '../../../../active-time-tracking/src/lib/time-tracking.service';
import {
    NetworkStatus,
    NetworkStatusService,
} from '../../../../network-status/src/lib/network-status.service';

@Component({
    selector: 'cna-learning-view',
    templateUrl: './learning-view.component.html',
    styleUrls: ['./learning-view.component.scss'],
})
export class LearningViewComponent
    implements OnInit, OnDestroy, CanComponentDeactivate
{
    @ViewChild('inactivityModal') inactivityModal: ElementRef;

    private _destroy$ = new Subject<void>();
    private pdfLoading$ = new Subject<boolean>();

    inactiveMessage$: Observable<string> =
        this.timeTrackingService.inactiveMessage$;

    readingNode: Section | FavoriteGroup;
    nextReadingId: UUID = undefined;
    renderedText: string;

    navTroughNextChapterButton = false;
    maxInactiveTime = this.timeTrackingConfig.maxInactiveTime;

    canDeactivateSubmitted = false;

    readingStyles$: Observable<ReadingStyles> = this.store.pipe(
        select(selectReadingStyles),
        takeUntil(this._destroy$)
    );
    bibliographies: Bibliography[];
    environment: MeinAkJuraEnvironment =
        this.environmentService.getEnvironment();

    currentModule$: Observable<LearningModuleDocumentInfo> = this.store
        .select(selectModuleInfo)
        .pipe(
            skipWhile(m => !m),
            takeUntil(this._destroy$)
        );

    controlTestPassed$ = this.currentModule$.pipe(
        map(
            module =>
                module.controlTestResults.filter(res => res.passed).length > 0
        )
    );
    readStatus$ = this.store
        .select(selectCurrentReadStatus)
        .pipe(takeUntil(this._destroy$));
    loading$ = merge(
        this.store.select(selectContentSectionLoading),
        this.pdfLoading$
    ).pipe(takeUntil(this._destroy$));

    courseParticipation$ = this.store
        .select(selectActualCourseObject)
        .pipe(takeUntil(this._destroy$));

    contactMailLink$ = this.store.select(selectSectionContactMailData).pipe(
        map(({ subject, body }) => {
            return `mailto:${
                environment.authorNoteMailAddress
            }?subject=${encodeURI(subject)}&body=${encodeURI(body)}`;
        })
    );

    loaded = false;

    @HostListener('window:beforeunload')
    async trySubmitPendingTrackingSession(): Promise<void> {
        await this.timeTrackingService.stopTracking(true);
    }

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private store: Store,
        private timeTrackingService: TimeTrackingService,
        public moduleDocumentHelperService: ModuleDocumentHelperService,
        private environmentService: EnvironmentService,
        private modalService: NgbModal,
        private numberToNumberingPipe: NumberToNumberingPipe,
        private http: HttpClient,
        @Inject(TIME_TRACKING_CONFIG)
        private timeTrackingConfig: TimeTrackingConfig,
        private networkStatusService: NetworkStatusService,
        private toaster: ToasterService
    ) {
        if (this.inactiveMessage$) {
            this.inactiveMessage$
                .pipe(takeUntil(this._destroy$))
                .subscribe(() => {
                    this.modalService.open(this.inactivityModal, {
                        backdrop: 'static',
                    });
                });
        }
        let lastStatus: NetworkStatus;
        this.networkStatusService.status$
            .pipe(takeUntil(this._destroy$))
            .subscribe(status => {
                if (
                    status === NetworkStatus.ONLINE &&
                    lastStatus === NetworkStatus.OFFLINE
                ) {
                    this.toaster.show(
                        'Sie sind wieder online und können wieder auf alle Funktionen von Mein AK JURA zugreifen.',
                        ToastType.success
                    );
                } else if (
                    NetworkStatus.OFFLINE &&
                    lastStatus === NetworkStatus.ONLINE
                ) {
                    this.toaster.show(
                        'Sie sind jetzt offline und haben nur noch eingschränkten Zugriff auf Mein AK JURA.',
                        ToastType.error
                    );
                }
                lastStatus = status;
            });
        this.setBreadcrumbs();
    }

    async ngOnInit(): Promise<void> {
        this.store.dispatch(loadCourseParticipationInfos());
        this.store.dispatch(loadModuleInfosForModuleId());
        this.loadReadingContent();

        this.store
            .select(selectReadingId)
            .pipe(
                filterNil(),
                takeUntil(this._destroy$),
                tap(async () => {
                    this.loaded = false;
                    this.store.dispatch(loadContentSection());
                    this.store.dispatch(loadCurrentReadStatus());
                    this.store.dispatch(loadModuleExaminables());
                    this.store.dispatch(updateLastLogin());

                    await this.timeTrackingService.startTracking();
                    this.loaded = true;
                })
            )
            .subscribe();
    }

    async ngOnDestroy(): Promise<void> {
        this.store
            .select(selectNavInsideSidenav)
            .subscribe(x => {
                if (x === false) {
                    this.store.dispatch(
                        setSidenavActiveState({ activeState: false })
                    );
                }
            })
            .unsubscribe();
        this.store.dispatch(setNavInsideSidenav({ activeState: false }));
        this._destroy$.next();
        this._destroy$.complete();
    }

    setBreadcrumbs() {
        combineLatest([
            this.store.select(selectCourseParticipationId).pipe(filterNil()),
            this.store.select(selectModuleId).pipe(filterNil()),
            this.store.select(selectReadingId).pipe(filterNil()),
            this.store.select(selectCurrentCourseType).pipe(filterNil()),
            this.store
                .select(selectCurrentModuleSequentialNumber)
                .pipe(filterNil()),
            this.store.select(selectCurrentSectionTitle).pipe(filterNil()),
        ])
            .pipe(takeUntil(this._destroy$))
            .subscribe(
                ([
                    courseParticipationId,
                    moduleId,
                    readingId,
                    courseType,
                    sequentialNumber,
                    sectionTitle,
                ]) => {
                    this.store.dispatch(
                        setBreadcrumbs({
                            breadcrumbs: [
                                {
                                    label: 'Mein AK JURA',
                                    routerLink: courseParticipationId,
                                },
                                {
                                    label: 'Lernstatus',
                                    routerLink:
                                        courseParticipationId +
                                        '/learning/courseOverview',
                                },
                                {
                                    label:
                                        courseType === CourseType.TrainingCourse
                                            ? 'Inhalt'
                                            : `MODUL ${this.numberToNumberingPipe.transform(
                                                  sequentialNumber,
                                                  'upperRomanNumerals'
                                              )}`,
                                    routerLink:
                                        courseParticipationId +
                                        '/learning/moduleOverview/' +
                                        moduleId,
                                },
                                {
                                    label: 'Leseansicht (' + sectionTitle + ')',
                                    routerLink:
                                        courseParticipationId +
                                        '/learning/moduleOverview/' +
                                        moduleId +
                                        '/readingView/' +
                                        readingId,
                                },
                            ],
                        })
                    );
                }
            );
    }

    loadReadingContent() {
        combineLatest([
            this.store.select(selectModuleInfo).pipe(skipWhile(mi => !mi)),
            this.store.select(selectReadingId).pipe(skipWhile(md => !md)),
        ])
            .pipe(takeUntil(this._destroy$))
            .subscribe(([moduleDocumentInfo, readingId]) => {
                const nodeData =
                    this.moduleDocumentHelperService.getNodeByIdAndNextId(
                        moduleDocumentInfo,
                        readingId
                    );

                if (nodeData) {
                    this.store.dispatch(
                        setActualNumberingArray({
                            numbering: nodeData.numbering,
                        })
                    );
                }

                this.readingNode = nodeData?.node;
                this.nextReadingId = nodeData?.nextId;
            });

        combineLatest([
            this.store.select(selectContentSection).pipe(skipWhile(md => !md)),
            this.store
                .select(selectModuleExaminables)
                .pipe(skipWhile(me => !me)),
        ])
            .pipe(takeUntil(this._destroy$))
            .subscribe(async ([moduleDocument, examinables]) => {
                this.bibliographies =
                    this.moduleDocumentHelperService.getBibliographiesOfNode(
                        moduleDocument
                    );

                this.renderedText =
                    this.moduleDocumentHelperService.parseAndFormatTextFromNodeElement(
                        /** this.readingNode */
                        moduleDocument,
                        examinables
                    );
            });
    }

    /**
     * determines whether the node already has a read status
     * and updates or creates a new one accordingly
     */
    setReadStatus(
        readStatus: ReadStatus | undefined,
        event: { readStatus: boolean }
    ): void {
        if (isSection(this.readingNode)) {
            if (readStatus) {
                this.store.dispatch(
                    updateReadStatus({
                        id: readStatus.id,
                        read: event.readStatus,
                    })
                );
            } else {
                this.store.dispatch(
                    createReadStatusForCurrent({ read: event.readStatus })
                );
            }
        }
    }

    routeToNextId() {
        this.navTroughNextChapterButton = true;

        combineLatest([
            this.store.select(selectCourseParticipationId),
            this.store.select(selectModuleId),
        ])
            .pipe(take(1))
            .subscribe(async ([cpId, mId]) => {
                await this.router.navigate([
                    cpId,
                    'learning',
                    'moduleOverview',
                    mId,
                    'readingView',
                    this.nextReadingId,
                ]);
            });
    }

    async routeToOverview() {
        this.modalService.dismissAll();
        await this.router.navigate(['../../'], {
            relativeTo: this.activatedRoute,
        });
    }

    closeRunningSession() {
        this.modalService.dismissAll();
        this.store.dispatch(closeRunningReadingSession());
    }

    async restartSession() {
        this.modalService.dismissAll();
        await this.timeTrackingService.startTracking();
    }

    printContent() {
        this.pdfLoading$.next(true);
        combineLatest([
            this.store.select(selectModuleId),
            this.store.select(selectReadingId),
        ])
            .pipe(
                mergeMap(([moduleId, sectionId]) =>
                    this.http.post<{
                        contentType: string;
                        filename: string;
                        base64Content: string;
                    }>(
                        `${environment.apiHost}/modules/${moduleId}/sections/${sectionId}/export`,
                        {}
                    )
                ),
                tap(async ({ contentType, base64Content, filename }) => {
                    const fetchResponse = await fetch(
                        `data:${contentType};base64,${base64Content}`
                    );
                    const blob = await fetchResponse.blob();
                    saveAs(blob, filename);
                }),
                take(1),
                finalize(() => this.pdfLoading$.next(false))
            )
            .subscribe();
    }

    async canDeactivate(): Promise<boolean> {
        if (!this.loaded) {
            return false;
        }

        await this.timeTrackingService.stopTracking();

        return true;
    }
}
