import { createAction, props } from '@ngrx/store';
import { ExamParticipation } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam-participation';
import { Exam } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam';
import type { UUID } from '@cna/shared/generic-types';
import {
    CustomerBaseData,
    InvoiceAddress,
} from '@cna/projects/ak-jura/shared/interfaces';

/**
 Save BookingExam
 */
export const validateAndSaveBookingExam = createAction(
    '[exams] save BookingExam',
    props<{
        legalAreaId: UUID;
        exam: Exam;
        customer: CustomerBaseData;
        invoiceAddress: InvoiceAddress;
    }>()
);
export const saveBookingExamSuccess = createAction(
    '[exams] save BookingExam success',
    props<{ examParticipation: ExamParticipation }>()
);
export const saveBookingExamFailure = createAction(
    '[exams] save BookingExam failure',
    props<{ error: Error }>()
);

/**
 * Load Exams Actions
 */
export const loadExamParticipations = createAction(
    '[exams] load Exam Participations'
);
export const loadExamParticipationsSuccess = createAction(
    '[exams] load Exam Participations success',
    props<{ examParticipations: ExamParticipation[] }>()
);
export const loadExamParticipationsFailure = createAction(
    '[exams] load Exam Participations Failure',
    props<{ error: Error }>()
);

/**
 * Load Exams Actions
 */
export const loadExams = createAction('[global] load Exams');

export const loadExamsSuccess = createAction(
    '[exams] load Exams success',
    props<{ exams: Exam[] }>()
);
export const loadExamsFailure = createAction(
    '[exams] load Exams Failure',
    props<{ error: Error }>()
);
