import { ModuleDocumentHelperService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/module-document-helper.service';
import { SidenavContentType } from '../../../../../../../../libs/projects/ak-jura/angular/my-ak-jura/shared-components/src/lib/sidenav/sidenav.component';
import { ExamParticipation } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam-participation';
import { Exam } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam';
import { LearningModuleDocumentInfo } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/learning-module-document-info';
import {
    Examinable,
    ModuleDocument,
    Repetitorium,
    TimeExpenditure,
} from '@cna/projects/ak-jura/shared/interfaces';
import { ControlTest } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/control-test/control-test';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './actions';

export const EXAMINABLES_STORE_NAME = 'examinables';
const moduleDocumentHelperService = new ModuleDocumentHelperService();

export interface ExaminablesState {
    moduleExaminables: Examinable[];
    loading: boolean;
}

const initialState = {
    moduleExaminables: undefined,
    loading: true,
};

const _examinablesReducer = createReducer<ExaminablesState>(
    initialState,

    on(fromActions.loadModuleExaminables, state => ({
        ...state,
        loading: true,
    })),
    on(fromActions.loadModuleExaminablesSuccess, (state, action) => ({
        ...state,
        moduleExaminables: action.examinables,
    })),
    on(fromActions.loadModuleExaminablesFailure, state => ({
        ...state,
        loading: false,
    }))
);

export function examinablesReducer(state, action) {
    return _examinablesReducer(state, action);
}
