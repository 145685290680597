import { Component, Input, OnInit } from '@angular/core';
import { Note } from '@cna/projects/ak-jura/shared/interfaces';
import { ModalComponent } from '../modal/modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocxCreatorService } from '../../../../../../../../angular/file-creation/src/lib/docx-creator.service';
import { Observable } from 'rxjs/Observable';
import { CourseParticipation } from '../../../../../../shared/interfaces/src/lib/data-management/course/course-participation';
import { selectCourseParticipationObjectForSelected } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course/selectors';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { AskDeleteModalComponent } from '../ask-delete-modal/ask-delete-modal.component';
import { selectCustomersCourseParticipation } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';

@Component({
    selector: 'cna-notes-accordion',
    templateUrl: './notes-accordion.component.html',
    styleUrls: ['./notes-accordion.component.scss'],
})
export class NotesAccordionComponent implements OnInit {
    @Input() notes: Note[];
    @Input() moduleName: number | string;
    @Input() moduleId: string;
    @Input() notesOverview: boolean;
    private destroy$ = new Subject();
    noteToBeEdited: Note;
    Modal;

    courseParticipation$: Observable<CourseParticipation> = this.store
        .select(selectCustomersCourseParticipation) //
        .pipe(takeUntil(this.destroy$));

    constructor(
        private store: Store,
        private modalService: NgbModal,
        private docxCreatorService: DocxCreatorService
    ) {}

    ngOnInit(): void {}

    open(event, clickedNote: Note) {
        event.preventDefault();
        event.stopPropagation();

        switch (event.target.id) {
            case 'edit':
                this.Modal = ModalComponent;
                break;
            case 'close':
                this.Modal = AskDeleteModalComponent;
                break;
            default:
                break;
        }

        const modalRef = this.modalService.open(this.Modal, {
            backdrop: true,
            size: 'lg',
            keyboard: true,
            centered: true,
            windowClass: 'custom-modal',
        });

        modalRef.componentInstance.id = clickedNote.id;
        modalRef.componentInstance.title = clickedNote.title;
        if (this.Modal === ModalComponent) {
            modalRef.componentInstance.content = clickedNote.description;
        }
    }

    exportNotes(notes: Note[]) {
        let course;
        this.courseParticipation$.subscribe(c => {
            course = c.bookedCourse.description;
        });
        this.docxCreatorService.createFromNotes(notes, this.moduleName, course);
    }
}
