import { createAction, props } from '@ngrx/store';
import { UUID } from '@cna/shared/generic-types';
import { LearningModuleDocumentInfo } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/learning-module-document-info';

export const loadModuleInfosForModuleId = createAction(
    '[global] load module infos for module id'
);

export const loadModuleInfosForModuleIdSuccess = createAction(
    '[global] load module infos for module id success',
    props<{
        moduleInfo: LearningModuleDocumentInfo[];
    }>()
);

export const loadModuleInfosForModuleIdFailure = createAction(
    '[global] load module infos for module id failure',
    props<{
        error: Error;
    }>()
);
