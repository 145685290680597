<div
    [ngClass]="{ locked: isDeactivated }"
    [routerLink]="isDeactivated ? null : routeLink"
    [routerLinkActive]="isDeactivated ? 'is_disabled' : 'is_active'"
    (click)="test()"
>
    <img
        [src]="tileImage"
        [alt]="tileTitle"
        [ngClass]="{ notice: tileTitle === 'Notizen' }"
    />
    <p>
        {{ tileTitle }}
    </p>
</div>
