import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';
import { selectBreadcrumbs } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/selectors';

export class BreadCrumbInfo {
    label: string;
    routerLink: string;
}

@Component({
    selector: 'cna-my-ak-jura-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
    breadcrumbs$: Observable<BreadCrumbInfo[]> =
        this.store.select(selectBreadcrumbs);

    constructor(private store: Store) {}

    ngOnInit(): void {}
}
