import { createReducer, on } from '@ngrx/store';
import * as fromActions from './actions';
import { ControlTest } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/control-test/control-test';

export const CONTROL_TESTS_STORE_NAME = 'control_tests';

export interface ControlTestsState {
    controlTest: ControlTest;
    controlTestResult: boolean;
    loading: boolean;
    errorDescription: string;
}

const initialState = {
    controlTest: undefined,
    controlTestResult: undefined,
    loading: true,
    errorDescription: undefined,
};

const _controlTestsReducer = createReducer<ControlTestsState>(
    initialState,

    /**
     * load ControlTest for actual Course
     */
    on(fromActions.loadControlTestForActualCourse, state => ({
        ...state,
        loading: true,
    })),

    on(fromActions.loadControlTestForActualCourseSuccess, (state, action) => ({
        ...state,
        controlTest: action.controlTest,
        loading: false,
    })),

    on(fromActions.loadControlTestForActualCourseFailure, state => ({
        ...state,
        loading: false,
    })),

    on(fromActions.saveControlTestAttemptSuccess, (state, action) => ({
        ...state,
        controlTestResult: action.result.passed,
        loading: false,
        /**
        moduleInfos: state.moduleInfos.map(mi =>
            mi.moduleId === action.moduleId
                ? {
                    ...mi,
                    controlTestResults: mi.controlTestResults.concat([
                        action.result,
                    ]),
                    controlTestPassed: action.result.passed,
                }
                : mi
        ), */
    })),

    on(fromActions.resetControlTestValidationResult, state => ({
        ...state,
        controlTestResult: undefined,
    }))
);

export function controlTestsReducer(state, action) {
    return _controlTestsReducer(state, action);
}
