<div style="display: flex; justify-content: center">
    <div class="sizeTable">
        <cna-page-heading pageHeadingTitle="Repetitorium"></cna-page-heading>
        <div
            class="adelle-sans-17 font-weight-300 dark-gray"
            style="margin-top: 12px; margin-bottom: 52px"
        >
            zum Kurs {{ courseTitle$ | async }}
        </div>
        <ngb-accordion #acc="ngbAccordion" class="description">
            <ngb-panel cardClass="accordion-title">
                <ng-template ngbPanelTitle>
                    <img
                        src="assets/Pfeil_Accordion-unten.svg"
                        alt="acc-arrow"
                        class="acc-arrow"
                    />
                    <span
                        class="learntime-accordion-ebene1-t1-text adelle-sans-17 dark-gray font-weight-600"
                        >Einführung</span
                    >
                </ng-template>
                <ng-template ngbPanelContent>
                    <p class="adelle-sans-17 font-weight-300 dark-gray">
                        Das AK JURA Modul »Repetitorium« dient der Vorbereitung
                        auf Ihre Abschlussprüfung. Hier sind alle
                        prüfungsrelevanten Themen Ihres Fachgebiets so
                        komprimiert und zusammengefasst, dass Ihr Prüfungserfolg
                        gewährleistet ist. <br /><br />
                        Die für die Prüfung benötigten (und zugelassenen)
                        Gesetzesmaterialien entsprechen denen, die Sie für die
                        Bearbeitung des nachfolgenden Repetitoriums benötigen.
                    </p>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</div>

<div style="display: flex; justify-content: center; margin-top: 24px">
    <div class="sizeTable">
        <cna-repetitorium-accordion [sidebar]="false">
        </cna-repetitorium-accordion>
    </div>
</div>

<div>
    <div class="d-flex justify-content-center pt-4" style="margin-bottom: 32px">
        <div class="sizeTable">
            <cna-page-heading
                pageHeadingTitle="Notizen – Repetitorium"
            ></cna-page-heading>
        </div>
    </div>

    <div class="d-flex justify-content-center pb-3">
        <div class="sizeTable">
            <cna-notes-accordion
                [notes]="notes$ | async"
                moduleName="Repetitorium"
            ></cna-notes-accordion>
        </div>
    </div>
</div>
