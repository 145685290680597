import { ActionReducer, createReducer, INIT, on, UPDATE } from '@ngrx/store';
import { SidenavContentType } from 'libs/projects/ak-jura/angular/my-ak-jura/shared-components/src/lib/sidenav/sidenav.component';
import * as sidenavActions from './actions';

export const SIDENAV_STORE_NAME = 'sidenav';

export interface SidenavState {
    sidenavIsActive: boolean;
    navInsideSidenav: boolean;
    sidenavContentType: SidenavContentType;
}

const initialState = {
    sidenavIsActive: false,
    navInsideSidenav: false,
    sidenavContentType: undefined,
};

const _sidenavReducer = createReducer<SidenavState>(
    initialState,

    /**
     * SideNav
     */
    on(sidenavActions.setSidenavActiveState, (state, { activeState }) => ({
        ...state,
        sidenavIsActive: activeState,
    })),
    on(sidenavActions.setSidenavContentType, (state, { contentType }) => ({
        ...state,
        sidenavContentType: contentType,
    })),
    on(sidenavActions.setNavInsideSidenav, (state, { activeState }) => ({
        ...state,
        navInsideSidenav: activeState,
    }))
);

export function sidenavReducer(state, action) {
    return _sidenavReducer(state, action);
}
