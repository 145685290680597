import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { CourseParticipation } from '../../../../../../shared/interfaces/src/lib/data-management/course/course-participation';
import { Observable } from 'rxjs/Observable';
import {
    selectNotesPerModule,
    selectRepNotes,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/notes/selectors';
import { translateCourseTypeEnum } from '../../../../../../shared/interfaces/src/lib/data-management/course/course';
import { selectCourseParticipationId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { setBreadcrumbs } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';
import { map, skipWhile, takeUntil, takeWhile } from 'rxjs/operators';
import { Subject, zip } from 'rxjs';
import { selectCustomersCourseParticipation } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';
import { loadNotes } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/notes/actions';
import { loadCourseParticipationInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';
import { ModuleNotes } from '@cna/projects/ak-jura/shared/interfaces';

@Component({
    selector: 'cna-notes-view',
    templateUrl: './notes-view.component.html',
    styleUrls: ['./notes-view.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class NotesViewComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject();
    courseType = '';

    notesPerModules$ = this.store.select(selectNotesPerModule).pipe(
        skipWhile(x => !x),
        takeUntil(this.destroy$)
    );

    repNotes$ = this.store.select(selectRepNotes).pipe(
        skipWhile(x => !x),
        takeUntil(this.destroy$)
    );

    courseParticipation$: Observable<CourseParticipation> = this.store
        .select(selectCustomersCourseParticipation)
        .pipe(
            skipWhile(x => !x),
            takeUntil(this.destroy$)
        );

    notesAmount$ = this.notesPerModules$.pipe(
        takeWhile(modules => modules.length > 0),
        map(modules =>
            modules.map(module => module.notes.length).reduce((a, b) => a + b)
        )
    );

    hasNotes$ = zip(this.notesPerModules$, this.repNotes$).pipe(
        map(([moduleNotes, repNotes]) => {
            return (
                repNotes.length > 0 ||
                moduleNotes.reduce(
                    (count: number, moduleNote: ModuleNotes) =>
                        count + moduleNote.notes.length,
                    0
                ) > 0
            );
        })
    );

    constructor(private store: Store) {}

    setBreadcrumbs() {
        this.store
            .select(selectCourseParticipationId)
            .subscribe(courseParticipationId => {
                if (courseParticipationId) {
                    this.store.dispatch(
                        setBreadcrumbs({
                            breadcrumbs: [
                                {
                                    label: 'Mein AK JURA',
                                    routerLink: courseParticipationId,
                                },
                                {
                                    label: 'Notizen',
                                    routerLink:
                                        courseParticipationId + '/notes',
                                },
                            ],
                        })
                    );
                }
            });
    }

    ngOnInit(): void {
        this.store.dispatch(loadCourseParticipationInfos());

        this.courseParticipation$.subscribe(() =>
            this.store.dispatch(loadNotes())
        );

        this.setBreadcrumbs();

        this.courseParticipation$
            .subscribe(value => {
                this.courseType = translateCourseTypeEnum(
                    value?.bookedCourse.type
                );
            })
            .unsubscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
