import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'cna-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class LoadingSpinnerComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
