import { createReducer, on } from '@ngrx/store';
import * as fromActions from './actions';
import * as fromCourseActions from '../course/actions';
import * as fromModulActions from '../actions';
import { uniqBy } from 'lodash';
import { ExamParticipation } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam-participation';
import { CustomerBaseData } from '@cna/projects/ak-jura/shared/interfaces';

export const CUSTOMER_STORE_NAME = 'customer';

export interface CustomerState {
    customer: CustomerBaseData;
    course: any; //TODO
    loading: boolean;
    error: any;
    course_participations: any; // all courses the specific user has - but only contains ids + title
    // examParticipations: ExamParticipation[];
}

const initialState = {
    customer: undefined,
    course: undefined,
    loading: false,
    error: undefined,
    course_participations: undefined,
    // examParticipations: ExamParticipation[];
};

const _customerReducer = createReducer<CustomerState>(
    initialState,

    /**
     * Load Customer
     */
    on(fromActions.loadCustomer, state => ({
        ...state,
        loading: true,
    })),
    on(fromActions.loadCustomerSuccess, (state, action) => ({
        ...state,
        loading: false,
        customer: action.customer,
    })),
    on(fromActions.loadCustomerFailure, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
    })),

    /**
     * Load Course
     */
    on(fromActions.loadCourse, state => ({
        ...state,
        loading: true,
    })),
    on(fromActions.loadCourseSuccess, (state, action) => ({
        ...state,
        loading: false,
        course: action.course,
    })),
    on(fromActions.loadCourseFailure, (state, action) => ({
        ...state,
        loading: false,
        error: action.error,
    })),

    /**
     * Edit Customer
     */
    on(fromActions.editCustomer, state => ({
        ...state,
        loading: true,
    })),
    on(fromActions.editCustomerSuccess, (state, action) => ({
        ...state,
        loading: false,
        customer: { ...state.customer, user: action.userData },
    })),
    on(fromActions.editCustomerFailure, state => ({
        ...state,
        loading: false,
    })),

    on(fromModulActions.loadModuleInfosSuccess, (state, action) => ({
        ...state,
        course_participations: state.course_participations.map(cp =>
            cp.id === action.moduleInfos[0].courseParticipationId
                ? {
                      ...cp,
                      reloadModuleInfo: false,
                  }
                : cp
        ),
        loading: false,
    })),

    on(fromModulActions.loadModuleDocumentSuccess, (state, action) => ({
        ...state,
        course_participations: state.course_participations.map(cp =>
            cp.id === action.moduleDocument.courseParticipationId
                ? {
                      ...cp,
                      reloadModuleDocument: false,
                  }
                : cp
        ),
        loading: false,
    }))
);

export function customerReducer(state, action) {
    return _customerReducer(state, action);
}
