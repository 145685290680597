import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IconTileComponent } from './icon-tile/icon-tile.component';
import { DashboardTileGridComponent } from './dashboard-tile-grid/dashboard-tile-grid.component';
import { PageHeadingComponent } from './page-heading/page-heading.component';
import { HeaderNavigationRowComponent } from './header-navigation-row/header-navigation-row.component';
import { HeaderNavigationIconTileComponent } from './header-navigation-icon-tile/header-navigation-icon-tile.component';

import { WarningTileComponent } from './warning-tile/warning-tile.component';
import { InputFieldWithLabelComponent } from './input-field-with-label/input-field-with-label.component';
import { GradientButtonComponent } from './gradient-button/gradient-button.component';
import { ExamTableComponent } from './exam-table/exam-table.component';
import { ExamDatesComponent } from './exam-dates/exam-dates.component';
import { RouterModule } from '@angular/router';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { LearnTimeWithProgressBarComponent } from './learn-time-with-progress-bar/learn-time-with-progress-bar.component';
import { ModuleTileComponent } from './module-tile/module-tile.component';
import { LearningDashboardTileGridComponent } from './learning-dashboard-tile-grid/learning-dashboard-tile-grid.component';
import { QuestionsAndSupportEducationAccordionComponent } from './questions-and-support-education-accordion/questions-and-support-education-accordion.component';
import { QuestionsAndSupportTrainingAccordionComponent } from './questions-and-support-training-accordion/questions-and-support-training-accordion.component';
import {
    NgbAccordionModule,
    NgbButtonsModule,
    NgbDropdownModule,
    NgbToastModule,
    NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { LearnstateAccordionComponent } from './learnstate-accordion/learnstate-accordion.component';
import { LearnstateMobileDropdownComponent } from './learnstate-mobile-dropdown/learnstate-mobile-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CourseListComponent } from '../../../learning/src/lib/course-list/course-list.component';
import { LearningModuleAccordionComponent } from './learning-module-accordion/learning-module-accordion.component';
import { NbSpinnerModule, NbTooltipModule } from '@nebular/theme';
import { NotesAccordionComponent } from './notes-accordion/notes-accordion.component';
import { HeaderSidenavIconTileComponent } from './header-sidenav-icon-tile/header-sidenav-icon-tile.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { SidenavContentOverviewComponent } from './sidenav-content-overview/sidenav-content-overview.component';
import { SidenavNotesComponent } from './sidenav-notes/sidenav-notes.component';
import { SidenavCustomizationComponent } from './sidenav-customization/sidenav-customization.component';
import { ModuleOverviewComponent } from './module-overview/module-overview.component';
import { SidenavNotesAccordionComponent } from './sidenav-notes-accordion/sidenav-notes-accordion.component';
import { AngularUiPipesModule } from '@cna/angular/ui/pipes';
import { RepetitoriumTileComponent } from './repetitorium-tile/repetitorium-tile.component';
import { AppToastsComponent } from './app-toasts/app-toasts.component';
import { ModalComponent } from './modal/modal.component';
import { AskDeleteModalComponent } from './ask-delete-modal/ask-delete-modal.component';
import { LearnTimeAccordionComponent } from './learn-time-accordion/learn-time-accordion.component';
import { ChapterCircleComponent } from './chapter-circle/chapter-circle.component';
import { ExamRelevantComponent } from './exam-relevant/exam-relevant.component';
import { NgLetModule } from '@ngrx-utils/store';
import { RepetitoriumOverviewComponent } from './repetitorium-overview/repetitorium-overview.component';
import { RepetitoriumAccordionComponent } from './repetitorium-accordion/repetitorium-accordion.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { EditCustomerPopupComponent } from './edit-customer-popup/edit-customer-popup.component';
import { LearningModuleAccordionSidenavComponent } from './learning-module-accordion-sidenav/learning-module-accordion-sidenav.component';
import { ArrowComponent } from './arrow/arrow.component';
import { CourseSelectComponent } from './course-select/course-select.component';
import { CollapseIconComponent } from './collapse-icon/collapse-icon.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { LearningFooterComponent } from './learning-footer/learning-footer.component';
import { CheckboxInputComponent } from './checkbox-input/checkbox-input.component';
import { BibliographyAccordionComponent } from './bibliography-accordion/bibliography-accordion.component';
import { ReadingContentComponent } from './reading-content/reading-content.component';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { LoadingContentComponent } from './loading-content/loading-content.component';
import { EditInvoiceAddressModalComponent } from './edit-invoice-address-modal/edit-invoice-address-modal.component';
import { StartIconComponent } from './start-icon/start-icon.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        NgbAccordionModule,
        FormsModule,
        NbSpinnerModule,
        AngularUiPipesModule,
        ReactiveFormsModule,
        NgbToastModule,
        NbTooltipModule,
        NgbTooltipModule,
        NgLetModule,
        NgbDropdownModule,
        NgbButtonsModule,
    ],
    declarations: [
        IconTileComponent,
        DashboardTileGridComponent,
        PageHeadingComponent,
        HeaderNavigationRowComponent,
        HeaderNavigationIconTileComponent,
        WarningTileComponent,
        InputFieldWithLabelComponent,
        GradientButtonComponent,
        ExamTableComponent,
        ExamDatesComponent,
        ProgressBarComponent,
        LearnTimeWithProgressBarComponent,
        ModuleTileComponent,
        LearningDashboardTileGridComponent,
        QuestionsAndSupportEducationAccordionComponent,
        QuestionsAndSupportTrainingAccordionComponent,
        LearnstateAccordionComponent,
        LearnstateMobileDropdownComponent,
        CourseListComponent,
        LearningModuleAccordionComponent,
        NotesAccordionComponent,
        HeaderSidenavIconTileComponent,
        SidenavComponent,
        SidenavContentOverviewComponent,
        SidenavNotesComponent,
        SidenavCustomizationComponent,
        ModuleOverviewComponent,
        SidenavNotesAccordionComponent,
        AppToastsComponent,
        RepetitoriumTileComponent,
        ModalComponent,
        AskDeleteModalComponent,
        LearnTimeAccordionComponent,
        ChapterCircleComponent,
        ExamRelevantComponent,
        RepetitoriumOverviewComponent,
        RepetitoriumAccordionComponent,
        BreadcrumbsComponent,
        EditCustomerPopupComponent,
        LearningModuleAccordionSidenavComponent,
        ArrowComponent,
        CourseSelectComponent,
        CollapseIconComponent,
        LoadingScreenComponent,
        LearningFooterComponent,
        CheckboxInputComponent,
        BibliographyAccordionComponent,
        ReadingContentComponent,
        LoadingSpinnerComponent,
        LoadingContentComponent,
        EditInvoiceAddressModalComponent,
        StartIconComponent,
    ],
    exports: [
        IconTileComponent,
        DashboardTileGridComponent,
        PageHeadingComponent,
        HeaderNavigationRowComponent,
        HeaderNavigationIconTileComponent,
        WarningTileComponent,
        InputFieldWithLabelComponent,
        GradientButtonComponent,
        ExamTableComponent,
        ExamDatesComponent,
        ProgressBarComponent,
        LearnTimeWithProgressBarComponent,
        ModuleTileComponent,
        LearningDashboardTileGridComponent,
        QuestionsAndSupportEducationAccordionComponent,
        QuestionsAndSupportTrainingAccordionComponent,
        LearnstateAccordionComponent,
        LearnstateMobileDropdownComponent,
        LearningModuleAccordionComponent,
        NotesAccordionComponent,
        HeaderSidenavIconTileComponent,
        SidenavComponent,
        SidenavContentOverviewComponent,
        SidenavNotesComponent,
        SidenavCustomizationComponent,
        AppToastsComponent,
        LearnTimeAccordionComponent,
        BreadcrumbsComponent,
        ArrowComponent,
        CourseSelectComponent,
        CollapseIconComponent,
        LoadingScreenComponent,
        LearningFooterComponent,
        CheckboxInputComponent,
        BibliographyAccordionComponent,
        BibliographyAccordionComponent,
        ReadingContentComponent,
        LoadingSpinnerComponent,
        LoadingContentComponent,
        EditInvoiceAddressModalComponent,
        StartIconComponent,
    ],
    providers: [DatePipe],
})
export class SharedComponentsModule {}
