import { Injectable } from '@angular/core';
import {
    Bibliography,
    Chapter,
    Examinable,
    FavoriteGroup,
    Note,
    ReadStatus,
    Section,
    TimeExpenditure,
    WoltersKluwerContent,
} from '@cna/projects/ak-jura/shared/interfaces';
import { filterNil } from '@cna/shared/helper';
import { isNil } from '@nestjs/common/utils/shared.utils';
import { OwnContent } from '../../../../../shared/interfaces/src/lib/own-content';
import { LearningModuleDocumentInfo } from '../../../../../shared/interfaces/src/lib/learning-module-document-info';
import { SectionNumberingPipe } from '../../../../../../../angular/ui/pipes/src/lib/section-numbering.pipe';

import { rfdc } from '@cna/shared/utils/deepclone';
import { UUID } from '@cna/shared/generic-types';

const clone = rfdc({});

@Injectable({
    providedIn: 'root',
})
export class ModuleDocumentHelperService {
    private footnoteContents = [];
    private sectionNumberingPipe: SectionNumberingPipe;
    constructor() {
        this.sectionNumberingPipe = new SectionNumberingPipe();
    }

    getAllLatestSectionNodes(node): Section[] {
        const childArrayName = this.getNodeChildArrayName(node);
        if (childArrayName === 'content') {
            return [node];
        }
        let temp = [];
        if (childArrayName) {
            node[childArrayName].forEach(childNode => {
                temp = temp.concat(this.getAllLatestSectionNodes(childNode));
            });
        }
        return temp;
    }

    getAllLatestSectionNodesNew(node): Section[] {
        let temp = [];

        /**const childArrayName = this.getNodeChildArrayName(node);
        if (childArrayName === 'content') {
            return [node];
        }
        let temp = [];
        if (childArrayName) {
            node[childArrayName].forEach(childNode => {
                temp = temp.concat(this.getAllLatestSectionNodes(childNode));
            });
        }
        return temp;*/

        if (node?.readStatus?.read) {
            return node;
        }

        if (node && 'sections' in node /** && !('readStatus' in node)*/) {
            for (let i = 0; i < node.sections.length; i++) {
                temp = temp.concat(
                    this.getAllLatestSectionNodesNew(node.sections[i])
                );
            }
        }

        return temp;
    }

    getPercentageReadForNode(
        node: Section | Chapter // | LearningModuleDocumentInfo
    ): number {
        if (node && 'readStatus' in node && node.readStatus?.read) {
            return 100;
        } else {
            return 0;

            /**
            const leaveSections = this.getAllLatestSectionNodesNew(node)

            /**
            const leaveSections = this.getAllLatestSectionNodes(node);
            const readSections = leaveSections.filter(s => s.readStatus?.read);
            return readSections.length
            // */
            /**
            if(node !== undefined && !('marginal' in node)){
                return (leaveSections.length * 100 / node.sections?.length);
            } */
        }
    }

    getPercentageReadForNodeModul(node: any): number {
        let numberOfAllSections = 0;
        let readSections = 0;

        for (let i = 0; i < node?.chapters?.length; i++) {
            for (let k = 0; k < node?.chapters[i].sections?.length; k++) {
                if (node?.chapters[i].sections[k].readable) {
                    numberOfAllSections++;
                    if (node?.chapters[i].sections[k].readStatus?.read) {
                        readSections++;
                    }
                } else {
                    for (
                        let o = 0;
                        o < node?.chapters[i].sections[k].sections?.length;
                        o++
                    ) {
                        numberOfAllSections++;
                        if (
                            node?.chapters[i].sections[k].sections[o].readStatus
                                ?.read
                        ) {
                            readSections++;
                        }
                    }
                }
            }
        }
        return (readSections / numberOfAllSections) * 100;
    }

    /** go 2 levels deep to sections containing the readstatus and then count and calc */
    getPercentageReadForNodeChapter(
        node: Chapter // | LearningModuleDocumentInfo
    ): number {
        let numberOfAllSections = 0;
        let readSections = 0;

        for (let i = 0; i < node?.sections?.length; i++) {
            if (node.sections[i]?.readable) {
                numberOfAllSections++;
                if (node.sections[i].readStatus?.read) {
                    readSections++;
                }
            } else {
                for (let k = 0; k < node.sections[i].sections?.length; k++) {
                    numberOfAllSections++;
                    if (node.sections[i].sections[k].readStatus?.read) {
                        readSections++;
                    }
                }
            }
        }

        return (readSections / numberOfAllSections) * 100;
    }

    /** */

    getTimeForNodeChapter(
        node: Chapter // | LearningModuleDocumentInfo
    ): number {
        let time: number;

        for (let i = 0; i < node?.sections?.length; i++) {
            if (node.sections[i].timeExpenditure?.duration) {
                time = this.sumTimeExpenditure(
                    time,
                    node.sections[i].timeExpenditure?.duration
                );
            }
            for (let k = 0; k < node.sections[i].sections?.length; k++) {
                time = this.sumTimeExpenditure(
                    time,
                    node.sections[i].sections[k].timeExpenditure?.duration
                );
            }
        }

        return time;
    }

    /** */
    getPercentageReadForNodeSection(
        node: Section // | LearningModuleDocumentInfo
    ): number {
        let numberOfAllSections = 0;
        let readSections = 0;

        for (let i = 0; i < node?.sections?.length; i++) {
            if (node.sections[i].readStatus?.read) {
                readSections++;
                numberOfAllSections++;
            } else {
                numberOfAllSections++;
            }
        }

        return (readSections / numberOfAllSections) * 100;
    }

    /** */

    getTimeForNodeSection(
        node: Section // | LearningModuleDocumentInfo
    ): number {
        let time: number;

        for (let i = 0; i < node?.sections?.length; i++) {
            time = this.sumTimeExpenditure(
                time,
                node.sections[i].timeExpenditure?.duration
            );
        }

        return time;
    }

    /** */

    getTimeForNodeSubSection(
        node: Section // | LearningModuleDocumentInfo
    ): number {
        return this.sumTimeExpenditure(
            undefined,
            node?.timeExpenditure?.duration
        );
    }

    /** */

    updateProgressOfNodeRecursive(node) {
        node.progress = this.getPercentageReadForNode(node);
        const childArrayName = this.getNodeChildArrayName(node);
        if (childArrayName) {
            node[childArrayName].forEach(childNode => {
                this.updateProgressOfNodeRecursive(childNode);
            });
        }
        return node;
    }

    setReadStatusForNodeInTree(node, readStatus: ReadStatus): boolean {
        if ('id' in node) {
            if (node.id === readStatus.sectionId) {
                node['readStatus'] = {
                    id: readStatus.id,
                    read: readStatus.read,
                };
                return true;
            }
        }
        if ('chapters' in node) {
            node.chapters.forEach(chapter =>
                this.setReadStatusForNodeInTree(chapter, readStatus)
            );
        }
        if ('sections' in node) {
            node.sections.forEach(ss =>
                this.setReadStatusForNodeInTree(ss, readStatus)
            );
        }
    }
    getNodeChildArrayName(
        node
    ): 'chapters' | 'sections' | 'content' | undefined {
        if (!node) {
            return undefined;
        }
        if (node.chapters) {
            return 'chapters';
        } else if (node.sections) {
            return 'sections';
        } else if (node.content) {
            return 'content';
        } else {
            return undefined;
        }
    }

    getIdOfFirstUnreadNode(node, isRootNode = true) {
        if (!isRootNode && node.id) {
            if (
                !node.readStatus ||
                (node.readStatus && node.readStatus.read === false)
            ) {
                return node.id;
            }
        }
        if (this.getNodeChildArrayName(node)) {
            for (const childNode of node[this.getNodeChildArrayName(node)]) {
                const unreadId = this.getIdOfFirstUnreadNode(childNode, false);
                if (unreadId !== undefined) {
                    return unreadId;
                }
            }
        }
    }

    /**
     * Searches the Tree recursive for the searchId  and returns the Node (with Subnodes) with the given Id
     * @param rootNode Entrynode to search in
     * @typeParam LearningModuleDocumentInfo, Chapter, Section
     * @param searchId UUID of the requested Node
     */
    getNodeByIdAndNextId(rootNode, searchId: UUID) {
        let nextReadingId;
        let tempNumberingChapter;
        let tempNumberingSection;
        let tempNumberingSubSection;

        if (!searchId || !rootNode) {
            return undefined;
        }

        // TODO
        for (let ci = 0; ci < rootNode.chapters.length; ci++) {
            for (let si = 0; si < rootNode.chapters[ci].sections.length; si++) {
                if (rootNode.chapters[ci].sections[si].id === searchId) {
                    if (rootNode.chapters[ci].sections[si + 1]) {
                        if (rootNode.chapters[ci].sections[si + 1].sections) {
                            nextReadingId =
                                rootNode.chapters[ci].sections[si + 1]
                                    .sections[0].id;
                        } else {
                            /** von section to next section - funktioniert */
                            nextReadingId =
                                rootNode.chapters[ci].sections[si + 1].id;
                        }
                    } else if (rootNode.chapters[ci + 1]) {
                        if (rootNode.chapters[ci + 1].sections[0])
                            if (
                                rootNode.chapters[ci + 1].sections[0].sections
                            ) {
                                nextReadingId =
                                    rootNode.chapters[ci + 1].sections[0]
                                        .sections[0].id;
                            } else {
                                nextReadingId =
                                    rootNode.chapters[ci + 1].sections[0].id;
                            }
                    }

                    tempNumberingChapter = this.sectionNumberingPipe.transform(
                        rootNode.chapters[ci].title
                    );
                    tempNumberingSection = this.sectionNumberingPipe.transform(
                        rootNode.chapters[ci].sections[si].title
                    );

                    return {
                        node: rootNode.chapters[ci].sections[si],
                        nextId: nextReadingId,
                        numbering: [tempNumberingChapter, tempNumberingSection],
                    };
                }

                if (rootNode.chapters[ci].sections[si].sections) {
                    for (
                        let ssi = 0;
                        ssi <
                        rootNode.chapters[ci].sections[si].sections.length;
                        ssi++
                    ) {
                        if (
                            rootNode.chapters[ci].sections[si].sections[ssi]
                                .id === searchId
                        ) {
                            if (
                                rootNode.chapters[ci].sections[si].sections[
                                    ssi + 1
                                ]
                            ) {
                                /** von subsection zu subcestion in derselben section - funktioniert - */
                                nextReadingId =
                                    rootNode.chapters[ci].sections[si].sections[
                                        ssi + 1
                                    ].id;
                            } else if (
                                !rootNode.chapters[ci].sections[si].sections[
                                    ssi + 1
                                ]
                            ) {
                                if (rootNode.chapters[ci].sections[si + 1]) {
                                    if (
                                        rootNode.chapters[ci].sections[si + 1]
                                            .sections
                                    ) {
                                        nextReadingId =
                                            rootNode.chapters[ci].sections[
                                                si + 1
                                            ].sections[0].id;
                                    } else {
                                        /** von subsection in section to 'just' the next section - funktioniert - */
                                        nextReadingId =
                                            rootNode.chapters[ci].sections[
                                                si + 1
                                            ].id;
                                    }
                                } else {
                                    if (rootNode.chapters[ci + 1]) {
                                        if (
                                            rootNode.chapters[ci + 1]
                                                .sections[0]
                                        ) {
                                            if (
                                                rootNode.chapters[ci + 1]
                                                    .sections[0].sections !==
                                                undefined
                                            ) {
                                                nextReadingId =
                                                    rootNode.chapters[ci + 1]
                                                        .sections[0].sections[0]
                                                        .id;
                                            } else {
                                                nextReadingId =
                                                    rootNode.chapters[ci + 1]
                                                        .sections[0].id;
                                            }
                                            /** von subsection in section to next chapter's subsection of section */
                                        } else {
                                            nextReadingId = undefined;
                                        }
                                    } else {
                                        nextReadingId = undefined;
                                    }
                                }
                            } else if (
                                rootNode.chapters[ci + 1].sections[0].section[0]
                            ) {
                                nextReadingId =
                                    rootNode.chapters[ci + 1].sections[0]
                                        .section[0];
                                /** zu überprüfen */
                            }

                            tempNumberingChapter =
                                this.sectionNumberingPipe.transform(
                                    rootNode.chapters[ci].title
                                );
                            tempNumberingSection =
                                this.sectionNumberingPipe.transform(
                                    rootNode.chapters[ci].sections[si].title
                                );
                            tempNumberingSubSection =
                                this.sectionNumberingPipe.transform(
                                    rootNode.chapters[ci].sections[si].sections[
                                        ssi
                                    ].title
                                );

                            return {
                                node: rootNode.chapters[ci].sections[si]
                                    .sections[ssi],
                                nextId: nextReadingId,
                                numbering: [
                                    tempNumberingChapter,
                                    tempNumberingSection,
                                    tempNumberingSubSection,
                                ],
                            };
                        }
                    }
                }
            }
        }
    }

    formatHeadline(node: Section | Chapter | OwnContent, level): string {
        if (
            node?.title &&
            !node?.title.startsWith('t3#') &&
            (<OwnContent>node).type !== 'OWN'
        ) {
            return level <= 7 && level > 0
                ? `<h${level}>${node.title}</h${level}>`
                : `<p class="headline level-${level}">${node.title}</p>`;
        }
        return '';
    }

    parseAndFormatTextFromFavoriteGroup(favoriteGroup: FavoriteGroup) {
        let text = `<h1>${favoriteGroup.title}</h1>`;
        favoriteGroup.examinables.forEach(
            (examinable: Omit<WoltersKluwerContent, 'documentRef'>) => {
                text = text.concat(examinable.text);
                text += '<br><br>';
            }
        );
        return text;
    }

    /**
     * Iterates recursive over the given Node and returns the HTML of the Contentnodes as string.
     * @param node Entry/Parent node
     */
    parseAndFormatTextFromNodeElement(
        node: Chapter | Section,
        examinables: Examinable[],
        level = 1
    ): string {
        let nodeText = '';

        // add Title / Headline
        nodeText = nodeText.concat(this.formatHeadline(node, level));

        // Add Content Nodes Text
        if (node && 'content' in node) {
            node.content.forEach(contentNode => {
                if ('text' in contentNode) {
                    if (
                        'documentRef' in contentNode &&
                        'marginal' in contentNode &&
                        examinables.find(
                            examinable =>
                                examinable.sectionId === node.id &&
                                examinable.documentRef ===
                                    contentNode.documentRef &&
                                examinable.marginal === contentNode.marginal &&
                                examinable.examinable === true
                        )
                    ) {
                        nodeText = nodeText.concat(
                            '<div class="examinable">' +
                                contentNode.text +
                                '</div>'
                        );
                    } else {
                        nodeText = nodeText.concat(contentNode.text);
                    }
                }
            });
        }

        // Add content of the subChapters
        const childArrayName = this.getNodeChildArrayName(node);
        if (childArrayName) {
            node[childArrayName].forEach(childNode => {
                nodeText = nodeText.concat(
                    this.parseAndFormatTextFromNodeElement(
                        childNode,
                        examinables,
                        level + 1
                    )
                );
            });
        }

        return nodeText;
    }

    getNotesRecursive(
        node: LearningModuleDocumentInfo | Chapter | Section,
        chapterNumbering: string[] = []
    ): Note[] {
        const tempNumbering = clone(chapterNumbering);
        if (node) {
            if ('title' in node) {
                tempNumbering.push(
                    this.sectionNumberingPipe.transform(node?.title)
                );
            }
        }
        let tempNotes: Note[] = [];

        if (node) {
            if ('notes' in node) {
                tempNotes = tempNotes.concat(
                    node.notes.map(note => ({
                        ...note,
                        chapterNumbering: tempNumbering,
                    }))
                );
            }
        }

        const childArrayName = this.getNodeChildArrayName(node);
        if (!(childArrayName === 'content') && childArrayName) {
            node[childArrayName].forEach(childNode => {
                tempNotes = tempNotes.concat(
                    // call the func again
                    this.getNotesRecursive(childNode, tempNumbering)
                );
            });
        }
        return tempNotes;
    }

    /**
     * Evaluates if the readstatus of the given node is True, if not evaluates if all subnodes has readstatus true.
     * @param node
     */
    getReadStatusForNode(
        node: Chapter | Section | LearningModuleDocumentInfo | FavoriteGroup
    ): number {
        if (
            node &&
            'workedOut' in node &&
            node.workedOut &&
            node.workedOut.workedOut === true
        ) {
            return 100;
        }
        if (node && 'readStatus' in node && node.readStatus?.read === true) {
            return 100;
        } else {
            const nodeArrayName = this.getNodeChildArrayName(node);
            if (nodeArrayName) {
                const nodesRead = node[nodeArrayName].filter(
                    n =>
                        n.readStatus?.read ||
                        this.getPercentageReadForNode(n) === 100
                );
                if (nodesRead) {
                    return (
                        (nodesRead.length / node[nodeArrayName].length) * 100
                    );
                }
            }
            return 0;
        }
    }

    getNodeIncludesExaminables(
        node: Chapter | Section | WoltersKluwerContent | OwnContent,
        examinables: Examinable[]
    ): boolean {
        if (!examinables) {
            return false;
        }
        if (node['marginal'] && node['documentRef']) {
            if (
                examinables.find(
                    ex =>
                        ex.marginal === node['marginal'] &&
                        ex.documentRef === node['documentRef'] &&
                        ex.examinable === true
                )
            ) {
                return true;
            }
        }
        let includesExaminable = false;
        if (this.getNodeChildArrayName(node)) {
            node[this.getNodeChildArrayName(node)].forEach(child => {
                includesExaminable =
                    includesExaminable ||
                    this.getNodeIncludesExaminables(child, examinables);
                if (includesExaminable) {
                    return true;
                }
            });
        }
        return includesExaminable;
    }

    getBibliographiesOfNode(
        node: Chapter | Section | WoltersKluwerContent | OwnContent
    ): Bibliography[] {
        if (node && 'bibliographies' in node) {
            return node.bibliographies;
        }
    }

    updateReadStatusOfNodesInTree(
        node: LearningModuleDocumentInfo | Chapter | Section,
        readStatusArray: ReadStatus[]
    ) {
        if ('id' in node) {
            const tempRS = readStatusArray.find(rs => rs.sectionId === node.id);
            if (tempRS) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                node.readStatus = tempRS;
            }
        }
        const childArrayName = this.getNodeChildArrayName(node);
        if (childArrayName) {
            node[childArrayName].forEach(childNode =>
                this.updateReadStatusOfNodesInTree(childNode, readStatusArray)
            );
        }
    }

    /**
     * Adds the given TimeExpenditure to the right Node, returns and updates accumulated timeRead
     * @param childArray Array of chapters or sections. Gets updated in place.
     * @param timeExpenditure a new TimeExpenditure Object to add to the reading Time.
     */
    updateReadingTime(
        childArray: Chapter[] | Section[],
        timeExpenditure?: TimeExpenditure
    ): { childArray: Chapter[] | Section[]; timeRead: number } {
        let accTimeRead = 0;
        childArray.forEach(child => {
            child.timeRead =
                'timeExpenditure' in child &&
                'duration' in child.timeExpenditure
                    ? child.timeExpenditure.duration
                    : 0;

            if (
                timeExpenditure &&
                'duration' in timeExpenditure &&
                child.id &&
                child.id === timeExpenditure.sectionId
            ) {
                child.timeRead += timeExpenditure.duration;
                if ('timeExpenditure' in child) {
                    child.timeExpenditure.duration += timeExpenditure.duration;
                } else {
                    child.timeExpenditure = {
                        duration: timeExpenditure.duration,
                    };
                }
            }
            const childArrayName = this.getNodeChildArrayName(child);
            if (childArrayName) {
                const temp = this.updateReadingTime(
                    child[childArrayName],
                    timeExpenditure
                );
                child.timeRead += temp.timeRead;
            }
            accTimeRead += child.timeRead;
        });

        return {
            childArray: childArray,
            timeRead: accTimeRead,
        };
    }

    /**
     * Rounds a time expanditure to the next complete minute
     * @param duration
     * @param rounding
     * @private
     */
    private roundTimeExpenditure(
        duration: number,
        rounding: 'up' | 'down' | 'precise' = 'up'
    ): number {
        switch (rounding) {
            case 'up':
                return (
                    duration + (duration % 60 === 0 ? 0 : 60 - (duration % 60))
                );
            case 'down':
                return duration - (duration % 60);
            case 'precise':
                return duration;
        }
    }

    private sumTimeExpenditure(
        sum: number | undefined | null,
        duration: number | undefined | null
    ): number | undefined {
        if (
            (sum === null || sum === undefined) &&
            (duration === undefined || duration === null)
        ) {
            return undefined;
        } else if (sum === null || sum === undefined) {
            return this.roundTimeExpenditure(duration);
        } else if (duration === null || duration === undefined) {
            return sum;
        } else {
            return sum + this.roundTimeExpenditure(duration);
        }
    }
}
