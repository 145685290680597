import { Injectable } from '@angular/core';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { LearningModuleDocumentInfo } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/learning-module-document-info';
import { Exam } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam';
import { ModuleNotes, Note } from '@cna/projects/ak-jura/shared/interfaces';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleDocumentHelperService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/module-document-helper.service';
import { ToasterService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/toaster.service';
import {
    catchError,
    flatMap,
    map,
    mergeAll,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { selectCourseParticipationId } from '../router.selector';
import { forkJoin, Observable, of } from 'rxjs';
import { ToastType } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/toast-type.enum';
import { environment } from '../../../environments/environment';
import { UUID } from '@cna/shared/generic-types';
import * as notesActions from './actions';
import { selectCustomersCourseParticipationModules } from '../course_participation/selectors';
import { selectModuleInfo } from '../module_infos/selectors';
import { loadCourseParticipationInfosSuccess } from '../course_participation/actions';

@Injectable()
export class NotesEffects {
    private moduleDocumentInfoService: EntityCollectionService<LearningModuleDocumentInfo>;
    private examService: EntityCollectionService<Exam>;
    private noteService: EntityCollectionService<Note>;

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private entityServices: EntityServices,
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private moduleDocumentHelperService: ModuleDocumentHelperService,
        private toasterService: ToasterService
    ) {
        this.moduleDocumentInfoService =
            this.entityServices.getEntityCollectionService<LearningModuleDocumentInfo>(
                'Module-document-info'
            );
        this.noteService =
            entityServices.getEntityCollectionService<Note>('Note');
        this.examService =
            this.entityServices.getEntityCollectionService<Exam>('Exam');
    }

    /**
     * Extract Module notes
     */
    extractModuleNotes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(notesActions.extractNotesForCourse),
            withLatestFrom(
                this.store.select(selectModuleInfo),
                this.store.select(selectCourseParticipationId)
            ),
            mergeMap(([action, moduleInfos, cpId]) => {
                try {
                    const temp: ModuleNotes[] = [];

                    /** ggf. wenn wieder alle 4 Module geladen werden
                    moduleInfos.forEach(moduleInfo => {
                        temp.push({
                            courseParticipationId: cpId,
                            moduleNumber: moduleInfo.sequentialNumber,
                            notes: this.moduleDocumentHelperService.getNotesRecursive(
                                moduleInfo
                            ),
                            moduleId: moduleInfo.moduleId,
                        });
                    }); */

                    temp.push({
                        courseParticipationId: cpId,
                        moduleNumber: moduleInfos?.sequentialNumber ?? 66,
                        notes: this.moduleDocumentHelperService.getNotesRecursive(
                            moduleInfos
                        ),
                        moduleId: moduleInfos?.moduleId,
                    });

                    return of(
                        notesActions.extractNotesForCourseSuccess({
                            notes: temp,
                        })
                    );
                } catch (error) {
                    console.log('extractNotesForCourseFailure', error);
                    return of(
                        notesActions.extractNotesForCourseFailure({ error })
                    );
                }
            })
        );
    });

    /**
     * Add Note
     */
    addNewNote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(notesActions.addNote),
            switchMap(action => this.noteService.add(action.note)),
            withLatestFrom(this.store.select(selectCourseParticipationId)),
            map(([note, cpId]) =>
                notesActions.addNoteSuccess({
                    note: note,
                    courseParticipationId: cpId.toString(),
                })
            ),
            catchError(err => of(notesActions.addNoteFailure({ err })))
        );
    });

    addNewNoteFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notesActions.addNoteFailure),
                tap(() => {
                    this.toasterService.show(
                        'Es ist ein Fehler beim speichern der Notiz aufgetreten!',
                        ToastType.error
                    );
                })
            ),
        { dispatch: false }
    );

    /**
     * Update Note
     */
    updateNote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(notesActions.updateNote),
            switchMap(action =>
                this.http.patch(environment.apiHost + '/note/' + action.id, {
                    description: action.content,
                    title: action.title,
                })
            ),
            map((note: Note) => notesActions.updateNoteSuccess({ note: note })),
            catchError(err => of(notesActions.updateNoteFailure({ err })))
        );
    });

    updateNoteFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notesActions.updateNoteFailure),
                tap(() => {
                    this.toasterService.show(
                        'Es ist ein Fehler beim aktualisieren der Notiz aufgetreten!',
                        ToastType.error
                    );
                })
            ),
        { dispatch: false }
    );

    /**
     * Delete Note
     */
    deleteNote$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(notesActions.deleteNote),
            mergeMap(action => {
                return this.http
                    .delete(environment.apiHost + '/note/' + action.noteId, {})
                    .pipe(
                        map(delRes => [delRes, action.noteId]),
                        catchError(err =>
                            of(notesActions.deleteNoteFailure({ err }))
                        )
                    );
            }),
            map(([action, noteId]: [any, UUID]) => {
                if (action != null) {
                    if (action.type === notesActions.deleteNoteFailure.type) {
                        return notesActions.deleteNoteFailure({
                            err: action.err,
                        });
                    }
                }
                return notesActions.deleteNoteSuccess({
                    noteId: noteId,
                });
            })
        );
    });

    addNoteFailure = createEffect(
        () =>
            this.actions$.pipe(
                ofType(notesActions.deleteNoteFailure),
                tap(() => {
                    this.toasterService.show(
                        'Es ist ein Fehler beim löschen der Notiz aufgetreten!',
                        ToastType.error
                    );
                })
            ),
        { dispatch: false }
    );

    selectNotes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(notesActions.loadNotes, loadCourseParticipationInfosSuccess),
            withLatestFrom(
                this.store.select(selectCourseParticipationId),
                this.store.select(selectCustomersCourseParticipationModules)
            ),
            mergeMap(([action, courseParticipationId, modules]) => {
                return this.http
                    .get<Note[]>(`${environment.apiHost}/note`, {
                        params: {
                            filter: `courseParticipationId||$eq||${courseParticipationId}`,
                        },
                    })
                    .pipe(
                        mergeMap<Note[], Observable<Note[]>>(notes => {
                            return forkJoin(
                                notes.map(note =>
                                    this.http
                                        .get<string[]>(
                                            environment.apiHost + '/numbering',
                                            {
                                                params:
                                                    note.sectionId &&
                                                    note.moduleId
                                                        ? {
                                                              sectionId:
                                                                  note.sectionId,
                                                              moduleId:
                                                                  note.moduleId,
                                                          }
                                                        : {
                                                              favoriteGroupId:
                                                                  note.favoriteGroupId,
                                                          },
                                            }
                                        )
                                        .pipe(
                                            map<string[], Note>(
                                                chapterNumbering => ({
                                                    ...note,
                                                    chapterNumbering,
                                                })
                                            )
                                        )
                                )
                            );
                        }),
                        map<
                            Note[],
                            { moduleNotes: ModuleNotes[]; repNotes: Note[] }
                        >(notes => {
                            return {
                                moduleNotes: modules.map(module => ({
                                    courseParticipationId,
                                    moduleNumber: module.sequentialNumber,
                                    notes: notes.filter(
                                        note => note.moduleId === module.id
                                    ),
                                    moduleId: module.id,
                                    chapterNumbering: module.sequentialNumber,
                                })),
                                repNotes: notes.filter(
                                    note => note.favoriteGroupId
                                ),
                            };
                        })
                    );
            }),
            map(result => {
                return notesActions.loadNotesSuccess(result);
            }),
            catchError(error => of(notesActions.loadNotesFailure({ error })))
        )
    );
}
