import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as globalActions from './actions';
import * as notesActions from './notes/actions';

import {
    catchError,
    map,
    mergeMap,
    skipWhile,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { combineLatest, of } from 'rxjs';
import { selectCourseParticipationId, selectModuleId } from './router.selector';
import { Store } from '@ngrx/store';
import {
    selectActualReloadModuleInfo,
    selectModuleInfosForActualCourseParticipation,
} from './selectors';

import {
    selectActualCourseId,
    selectActualCourseObject,
} from './course/selectors';

import { selectCustomer } from './customer/selectors';

import { ActivatedRoute, Router } from '@angular/router';
import {
    ModuleDocument,
    Note,
    ReadStatus,
    TimeExpenditure,
} from '@cna/projects/ak-jura/shared/interfaces';
import { ModuleDocumentHelperService } from '../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/module-document-helper.service';
import { LearningModuleDocumentInfo } from '../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/learning-module-document-info';
import { ToasterService } from 'libs/projects/ak-jura/angular/shared-components/src/lib/services/toaster.service';
import { ToastType } from '../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/toast-type.enum';
import { Exam } from '../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam';

import * as errorDescribtionActions from './error_describtion/actions';
import * as repetitoriumActions from './repetitorium/actions';

import { selectModuleInfo } from './module_infos/selectors';

@Injectable()
export class GlobalEffects {
    private moduleDocumentInfoService: EntityCollectionService<LearningModuleDocumentInfo>;
    private examService: EntityCollectionService<Exam>;
    private noteService: EntityCollectionService<Note>;

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private entityServices: EntityServices,
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private moduleDocumentHelperService: ModuleDocumentHelperService,
        private toasterService: ToasterService
    ) {
        this.moduleDocumentInfoService =
            this.entityServices.getEntityCollectionService<LearningModuleDocumentInfo>(
                'Module-document-info'
            );
        this.noteService =
            entityServices.getEntityCollectionService<Note>('Note');
        this.examService =
            this.entityServices.getEntityCollectionService<Exam>('Exam');
    }

    /**
     * Load Module Info
     */
    loadModuleInfos$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(globalActions.loadModuleInfos),
            withLatestFrom(
                this.store.select(selectCourseParticipationId),
                this.store.select(selectActualCourseObject),
                this.store.select(selectActualReloadModuleInfo),
                this.store.select(selectModuleInfosForActualCourseParticipation)
            ),
            mergeMap(
                ([
                    action,
                    courseParticipationId,
                    course,
                    reloadModuleInfo,
                    previousModuleInfos,
                ]) => {
                    if (reloadModuleInfo || previousModuleInfos.length === 0) {
                        const infos = [];
                        course?.modules?.forEach(learnModule => {
                            infos.push(
                                this.moduleDocumentInfoService
                                    .getWithQuery({
                                        moduleId: learnModule.id.toString(),
                                        courseParticipationId:
                                            courseParticipationId,
                                    })
                                    .pipe(
                                        map(
                                            (
                                                moduleDocumentInfoArray: LearningModuleDocumentInfo[]
                                            ) => {
                                                console.log('after');
                                                return moduleDocumentInfoArray[0];
                                            }
                                        ),
                                        map(
                                            (
                                                moduleInfo: LearningModuleDocumentInfo
                                            ) => {
                                                const expectedHours =
                                                    course.targetReadingTime /
                                                    course.modules.length;
                                                const expectedSeconds =
                                                    expectedHours * 3600; // cause reading time is in seconds

                                                const temp =
                                                    this.moduleDocumentHelperService.updateReadingTime(
                                                        moduleInfo.chapters
                                                    );
                                                return {
                                                    ...this.moduleDocumentHelperService.updateProgressOfNodeRecursive(
                                                        moduleInfo
                                                    ),
                                                    chapters: temp.childArray,
                                                    timeRead: temp.timeRead,
                                                    learnModuleId:
                                                        learnModule.id,
                                                    sequentialNumber:
                                                        learnModule.sequentialNumber,
                                                    controlTestId:
                                                        learnModule.controlTestId,
                                                    controlTestPassed:
                                                        moduleInfo
                                                            .controlTestResults
                                                            .length === 0
                                                            ? null
                                                            : moduleInfo.controlTestResults.filter(
                                                                  x => x.passed
                                                              ).length > 0,
                                                    expectedHours:
                                                        expectedHours,
                                                    userProgressHours:
                                                        Math.round(
                                                            temp.timeRead / 3600
                                                        ),
                                                    userProgressPercent:
                                                        temp.timeRead === 0
                                                            ? 0
                                                            : (temp.timeRead *
                                                                  100) /
                                                              expectedSeconds,
                                                    unlocked: true,
                                                };
                                            }
                                        )
                                    )
                            );
                        });
                        const returnO = combineLatest(infos).pipe(
                            map((moduleInfos: LearningModuleDocumentInfo[]) =>
                                moduleInfos.sort(
                                    (a, b) =>
                                        a.sequentialNumber - b.sequentialNumber
                                )
                            ),
                            withLatestFrom(of(true))
                        );
                        return returnO;
                    } else {
                        return of(previousModuleInfos).pipe(
                            withLatestFrom(of(false))
                        );
                    }
                }
            ),
            map(
                ([moduleInfos, updated]: [
                    LearningModuleDocumentInfo[],
                    boolean
                ]) => {
                    let previousModuleCompleted = true;

                    moduleInfos.forEach(moduleInfo => {
                        moduleInfo.unlocked = previousModuleCompleted;
                        previousModuleCompleted =
                            this.moduleDocumentHelperService.getReadStatusForNode(
                                moduleInfo
                            ) >= 100 && moduleInfo.controlTestPassed;
                    });

                    this.store.dispatch(
                        repetitoriumActions.setRepetitoriumUnlockedState({
                            unlockedState:
                                /** environment.devEnvironment || */
                                previousModuleCompleted,
                        })
                    );

                    return globalActions.loadModuleInfosSuccess({
                        moduleInfos,
                        updated,
                    });
                }
            ),

            catchError(err => {
                console.log('error', err);
                return of(globalActions.loadModuleInfosFailure());
            })
        );
    });

    loadModuleInfosSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.loadModuleInfosSuccess),
                tap(action => {
                    //if (action.updated) {
                    this.store.dispatch(notesActions.extractNotesForCourse());
                    //}
                })
            );
        },
        { dispatch: false }
    );

    loadModuleInfosFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.loadModuleInfosFailure),
                tap(() => {
                    this.store.dispatch(
                        errorDescribtionActions.setErrorDescription({
                            errorDescription:
                                'Beim Laden der Kursdaten ist ein Fehler aufgetreten.',
                        })
                    );
                })
            );
        },
        { dispatch: false }
    );

    /**
     * Load Module Document
     */
    loadModuleDocument$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.loadModuleDocument),
                withLatestFrom(
                    this.store.select(selectCourseParticipationId),
                    this.store.select(selectModuleId),
                    this.store.select(selectModuleInfo)
                    //this.store.select(selectActualReloadModuleDocument)
                ),
                tap(
                    ([
                        action,
                        courseParticipationId,
                        moduleId,
                        moduleDocument,
                        // reloadModuleDocument,
                    ]) => {
                        /** if (reloadModuleDocument || !moduleDocument) { */
                        this.http
                            .get<ModuleDocument>(
                                environment.apiHost + '/module-document',
                                {
                                    params: {
                                        moduleId: moduleId,
                                        courseParticipationId:
                                            courseParticipationId,
                                    },
                                }
                            )
                            .subscribe(
                                loadedModuleDocument => {
                                    this.store.dispatch(
                                        globalActions.loadModuleDocumentSuccess(
                                            {
                                                moduleDocument:
                                                    loadedModuleDocument[0],
                                            }
                                        )
                                    );
                                },
                                error => {
                                    this.store.dispatch(
                                        globalActions.loadModuleDocumentFailure(
                                            {
                                                error,
                                            }
                                        )
                                    );
                                    return error;
                                }
                            );
                        /** } else {
                            this.store.dispatch(
                                globalActions.loadModuleDocumentSkipped()
                            );
                        } */
                    }
                )
            );
        },
        { dispatch: false }
    );

    /**
     * Load Time Expenditures
     */
    loadTimeExpendituresForActualCourse$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(globalActions.loadTimeExpendituresForActualCourse),
            withLatestFrom(this.store.select(selectCourseParticipationId)),
            mergeMap(([action, courseParticipationId]) =>
                this.http.get(
                    environment.apiHost +
                        '/time-expenditure/' +
                        courseParticipationId
                )
            ),

            map((timeExpenditures: TimeExpenditure[]) =>
                globalActions.loadTimeExpendituresForActualCourseSuccess({
                    timeExpenditures: timeExpenditures,
                })
            ),
            catchError(error =>
                of(
                    globalActions.loadTimeExpendituresForActualCourseFailure({
                        error,
                    })
                )
            )
        );
    });

    loadTimeExpendituresForActualCourseFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(
                    globalActions.loadTimeExpendituresForActualCourseFailure
                ),
                tap(error => {
                    console.log(
                        'Error in loadTimeExpendituresForActualCourse',
                        error
                    );
                    this.toasterService.show(
                        'Lesezeiten konnten nicht vom Server abgerufen werden, bitte versuchen Sie die Seite neu zu laden.',
                        ToastType.error
                    );
                })
            );
        },
        { dispatch: false }
    );

    /**
     * Set Read Status for Section
     */
    setReadStatusForSections$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(globalActions.setReadStatusForSection),
            withLatestFrom(
                this.store.select(selectCourseParticipationId),
                this.store.select(selectModuleId)
            ),
            mergeMap(([action, courseParticipationId, moduleId]) =>
                this.http.post<ReadStatus[]>(
                    environment.apiHost + '/read-status',
                    {
                        courseParticipationId: courseParticipationId,
                        moduleId: moduleId,
                        sectionId: action.sectionId,
                        read: action.readStatus,
                    }
                )
            ),
            map(rs => {
                return globalActions.setReadStatusForSectionsSuccess({
                    readStatus: rs,
                });
            }),
            catchError(error =>
                of(
                    globalActions.setReadStatusForSectionsFailure({
                        error,
                    })
                )
            )
        );
    });

    setReadStatusForSectionFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(globalActions.setReadStatusForSectionsFailure),
                tap(err => {
                    console.log(err);
                    this.toasterService.show(
                        'Gelesen Status für das aktuelle Kapitel konnte nicht gespeichert werden, bitte versuchen Sie es später noch einmal!',
                        ToastType.error
                    );
                })
            ),
        { dispatch: false }
    );

    closeRunningReadingSession$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(globalActions.closeRunningReadingSession),
            withLatestFrom(this.store.select(selectCustomer)),
            mergeMap(([action, customer]) =>
                this.http.delete(
                    environment.apiHost + `/sessions?sub=${customer.sub}`
                )
            ),
            map(() => globalActions.closeRunningReadingSessionSuccess()),
            catchError(error =>
                of(
                    globalActions.closeRunningReadingSessionFailure({
                        error,
                    })
                )
            )
        );
    });

    closeRunningReadingSessionSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.closeRunningReadingSessionSuccess),
                tap(action => {
                    window.location.reload();
                })
            );
        },
        { dispatch: false }
    );

    closeRunningReadingSessionFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(globalActions.closeRunningReadingSessionFailure),
                tap(error => {
                    this.toasterService.show(
                        'Sitzung konnte nicht beendet werden: ' + error.error,
                        ToastType.error
                    );
                })
            ),
        { dispatch: false }
    );
}
