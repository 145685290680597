import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/environments/environment';
import { Store } from '@ngrx/store';
import { setBreadcrumbs } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';
import { selectCourseParticipationId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { loadCourseParticipationInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';
import {
    selectCourseParticipationLoading,
    selectCustomersCourseParticipation,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';
import { Observable } from 'rxjs/Observable';
import {
    Course,
    CourseParticipation,
    CourseType,
} from '@cna/projects/ak-jura/shared/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { UUID } from '@cna/shared/generic-types';
import { merge, Subject } from 'rxjs';
import { finalize, skipWhile, take, takeUntil, tap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { LearningModuleDocumentInfo } from '../../../../../../shared/interfaces/src/lib/learning-module-document-info';

// Übersicht der Module
@Component({
    selector: 'cna-course-list',
    templateUrl: './course-list.component.html',
    styleUrls: ['./course-list.component.scss'],
})
export class CourseListComponent implements OnInit, OnDestroy {
    private _destroy$ = new Subject<void>();

    private pdfLoading$ = new Subject<boolean>();

    loading$: Observable<boolean> = merge(
        this.store.select(selectCourseParticipationLoading),
        this.pdfLoading$
    ).pipe(takeUntil(this._destroy$));

    showPdfLoadingInfo$ = this.pdfLoading$.asObservable();

    courseParticipation$: Observable<
        CourseParticipation & {
            bookedCourse: Course & {
                modules: LearningModuleDocumentInfo[];
                repetitorium: { unlocked: boolean } | undefined;
            };
        }
    > = this.store.select(selectCustomersCourseParticipation).pipe(
        takeUntil(this._destroy$),
        skipWhile(cP => !cP)
    );

    CourseType = CourseType;

    constructor(
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient
    ) {}

    ngOnInit(): void {
        this.setBreadcrumbs();
        this.store.dispatch(loadCourseParticipationInfos());

        /** only for development
        this.store.dispatch(setRepetitoriumUnlockedState({ unlockedState: true }));
         */
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    setBreadcrumbs() {
        this.store
            .select(selectCourseParticipationId)
            .subscribe(courseParticipationId =>
                this.store.dispatch(
                    setBreadcrumbs({
                        breadcrumbs: [
                            {
                                label: 'Mein AK JURA',
                                routerLink: courseParticipationId,
                            },
                            {
                                label: 'Lernstatus',
                                routerLink:
                                    courseParticipationId +
                                    '/learning/courseOverview',
                            },
                        ],
                    })
                )
            )
            .unsubscribe();
    }

    async navigateToModule(event: { moduleId: UUID }): Promise<void> {
        await this.router.navigate(
            ['../', 'moduleOverview', event.moduleId], // learnModuleId
            { relativeTo: this.route }
        );
    }

    async navigateToRepetitorium(): Promise<void> {
        await this.router.navigate(['../', 'repetitorium'], {
            relativeTo: this.route,
        });
    }

    printModule(event: { moduleId: UUID }): void {
        this.pdfLoading$.next(true);
        this.http
            .post<{
                contentType: string;
                filename: string;
                base64Content: string;
            }>(`${environment.apiHost}/modules/${event.moduleId}/export`, {})
            .pipe(
                tap(async ({ contentType, base64Content, filename }) => {
                    const fetchResponse = await fetch(
                        `data:${contentType};base64,${base64Content}`
                    );
                    const blob = await fetchResponse.blob();
                    saveAs(blob, filename);
                }),
                take(1),
                finalize(() => this.pdfLoading$.next(false))
            )
            .subscribe();
    }

    printRepetitorium(event: { courseId: UUID }): void {
        this.pdfLoading$.next(true);
        this.http
            .post<{
                contentType: string;
                filename: string;
                base64Content: string;
            }>(
                `${environment.apiHost}/repetitorium/export?courseId=${event.courseId}`,
                {}
            )
            .pipe(
                tap(async ({ contentType, base64Content, filename }) => {
                    const fetchResponse = await fetch(
                        `data:${contentType};base64,${base64Content}`
                    );
                    const blob = await fetchResponse.blob();
                    saveAs(blob, filename);
                }),
                take(1),
                finalize(() => this.pdfLoading$.next(false))
            )
            .subscribe();
    }
}
