<div ngbTooltip="Prüfungsrelevant" placement="left">
    <div class="d-flex flex-column justify-content-start">
        <img
            [ngClass]="(sidebar$ | async) ? 'sidenav-star' : 'normal-star'"
            src="assets/stern.svg"
        />
    </div>
</div>

<!-- [ngStyle]="{ marginTop: (sidebar$ | async) ? '16px' : '14px' }" -->
