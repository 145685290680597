import { Component, OnDestroy, OnInit } from '@angular/core';
import { filterNil } from '@cna/shared/helper';
import { Store } from '@ngrx/store';
import { setBreadcrumbs } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';
import { selectCourseParticipationId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import {
    map,
    skipWhile,
    startWith,
    takeUntil,
    withLatestFrom,
} from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { selectActualCourseObject } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course/selectors';
import { loadRepetitoriumForActualCourse } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/repetitorium/actions';
import { loadCourseParticipationInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';
import {
    selectModuleNotesByModuleIdFromRouter,
    selectRepNotes,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/notes/selectors';
import { Subject } from 'rxjs';

@Component({
    selector: 'cna-repetitorium-overview',
    templateUrl: './repetitorium-overview.component.html',
    styleUrls: ['./repetitorium-overview.component.scss'],
})
export class RepetitoriumOverviewComponent implements OnInit, OnDestroy {
    private _destroy$ = new Subject<void>();

    courseTitle$: Observable<any> = this.store
        .select(selectActualCourseObject)
        .pipe(
            takeUntil(this._destroy$),
            filterNil(),
            map(x => x.description)
        );

    notes$ = this.store
        .select(selectRepNotes)
        .pipe(takeUntil(this._destroy$), startWith([]));

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.dispatch(loadCourseParticipationInfos());
        this.store.dispatch(loadRepetitoriumForActualCourse());

        this.setBreadcrumbs();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }

    setBreadcrumbs() {
        this.store
            .select(selectCourseParticipationId)
            .pipe(takeUntil(this._destroy$))
            .subscribe(courseParticipationId => {
                this.store.dispatch(
                    setBreadcrumbs({
                        breadcrumbs: [
                            {
                                label: 'Mein AK JURA',
                                routerLink: courseParticipationId,
                            },
                            {
                                label: 'Lernstatus',
                                routerLink:
                                    courseParticipationId +
                                    '/learning/courseOverview',
                            },
                            {
                                label: 'Repetitorium',
                                routerLink:
                                    courseParticipationId +
                                    '/learning/repetitorium/',
                            },
                        ],
                    })
                );
            });
    }
}
