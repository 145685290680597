import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ExamsService {
    constructor(
        private httpClient: HttpClient,
    ) {}

    cancelExamParticipation(examParticipationId: string) {
        return this.httpClient.post(`${environment.apiHost}/exam-participation/${examParticipationId}/cancellation-request`, {});
    }
}
