import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import {
    CourseType,
    Examinable,
} from '@cna/projects/ak-jura/shared/interfaces';
import { select, Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import {
    selectCourseParticipationId,
    selectCurrentRoute,
    selectReadingId,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { LearningModuleDocumentInfo } from '../../../../../../shared/interfaces/src/lib/learning-module-document-info';
import { ModuleDocumentHelperService } from '../../../../../shared-components/src/lib/services/module-document-helper.service';
import { selectModuleExaminables } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/examinables/selectors';

import {
    /** TODO Sidenav kann hier raus? */
    selectSidenavActiveState,
    selectSidenavContentType,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/selectors';

import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { setNavInsideSidenav } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/actions';
import { SidenavContentType } from '../sidenav/sidenav.component';
import { loadModuleExaminables } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/examinables/actions';

@Component({
    selector: 'cna-learning-module-accordion',
    templateUrl: './learning-module-accordion.component.html',
    styleUrls: ['./learning-module-accordion.component.scss'],
})
export class LearningModuleAccordionComponent implements OnInit, OnDestroy {
    @Input() moduleDocumentInfo: LearningModuleDocumentInfo;
    @Input() moduleName: string;
    @Input() sidebar: boolean;
    @Input() courseType: CourseType;

    CourseType = CourseType;

    moduleProgress: number;
    public examinables: Examinable[];
    destroy$ = new Subject<void>();
    info = {};
    openChapterPanelNames = '';
    openSectionPanel = '';

    readingId$ = this.store.pipe(
        select(selectReadingId),
        takeUntil(this.destroy$)
    );

    constructor(
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        public moduleDocumentHelperService: ModuleDocumentHelperService
    ) {}

    ngOnInit(): void {
        this.store.dispatch(loadModuleExaminables());

        this.store.dispatch(setNavInsideSidenav({ activeState: false }));
        this.store
            .select(selectSidenavActiveState)
            .pipe(
                withLatestFrom(this.store.select(selectSidenavContentType)),
                takeUntil(this.destroy$)
            )
            .subscribe(([state, type]) => {
                if (state && type === SidenavContentType.CONTENT_OVERVIEW) {
                    this.openAccordionsByReadingId();
                }
            });
        this.moduleProgress = this.moduleDocumentInfo?.userProgressPercent;
        this.store
            .select(selectModuleExaminables)
            .pipe(takeUntil(this.destroy$))
            .subscribe(examinables => (this.examinables = examinables));

        this.openedAccordionByUnread();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openedAccordionByUnread() {
        if (this.moduleDocumentInfo) {
            for (const i in this.moduleDocumentInfo.chapters) {
                if (
                    this.moduleDocumentHelperService.getReadStatusForNode(
                        this.moduleDocumentInfo.chapters[i]
                    ) !== 100
                ) {
                    this.openChapterPanelNames = `chapterPanel-${i}`;

                    for (const k in this.moduleDocumentInfo.chapters[i]
                        .sections) {
                        if (
                            this.moduleDocumentHelperService.getReadStatusForNode(
                                this.moduleDocumentInfo.chapters[i].sections[k]
                            ) !== 100
                        ) {
                            this.openSectionPanel = `section-panel-${i}-${k}`;
                            return;
                        }
                    }
                }
            }
        }
    }

    openAccordionsByReadingId() {
        this.readingId$.subscribe(x => {
            const readingId = x;
            if (this.moduleDocumentInfo) {
                for (const chapterIndex in this.moduleDocumentInfo.chapters) {
                    if (this.moduleDocumentInfo.chapters) {
                        for (const sectionIndex in this.moduleDocumentInfo
                            .chapters[chapterIndex].sections) {
                            if (
                                this.moduleDocumentInfo.chapters[chapterIndex]
                                    .sections
                            ) {
                                for (const subSectionIndex in this
                                    .moduleDocumentInfo.chapters[chapterIndex]
                                    .sections[sectionIndex].sections) {
                                    if (
                                        this.moduleDocumentInfo.chapters[
                                            chapterIndex
                                        ].sections[sectionIndex].id ===
                                            readingId ||
                                        this.moduleDocumentInfo.chapters[
                                            chapterIndex
                                        ].sections[sectionIndex].sections[
                                            subSectionIndex
                                        ].id === readingId
                                    ) {
                                        this.openChapterPanelNames = `chapterPanel-${chapterIndex}`;
                                        this.openSectionPanel = `section-panel-${chapterIndex}-${sectionIndex}`;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        });
    }

    navigate(node) {
        if (this.sidebar) {
            this.store.dispatch(setNavInsideSidenav({ activeState: true }));
            this.store
                .select(selectCurrentRoute)
                .pipe(
                    withLatestFrom(
                        this.store.select(selectCourseParticipationId)
                    )
                )
                .subscribe(([route, cpId]) => {
                    //relativeTo did not work...
                    this.router
                        .navigate([cpId])
                        .then(() =>
                            this.router.navigate([
                                cpId,
                                'learning',
                                route.url[0].path,
                                route.url[1].path,
                                route.url[2].path,
                                node.id,
                            ])
                        );
                })
                .unsubscribe();
        } else {
            this.router.navigate(['readingView', node.id], {
                relativeTo: this.route,
            });
        }
    }
}
