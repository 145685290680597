import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { deleteNote } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/notes/actions';

@Component({
    selector: 'cna-ask-delete-modal',
    templateUrl: './ask-delete-modal.component.html',
    styleUrls: ['./ask-delete-modal.component.scss'],
})
export class AskDeleteModalComponent implements OnInit {
    @Input() id;
    @Input() title;

    constructor(private store: Store, public activeModal1: NgbActiveModal) {}

    ngOnInit(): void {}

    deleteNote() {
        this.store.dispatch(
            deleteNote({
                noteId: this.id,
            })
        );

        this.activeModal1.close();
    }
}
