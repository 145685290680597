import { ModuleNotes, Note } from '@cna/projects/ak-jura/shared/interfaces';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './actions';
import { uniqBy } from 'lodash';

export const NOTES_STORE_NAME = 'notes';

export interface NotesState {
    moduleNotes: ModuleNotes[];
    repNotes: Note[];
    actualNumbering: string[];
    loading: boolean;
    errorDescription: string;
    deleteNote: string;
}

const initialState = {
    moduleNotes: [],
    repNotes: [],
    actualNumbering: [],
    loading: false,
    deleteNote: undefined,
    errorDescription: undefined,
};

const _notesReducer = createReducer<NotesState>(
    initialState,

    /**
     * Extract notes from course
     */
    on(fromActions.extractNotesForCourse, state => ({
        ...state,
        loading: true,
    })),
    on(fromActions.extractNotesForCourseSuccess, (state, action) => ({
        ...state,
        moduleNotes: uniqBy(
            [...state.moduleNotes, ...action.notes],
            'moduleId'
        ),
        loading: false,
    })),
    on(fromActions.extractNotesForCourseFailure, (state, action) => ({
        ...state,
        loading: false,
    })),

    on(fromActions.setActualNumberingArray, (state, action) => ({
        ...state,
        actualNumbering: action.numbering,
    })),

    /** */

    on(fromActions.deleteNoteSuccess, (state, action) => ({
        ...state,
        moduleNotes: state.moduleNotes.map(m => {
            m.notes = m.notes.filter(n => n.id !== action.noteId);
            return m;
        }),
        repNotes: state.repNotes.filter(note => note.id !== action.noteId),
    })),

    /**
     * Add Note
     */
    on(fromActions.addNote, state => ({
        ...state,
    })),

    on(fromActions.addNoteSuccess, (state, action) => ({
        ...state,
        moduleNotes: state.moduleNotes.map(moduleNote => {
            return moduleNote.moduleId === action.note.moduleId
                ? {
                      courseParticipationId: action.courseParticipationId,
                      moduleNumber: moduleNote.moduleNumber,
                      notes: [
                          ...moduleNote.notes,
                          {
                              ...action.note,
                              chapterNumbering: state.actualNumbering,
                          },
                      ],
                      moduleId: moduleNote.moduleId,
                  }
                : moduleNote;
        }),
        repNotes: action.note.favoriteGroupId
            ? [...state.repNotes, action.note]
            : state.repNotes,
    })),
    on(fromActions.addNoteFailure, state => ({
        ...state,
    })),

    /**
     * Update Note
     */
    on(fromActions.updateNote, state => ({
        ...state,
    })),
    on(fromActions.updateNoteSuccess, (state, action) => ({
        ...state,
        moduleNotes: [
            ...state.moduleNotes.map(value => {
                return {
                    courseParticipationId: value.courseParticipationId,
                    moduleNumber: value.moduleNumber,
                    notes: value.notes.map(note => {
                        return note.id === action.note.id
                            ? {
                                  ...note,
                                  id: note.id,
                                  title: action.note.title,
                                  description: action.note.description,
                              }
                            : note;
                    }),
                    moduleId: value.moduleId,
                };
            }),
        ],
        repNotes: [
            ...state.repNotes.map(note => {
                return note.id === action.note.id ? action.note : note;
            }),
        ],
    })),
    on(fromActions.updateNoteFailure, state => ({
        ...state,
    })),

    /**
     * select notes
     */
    on(fromActions.loadNotes, state => ({
        ...state,
        loading: true,
    })),

    on(fromActions.loadNotesSuccess, (state, action) => ({
        ...state,
        moduleNotes: action.moduleNotes,
        repNotes: action.repNotes,
        loading: false,
    })),

    on(fromActions.loadNotesFailure, state => ({
        ...state,
        loading: false,
    }))
);

export function notesReducer(state, action) {
    return _notesReducer(state, action);
}
