import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sectionNumbering',
})
export class SectionNumberingPipe implements PipeTransform {
    /**
     * returns either the Numbering or the Title text of the given String
     * Tries to splice by ". " or ") "
     * @param value the whole Title, including Numbering and Title text
     * @param separator
     */
    transform(value: string, separator = '. '): string | undefined {
        const split = value.split(separator);
        if (split.length > 1) {
            return split[0];
        } else {
            return undefined;
        }
    }
}
