import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CONTROL_TESTS_STORE_NAME, ControlTestsState } from './reducers';

export const selectControlTests = createFeatureSelector<ControlTestsState>(
    CONTROL_TESTS_STORE_NAME
);

export const selectControlTest = createSelector(
    selectControlTests,
    global => global?.controlTest
);
export const selectControlTestResult = createSelector(
    selectControlTests,
    global => global?.controlTestResult
);
