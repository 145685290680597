import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'cna-repetitorium-tile',
    templateUrl: './repetitorium-tile.component.html',
    styleUrls: ['./repetitorium-tile.component.scss'],
})
export class RepetitoriumTileComponent implements OnInit {
    @Input()
    public unlocked: boolean;

    @Output()
    public selectRepetitorium = new EventEmitter<void>();

    @Output()
    public printRepetitorium = new EventEmitter<void>();

    ngOnInit() {
        // TODO Limit repetitorium again after testing
        this.unlocked = true;
    }

    async navigateToRepetitorium(): Promise<void> {
        this.selectRepetitorium.emit();
    }

    async onPrintRepetitorium(event: Event): Promise<void> {
        event.stopPropagation();
        this.printRepetitorium.emit();
    }
}
