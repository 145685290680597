<div *ngIf="learningModuleInfo.unlocked" (click)="navigateOverview()">
    <div class="module-tile modulTile">
        <div class="d-flex justify-content-between no-gutters px-4 pt-4">
            <p
                class="adelle-sans-17 font-weight-600 white-color"
                style="letter-spacing: 0.6px; text-transform: uppercase"
            >
                <span *ngIf="courseType === CourseType.EducationCourse">{{
                    'MODUL ' +
                        (learningModuleInfo.sequentialNumber
                            | numberToNumbering: 'upperRomanNumerals')
                }}</span>
                <span *ngIf="courseType === CourseType.TrainingCourse"
                    >Kurs</span
                >
            </p>
        </div>
        <div
            class="d-flex flex-column px-4"
            style="padding-top: 14px; min-height: 120px"
        >
            <div
                class="d-flex flex-row progress-line"
                style="min-height: 25px; width: 182px"
            >
                <cna-progress-bar
                    [progressPercentage]="
                        learningModuleInfo?.percentageRead * 100
                    "
                ></cna-progress-bar>

                <span
                    class="adelle-sans-14 font-weight-300 white-color ml-2"
                    style="margin-top: 2px; width: 44px"
                >
                    {{
                        learningModuleInfo?.percentageRead * 100
                            | number: '1.0-0'
                    }}
                    %</span
                >
            </div>
            <div>
                <p
                    class="mb-0 adelle-sans-14 font-weight-300 white-color text-left"
                    style="padding-top: 13px; white-space: nowrap"
                >
                    Lernzeit:
                    {{ learningModuleInfo.totalTimeRead || 0 | secondsToHours }}
                    von
                    {{
                        learningModuleInfo.targetReadingTime || 0
                            | secondsToHours
                    }}
                    Stunden
                </p>
                <p
                    *ngIf="learningModuleInfo.controlTestPassed"
                    class="adelle-sans-14 font-weight-300 white-color text-left"
                >
                    Lernkontrolle: Bestanden
                </p>
            </div>
        </div>
        <div class="d-flex justify-content-between p-3">
            <p
                class="color-white mb-0 adelle-sans-11 font-weight-600 pr-2 align-self-center letterSpacing-08"
            >
                <cna-arrow
                    label="PDF"
                    [clickable]="true"
                    (click)="onPrintModule($event)"
                ></cna-arrow>
            </p>
            <div>
                <div
                    *ngIf="
                        learningModuleInfo?.percentageRead >= 1 &&
                            learningModuleInfo.controlTestPassed === true;
                        else notDone
                    "
                    class="d-flex button-continue-repeat"
                >
                    <p
                        class="mb-0 adelle-sans-11 font-weight-600 pr-2 align-self-center letterSpacing-08"
                    >
                        WIEDERHOLEN
                    </p>

                    <img
                        class="button-continue-repeat"
                        src="assets/play_white.svg"
                        width="30px"
                    />
                </div>
                <ng-template #notDone>
                    <a class="d-flex button-continue-repeat">
                        <span
                            *ngIf="learningModuleInfo.percentageRead === 0"
                            class="adelle-sans-11 letterSpacing-08 font-weight-600 pr-2 mt-1"
                        >
                            STARTEN
                        </span>
                        <span
                            *ngIf="learningModuleInfo.percentageRead !== 0"
                            class="adelle-sans-11 letterSpacing-08 font-weight-600 pr-2 mt-1 letterSpacing-08"
                        >
                            FORTSETZEN
                        </span>
                        <img
                            alt="PLAY"
                            src="assets/play_white.svg"
                            width="30px"
                        />
                    </a>
                </ng-template>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!learningModuleInfo.unlocked">
    <div class="module-tile-locked modulTile">
        <div
            class="d-flex justify-content-between no-gutters px-4 pt-4"
            style="min-width: 230px"
        >
            <p class="adelle-sans-17 font-weight-400" style="color: #d9dbdc">
                {{
                    'MODUL ' +
                        (learningModuleInfo.sequentialNumber
                            | numberToNumbering: 'upperRomanNumerals')
                }}
            </p>
        </div>
        <div
            class="d-flex flex-column px-4"
            style="padding-top: 14px; min-height: 120px"
        >
            <div style="min-height: 25px; width: 182px">
                <cna-progress-bar
                    [progressPercentage]="progress$ | async"
                ></cna-progress-bar>
            </div>
            <p
                class="mb-0 adelle-sans-14 font-weight-300 white-color text-left"
                style="padding-top: 13px; color: #d9dbdc"
            >
                Lernzeit: {{ learningModuleInfo.userProgressHours }} von
                {{ learningModuleInfo.expectedHours }} Stunden
            </p>
            <div *ngIf="learningModuleInfo.controlTestPassed">
                <p
                    class="adelle-sans-14 font-weight-300 white-color d-none text-left"
                >
                    Lernkontrolle bestanden
                </p>
            </div>
            <div *ngIf="!learningModuleInfo.controlTestPassed">
                <p class="adelle-sans-14 font-weight-300 white-color text-left">
                    &nbsp;
                </p>
            </div>
        </div>
        <div class="d-flex justify-content-end p-3">
            <div>
                <p
                    class="mb-0 adelle-sans-11 letterSpacing-08 font-weight-600 text-uppercase white-color"
                    style="min-height: 30px; color: #d9dbdc"
                >
                    Noch nicht freigeschaltet
                </p>
            </div>
        </div>
    </div>
</div>
