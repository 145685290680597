import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CanDeactivateGuard } from '../../../../../../../angular/ui/confirm-before-leave/can-deactivate-guard.service';
import { CourseListComponent } from './course-list/course-list.component';
import { routes } from './routes';
import { SharedComponentsModule } from './../../../shared-components/src/lib/shared-components.module';
import { LearningViewComponent } from './learning-view/learning-view.component';
import { LearnstateViewComponent } from './learnstate-view/learnstate-view.component';
import { LearningModuleOverviewViewComponent } from './learning-module-overview-view/learning-module-overview-view.component';
import {
    NgbAccordionModule,
    NgbButtonsModule,
    NgbCarouselModule,
    NgbNavModule,
} from '@ng-bootstrap/ng-bootstrap';
import { LearningControlViewComponent } from './learning-control-view/learning-control-view.component';
import { FormsModule } from '@angular/forms';
import { TestFormComponent } from './test-form/test-form.component';
import { AngularUiPipesModule } from '../../../../../../../angular/ui/pipes/src';
import { NgLetModule } from '@ngrx-utils/store';
import { RepetitoriumViewComponent } from './repetitorium-view/repetitorium-view.component';

@NgModule({
    imports: [
        CommonModule,
        NgbNavModule,
        RouterModule.forChild(routes),
        SharedComponentsModule,
        NgbCarouselModule,
        FormsModule,
        NgbAccordionModule,
        NgbButtonsModule,
        AngularUiPipesModule,
        NgLetModule,
    ],
    providers: [
        NgbNavModule,
        CourseListComponent,
        LearnstateViewComponent,
        LearningViewComponent,
        LearningModuleOverviewViewComponent,
        CanDeactivateGuard,
    ],
    declarations: [
        LearningViewComponent,
        LearningModuleOverviewViewComponent,
        LearningControlViewComponent,
        TestFormComponent,
        RepetitoriumViewComponent,
    ],
})
export class LearnModule {}
