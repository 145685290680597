import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LearningModuleDocumentInfo } from '../../../../../../shared/interfaces/src/lib/learning-module-document-info';
import { CourseType } from '@cna/projects/ak-jura/shared/interfaces';
import type { UUID } from '@cna/shared/generic-types';

@Component({
    selector: 'cna-learning-dashboard-tile-grid',
    templateUrl: './learning-dashboard-tile-grid.component.html',
    styleUrls: ['./learning-dashboard-tile-grid.component.scss'],
})
export class LearningDashboardTileGridComponent {
    @Input()
    public modules: LearningModuleDocumentInfo[];
    @Input()
    public courseType: CourseType;
    @Input()
    public repetitorium: { unlocked: boolean } | undefined;

    @Output()
    public selectModule = new EventEmitter<{ moduleId: UUID }>();
    @Output()
    public selectRepetitorium = new EventEmitter<void>();
    @Output()
    public printModule = new EventEmitter<{ moduleId: UUID }>();
    @Output()
    public printRepetitorium = new EventEmitter<{ courseId: UUID }>();

    CourseType = CourseType;

    async navigateToModule(event: { moduleId: UUID }): Promise<void> {
        this.selectModule.emit(event);
    }

    async navigateToRepetitorium(): Promise<void> {
        this.selectRepetitorium.emit();
    }

    async onPrintModule(event: { moduleId: UUID }): Promise<void> {
        this.printModule.emit(event);
    }

    async onPrintRepetitorium(): Promise<void> {
        this.printRepetitorium.emit();
    }
}
