import {
    Component,
    Input,
    OnChanges,
    OnInit,
    SecurityContext,
    ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ReadingStyles } from '@cna/projects/ak-jura/shared/interfaces';
import { Observable } from 'rxjs';

@Component({
    selector: 'cna-reading-content',
    templateUrl: './reading-content.component.html',
    styleUrls: ['./reading-content.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ReadingContentComponent implements OnInit, OnChanges {
    @Input() content: string;
    @Input() readingStyles$: Observable<ReadingStyles>;
    sanitizedContent: string;

    constructor(private domSanitizer: DomSanitizer) {}

    ngOnInit() {
        this.sanitizedContent = this.sanitizeContent(this.content);
    }

    ngOnChanges(): void {
        this.sanitizedContent = this.sanitizeContent(this.content);
    }

    private sanitizeContent(content: string): string {
        return this.domSanitizer.sanitize(SecurityContext.HTML, content);
    }
}
