import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, skipWhile, take, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { selectRepetitoriumUnlockedState } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/repetitorium/selectors';

import { selectCourseParticipationObjectForSelectedNew } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course/selectors';

import { LearningModuleDocumentInfo } from '../../../../../../shared/interfaces/src/lib/learning-module-document-info';
import { ModuleDocumentHelperService } from '../../../../../shared-components/src/lib/services/module-document-helper.service';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from '../../../../../../../../angular/services/src/lib/environment/environment.service';
import { MeinAkJuraEnvironment } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/environments/environment.interface';
import { PdfCreatorService } from '../../../../../file-creation/src/lib/pdf-creator.service';
import { ControlTestEvidence } from '../../../../../../shared/interfaces/src/lib/data-management/control-test/control-test-evidence';
import { selectCustomer } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/customer/selectors';
import {
    selectCustomersCourseParticipation,
    selectCustomersCourseParticipationModules,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';
import { CourseType } from '@cna/projects/ak-jura/shared/interfaces';

@Component({
    selector: 'cna-learnstate-accordion',
    templateUrl: './learnstate-accordion.component.html',
    styleUrls: ['./learnstate-accordion.component.scss'],
})
export class LearnstateAccordionComponent implements OnInit {
    @Input() selectedCategory: string;

    totalProgress$: Observable<number>;
    totalTimeSpent$: Observable<number>;
    unlocked$ = this.store.select(selectRepetitoriumUnlockedState);

    courseType$: Observable<CourseType> = this.store
        .select(selectCustomersCourseParticipation)
        .pipe(map(course => course?.bookedCourse.type));

    moduleInfos$: Observable<LearningModuleDocumentInfo[]> = this.store.select(
        selectCustomersCourseParticipationModules
    );

    private environment: MeinAkJuraEnvironment;
    CourseType = CourseType;

    constructor(
        private environmentService: EnvironmentService,
        private http: HttpClient,
        private store: Store,
        public moduleDocumentHelperService: ModuleDocumentHelperService,
        private pdfCreatorService: PdfCreatorService
    ) {
        this.environment = this.environmentService.getEnvironment();
    }

    ngOnInit(): void {
        this.totalTimeSpent$ = this.moduleInfos$.pipe(
            map(val => val.map(vala => vala.totalTimeRead)),
            map(learnTimes =>
                learnTimes && learnTimes.length > 0
                    ? learnTimes.reduce((acc, curr) => acc + curr)
                    : 0
            )
        );

        this.totalProgress$ = this.moduleInfos$.pipe(
            map(val => val.map(vala => vala.percentageRead)),
            map(progresses =>
                progresses && progresses.length > 0
                    ? progresses.reduce((acc, curr) => acc * curr)
                    : 0
            )
        );
    }

    getProgressObservable(number): Observable<number> {
        return of(number);
    }

    async downloadLearnControlPdf(
        moduleInfo: LearningModuleDocumentInfo,
        courseType: CourseType
    ) {
        this.store
            .select(selectCourseParticipationObjectForSelectedNew)
            .pipe(
                withLatestFrom(this.store.select(selectCustomer)),
                skipWhile(id => !id),
                take(1)
            )
            .subscribe(([courseParticipation, customer]) => {
                this.http
                    .get(
                        `${this.environment.apiHost}/customer/me/${courseParticipation.id}/control-test-evidence/${moduleInfo.controlTestId}`
                    )
                    .subscribe(
                        async (controlTestEvidence: ControlTestEvidence) => {
                            if (courseType === CourseType.EducationCourse) {
                                this.pdfCreatorService.createLearnControlPdf(
                                    customer,
                                    courseParticipation,
                                    moduleInfo,
                                    controlTestEvidence
                                );
                            } else if (
                                courseType === CourseType.TrainingCourse
                            ) {
                                await this.pdfCreatorService.createTrainingCourseCertificate(
                                    customer.displayName,
                                    {
                                        ...courseParticipation,
                                        controlTestEvidence,
                                    }
                                );
                            }
                        }
                    );
            });
    }
}
