import { LearnModule } from './learn-module';
import { PriceStructure } from '@cna/projects/ak-jura/shared/interfaces';
import { LegalArea } from '../legal-area';
import { UUID } from '@cna/shared/generic-types';

export enum CourseType {
    EducationCourse = 'EDUCATION_COURSE', // Ausbildung
    TrainingCourse = 'TRAINING_COURSE', // Weiterbildung
}

export interface Course {
    updatedAt: string;
    id: UUID;
    description: string;
    validFrom: Date;
    pricingStructureElements?: PriceStructure[];
    modules: LearnModule[];
    type: CourseType;
    associatedLegalArea?: LegalArea | UUID;
    repetitoriumId: UUID | null;
    targetReadingTime: number;
    hasCourseParticipations?: boolean;
    participations: number;
    approvalNumber: string;
    authorNotes: string;
    bibliography: string;
}

export function translateCourseTypeEnum(courseType: CourseType): string {
    switch (courseType) {
        case CourseType.EducationCourse:
            return 'Ausbildung';
        case CourseType.TrainingCourse:
            return 'Weiterbildung';
        default:
            return 'Unbekannter Kurstyp';
    }
}
