<div *ngIf="customer$ | async as customer" class="dashboard-container">
    <cna-page-heading pageHeadingTitle="Mein AK JURA"></cna-page-heading>

    <cna-course-select
        [courses]="customersCourse$ | async"
        [selectedCourseId]="selectedCourseId$ | async"
        (selectCourse)="onCourseSelected($event)"
    ></cna-course-select>

    <cna-dashboard-tile-grid
        [courseSelected]="courseSelected"
        [courseLocked]="courseLocked"
        [courseType]="courseType"
        [isTest]="isTest"
    ></cna-dashboard-tile-grid>

    <cna-warning-tile *ngIf="warning" [ngSwitch]="warning">
        <ng-container *ngSwitchCase="Warnings.COURSE_BLOCKED">
            <p>
                <span class="heading"
                    >Ihr Zugang wurde vorübergehend deaktiviert.</span
                >
            </p>
            <p>Bitte prüfen Sie, ob sämtliche Rechnungen vollständig bezahlt sind.</p>
            <p>Teilen Sie uns mit, sobald der noch offene Betrag ausgeglichen ist. Ihr Lehrgang wird dann unverzüglich wieder frei geschaltet.</p>
            <cna-arrow
                class=""
                href="mailto:kontakt@ak-jura.de"
                >kontakt@ak-jura.de</cna-arrow
            >
            <p>
                Vielen Dank für Ihr Verständnis
            </p>
            <p>
                Ihr AK JURA Team
            </p>
        </ng-container>
        <ng-container *ngSwitchCase="Warnings.COURSE_EXPIRED">
            <p>
                <span class="heading"
                    >Die Laufzeit
                    {{
                        courseType === CourseType.EducationCourse
                            ? 'Ihres Lehrgangs'
                            : 'Ihrer 5-stündige Fortbildung'
                    }}
                    ist leider abgelaufen!</span
                >
                <span *ngIf="courseType === CourseType.EducationCourse">
                    Eine kostenpflichtige Verlängerung ist jederzeit hier
                    möglich:
                </span>
            </p>
            <cna-arrow
                *ngIf="courseType === CourseType.EducationCourse"
                [href]="bookExtensionUrl$ | async"
                >Laufzeit verlängern</cna-arrow
            >
        </ng-container>
        <ng-container *ngSwitchCase="Warnings.COURSE_NEARLY_EXPIRED">
            <p>
                <span class="heading"
                    >Warnung! Ihr Lehrgang läuft in {{ courseDaysLeft }} Tagen
                    ab!</span
                >
                <span *ngIf="courseType === CourseType.EducationCourse">
                    Eine kostenpflichtige Verlängerung jederzeit hier möglich:
                </span>
            </p>
            <cna-arrow
                *ngIf="courseType === CourseType.EducationCourse"
                [href]="bookExtensionUrl$ | async"
                >Laufzeit verlängern</cna-arrow
            >
        </ng-container>
        <ng-container *ngSwitchCase="Warnings.TEST_COURSE_EXPIRED">
            <p>
                <span class="heading"
                    >Der kostenlose Testzeitraum des Lehrgangs ist leider
                    abgelaufen!</span
                >
                Buchen Sie Ihren Fachanwaltslehrgang hier:
            </p>
            <cna-arrow
                href="{{
                    frontendHost
                }}/fachanwaltslehrgang/fachanwaltslehrgang-buchen-anmeldung"
                >Fachanwaltslehrgang buchen</cna-arrow
            >
        </ng-container>
        <ng-container *ngSwitchCase="Warnings.TEST_COURSE_NEARLY_EXPIRED">
            <p>
                <span class="heading"
                    >Warnung! Dieser Kurs ist nur noch
                    {{ millisecondsLeft$ | async | date: 'HH:mm' }} Stunden
                    aktiv.</span
                >
                Sie haben die Möglichkeit, den Kurs kostenpflichtig zu buchen.
            </p>
            <cna-arrow href="{{ frontendHost }}"
                >Fachanwaltslehrgang buchen</cna-arrow
            >
        </ng-container>
        <ng-container *ngSwitchCase="Warnings.NO_COURSE_BOOKED">
            <p>
                <span class="heading">Sie haben noch keinen Kurs gebucht.</span>
                Hier haben Sie die Möglichkeit, einen Fachanwaltslehrgang zu
                buchen oder kostenlos zu testen:
            </p>
            <div class="arrow-group">
                <cna-arrow
                    href="{{
                        frontendHost
                    }}/fachanwaltslehrgang/fachanwaltslehrgang-buchen-anmeldung"
                    >Fachanwaltslehrgang buchen</cna-arrow
                >
                <cna-arrow href="{{ frontendHost }}/testzugang-buchen-anmeldung"
                    >Fachanwaltslehrgang kostenlos testen</cna-arrow
                >
            </div>
            <p>
                Hier haben Sie die Möglichkeit, eine 5-stündige Fortbildung zu
                buchen:
            </p>
            <cna-arrow
                href="{{
                    frontendHost
                }}/fortbildungen/fortbildung-buchen-anmeldung"
                >Fortbildung buchen</cna-arrow
            >
        </ng-container>
    </cna-warning-tile>

    <div class="button-nav">
        <cna-arrow
            label="Logout"
            style="--color: #454f54; --hover-color: #2c383d"
            clickable="true"
            (click)="logout()"
        ></cna-arrow>
    </div>
</div>
