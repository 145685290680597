<ng-container *ngIf="modules">
    <ng-container *ngFor="let module of modules">
        <cna-module-tile
            [learningModuleInfo]="module"
            [courseType]="courseType"
            (selectModule)="navigateToModule($event)"
            (printModule)="onPrintModule($event)"
        ></cna-module-tile>
    </ng-container>
    <cna-repetitorium-tile
        *ngIf="repetitorium"
        [unlocked]="repetitorium?.unlocked"
        (selectRepetitorium)="navigateToRepetitorium()"
        (printRepetitorium)="onPrintRepetitorium()"
    ></cna-repetitorium-tile>
    <div
        class="placeholder"
        *ngIf="
            modules.length + ((repetitorium ? 1 : 0) % 2) !== 0 &&
            modules.length > 1
        "
    ></div>
</ng-container>
