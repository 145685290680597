<cna-loading-content [loading]="loading$ | async">
    <div *ngIf="module$ | async as module" class="module-overview-container">
        <div style="display: flex; justify-content: center">
            <div class="sizeTable" style="width: 1163px">
                <div>
                    <cna-page-heading
                        *ngIf="
                            (courseType$ | async) === CourseType.EducationCourse
                        "
                        pageHeadingTitle="{{ moduleName$ | async }} – Übersicht"
                    ></cna-page-heading>
                    <cna-page-heading
                        *ngIf="
                            (courseType$ | async) === CourseType.TrainingCourse
                        "
                        pageHeadingTitle="{{
                            (courseParticipation$ | async)?.bookedCourse
                                ?.targetReadingTime
                        }}-stündige Fortbildung im Selbststudium"
                    ></cna-page-heading>
                </div>
                <div *ngIf="courseName$ | async" style="margin-top: 12px">
                    <p class="adelle-sans-17 font-weight-300 dark-gray">
                        {{ courseName$ | async }}
                    </p>
                </div>
            </div>
        </div>

        <div>
            <div
                style="display: flex; justify-content: center; margin-top: 24px"
            >
                <div class="sizeTable">
                    <cna-learning-module-accordion
                        [moduleName]="moduleName$ | async"
                        [moduleDocumentInfo]="module"
                        [sidebar]="false"
                        [courseType]="
                            (courseParticipation$ | async)?.bookedCourse?.type
                        "
                    ></cna-learning-module-accordion>
                </div>
            </div>

            <div
                class="d-flex justify-content-center mt-4 pb-2 gray-background"
            >
                <div class="learncontrol mb-5 mt-5">
                    <p
                        class="adelle-pe-31 font-weight-400 dark-gray"
                        style="margin-bottom: 32px"
                    >
                        Lernkontrolle
                    </p>
                    <p
                        *ngIf="!(isModuleReadAndMarkedDone() | async)"
                        class="adelle-sans-17 font-weight-300 dark-gray"
                    >
                        Die Lernkontrolle steht Ihnen zur Verfügung, nachdem Sie
                        das Modul <br />
                        vollständig bearbeitet haben. Dazu müssen Ihr
                        Lernfortschritt 100 % und <br />
                        Ihre Lernzeit mindestens
                        {{ (module$ | async)?.targetReadingTime / 3600 }}
                        Stunden betragen.
                    </p>
                    <p
                        class="adelle-sans-17 font-weight-300 dark-gray"
                        *ngIf="
                            (isModuleReadAndMarkedDone() | async) &&
                            !(controlTestPassed$ | async)
                        "
                    >
                        Sie haben die Lernkontrolle für dieses Modul noch nicht
                        bestanden. <br />
                        Nicht bestandene Lernkontrollen können wiederholt
                        werden.
                    </p>
                    <p
                        class="adelle-sans-17 font-weight-300 dark-gray"
                        *ngIf="controlTestPassed$ | async"
                    >
                        Sie haben die Lernkontrolle bereits erfolgreich
                        bestanden.
                        <br />
                        Bestandene Lernkontrollen können nicht wiederholt
                        werden.
                    </p>

                    <div style="margin-top: 53px">
                        <cna-gradient-button
                            (akClick)="startLearnControl()"
                            buttonTitle="Lernkontrolle starten"
                            [disabled]="
                                !(isModuleReadAndMarkedDone() | async) ||
                                (controlTestPassed$ | async)
                            "
                            notgradient="true"
                        ></cna-gradient-button>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div
                class="d-flex justify-content-center pt-4"
                style="margin-bottom: 32px"
            >
                <div class="sizeTable">
                    <cna-page-heading
                        *ngIf="
                            (courseType$ | async) === CourseType.EducationCourse
                        "
                        pageHeadingTitle="Notizen – {{ moduleName$ | async }}"
                    ></cna-page-heading>
                    <cna-page-heading
                        *ngIf="
                            (courseType$ | async) === CourseType.TrainingCourse
                        "
                        pageHeadingTitle="Notizen"
                    ></cna-page-heading>
                </div>
            </div>

            <div class="d-flex justify-content-center pb-3">
                <div class="notes-accordion">
                    <cna-notes-accordion
                        [notes]="notes$ | async"
                        [moduleName]="moduleName$ | async"
                    ></cna-notes-accordion>
                </div>
            </div>
        </div>
    </div>
</cna-loading-content>
