import { createFeatureSelector, createSelector } from '@ngrx/store';
import { READINGSTYLE_STORE_NAME, ReadingStyleState } from './reducers';

export const selectReadingStyle = createFeatureSelector<ReadingStyleState>(
    READINGSTYLE_STORE_NAME
);
export const selectReadingStyles = createSelector(
    selectReadingStyle,
    style => style?.readingStyles
);
