<svg
    viewBox="0 0 24 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
>
    <g>
        <polyline points="2,2 12,12 22,1"></polyline>
    </g>
</svg>
