import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    selectCourseParticipationId,
    selectFavoriteGroupId,
    selectModuleId,
} from '../router.selector';
import { NOTES_STORE_NAME, NotesState } from './reducers';

export const selectNotesGlobal =
    createFeatureSelector<NotesState>(NOTES_STORE_NAME);

export const selectNotesPerModule = createSelector(
    selectNotesGlobal,
    selectCourseParticipationId,
    (globalState, cpId) => {
        return globalState?.moduleNotes
            ?.filter(moduleNotes => moduleNotes.courseParticipationId === cpId)
            .sort((a, b) => a.moduleNumber - b.moduleNumber);
    }
);

export const selectNotesForThisModule = createSelector(
    selectNotesGlobal,
    notes => notes.moduleNotes
);

export const selectDeleteNote = createSelector(
    selectNotesGlobal,
    globalState => {
        return globalState?.deleteNote;
    }
);

export const selectModuleNotesByModuleIdFromRouter = createSelector(
    selectNotesGlobal,
    selectModuleId,
    selectFavoriteGroupId,
    (globalNotes, moduleId, favoriteGroupId) =>
        favoriteGroupId
            ? globalNotes.repNotes.filter(
                  note => note.favoriteGroupId === favoriteGroupId
              )
            : globalNotes.moduleNotes?.find(
                  moduleNote => moduleNote?.moduleId === moduleId
              )?.notes
);

export const selectModuleNotesByModuleId = createSelector(
    selectNotesPerModule,
    (moduleNotes, props) =>
        moduleNotes?.find(moduleNote => moduleNote.moduleId === props.moduleId)
            ?.notes
);

export const selectRepNotes = createSelector(
    selectNotesGlobal,
    selectCourseParticipationId,
    (globalState, cpId) =>
        globalState.repNotes.filter(note => note.courseParticipationId === cpId)
);
