import { createAction, props } from '@ngrx/store';
import { CustomerBaseData } from '@cna/projects/ak-jura/shared/interfaces';

/**
 * load Customer Actions
 */
export const loadCustomer = createAction('[customer] load Customer');

export const loadCustomerSuccess = createAction(
    '[customer] load Customer success',
    props<{ customer: CustomerBaseData }>() // Customer
);
export const loadCustomerFailure = createAction(
    '[customer] load Customer Failure',
    props<{ error: Error }>()
); /**

 /**
 * load Course Actions
 */
export const loadCourse = createAction('[customer] load course');

export const loadCourseSuccess = createAction(
    '[customer] load course success',
    props<{ course: any }>() // Customer
);
export const loadCourseFailure = createAction(
    '[customer] load course failure',
    props<{ error: Error }>()
); /**


 /**
 * edit Customer Actions
 */
export const editCustomer = createAction(
    '[customer] edit Customer',
    props<{ userData: CustomerBaseData }>()
);

export const editCustomerSuccess = createAction(
    '[customer] edit Customer success',
    props<{ userData: CustomerBaseData }>()
);
export const editCustomerFailure = createAction(
    '[customer] edit Customer Failure'
);
