import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as customerActions from './actions';
import {
    catchError,
    map,
    mergeMap,
    switchMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';
import { ToastType } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/toast-type.enum';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ToasterService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/toaster.service';
import { Injectable } from '@angular/core';
import { EntityServices } from '@ngrx/data';
import { CustomerBaseData } from '@cna/projects/ak-jura/shared/interfaces';

@Injectable()
export class CustomerEffects {
    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private store: Store,
        private toasterService: ToasterService
    ) {}

    loadCustomer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(customerActions.loadCustomer),
            mergeMap(action => {
                return this.http
                    .get(environment.apiHost + '/v2/customer/me/base-data')
                    .pipe(
                        map((loadedCustomer: CustomerBaseData) => {
                            return customerActions.loadCustomerSuccess({
                                customer: loadedCustomer,
                            });
                        }),
                        catchError(err =>
                            of(
                                customerActions.loadCustomerFailure({
                                    error: err,
                                })
                            )
                        )
                    );
            })
        );
    });

    /** */
    loadCourseOfCustomer$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(customerActions.loadCourse),
            mergeMap(action => {
                return this.http
                    .get(environment.apiHost + '/course-participation/me')
                    .pipe(
                        map(loadedCourse => {
                            return customerActions.loadCourseSuccess({
                                course: loadedCourse,
                            });
                        }),
                        catchError(err =>
                            of(
                                customerActions.loadCourseFailure({
                                    error: err,
                                })
                            )
                        )
                    );
            })
        );
    });

    /**
     * Edit Customer
     */
    editCustomer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(customerActions.editCustomer),
            switchMap(action => {
                return this.http.put(
                    environment.apiHost + '/customer/' + 'me',
                    {
                        title: action.userData.title,
                        salutation: action.userData.salutation,
                        firstName: action.userData.firstName,
                        lastName: action.userData.lastName,
                        phone: action.userData.phone,
                        address: {
                            addressLine1: action.userData.address.addressLine1,
                            addressLine2: action.userData.address.addressLine2,
                            city: action.userData.address.city,
                            postCode: action.userData.address.postCode,
                            country: action.userData.address.country,
                            stateOrProvince:
                                action.userData.address.stateOrProvince,
                        },
                        newsletter: action.userData.newsletter,
                    }
                );
            }),
            map((c: CustomerBaseData) =>
                customerActions.editCustomerSuccess({ userData: c })
            ),
            catchError(err => of(customerActions.editCustomerFailure()))
        )
    );
    editCustomerSuccess$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(customerActions.editCustomerSuccess),
            tap(() => {
                this.toasterService.show(
                    'Account Infos erfolgreich aktualisiert!',
                    ToastType.success
                );
            }),
            map(() => customerActions.loadCustomer())
        );
    });
    editCustomerFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(customerActions.editCustomerFailure),
                tap(() => {
                    this.toasterService.show(
                        'Es ist ein Fehler beim aktualisieren der Account Infos aufgetreten!',
                        ToastType.error
                    );
                })
            ),
        { dispatch: false }
    );
}
