export enum CourseStatus {
    // Educations
    EDUCATION_MODULE_1 = 'I',
    EDUCATION_MODULE_2 = 'II',
    EDUCATION_MODULE_3 = 'III',
    EDUCATION_MODULE_4 = 'IV',
    EDUCATION_REPETITORIUM = 'R',
    EDUCATION_DONE = 'B',

    // Trainings
    TRAINING_IN_PROGRESS = 'FA',
    TRAINING_DONE = 'FB',
}
