<ngb-accordion
    #acc="ngbAccordion"
    class="qanda"
    activeIds="{{ openSectionPanel }}"
>
    <!-- add activeIds="ngb-panel-0" to open first-->
    <ngb-panel id="p1">
        <ng-template ngbPanelTitle>
            <div class="row justify-content-between">
                <div class="col text-left">
                    <p class="blue-color adelle-sans-17 font-weight-600 mb-0">
                        Anleitung zur Nutzung der Lernplattform
                    </p>
                </div>
                <div class="col-2 text-right qa_arrow">
                    <img src="assets/accordion_down.svg" />
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Lernstatus
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Gelernte Themen markieren Sie, indem Sie ein Häkchen setzen. Die
                gelernten Themen werden grafisch dargestellt und in %-Werten
                angegeben. Dadurch erhalten Sie jederzeit einen genauen
                Überblick über Ihren aktuellen Lernfortschritt.
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Zeiterfassung
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Ihre Aktivität wird vom System dokumentiert. Wenn länger als 15
                Minuten keine Interaktion (Klick, Scroll, Mausbewegung etc.)
                erfolgt, stoppt die Zeiterfassung. Durch erneute Interaktion
                setzen Sie die Zeiterfassung fort.
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Lernkontrolle
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Wenn Ihr Lernfortschritt 100 % beträgt und Ihr Zeitkonto 5
                Stunden aufweist, können Sie die abschließende Lernkontrolle
                (Multiple-Choice-Test) durchführen. Nicht bestandene
                Lernkontrollen können beliebig oft wiederholt werden.
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Zeitkonto
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Sind sämtliche Inhalte erarbeitet, aber Ihr Zeitkonto weist noch
                nicht die erforderlichen 5 Stunden auf, wiederholen und
                vertiefen Sie die Beiträge nochmals.
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Zugang
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Ihr Fortbildungsseminar steht Ihnen ab Anmeldung längstens bis
                zum 31.12. eines Jahres zur Verfügung. Innerhalb dieses
                Zeitraums müssen Sie die 5 Zeitstunden absolvieren und die
                Lernkontrolle bestehen.
            </p>
        </ng-template>
    </ngb-panel>

    <ngb-panel id="p2">
        <ng-template ngbPanelTitle>
            <div class="row justify-content-between">
                <div class="col text-left">
                    <p class="blue-color adelle-sans-17 font-weight-600 mb-0">
                        Fortbildungszertifikat gem. § 15 Abs. 4 FAO
                    </p>
                </div>
                <div class="col-2 text-right qa_arrow">
                    <img src="assets/accordion_down.svg" />
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Mit Bestehen der Lernkontrolle (Multiple-Choice-Test), erhalten
                Sie Ihr Fortbildungszertifikat inklusive des Nachweises der
                bestandenen Lernerfolgskontrolle per E-Mail (jeweils als PDF)
                zur Vorlage bei Ihrer Kammer.
            </p>
        </ng-template>
    </ngb-panel>

    <ngb-panel id="p3">
        <ng-template ngbPanelTitle>
            <div class="row justify-content-between">
                <div class="col text-left">
                    <p class="blue-color adelle-sans-17 font-weight-600 mb-0">
                        Support
                    </p>
                </div>
                <div class="col-2 text-right qa_arrow">
                    <img src="assets/accordion_down.svg" />
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <span class="adelle-sans-17 font-weight-600 dark-gray">
                Allgemeine Fragen
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Bei allgemeinen Fragen wenden Sie sich bitte per Mail an:
                <a
                    class="dark-gray"
                    style="text-decoration: underline"
                    href="mailto:kontakt@ak-jura.de"
                    >kontakt@ak-jura.de</a
                >
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Technische Fragen
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Bei technischen Fragen oder Problemen wenden Sie sich bitte an:
                <a
                    class="dark-gray"
                    style="text-decoration: underline"
                    href="mailto:support@ak-jura.de"
                    >support@ak-jura.de</a
                >
            </p>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
