import { ReadStatus } from '@cna/projects/ak-jura/shared/interfaces';
import { createReducer, on } from '@ngrx/store';
import * as readStatusActions from './actions';

export const READ_STATUS_STORE_NAME = 'read_status';

export interface ReadStatusState {
    courseParticipations: {
        [courseParticipationId: string]: {
            modules: {
                [moduleId: string]: {
                    sections: {
                        [sectionId: string]: ReadStatus;
                    };
                };
            };
        };
    };
    loading: boolean;
}

const initialState: ReadStatusState = {
    courseParticipations: {},
    loading: false,
};

const updateReadStatus = (
    state: ReadStatusState,
    { readStatus }: { readStatus?: ReadStatus }
): ReadStatusState =>
    readStatus
        ? {
              ...state,
              courseParticipations: {
                  ...state.courseParticipations,
                  [readStatus.courseParticipationId]: {
                      modules: {
                          ...state.courseParticipations[
                              readStatus.courseParticipationId
                          ]?.modules,
                          [readStatus.moduleId]: {
                              sections: {
                                  ...state.courseParticipations[
                                      readStatus.courseParticipationId
                                  ]?.modules[readStatus.moduleId]?.sections,
                                  [readStatus.sectionId]: readStatus,
                              },
                          },
                      },
                  },
              },
              loading: false,
          }
        : {
              ...state,
              loading: false,
          };

const setLoading = (state: ReadStatusState) => ({
    ...state,
    loading: true,
});

export const readStatusReducer = createReducer<ReadStatusState>(
    initialState,
    on(readStatusActions.loadReadStatus, setLoading),
    on(readStatusActions.loadReadStatusSuccess, updateReadStatus),
    on(readStatusActions.createReadStatus, setLoading),
    on(readStatusActions.createReadStatusSuccess, updateReadStatus),
    on(readStatusActions.updateReadStatus, setLoading),
    on(readStatusActions.updateReadStatusSuccess, updateReadStatus)
);
