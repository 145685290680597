import { createAction, props } from '@ngrx/store';
import { SidenavContentType } from 'libs/projects/ak-jura/angular/my-ak-jura/shared-components/src/lib/sidenav/sidenav.component';

/**
 * SideNav Actions
 */
export const setSidenavActiveState = createAction(
    '[SidenavActiveState] set sidenav state ',
    props<{ activeState: boolean }>()
);

export const setSidenavContentType = createAction(
    '[SidenavContentType] set sidenav content type',
    props<{ contentType: SidenavContentType }>()
);

export const setNavInsideSidenav = createAction(
    '[NavInsideSidenav] set sidenav content type',
    props<{ activeState: boolean }>()
);
