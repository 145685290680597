import { createFeatureSelector, createSelector } from '@ngrx/store';
import { REPETITORIUM_STORE_NAME, RepetitoriumState } from './reducers';
import { selectCourseParticipationState } from '../course_participation/selectors';
import { selectFavoriteGroupId } from '../router.selector';

export const selectGlobal = createFeatureSelector<RepetitoriumState>(
    REPETITORIUM_STORE_NAME
);

export const selectRepetitorium = createSelector(
    selectGlobal,
    global => global?.repetitorium
);

export const selectRepetitoriumUnlockedState = createSelector(
    selectGlobal,
    selectCourseParticipationState,
    (globalState, courseParticipation) => {
        return courseParticipation.course_participation.bookedCourse
            .repetitorium.unlocked;
    }
);

export const selectCurrentFavoriteGroupIndex = createSelector(
    selectRepetitorium,
    selectFavoriteGroupId,
    (repetitorium, favoriteGroupId) => {
        return repetitorium.favoriteGroups.findIndex(
            favoriteGroup => favoriteGroup.id === favoriteGroupId
        );
    }
);

export const selectCurrentFavoriteGroup = createSelector(
    selectRepetitorium,
    selectCurrentFavoriteGroupIndex,
    (repetitorium, index) => repetitorium.favoriteGroups[index]
);

export const selectCurrentFavoriteGroupNumber = createSelector(
    selectCurrentFavoriteGroupIndex,
    index => index + 1
);

export const selectCurrentFavoriteGroupTitle = createSelector(
    selectCurrentFavoriteGroup,
    favoriteGroup => favoriteGroup?.title
);
