import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'cna-chapter-circle',
    templateUrl: './chapter-circle.component.html',
    styleUrls: ['./chapter-circle.component.scss'],
})
export class ChapterCircleComponent implements OnInit {
    @Input() chapter: string;
    @Input() done?: boolean;
    @Input() sidebar: boolean;

    constructor() {}

    ngOnInit(): void {}
}
