import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CUSTOMER_STORE_NAME, CustomerState } from './reducers';
import { Customer } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/people/customer';
import { CustomerBaseData } from '@cna/projects/ak-jura/shared/interfaces';

export const selectC =
    createFeatureSelector<CustomerState>(CUSTOMER_STORE_NAME);

export const selectCustomer = createSelector(selectC, customer => {
    return customer?.customer;
});

export const selectAllCourseParticipations = createSelector(
    selectC,
    (customerState: CustomerState) => {
        return customerState?.course_participations;
    }
);

export const selectCustomersCourses = createSelector(selectC, customer => {
    return customer?.course;
});

export const selectCourseSlug = courseParticipationId =>
    createSelector(selectC, (state: CustomerState) => {
        return state.course?.find(course => course.id === courseParticipationId)
            ?.bookedCourse.slug;
    });
