<div class="d-flex align-items-center justify-content-between">
    <div class="adelle-pe-17 font-weight-600" style="color: #454f54">
        Notizen
    </div>
    <img
        *ngIf="addNoticeActive"
        src="assets/x-close.svg"
        (click)="changeAddNoticeState()"
        style="cursor: pointer"
    />
</div>
<div *ngIf="!addNoticeActive; else inactive">
    <button
        type="button"
        class="btn btn-link collapsed pl-0"
        (click)="changeAddNoticeState()"
    >
        <div class="d-flex align-items-center py-2">
            <img src="assets/plus.svg" class="mr-2" />
            <span class="adelle-sans-14 font-weight-400 dark-gray"
                >neue Notiz hinzufügen</span
            >
        </div>
    </button>
</div>
<ng-template #inactive>
    <div class="my-3">
        <form #noticeForm="ngForm" (ngSubmit)="createNotice(noticeForm)">
            <div class="form-group">
                <input
                    type="text"
                    class="form-control"
                    name="noticeHeader"
                    placeholder="Notiz Titel"
                    ngModel
                    required
                />
            </div>
            <div class="form-group">
                <textarea
                    type="text"
                    class="form-control"
                    name="noticeContent"
                    placeholder="Hier neue Notiz erstellen"
                    ngModel
                    required
                ></textarea>
            </div>
            <cna-gradient-button
                notgradient="true"
                buttonTitle="Speichern"
            ></cna-gradient-button>
            <div>
                <p
                    *ngIf="noticeForm.submitted && noticeForm.invalid"
                    class="red-color adelle-sans-12"
                >
                    Bitte füllen Sie alle Felder aus!
                </p>
            </div>
        </form>
    </div>
</ng-template>

<div class="mb-5">
    <cna-sidenav-notes-accordion></cna-sidenav-notes-accordion>
</div>
<!--<div class="adelle-pe-25 font-weight-400">Fragen stellen</div>
<p>
    Stellen Sie hier eine Frage. Im Board kann diese dann mit anderen diskutiert
    werden.
</p>
<div class="my-3"><img src="assets/plus.svg" /> Frage stellen</div>-->
