import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    FavoriteGroup,
    ReadingStyles,
} from '@cna/projects/ak-jura/shared/interfaces';
import { selectActualCourseId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course/selectors';
import {
    loadRepetitoriumForActualCourse,
    setReadStatusForRepetitorium,
    updateReadStatusForRepetitorium,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/repetitorium/actions';
import {
    setNavInsideSidenav,
    setSidenavActiveState,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/actions';
import { select, Store } from '@ngrx/store';
import { combineLatest, merge, Observable, Subject } from 'rxjs';
import {
    selectCurrentFavoriteGroupNumber,
    selectCurrentFavoriteGroupTitle,
    selectRepetitorium,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/repetitorium/selectors';
import { selectReadingStyles } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/readingstyles/selectors';
import { selectNavInsideSidenav } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/selectors';
import {
    finalize,
    map,
    mergeMap,
    skipWhile,
    take,
    takeUntil,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import {
    selectCourseParticipationId,
    selectFavoriteGroupId,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { ModuleDocumentHelperService } from '../../../../../shared-components/src/lib/services/module-document-helper.service';
import { EnvironmentService } from '../../../../../../../../angular/services/src/lib/environment/environment.service';
import { ActivatedRoute, Router } from '@angular/router';
import type { UUID } from '@cna/shared/generic-types';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MeinAkJuraEnvironment } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/environments/environment.interface';
import { selectContentSectionLoading } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/load_content/selectors';
import {
    loadCourseParticipationInfos,
    updateLastLogin,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';
import { selectCourseParticipationIsDone } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';
import { filterNil } from '@cna/shared/helper';
import { setBreadcrumbs } from 'apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';
import { environment } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/environments/environment';
import { saveAs } from 'file-saver';

@Component({
    selector: 'cna-repetitorium-view',
    templateUrl: './repetitorium-view.component.html',
    styleUrls: ['./repetitorium-view.component.scss'],
})
export class RepetitoriumViewComponent implements OnInit, OnDestroy {
    private _destroy$ = new Subject<void>();
    private pdfLoading$ = new Subject<boolean>();

    navTroughNextChapterButton = false;
    readingStyles$: Observable<ReadingStyles> = this.store.pipe(
        select(selectReadingStyles),
        takeUntil(this._destroy$)
    );
    environment: MeinAkJuraEnvironment =
        this.environmentService.getEnvironment();

    loading$ = merge(
        this.store.select(selectContentSectionLoading),
        this.pdfLoading$
    ).pipe(takeUntil(this._destroy$));
    private repetitorium$ = this.store.select(selectRepetitorium).pipe(
        takeUntil(this._destroy$),
        skipWhile(rep => !rep)
    );
    readingNode$ = this.repetitorium$.pipe(
        withLatestFrom(this.store.select(selectFavoriteGroupId)),
        map(([repetitorium, favoriteGroupId]) =>
            repetitorium.favoriteGroups.find(fg => fg.id === favoriteGroupId)
        )
    );
    nextReadingId$ = this.repetitorium$.pipe(
        withLatestFrom(this.store.select(selectFavoriteGroupId)),
        map(
            ([repetitorium, favoriteGroupId]) =>
                repetitorium.favoriteGroups[
                    repetitorium.favoriteGroups.findIndex(
                        fg => fg.id === favoriteGroupId
                    ) + 1
                ]?.id
        )
    );
    readStatus$ = this.readingNode$.pipe(
        map(node => node.workedOut && node.workedOut.workedOut === true)
    );
    renderedText$ = this.readingNode$.pipe(
        map(node =>
            this.moduleDocumentHelperService.parseAndFormatTextFromFavoriteGroup(
                node
            )
        )
    );
    isCourseDone$ = this.store
        .select(selectCourseParticipationIsDone)
        .pipe(takeUntil(this._destroy$));

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private store: Store,
        public moduleDocumentHelperService: ModuleDocumentHelperService,
        private environmentService: EnvironmentService,
        private modal: NgbModal,
        private http: HttpClient
    ) {
        this.setBreadcrumbs();
    }

    ngOnInit(): void {
        this.store.dispatch(loadCourseParticipationInfos());
        this.store.dispatch(loadRepetitoriumForActualCourse());
        this.store.dispatch(updateLastLogin());
    }

    ngOnDestroy(): void {
        if (!this.navTroughNextChapterButton) {
            this.leavePage();
        }
    }

    setBreadcrumbs() {
        combineLatest([
            this.store.select(selectCourseParticipationId).pipe(filterNil()),
            this.store.select(selectFavoriteGroupId).pipe(filterNil()),
            this.store
                .select(selectCurrentFavoriteGroupNumber)
                .pipe(filterNil()),
            this.store
                .select(selectCurrentFavoriteGroupTitle)
                .pipe(filterNil()),
        ])
            .pipe(takeUntil(this._destroy$))
            .subscribe(
                ([
                    courseParticipationId,
                    favoriteGroupId,
                    favoriteGroupNumber,
                    favoriteGroupTitle,
                ]) => {
                    this.store.dispatch(
                        setBreadcrumbs({
                            breadcrumbs: [
                                {
                                    label: 'Mein AK JURA',
                                    routerLink: courseParticipationId,
                                },
                                {
                                    label: 'Lernstatus',
                                    routerLink:
                                        courseParticipationId +
                                        '/learning/courseOverview',
                                },
                                {
                                    label: 'Repetitorium',
                                    routerLink:
                                        courseParticipationId +
                                        '/learning/repetitorium',
                                },
                                {
                                    label: `Leseansicht (${favoriteGroupNumber}. ${favoriteGroupTitle})`,
                                    routerLink:
                                        courseParticipationId +
                                        '/learning/repetitorium/' +
                                        favoriteGroupId,
                                },
                            ],
                        })
                    );
                }
            );
    }

    leavePage() {
        this.store
            .select(selectNavInsideSidenav)
            .subscribe(x => {
                if (x === false) {
                    this.store.dispatch(
                        setSidenavActiveState({ activeState: false })
                    );
                }
            })
            .unsubscribe();
        this.store.dispatch(setNavInsideSidenav({ activeState: false }));

        this._destroy$.next();
        this._destroy$.complete();
    }

    /**
     * determines whether the favorite group already has a read status
     * and updates or creates a new one accordingly
     */
    setReadStatus(
        readingNode: FavoriteGroup,
        { readStatus }: { readStatus: boolean }
    ): void {
        if (readingNode.workedOut !== undefined) {
            this.store.dispatch(
                updateReadStatusForRepetitorium({
                    progressId: readingNode.workedOut.id,
                    workedOut: readStatus,
                })
            );
        } else {
            this.store.dispatch(setReadStatusForRepetitorium());
        }
    }

    routeToId(id: UUID) {
        this.navTroughNextChapterButton = true;

        this.store
            .select(selectCourseParticipationId)
            .pipe(take(1))
            .subscribe(async cpId => {
                const route = [cpId, 'learning', 'repetitorium', id];
                await this.router.navigate(route);
                this.ngOnInit();
            });
    }

    async routeToOverview() {
        this.modal.dismissAll();
        await this.router.navigate(['../'], {
            relativeTo: this.activatedRoute,
        });
    }

    printContent() {
        this.pdfLoading$.next(true);
        combineLatest([
            this.store.select(selectActualCourseId),
            this.store.select(selectFavoriteGroupId),
        ])
            .pipe(
                mergeMap(([courseId, favoriteGroupId]) =>
                    this.http.post<{
                        contentType: string;
                        filename: string;
                        base64Content: string;
                    }>(
                        `${environment.apiHost}/repetitorium/export?courseId=${courseId}&favoriteGroupId=${favoriteGroupId}`,
                        {}
                    )
                ),
                tap(async ({ contentType, base64Content, filename }) => {
                    const fetchResponse = await fetch(
                        `data:${contentType};base64,${base64Content}`
                    );
                    const blob = await fetchResponse.blob();
                    saveAs(blob, filename);
                }),
                take(1),
                finalize(() => this.pdfLoading$.next(false))
            )
            .subscribe();
    }
}
