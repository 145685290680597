import { createReducer, on } from '@ngrx/store';
import * as fromActions from './actions';
import { BreadCrumbInfo } from '../../../../../../../../libs/projects/ak-jura/angular/my-ak-jura/shared-components/src/lib/breadcrumbs/breadcrumbs.component';

export const BREADCRUMB_STORE_NAME = 'breadcrumb';

export interface BreadcrumbReducer {
    breadcrumbs: BreadCrumbInfo[];
}

const initialState = {
    breadcrumbs: [{ label: 'Mein AK JURA', routerLink: '' }],
};

const _breadcrumbReducer = createReducer<BreadcrumbReducer>(
    initialState,
    /**
     * Set Breadcrumbs
     */
    on(fromActions.setBreadcrumbs, (state, action) => ({
        ...state,
        breadcrumbs: action.breadcrumbs,
    })),

    //removes same breadcrumbs before adding the new ones
    on(fromActions.addBreadcrumbsUnique, (state, action) => ({
        ...state,
        breadcrumbs: state.breadcrumbs
            .filter(
                ebc =>
                    action.breadcrumbs.filter(
                        ibc =>
                            ibc.label === ebc.label &&
                            ibc.routerLink === ebc.routerLink
                    ).length === 0
            )
            .concat(action.breadcrumbs),
    }))
);

export function breadcrumbReducer(state, action) {
    return _breadcrumbReducer(state, action);
}
