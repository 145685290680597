import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCourseParticipationId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { CourseType } from '../../../../../../shared/interfaces/src/lib/data-management/course/course';

@Component({
    selector: 'cna-warning-tile',
    templateUrl: './warning-tile.component.html',
    styleUrls: ['./warning-tile.component.scss'],
})
export class WarningTileComponent implements OnInit {
    constructor() {}
    ngOnInit(): void {}
}
