import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

@Component({
    selector: 'cna-arrow',
    templateUrl: './arrow.component.html',
    styleUrls: ['./arrow.component.scss'],
})
export class ArrowComponent implements OnInit {
    @Input() label: string;
    @Input() disabled = false;
    @Input() href: string | UrlTree = undefined;
    @Input() clickable: boolean = undefined;
    @HostBinding('class.clickable') clickableClass = false;

    constructor(private routingService: Router) {}

    ngOnInit(): void {
        this.clickableClass = this.clickable ?? !!this.href;
    }

    onClick() {
        if (this.href) {
            if (
                typeof this.href === 'string' &&
                this.href.match(/^(https?:\/\/)|mailto:/)
            ) {
                window.location.href = this.href;
            } else {
                this.routingService.navigateByUrl(this.href);
            }
        }
    }
}
