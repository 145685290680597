<ngb-accordion id="sidebar-accordion">
    <ngb-panel *ngFor="let note of notes$ | async">
        <ng-template ngbPanelTitle>
            <div
                class="d-flex flex-row justify-content-between"
                style="width: 100%; min-height: 40px"
            >
                <div class="d-flex flex-row align-items-baseline">
                    <div>
                        <img
                            src="assets/Pfeil_Accordion-unten.svg"
                            style="width: 20px; opacity: 50%"
                            class="acc-arrow"
                        />
                    </div>
                    <div
                        class="adelle-sans-14 font-weight-600 dark-gray"
                        style="
                            margin-left: 13px;
                            margin-top: 9.09px;
                            margin-bottom: 9.09px;
                            word-break: break-all;
                            margin-right: 13px;
                        "
                    >
                        {{ note.title }}
                    </div>
                </div>
                <div class="d-flex flex-row" style="margin-top: 6.09px">
                    <span (click)="open($event, note)">
                        <img
                            id="edit"
                            alt="edit"
                            src="assets/bearbeiten.svg"
                            style="width: 20px; height: 20px"
                        />
                    </span>
                    <span (click)="open($event, note)">
                        <img
                            id="close"
                            alt="close"
                            src="assets/trash.svg"
                            style="width: 20px; height: 20px; margin-left: 15px"
                        />
                    </span>
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <div
                class="adelle-sans-14 font-weight-300 dark-gray"
                style="
                    line-height: 22px;
                    width: 500px;
                    margin-left: 33px;
                    margin-right: 33px;
                "
            >
                {{ note.description }}
            </div>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
