<div *ngIf="courseParticipation" class="courseWrapper">
    <div class="course">
        <div class="justify-content-center pt-4">
            <cna-learn-time-with-progress-bar
                [currentLearnTimeInSeconds]="learnTimeInSeconds"
                [targetReadingTimeInHours]="targetTimeInHours"
            ></cna-learn-time-with-progress-bar>
        </div>

        <div class="justify-content-center pt-3 pb-5">
            <cna-learning-dashboard-tile-grid
                [modules]="courseParticipation.bookedCourse.modules"
                [courseType]="courseParticipation.bookedCourse.type"
                [repetitorium]="courseParticipation.bookedCourse.repetitorium"
                (selectModule)="navigateToModule($event)"
                (selectRepetitorium)="navigateToRepetitorium()"
                (printModule)="onPrintModule($event)"
                (printRepetitorium)="onPrintRepetitorium()"
            ></cna-learning-dashboard-tile-grid>
        </div>
    </div>
</div>
