<ng-container *ngFor="let toast of toastService.toasts">
    <ngb-toast
        *ngIf="toast.type === 'error'"
        class="bg-danger text-light"
        [autohide]="true"
        [delay]="toast.delay || 7000"
        (hidden)="toastService.remove(toast)"
    >
        <ng-template ngbToastHeader>
            <strong class="mx-1">Fehler!</strong>
        </ng-template>
        {{ toast.text }}
    </ngb-toast>

    <ngb-toast
        *ngIf="toast.type === 'success'"
        class="bg-success text-light"
        [autohide]="true"
        [delay]="toast.delay || 7000"
        (hidden)="toastService.remove(toast)"
    >
        <ng-template ngbToastHeader>
            <strong class="mx-1">Erfolg!</strong>
        </ng-template>
        {{ toast.text }}
    </ngb-toast>

    <ngb-toast
        *ngIf="toast.type === 'info'"
        class="bg-light text-dark"
        [autohide]="true"
        [delay]="toast.delay || 7000"
        (hidden)="toastService.remove(toast)"
    >
        <ng-template ngbToastHeader>
            <strong class="mx-1">Information</strong>
        </ng-template>
        {{ toast.text }}
    </ngb-toast>
</ng-container>
