import { createAction, props } from '@ngrx/store';
import type { UUID } from '@cna/shared/generic-types';
import { InvoiceAddress } from '@cna/projects/ak-jura/shared/interfaces';

/**
 * Set Course Participation
 */
export const setCourseParticipation = createAction(
    '[course] set selected courseParticipation',
    props<{ courseParticipationId: UUID }>()
);
export const setCourseParticipationSuccess = createAction(
    '[course] set selected courseParticipation success'
);
export const setCourseParticipationFailure = createAction(
    '[course] set selected courseParticipation failure'
);
export const setCourseParticipationSkipped = createAction(
    '[course] set selected courseParticipation skipped'
);

/**
 Save CourseExtension
 */
export const saveCourseExtension = createAction(
    '[courseExtension] save CourseExtension attempt',
    props<{
        courseParticipationId: UUID;
        extensionMonthsNumber: number;
        invoiceAddress: InvoiceAddress;
        costPerMonth: any;
        vat: any;
    }>()
);
export const saveCourseExtensionSuccess = createAction(
    '[courseExtension] save CourseExtension success',
    props<{
        courseParticipationId: UUID;
        newValidUntil: string;
    }>()
);
export const saveCourseExtensionFailure = createAction(
    '[courseExtension] save CourseExtension failure',
    props<{ error: Error }>()
);
