import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as courseParticipationActions from './actions';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { forkJoin, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { selectCourseParticipationId, selectUrl } from '../router.selector';

@Injectable()
export class CourseParticipationEffects {
    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private store: Store,
        private router: Router
    ) {}

    // /api/module-document-info/?moduleId=b87f35f6-14f7-4fbd-ab42-8b471751550a&courseParticipationId=f40c2652-94bf-4339-aefb-aa2a53483f28

    loadCourseParticipationInfos$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(courseParticipationActions.loadCourseParticipationInfos),
            withLatestFrom(this.store.select(selectCourseParticipationId)),
            mergeMap(([action, courseParticipationId]) => {
                return this.http
                    .get(
                        environment.apiHost +
                            `/course-participation/${courseParticipationId}/overview`
                    )
                    .pipe(
                        map(res => {
                            return courseParticipationActions.loadCourseParticipationInfosSuccess(
                                {
                                    courseParticipation: res,
                                }
                            );
                        }),
                        map(loadedModules => {
                            loadedModules.courseParticipation.bookedCourse.modules.sort(
                                (module1, module2) =>
                                    module1.sequentialNumber -
                                    module2.sequentialNumber
                            );
                            return loadedModules;
                        }),
                        catchError(err =>
                            of(
                                courseParticipationActions.loadCourseParticipationInfosFailure(
                                    { error: err }
                                )
                            )
                        )
                    );
            })
        );
    });

    loadAllCourseParticipationInfos$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(courseParticipationActions.loadAllCourseParticipationInfo),
            mergeMap(action => {
                return this.http
                    .get(environment.apiHost + `/course-participation/me`)
                    .pipe(
                        map(res => {
                            return courseParticipationActions.loadAllCourseParticipationInfoDetails(
                                {
                                    courseParticipations: res,
                                }
                            );
                        }),
                        catchError(err =>
                            of(
                                courseParticipationActions.loadAllCourseParticipationInfoFailure(
                                    { error: err }
                                )
                            )
                        )
                    );
            })
        );
    });

    loadAllCourseParticipationInfosDetails$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(
                courseParticipationActions.loadAllCourseParticipationInfoDetails
            ),
            mergeMap(action => {
                return forkJoin(
                    (action.courseParticipations as any[]).map(
                        courseParticipation => {
                            return this.http
                                .get(
                                    environment.apiHost +
                                        `/course-participation/${courseParticipation.id}/overview`
                                )
                                .pipe(
                                    map((details: any) => {
                                        console.log(details);
                                        return {
                                            ...courseParticipation,
                                            bookedCourse: {
                                                ...courseParticipation.bookedCourse,
                                                associatedLegalArea:
                                                    details.bookedCourse
                                                        .associatedLegalArea,
                                            },
                                            isDone: (
                                                details.bookedCourse
                                                    .modules as any[]
                                            ).reduce((previous, module) => {
                                                return (
                                                    previous &&
                                                    module.controlTestPassed &&
                                                    module.targetReadingTime <=
                                                        module.totalTimeRead
                                                );
                                            }, true),
                                            controlTestIds:
                                                details.bookedCourse.modules.map(
                                                    module =>
                                                        module.controlTestId
                                                ),
                                        };
                                    })
                                );
                        }
                    )
                ).pipe(
                    map(res => {
                        return courseParticipationActions.loadAllCourseParticipationInfoDetailsSuccess(
                            {
                                courseParticipations: res,
                            }
                        );
                    }),
                    catchError(err =>
                        of(
                            courseParticipationActions.loadAllCourseParticipationInfoDetailsFailure(
                                { error: err }
                            )
                        )
                    )
                );
            })
        );
    });

    updateLastLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(courseParticipationActions.updateLastLogin),
            withLatestFrom(this.store.select(selectCourseParticipationId)),
            mergeMap(([, courseParticipationId]) =>
                this.http
                    .patch(
                        `${environment.apiHost}/course-participation/${courseParticipationId}`,
                        {
                            lastLogin: new Date().toISOString(),
                        }
                    )
                    .pipe(
                        map(() =>
                            courseParticipationActions.updateLastLoginSuccess({
                                courseParticipationId,
                            })
                        ),
                        catchError(err =>
                            of(
                                courseParticipationActions.updateLastLoginFailure(
                                    { error: err.message }
                                )
                            )
                        )
                    )
            )
        )
    );

    routeDashboardOnForbidden$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    courseParticipationActions.loadCourseParticipationInfosSuccess
                ),
                withLatestFrom(this.store.select(selectUrl)),
                tap(([{ courseParticipation }, url]) => {
                    const isInvalid =
                        courseParticipation.isBlocked ||
                        new Date(courseParticipation.validUntil).getTime() <
                            Date.now();
                    const isLearningView = new RegExp(
                        `^/${courseParticipation.id}/learning/.*$`
                    ).test(url);
                    if (isInvalid && isLearningView) {
                        this.router.navigate(['/', courseParticipation.id]);
                    }
                })
            ),
        { dispatch: false }
    );
}
