import { createReducer, on } from '@ngrx/store';
import { SidenavContentType } from 'libs/projects/ak-jura/angular/my-ak-jura/shared-components/src/lib/sidenav/sidenav.component';
import * as fromActions from './actions';
// import { Customer } from 'libs/projects/ak-jura/shared/interfaces/src/lib/data-management/people/customer';
import {
    Examinable,
    ModuleDocument,
    Repetitorium,
    TimeExpenditure,
} from '@cna/projects/ak-jura/shared/interfaces';
import { Exam } from '../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam';
import { ExamParticipation } from '../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam-participation';
import { ControlTest } from '../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/control-test/control-test';
import { ModuleDocumentHelperService } from '../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/module-document-helper.service';
import { LearningModuleDocumentInfo } from '../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/learning-module-document-info';
import { uniqBy } from 'lodash';

export const GLOBAL_STORE_NAME = 'global';
const moduleDocumentHelperService = new ModuleDocumentHelperService();

export interface GlobalState {
    version: number;

    // customer: Customer;
    examParticipations: ExamParticipation[];
    exams: Exam[];
    moduleInfos: LearningModuleDocumentInfo[];
    moduleDocuments: ModuleDocument[];
    moduleExaminables: Examinable[];
    actualNumbering: string[];
    repetitorium: Repetitorium;
    repetitoriumUnlocked: boolean;
    controlTest: ControlTest;
    controlTestResult: boolean;
    timeExpenditures: TimeExpenditure[];
    loading: boolean;
    errorDescription: string;
    deleteNote: string;

    module: any;
}

const initialState = {
    version: 0,

    // customer: undefined,
    examParticipations: undefined,
    exams: undefined,
    moduleInfos: [],
    moduleDocuments: [],
    moduleExaminables: undefined,
    actualNumbering: [],
    repetitorium: undefined,
    repetitoriumUnlocked: false,
    controlTest: undefined,
    controlTestResult: undefined,
    timeExpenditures: [],
    loading: true,
    deleteNote: undefined,
    errorDescription: undefined,

    module: undefined,
};

const _globalReducer = createReducer<GlobalState>(
    initialState,
    /**
     * Any action skipped
     */
    on(fromActions.skipped, state => ({
        ...state,
        loading: false,
    })),
    on(fromActions.setVersion, (state, action) => ({
        ...state,
        version: action.version,
    })),

    /**
     * SideNav
     */
    /**
     on(fromActions.setActiveLearningViewState, (state, { activeState }) => ({
        ...state,
        learningViewIsActive: activeState,
    })), */ /**
     on(fromActions.setSidenavActiveState, (state, { activeState }) => ({
        ...state,
        sidenavIsActive: activeState,
    })),
     on(fromActions.setSidenavContentType, (state, { contentType }) => ({
        ...state,
        sidenavContentType: contentType,
    })),
     on(fromActions.setNavInsideSidenav, (state, { activeState }) => ({
        ...state,
        navInsideSidenav: activeState,
    })), */

    /**
     * Error
     */ /**
     on(fromActions.setErrorDescription, (state, action) => ({
        ...state,
        errorDescription: action.errorDescription,
        loading: false,
    })),
     on(fromActions.resetErrorDescription, state => ({
        ...state,
        errorDescription: undefined,
        loading: false,
    })), */
    /**
     on(fromActions.setCourseParticipationSuccess, state => ({
        ...state,
    })), */

    /**
     * Load Customer
     */ /**
     on(fromActions.loadCustomer, state => ({
        ...state,
        loading: true,
    })),
     on(fromActions.loadCustomerSuccess, (state, action) => ({
        ...state,
        loading: false,
        customer: action.customer,
    })),
     on(fromActions.loadCustomerFailure, state => ({
        ...state,
        loading: false,
    })), */

    /**
     * Edit Customer
     */ /**
     on(fromActions.editCustomer, state => ({
        ...state,
        loading: true,
    })),
     on(fromActions.editCustomerSuccess, (state, action) => ({
        ...state,
        loading: false,
        customer: { ...state.customer, user: action.userData.user },
    })),
     on(fromActions.editCustomerFailure, state => ({
        ...state,
        loading: false,
    })), */

    /**
     * Load Exams
     */ /**
     on(fromActions.loadExamParticipations, state => ({
        ...state,
        loading: true,
    })),
     on(fromActions.loadExamParticipationsSuccess, (state, action) => ({
        ...state,
        loading: false,
        examParticipations: action.examParticipations,
    })),
     on(fromActions.loadExamParticipationsFailure, state => ({
        ...state,
        loading: false,
    })),

     /**
     * Load Exams
     */ /**
     on(fromActions.loadExams, state => ({
        ...state,
        loading: true,
    })),
     on(fromActions.loadExamsSuccess, (state, action) => ({
        ...state,
        loading: false,
        exams: action.exams,
    })),
     on(fromActions.loadExamsFailure, state => ({
        ...state,
        loading: false,
    })), */

    /**
     * load ModuleInfos //
     */
    on(fromActions.loadModuleInfos, state => ({
        ...state,
        loading: true,
    })),

    on(fromActions.loadModuleInfosSuccess, (state, action) => ({
        ...state,
        moduleInfos: uniqBy(
            [...action.moduleInfos, ...state.moduleInfos],
            'id'
        ),
        /**
         customer: {
            ...state.customer, // TODO
            courseParticipations: state.customer.courseParticipations.map(cp =>
                cp.id === action.moduleInfos[0].courseParticipationId
                    ? {
                          ...cp,
                          reloadModuleInfo: false,
                      }
                    : cp
            ),
        }, */
        loading: false,
    })),

    on(fromActions.loadModuleInfosFailure, state => ({
        ...state,
        loading: false,
    })),

    /**
     * Load ModuleDocument
     */
    on(fromActions.loadModuleDocument, state => ({
        ...state,
        loading: true,
    })),
    on(fromActions.loadModuleDocumentSuccess, (state, action) => ({
        ...state,
        /**
         customer: {
            ...state.customer,
            courseParticipations: state.customer.courseParticipations.map(cp =>
                cp.id === action.moduleDocument.courseParticipationId
                    ? {
                          ...cp,
                          reloadModuleDocument: false,
                      }
                    : cp
            ),
        }, */
        moduleDocuments: uniqBy(
            [...[action.moduleDocument], ...state.moduleDocuments],
            'moduleId'
        ),
        loading: false,
    })),

    on(fromActions.loadModuleDocumentFailure, state => ({
        ...state,
        loading: false,
    })),
    on(fromActions.loadModuleDocumentSkipped, state => ({
        ...state,
        loading: false,
    })),

    /**
     * Extract notes from course
     */ /**
     on(fromActions.extractNotesForCourse, state => ({
        ...state,
        loading: true,
    })),
     on(fromActions.extractNotesForCourseSuccess, (state, action) => ({
        ...state,
        moduleNotes: uniqBy(
            [...state.moduleNotes, ...action.notes],
            'moduleId'
        ),
        loading: false,
    })),
     on(fromActions.extractNotesForCourseFailure, (state, action) => ({
        ...state,
        loading: false,
    })),

     on(fromActions.setActualNumberingArray, (state, action) => ({
        ...state,
        actualNumbering: action.numbering,
    })), */

    /**
     * Load Time Expenditure for Course
     */
    on(fromActions.loadTimeExpendituresForActualCourse, state => ({
        ...state,
        loading: true,
    })),
    on(
        fromActions.loadTimeExpendituresForActualCourseSuccess,
        (state, action) => ({
            ...state,
            timeExpenditures: action.timeExpenditures,
            loading: false,
        })
    ),
    on(
        fromActions.loadTimeExpendituresForActualCourseFailure,
        (state, action) => ({
            ...state,
            loading: false,
        })
    ),
    on(fromActions.saveTimeExpenditureToLocalStore, (state, action) => {
        //update ReadingTime for Node and beyond
        state.moduleInfos.forEach(mi => {
            if (mi.moduleId === action.timeExpenditure.moduleId) {
                const temp = moduleDocumentHelperService.updateReadingTime(
                    mi.chapters,
                    action.timeExpenditure
                );
                mi.timeRead = temp.timeRead;
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                mi.chapters = temp.childArray;
            }
        });
        return {
            ...state,
            loading: false,
            timeExpenditures: state.timeExpenditures.concat([
                action.timeExpenditure,
            ]),
        };
    }),

    /**
     * load Repetitorium for actual Course
     */ /**
     on(fromActions.loadRepetitoriumForActualCourse, state => ({
        ...state,
        loading: true,
    })),
     on(fromActions.loadRepetitoriumForActualCourseSuccess, (state, action) => ({
        ...state,
        repetitorium: action.repetitorium,
        loading: false,
    })),
     on(fromActions.loadRepetitoriumForActualCourseFailure, state => ({
        ...state,
        loading: false,
    })),
     on(fromActions.setRepetitoriumUnlockedState, (state, action) => ({
        ...state,
        repetitoriumUnlocked: action.unlockedState,
    })), */

    /**
     * load ControlTest for actual Course
     */
    /**
    on(fromActions.loadControlTestForActualCourse, state => ({
        ...state,
        loading: true,
    })),
    on(fromActions.loadControlTestForActualCourseSuccess, (state, action) => ({
        ...state,
        controlTest: action.controlTest,
        loading: false,
    })),
    on(fromActions.loadControlTestForActualCourseFailure, state => ({
        ...state,
        loading: false,
    })), */

    /**
    on(fromActions.saveControlTestAttemptSuccess, (state, action) => ({
        ...state,
        controlTestResult: action.result.passed,
        loading: false,
        moduleInfos: state.moduleInfos.map(mi =>
            mi.moduleId === action.moduleId
                ? {
                      ...mi,
                      controlTestResults: mi.controlTestResults.concat([
                          action.result,
                      ]),
                      controlTestPassed: action.result.passed,
                  }
                : mi
        ),
    })), */

    /**
     on(fromActions.saveBookingExamSuccess, (state, action) => ({
        ...state,
        examParticipations: state.examParticipations.concat([
            action.examParticipation,
        ]),
    })), */

    /** */ /**
     on(fromActions.deleteNoteSuccess, (state, action) => ({
        ...state,
        moduleNotes: state.moduleNotes.map(m => {
            m.notes = m.notes.filter(n => n.id !== action.noteId);
            return m;
        }),
    })), */

    /**
     on(fromActions.saveCourseExtensionSuccess, (state, action) => ({
        ...state,
        customer: { // TODO
            ...state.customer,
            courseParticipations: state.customer.courseParticipations.map(cp =>
                cp.id === action.courseParticipationId
                    ? {
                          ...cp,
                          validUntil: action.newValidUntil,
                      }
                    : cp
            ),
        },
    })), */

    /**
    on(fromActions.resetControlTestValidationResult, state => ({
        ...state,
        controlTestResult: undefined,
    })), */

    on(
        fromActions.setReadStatusForSectionsSuccess,
        (state: GlobalState, action) => {
            if (action.readStatus.length > 0) {
                const actualModuleInfo = state.moduleInfos.find(
                    mi => mi.moduleId === action.readStatus[0].moduleId
                );
                if (actualModuleInfo) {
                    moduleDocumentHelperService.updateReadStatusOfNodesInTree(
                        actualModuleInfo,
                        action.readStatus
                    );
                    moduleDocumentHelperService.updateProgressOfNodeRecursive(
                        actualModuleInfo
                    );
                }
            }
            return {
                ...state,
                controlTestResult: undefined,
            };
        }
    ) /**

    /** setReadStatusForRepetitoriumSuccess */ /**
     on(fromActions.setReadStatusForRepetitoriumSuccess,
     (state: GlobalState, action) => {
            return {
                ...state,
                repetitorium: {
                    ...state.repetitorium,
                    favoriteGroups: state.repetitorium.favoriteGroups.map(fg =>
                        fg.id === action.readStatus.favoriteGroupId
                            ? {
                                  ...fg,
                                  workedOut: action.readStatus,
                              }
                            : fg
                    ),
                },
            };
        }
     ), */ /**

     /**
     * Add Note
     */ /**
     on(fromActions.addNote, state => ({
        ...state,
    })),

     on(fromActions.addNoteSuccess, (state, action) => ({
        ...state,
        moduleNotes: state.moduleNotes.map(moduleNote => {
            return moduleNote.moduleId === action.note.moduleId
                ? {
                      courseParticipationId: action.courseParticipationId,
                      moduleNumber: moduleNote.moduleNumber,
                      notes: [
                          ...moduleNote.notes,
                          {
                              ...action.note,
                              chapterNumbering: state.actualNumbering,
                          },
                      ],
                      moduleId: moduleNote.moduleId,
                  }
                : moduleNote;
        }),
    })),
     on(fromActions.addNoteFailure, state => ({
        ...state,
    })), */ /**

     /**
     * Update Note
     */ /**
     on(fromActions.updateNote, state => ({
        ...state,
    })),
     on(fromActions.updateNoteSuccess, (state, action) => ({
        ...state,
        moduleNotes: [
            ...state.moduleNotes.map(value => {
                return {
                    courseParticipationId: value.courseParticipationId,
                    moduleNumber: value.moduleNumber,
                    notes: value.notes.map(note => {
                        return note.id === action.note.id
                            ? {
                                  ...note,
                                  id: note.id,
                                  title: action.note.title,
                                  description: action.note.description,
                              }
                            : note;
                    }),
                    moduleId: value.moduleId,
                };
            }),
        ],
    })),
     on(fromActions.updateNoteFailure, state => ({
        ...state,
    })), */
    /**
     on(fromActions.loadModuleExaminables, state => ({
        ...state,
        loading: true,
    })),
     on(fromActions.loadModuleExaminablesSuccess, (state, action) => ({
        ...state,
        moduleExaminables: action.examinables,
    })),
     on(fromActions.loadModuleExaminablesFailure, state => ({
        ...state,
        loading: false,
    })) */
);

export function globalReducer(state, action) {
    return _globalReducer(state, action);
}
