import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Exam } from '../../../../../../shared/interfaces/src/lib/data-management/exam/exam';
import { ExamHelperService } from '../../../../../shared-components/src/lib/services/exam-helper.service';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { selectModuleInfosForActualCourseParticipation } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/selectors';

import {
    selectExamParticipations,
    selectVisibleExams,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/exam/selectors';

import { map, skipWhile, takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cna-exam-dates',
    templateUrl: './exam-dates.component.html',
    styleUrls: ['./exam-dates.component.scss'],
})
export class ExamDatesComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject();
    // @Input() exams: Exam[];

    //public viewableExams;

    public availableExams$: Observable<Exam[]> = this.store
        .select(selectVisibleExams)
        .pipe(
            skipWhile(sVE => sVE === undefined || sVE === null),
            takeUntil(this.destroy$)
        );

    public passedCourse$: Observable<boolean> = this.store
        .select(selectModuleInfosForActualCourseParticipation)
        .pipe(
            skipWhile(mi => !mi),
            map(moduleInfos =>
                moduleInfos
                    .map(moduleInfo => moduleInfo.controlTestPassed)
                    .every(passed => passed)
            )
        );

    constructor(
        public examHelperService: ExamHelperService,
        private store: Store
    ) {}

    ngOnInit(): void {}

    alreadyBookedOnce(examInput) {
        let returnBoolean;
        this.store
            .select(selectExamParticipations)
            .subscribe(exams => {
                exams.forEach(exam => {
                    if (exam.examId === examInput.id) {
                        returnBoolean = false;
                    } else {
                        returnBoolean = true;
                    }
                });
            })
            .unsubscribe();
        return returnBoolean;
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
