import { CourseParticipation } from '@cna/projects/ak-jura/shared/interfaces';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    COURSE_PARTICIPATION_STORE_NAME,
    CourseParticipationState,
} from './reducers';

export const selectCourseParticipationState =
    createFeatureSelector<CourseParticipationState>(
        COURSE_PARTICIPATION_STORE_NAME
    );

export const selectCourseParticipationLoading = createSelector(
    selectCourseParticipationState,
    state => state.loading
);

export const selectCustomersCourseParticipation = createSelector(
    selectCourseParticipationState,
    (courseParticipation: any) => {
        return courseParticipation.course_participation;
    }
);

export const selectAllCustomersCourseParticipations = createSelector(
    selectCourseParticipationState,
    (courseParticipations: any) => {
        console.log(
            'selectAllCustomersCourseParticipations',
            courseParticipations
        );
        return courseParticipations.course_participations;
    }
);

export const selectCustomersCourseParticipationID = createSelector(
    selectCourseParticipationState,
    (courseParticipation: any) => {
        return courseParticipation?.course_participation?.id;
    }
);

export const selectCustomersCourseParticipationBookedCourseId = createSelector(
    selectCourseParticipationState,
    global => {
        return global?.course_participation?.bookedCourse?.id;
    }
);

/** for learningstate view page */
export const selectCustomersCourseParticipationModules = createSelector(
    selectCourseParticipationState,
    (courseParticipation: any) => {
        return courseParticipation?.course_participation?.bookedCourse.modules;
    }
);

export const selectCourseParticipationIsDone = createSelector(
    selectCustomersCourseParticipation,
    cp => cp.isDone ?? false
);

export const selectCurrentCourseType = createSelector(
    selectCustomersCourseParticipation,
    (courseParticipation: CourseParticipation) =>
        courseParticipation?.bookedCourse?.type
);

export const selectExtensionLoading = createSelector(
    selectCourseParticipationState,
    state => state.extensionLoading
);
