<div class="modal-header">
    <h4 class="modal-title adelle-sans-17 font-weight-600">
        Meine Kontaktdaten bearbeiten
    </h4>
    <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"
    >
        <img alt="close" src="assets/x-close.svg" height="20px" width="20px" />
    </button>
</div>
<div class="modal-body">
    <form
        [formGroup]="customerForm"
        (ngSubmit)="onSaveClicked()"
        class="formnote"
    >
        <div class="form-group form-el">
            <label for="title" class="adelle-sans-17 font-weight-600"
                >Titel</label
            >
            <input
                id="title"
                type="text"
                class="form-control formtitle"
                name="title"
                formControlName="title"
            />
        </div>
        <div class="form-group form-el">
            <label for="salutation" class="adelle-sans-17 font-weight-600"
                >Anrede</label
            >
            <select
                id="salutation"
                class="form-control formtitle"
                name="salutation"
                formControlName="salutation"
            >
                <option
                    *ngFor="let salutation of salutations"
                    [ngValue]="salutation"
                >
                    {{ salutation }}
                </option>
            </select>
        </div>
        <div class="form-group form-el">
            <label for="firstName" class="adelle-sans-17 font-weight-600">
                Vorname*
            </label>
            <input
                id="firstName"
                type="text"
                class="form-control formtitle"
                name="firstName"
                formControlName="firstName"
                required
            />
        </div>
        <div class="form-group form-el">
            <label for="lastName" class="adelle-sans-17 font-weight-600">
                Nachname*
            </label>
            <input
                id="lastName"
                type="text"
                class="form-control formtitle"
                name="lastName"
                formControlName="lastName"
                required
            />
        </div>
        <div formGroupName="address">
            <div class="form-group form-el">
                <label
                    for="addressLine1"
                    class="adelle-sans-17 font-weight-600"
                >
                    Straße, Hausnummer*
                </label>
                <input
                    id="addressLine1"
                    type="text"
                    class="form-control formtitle"
                    name="addressLine1"
                    formControlName="addressLine1"
                    required
                />
            </div>
            <div class="form-group form-el">
                <label for="postCode" class="adelle-sans-17 font-weight-600">
                    Postleitzahl*
                </label>
                <input
                    id="postCode"
                    type="text"
                    class="form-control formtitle"
                    name="postCode"
                    formControlName="postCode"
                    required
                />
            </div>
            <div class="form-group form-el">
                <label for="city" class="adelle-sans-17 font-weight-600">
                    Ort*
                </label>
                <input
                    id="city"
                    type="text"
                    class="form-control formtitle"
                    name="city"
                    formControlName="city"
                    required
                />
            </div>
        </div>
        <div class="form-group form-el">
            <label for="phone" class="adelle-sans-17 font-weight-600">
                Telefonnummer
            </label>
            <input
                id="phone"
                type="text"
                class="form-control formtitle"
                name="phone"
                formControlName="phone"
            />
        </div>
        <div class="form-el" style="padding-top: 19px">
            <cna-gradient-button
                notgradient="true"
                buttonTitle="Speichern"
                [disabled]="customerForm.invalid"
            ></cna-gradient-button>
        </div>

        <div>
            <p
                *ngIf="customerForm.dirty && customerForm.invalid"
                class="red-color adelle-sans-12"
            >
                Bitte füllen Sie alle Pflichtfelder aus!
            </p>
        </div>
    </form>
</div>
