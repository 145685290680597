import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'secondsToHours',
})
export class SecondsToHoursPipe implements PipeTransform {
    transform(
        valueInSeconds: number,
        onlyHours = false,
        withSeconds = false
    ): string {
        if (valueInSeconds === null || valueInSeconds === undefined) {
            return '–';
        }
        const hours = Math.floor(valueInSeconds / 3600);

        if (onlyHours) {
            return hours.toString();
        }

        const minutes = Math.floor((valueInSeconds - hours * 3600) / 60);
        const seconds = valueInSeconds - hours * 3600 - minutes * 60;

        let formattedTime = '';

        if (hours > 0) {
            formattedTime += hours.toString().padStart(2, '0') + ':';
        } else {
            formattedTime += '00:';
        }
        if (minutes > 0) {
            formattedTime += minutes.toString().padStart(2, '0');
        } else {
            formattedTime += '00';
        }
        if (withSeconds) {
            if (seconds > 0) {
                formattedTime += ':' + seconds.toString().padStart(2, '0');
            } else {
                formattedTime += ':00';
            }
        }
        return `${formattedTime}`;
    }
}
