import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectRepetitorium } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/repetitorium/selectors';
import { map, withLatestFrom } from 'rxjs/operators';
import { FavoriteGroup } from '@cna/projects/ak-jura/shared/interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import {
    selectCourseParticipationId,
    selectCurrentRoute,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { setNavInsideSidenav } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/actions';

@Component({
    selector: 'cna-repetitorium-accordion',
    templateUrl: './repetitorium-accordion.component.html',
    styleUrls: ['./repetitorium-accordion.component.scss'],
})
export class RepetitoriumAccordionComponent implements OnInit {
    @Input() sidebar: boolean;

    repetitorium$ = this.store.select(selectRepetitorium);
    favoriteGroups$ = this.store.select(selectRepetitorium).pipe(
        map(rep =>
            rep?.favoriteGroups
                .map(fg => ({
                    ...fg,
                    title: fg.title.split('.').slice(1).join('.'),
                    sort: Number(fg.title.split('.')[0]),
                }))
                .sort((fg1, fg2) => fg1.sort - fg2.sort)
        )
    );

    progress$: Observable<number> = this.favoriteGroups$.pipe(
        map(fgs => {
            if (fgs) {
                return (
                    (100 / fgs.length) *
                    fgs.filter(fg => fg.workedOut?.workedOut).length
                );
            }
        })
    );

    constructor(
        private store: Store,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit(): void {}

    navigate(favoriteGroup: FavoriteGroup) {
        if (this.sidebar) {
            this.store.dispatch(setNavInsideSidenav({ activeState: true }));
            this.store
                .select(selectCurrentRoute)
                .pipe(
                    withLatestFrom(
                        this.store.select(selectCourseParticipationId)
                    )
                )
                .subscribe(([route, cpId]) => {
                    this.router
                        .navigate([cpId])
                        .then(() =>
                            this.router.navigate([
                                cpId,
                                'learning',
                                route.url[0].path,
                                favoriteGroup.id,
                            ])
                        );
                })
                .unsubscribe();
        } else {
            this.router.navigate([favoriteGroup.id], {
                relativeTo: this.route,
            });
        }
    }

    getWorkedOutAsObservable(favoriteGroup: FavoriteGroup) {
        return of(favoriteGroup.workedOut ? 100 : 0);
    }
}
