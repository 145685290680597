import { Pipe, PipeTransform } from '@angular/core';
import { NumberingService } from '../../../../services/src/lib/numbering/numbering.service';

@Pipe({
    name: 'numberToNumbering',
})
export class NumberToNumberingPipe implements PipeTransform {
    constructor(private numberingService: NumberingService) {}
    transform(
        value: number,
        format?:
            | 'upperRomanNumerals'
            | 'lowerRomanNumerals'
            | 'lowerCaseLetters'
            | 'upperCaseLetters'
    ): string {
        switch (format) {
            case 'upperRomanNumerals':
                return this.numberingService.toUppercaseRomanNumbers(value);
            case 'lowerRomanNumerals':
                return this.numberingService.toLowercaseRomanNumbers(value);
            case 'upperCaseLetters':
                return this.numberingService.toLowercaseLetters(value);
            case 'lowerCaseLetters':
                return this.numberingService.toUppercaseLetters(value);
        }
    }
}
