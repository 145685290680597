import { Injectable } from '@angular/core';

const SPLITTER_STRING = '. ';

export enum NumberingTypes {
    UPPERCASE_ROMAN = 'uppercaseRoman',
    LOWERCASE_ROMAN = 'lowercaseRoman',
    UPPERCASE_LETTERS = 'uppercaseLetters',
    LOWERCASE_LETTERS = 'lowercaseLetters',
    DIGITS = 'digits',
}

@Injectable({
    providedIn: 'root',
})
export class NumberingService {
    private uppercaseRomanNumerals = [
        ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX'], // 1-9
        ['X', 'XX', 'XXX', 'XL', 'L', 'LX', 'LXX', 'LXXX', 'XC'], // 10-90
        ['C', 'CC', 'CCC', 'CD', 'D', 'DC', 'DCC', 'DCCC', 'CM'], // 100-900
        ['M', 'MM', 'MMM'], // 1000-3000
    ];

    private lowercaseRomanNumerals = [
        ['i', 'ii', 'iii', 'iv', 'v', 'vi', 'vii', 'viii', 'ix'], // 1-9
        ['x', 'xx', 'xxx', 'xl', 'l', 'lx', 'lxx', 'lxxx', 'xc'], // 10-90
        ['c', 'cc', 'ccc', 'cd', 'd', 'dc', 'dcc', 'dccc', 'cm'], // 100-900
        ['m', 'mm', 'mmm'], // 1000-3000
    ];

    private constToFunctionMap = new Map([
        [
            NumberingTypes.UPPERCASE_ROMAN,
            (value) => this.toUppercaseRomanNumbers(value),
        ],
        [
            NumberingTypes.LOWERCASE_ROMAN,
            (value) => this.toLowercaseRomanNumbers(value),
        ],
        [
            NumberingTypes.UPPERCASE_LETTERS,
            (value) => this.toUppercaseLetters(value),
        ],
        [
            NumberingTypes.LOWERCASE_LETTERS,
            (value) => this.toLowercaseLetters(value),
        ],
        [NumberingTypes.DIGITS, (value) => this.toNumbers(value)],
    ]);

    getNumberingForType(value, numberingType: NumberingTypes): string {
        return this.constToFunctionMap.get(numberingType)(value);
    }

    constructor() {}

    removeNumberingFromString(inputString: string): string {
        let splittedStringArray = inputString.split('. ');
        if (splittedStringArray.length === 1) {
            splittedStringArray = inputString.split(') ');
        }
        if (splittedStringArray.length > 1) {
            splittedStringArray.shift();
        }
        return splittedStringArray.join(SPLITTER_STRING);
    }

    /**
     * Just needed for constToFunctionMap
     * @param value input value
     */
    toNumbers(value: number): string {
        return '' + value;
    }

    /**
     * Translates a given value to uppercase Letters
     * 1 => A
     * 3 => C
     * 27 => AA
     * 677 => ZZA
     * BUGGY FOR GREATER THAT 677!!!
     * @param value
     */
    toUppercaseLetters(value: number): string {
        return this.translateToLetters(value, 64);
    }

    /**
     * Translates a given value to uppercase Letters
     * 1 => a
     * 3 => c
     * 27 => aa
     * 677 => zza
     * BUGGY FOR GREATER THAT 677!!!
     * @param value
     */
    toLowercaseLetters(value: number): string {
        return this.translateToLetters(value, 96);
    }

    private translateToLetters(value, firstLetterAsciiCode) {
        let letters = '';
        let rest: number;
        if (value > 26) {
            const temp = Math.floor(value / 26);
            letters =
                letters + this.translateToLetters(temp, firstLetterAsciiCode);
            rest = value - temp * 26;
        } else {
            rest = value;
        }
        letters = letters + String.fromCharCode(firstLetterAsciiCode + rest);
        return letters;
    }

    toUppercaseRomanNumbers(value): string {
        return this.numberToRomanNumber(this.uppercaseRomanNumerals, value);
    }

    toLowercaseRomanNumbers(value): string {
        return this.numberToRomanNumber(this.lowercaseRomanNumerals, value);
    }

    private numberToRomanNumber(numerals: string[][], value: number) {
        const digits = Math.round(value).toString().split('');
        let position = digits.length - 1;

        return digits.reduce((roman, digit) => {
            if (digit !== '0') {
                roman += numerals[position][parseInt(digit, 10) - 1];
            }

            position -= 1;

            return roman;
        }, '');
    }
}
