import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'cna-checkbox-input',
    templateUrl: './checkbox-input.component.html',
    styleUrls: ['./checkbox-input.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class CheckboxInputComponent {
    @Input() checked = false;
    @Input() disabled = false;
    @Input() label: string;
    @Output() changeEvent = new EventEmitter<{ checked: boolean }>();

    async onChange($event: Event): Promise<void> {
        this.checked = (<HTMLInputElement>$event.target).checked;
        this.changeEvent.emit({
            checked: this.checked,
        });
    }
}
