import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModuleDocument } from '@cna/projects/ak-jura/shared/interfaces';
import { filterNil } from '@cna/shared/helper';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';

import { takeUntil } from 'rxjs/operators';
import { selectRouter } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { selectModuleInfo } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/module_infos/selectors';

@Component({
    selector: 'cna-sidenav-content-overview',
    templateUrl: './sidenav-content-overview.component.html',
    styleUrls: ['./sidenav-content-overview.component.scss'],
})
export class SidenavContentOverviewComponent implements OnInit, OnDestroy {
    destroy$ = new Subject<void>();

    moduleInfo$ = this.store.pipe(
        select(selectModuleInfo),
        takeUntil(this.destroy$)
    );

    changedMode$ = this.store.pipe(
        select(selectRouter),
        takeUntil(this.destroy$)
    );

    isRepetitoriumMode: boolean;

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.changedMode$.pipe(filterNil()).subscribe(value => {
            const currentUrl = value.state.url;
            this.isRepetitoriumMode = currentUrl.includes('repetitorium');
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
