import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EXAMS_STORE_NAME, ExamState } from './reducers';
import { selectActualCourseLegalArea } from '../course/selectors';

export const selectExamsState =
    createFeatureSelector<ExamState>(EXAMS_STORE_NAME);

export const selectExamParticipations = createSelector(
    selectExamsState,
    global => {
        return global?.examParticipations;
    }
);

export const selectExams = createSelector(
    selectExamsState,
    global => global?.exams
);

export const selectVisibleExams = createSelector(selectExams, exams => {
    return exams
        ?.filter(e => {
            return e.isActive && e.maxParticipants > e.participants?.length;
        })
        .map(exam => ({
            ...exam,
            examinationPeriods: exam.examinationPeriods.sort(
                (ep1, ep2) =>
                    new Date(ep1.date).getTime() - new Date(ep2.date).getTime()
            ),
        }));
});

export const selectActualExamParticipation = createSelector(
    selectExamParticipations,
    selectActualCourseLegalArea,
    (exams, legalArea) => {
        if (typeof legalArea !== 'string') {
            return exams.find(exam => exam.legalAreaId === legalArea.id);
        }
    }
);
