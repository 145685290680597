import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as moduleInfosActions from './actions';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
    selectCourseParticipationId,
    selectModuleId,
} from '../router.selector';
import { Store } from '@ngrx/store';

@Injectable()
export class ModuleInfoEffects {
    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private store: Store
    ) {}

    /**
     * Load Module Info
     */
    loadModuleInfosForModuleId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(moduleInfosActions.loadModuleInfosForModuleId),
            withLatestFrom(
                this.store.select(selectCourseParticipationId),
                this.store.select(selectModuleId)
            ),
            mergeMap(([action, cpId, mId]) => {
                return this.http
                    .get<any>(environment.apiHost + '/module-document-info', {
                        params: {
                            moduleId: mId,
                            courseParticipationId: cpId,
                        },
                    })
                    .pipe(
                        map(response => {
                            return moduleInfosActions.loadModuleInfosForModuleIdSuccess(
                                {
                                    moduleInfo: response,
                                }
                            );
                        }),
                        catchError(err =>
                            of(
                                moduleInfosActions.loadModuleInfosForModuleIdFailure(
                                    { error: err }
                                )
                            )
                        )
                    );
            })
        );
    });
}
