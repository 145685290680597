import { createAction, props } from '@ngrx/store';
import type { UUID } from '@cna/shared/generic-types';
import { ReadStatus } from '@cna/projects/ak-jura/shared/interfaces';

export const loadReadStatus = createAction(
    '[ReadStatus] select read status for section',
    props<{
        courseParticipationId: UUID;
        moduleId: UUID;
        sectionId: UUID;
    }>()
);

export const loadReadStatusSuccess = createAction(
    '[ReadStatus] select read status for section success',
    props<{
        readStatus?: ReadStatus;
    }>()
);

export const loadReadStatusFailure = createAction(
    '[ReadStatus] select read status for section failure',
    props<{ message: string }>()
);

export const loadCurrentReadStatus = createAction(
    '[ReadStatus] select current read status'
);

export const createReadStatus = createAction(
    '[ReadStatus] create a new read status entry',
    props<{
        courseParticipationId: UUID;
        moduleId: UUID;
        sectionId: UUID;
        read: boolean;
    }>()
);

export const createReadStatusForCurrent = createAction(
    '[ReadStatus] create read status for current',
    props<{ read: boolean }>()
);

export const createReadStatusSuccess = createAction(
    '[ReadStatus] create read status success',
    props<{ readStatus: ReadStatus }>()
);

export const createReadStatusFailure = createAction(
    '[ReadStatus] create read status failure',
    props<{ message: string }>()
);

export const updateReadStatus = createAction(
    '[ReadStatus] update existing read status',
    props<{
        id: UUID;
        read: boolean;
    }>()
);

export const updateReadStatusSuccess = createAction(
    '[ReadStatus] update existing read status success',
    props<{ readStatus: ReadStatus }>()
);

export const updateReadStatusFailure = createAction(
    '[ReadStatus] update existing read status failure',
    props<{ message: string }>()
);
