import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectActualCourseObject } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course/selectors';

import { setBreadcrumbs } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';
import { map, skipWhile, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
    selectCourseParticipationId,
    selectModuleId,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { Subject, combineLatest } from 'rxjs';
import { loadModuleInfosForModuleId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/module_infos/actions';
import {
    loadControlTestForActualCourse,
    resetControlTestValidationResult,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/controlTests/actions';
import {
    selectControlTest,
    selectControlTestResult,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/controlTests/selectors';
import {
    selectCurrentModuleSequentialNumber,
    selectModuleInfo,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/module_infos/selectors';
import { filterNil } from '@cna/shared/helper';
import { selectCurrentCourseType } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';
import { CourseType } from '@cna/projects/ak-jura/shared/interfaces';
import { NumberToNumberingPipe } from '../../../../../../../../angular/ui/pipes/src/lib/number-to-numbering.pipe';

@Component({
    selector: 'cna-learning-control-view',
    templateUrl: './learning-control-view.component.html',
    styleUrls: ['./learning-control-view.component.scss'],
})
export class LearningControlViewComponent implements OnInit, OnDestroy {
    _destroy$ = new Subject();

    controlTestResult$ = this.store
        .select(selectControlTestResult)
        .pipe(takeUntil(this._destroy$));

    controlTest$ = this.store
        .select(selectControlTest)
        .pipe(takeUntil(this._destroy$));

    controlQuestions$ = this.controlTest$.pipe(
        takeUntil(this._destroy$),
        map(controlTest => controlTest?.controlQuestions)
    );

    selectModuleId$ = this.store.select(selectModuleInfo).pipe(
        skipWhile(value => value === undefined),
        takeUntil(this._destroy$)
    );

    courseType$ = this.store
        .select(selectActualCourseObject)
        .pipe(map(course => course?.type));

    constructor(
        private store: Store,
        private numberToNumberingPipe: NumberToNumberingPipe
    ) {}

    setBreadcrumbs() {
        combineLatest([
            this.store.select(selectCourseParticipationId).pipe(filterNil()),
            this.store.select(selectModuleId).pipe(filterNil()),
            this.store.select(selectCurrentCourseType).pipe(filterNil()),
            this.store
                .select(selectCurrentModuleSequentialNumber)
                .pipe(filterNil()),
        ])
            .pipe(takeUntil(this._destroy$))
            .subscribe(
                ([
                    courseParticipationId,
                    moduleId,
                    courseType,
                    sequentialNumber,
                ]) => {
                    this.store.dispatch(
                        setBreadcrumbs({
                            breadcrumbs: [
                                {
                                    label: 'Mein AK JURA',
                                    routerLink: courseParticipationId,
                                },
                                {
                                    label: 'Lernstatus',
                                    routerLink:
                                        courseParticipationId +
                                        '/learning/courseOverview',
                                },
                                {
                                    label:
                                        courseType === CourseType.TrainingCourse
                                            ? 'Inhalt'
                                            : `MODUL ${this.numberToNumberingPipe.transform(
                                                  sequentialNumber,
                                                  'upperRomanNumerals'
                                              )}`,
                                    routerLink:
                                        courseParticipationId +
                                        '/learning/moduleOverview/' +
                                        moduleId,
                                },
                                {
                                    label: 'Lernkontrolle',
                                    routerLink:
                                        courseParticipationId +
                                        '/learning/moduleOverview/' +
                                        moduleId +
                                        '/learningControl',
                                },
                            ],
                        })
                    );
                }
            );

        this.store
            .select(selectCourseParticipationId)
            .pipe(withLatestFrom(this.store.select(selectModuleId)))
            .subscribe(([courseParticipationId, moduleId]) => {
                if (courseParticipationId && moduleId) {
                }
            })
            .unsubscribe();
    }

    ngOnInit(): void {
        this.setBreadcrumbs();
        this.store.dispatch(loadModuleInfosForModuleId());
        this.selectModuleId$.subscribe(() => {
            this.store.dispatch(loadControlTestForActualCourse());
        });
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    retryTest() {
        this.store.dispatch(resetControlTestValidationResult());
    }
}
