import { createReducer, on } from '@ngrx/store';
import * as fromActions from './actions';

export const COURSE_STORE_NAME = 'course';

export interface CourseState {
    loading: boolean;
    course: any;
}

const initialState = {
    loading: false,
    course: undefined,
};

const _courseReducer = createReducer<CourseState>(
    initialState,

    on(fromActions.setCourseParticipation, state => ({
        ...state,
        loading: true,
    })),

    on(fromActions.setCourseParticipationSuccess, state => ({
        ...state,
        loading: false,
    }))
);

export function courseReducer(state, action) {
    return _courseReducer(state, action);
}
