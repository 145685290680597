import { Component } from '@angular/core';
import { ToasterService } from '../../../../../shared-components/src/lib/services/toaster.service';

@Component({
    selector: 'cna-app-toasts',
    templateUrl: './app-toasts.component.html',
    styleUrls: ['./app-toasts.component.scss'],
})
export class AppToastsComponent {
    constructor(public toastService: ToasterService) {}
}
