import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'cna-page-heading',
    templateUrl: './page-heading.component.html',
    styleUrls: ['./page-heading.component.css'],
})
export class PageHeadingComponent implements OnInit {
    @Input() pageHeadingTitle: string;
    @Input() pageHeadingSubTitle: string;

    constructor() {}

    ngOnInit(): void {}
}
