import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EXAMINABLES_STORE_NAME, ExaminablesState } from './reducers';

export const selectExaminables = createFeatureSelector<ExaminablesState>(
    EXAMINABLES_STORE_NAME
);

export const selectModuleExaminables = createSelector(
    selectExaminables,
    global => global?.moduleExaminables
);
