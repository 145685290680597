<div *ngLet="exam$ | async as exam">
    <div *ngLet="customer$ | async as customer">
        <div
            class="row justify-content-center"
            style="margin-top: 52px; margin-bottom: 64px"
        >
            <div class="col-15 col-sm-13 dark-gray">
                <cna-page-heading
                    pageHeadingTitle="Prüfung buchen"
                ></cna-page-heading>
            </div>
        </div>

        <div class="row justify-content-center mt-4 gray-background">
            <div class="col-15 col-sm-13 mb-5 mt-5">
                <div class="row">
                    <div class="col-13 col-md-8">
                        <span class="adelle-pe-25 font-weight-400 dark-gray"
                            >Kurs</span
                        >
                        <br />
                        <div *ngIf="selectedCourseParticipation$ | async">
                            <p class="adelle-sans-17 font-weight-300 dark-gray">
                                {{
                                    (selectedCourseParticipation$ | async)
                                        .bookedCourse.description
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mt-4 gray-background">
            <div class="col-15 col-sm-13 mb-5 mt-5">
                <div class="row">
                    <div class="col-13 col-md-8">
                        <div class="d-flex flex-row">
                            <div
                                class="adelle-pe-25 font-weight-400 dark-gray"
                                style="margin-bottom: 36px"
                            >
                                Prüfungstermin & -ort
                                <img
                                    class="ml-3 pencil"
                                    style="transform: translateY(-4px)"
                                    src="assets/bearbeiten.svg "
                                    [routerLink]="['../../exams']"
                                />
                            </div>
                            <br />
                        </div>
                        <p
                            class="adelle-sans-17 font-weight-300 dark-gray"
                            *ngIf="exam$ | async"
                        >
                            {{
                                this.examHelperService.getFromToDateString(
                                    exam$ | async
                                )
                            }}<br />
                            {{ exam.address.locationName }}<br />
                            {{ exam.address.addressLine1 }}<br />
                            {{ exam.address.postCode }} {{ exam.address.city }}
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="row justify-content-center mt-4 gray-background"
            *ngIf="customer"
        >
            <div class="col-15 col-sm-13 mb-5 mt-5">
                <div class="row">
                    <div class="col-13 col-md-8">
                        <div
                            class="d-flex flex-row"
                            (click)="openInvoiceAddressModal()"
                        >
                            <span
                                class="adelle-pe-25 font-weight-400 dark-gray"
                                style="margin-bottom: 36px"
                            >
                                Rechnungsadresse
                                <img
                                    class="ml-3 pencil"
                                    src="assets/bearbeiten.svg"
                                    style="transform: translateY(-4px)"
                                />
                            </span>
                        </div>
                        <p class="adelle-sans-17 font-weight-300 dark-gray">
                            {{ invoiceAddress.salutation }}
                            {{ invoiceAddress.title }}
                            {{ invoiceAddress.firstName }}
                            {{ invoiceAddress.lastName }}<br />
                            <span *ngIf="invoiceAddress.company"
                                >{{ invoiceAddress.company }}<br
                            /></span>
                            {{ invoiceAddress.address.addressLine1 }}<br />
                            {{ invoiceAddress.address.postCode }}
                            {{ invoiceAddress.address.city }}
                        </p>
                        <p class="adelle-sans-17 font-weight-300 dark-gray">
                            {{ invoiceAddress.mail }}
                        </p>
                        <p>
                            <cna-gradient-button
                                textAlign="left"
                                notgradient="true"
                                buttonTitle="Bearbeiten"
                                (click)="openInvoiceAddressModal()"
                            ></cna-gradient-button>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mt-4 gray-background">
            <div class="col-15 col-sm-13 mb-5 mt-5">
                <div class="row">
                    <div class="col-15 col-md-15">
                        <p
                            class="adelle-pe-25 font-weight-400 dark-gray"
                            style="margin-bottom: 24px"
                        >
                            Kostenübersicht
                        </p>
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th
                                        scope="col"
                                        class="adelle-sans-12 font-weight-600 letterSpacing-08 dark-gray no-border-top"
                                        style="
                                            padding-bottom: 4px;
                                            line-height: 1.417;
                                            padding-left: 0px;
                                        "
                                    >
                                        POSITION
                                    </th>
                                    <th scope="col" class="no-border-top"></th>
                                    <th scope="col" class="no-border-top"></th>
                                    <th
                                        scope="col"
                                        class="adelle-sans-12 font-weight-600 letterSpacing-08 dark-gray no-border-top"
                                        style="
                                            padding-bottom: 4px;
                                            line-height: 1.417;
                                            padding-left: 0px;
                                        "
                                    >
                                        EURO
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="costoverview">
                                    <th
                                        class="adelle-sans-17 font-weight-600 dark-gray border-top-exam"
                                        style="padding-left: 0px"
                                        *ngIf="exam$ | async"
                                    >
                                        Prüfung – {{ exam.address.city }}
                                    </th>
                                    <th class="border-top-exam"></th>
                                    <th class="border-top-exam"></th>
                                    <th
                                        class="adelle-sans-17 font-weight-600 dark-gray border-top-exam"
                                        style="padding-left: 0px"
                                    >
                                        {{
                                            grossPrice / 100
                                                | currency: '':'':''
                                        }}
                                        €
                                    </th>
                                </tr>
                                <tr>
                                    <th
                                        class="adelle-sans-17 font-weight-600 dark-gray border-top-summary"
                                        style="padding-left: 0px"
                                    >
                                        Gesamt
                                    </th>
                                    <th class="border-top-summary"></th>
                                    <th class="border-top-summary"></th>
                                    <th
                                        class="adelle-sans-17 font-weight-600 dark-gray border-top-summary blue-color"
                                        style="padding-left: 0px"
                                    >
                                        {{
                                            grossPrice / 100
                                                | currency: '':'':''
                                        }}
                                        €
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mt-4 gray-background">
            <div class="col-15 col-sm-13 mb-5 mt-5">
                <div class="row">
                    <div class="container-fluid">
                        <p
                            class="adelle-pe-25 font-weight-400 dark-gray"
                            style="margin-bottom: 36px"
                        >
                            Zahlungsart
                        </p>
                        <p
                            class="adelle-sans-17 font-weight-600 dark-gray"
                            style="margin-bottom: 10px"
                        >
                            Überweisung
                        </p>
                        <p class="adelle-sans-17 font-weight-300 dark-gray">
                            Die Prüfungsgebühr wird erst nach Ihrer Teilnahme an
                            der Prüfung fällig. Sie erhalten dann noch eine
                            gesonderte Rechnung.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row justify-content-center mt-4 gray-background">
            <div class="col-15 col-sm-13 mb-5 mt-5">
                <div class="row">
                    <div class="col-13 col-md-8">
                        <p
                            class="adelle-sans-17 font-weight-600 dark-gray my-3"
                        >
                            Hier finden Sie unsere
                            <a
                                href="{{ websiteHost }}/datenschutz"
                                target="_blank"
                                class="dark-gray"
                                style="text-decoration: underline"
                                >Datenschutzbestimmungen</a
                            >
                        </p>

                        <form [formGroup]="checkExamForm">
                            <div
                                class="my-2 d-flex flex-row align-items-center"
                            >
                                <div class="radioContainer ml-0">
                                    <label class="radio" for="radio_2">
                                        <span
                                            class="adelle-sans-17 font-weight-600 dark-gray"
                                            >Ich stimme den
                                            <a
                                                href="{{ websiteHost }}/agb"
                                                target="_blank"
                                                class="dark-gray"
                                                style="
                                                    text-decoration: underline;
                                                "
                                                >Vertragsbestimmungen</a
                                            >
                                            zu</span
                                        >
                                        <input
                                            id="radio_2"
                                            type="checkbox"
                                            name="checkReadAGB"
                                            formControlName="checkReadAGB"
                                        />
                                        <span class="checkround"></span>
                                    </label>
                                </div>
                            </div>
                        </form>

                        <div class="col-15 col-sm-13">
                            <div class="row justify-content-start">
                                <div
                                    class="col-auto pl-0"
                                    style="
                                        margin-top: 36px;
                                        margin-bottom: 36px;
                                    "
                                >
                                    <cna-gradient-button
                                        buttonTitle="Kostenpflichtig buchen"
                                        (click)="registerToExam(exam, customer)"
                                        [disabled]="!checkExamForm.valid"
                                    ></cna-gradient-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
