import { createFeatureSelector, createSelector } from '@ngrx/store';
import { READ_STATUS_STORE_NAME, ReadStatusState } from './reducers';
import type { UUID } from '@cna/shared/generic-types';
import {
    selectCourseParticipationId,
    selectModuleId,
    selectReadingId,
} from '../router.selector';

const selectReadStatusGlobal = createFeatureSelector<ReadStatusState>(
    READ_STATUS_STORE_NAME
);

export const selectReadStatus = (
    courseParticipationId: UUID,
    moduleId: UUID,
    sectionId: UUID
) =>
    createSelector(
        selectReadStatusGlobal,
        state =>
            state.courseParticipations[courseParticipationId]?.modules[moduleId]
                ?.sections[sectionId]
    );

export const selectCurrentReadStatus = createSelector(
    selectReadStatusGlobal,
    selectCourseParticipationId,
    selectModuleId,
    selectReadingId,
    (state, courseParticipationId, moduleId, sectionId) =>
        state.courseParticipations[courseParticipationId]?.modules[moduleId]
            ?.sections[sectionId]
);
