import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSidenavActiveState } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/selectors';

@Component({
    selector: 'cna-exam-relevant',
    templateUrl: './exam-relevant.component.html',
    styleUrls: ['./exam-relevant.component.scss'],
})
export class ExamRelevantComponent implements OnInit {
    constructor(private store: Store) {}

    sidebar$ = this.store.select(selectSidenavActiveState);

    ngOnInit(): void {}
}
