import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import * as readStatusActions from './actions';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ReadStatus } from '@cna/projects/ak-jura/shared/interfaces';
import { environment } from '../../../environments/environment';
import {
    selectCourseParticipationId,
    selectModuleId,
    selectReadingId,
} from '../router.selector';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { ToasterService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/toaster.service';
import { ToastType } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/toast-type.enum';

@Injectable()
export class ReadStatusEffects {
    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private store: Store,
        private toasterService: ToasterService
    ) {}

    getCurrentReadStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(readStatusActions.loadCurrentReadStatus),
            withLatestFrom(
                this.store.select(selectCourseParticipationId),
                this.store.select(selectModuleId),
                this.store.select(selectReadingId)
            ),
            map(([, courseParticipationId, moduleId, sectionId]) =>
                readStatusActions.loadReadStatus({
                    courseParticipationId,
                    moduleId,
                    sectionId,
                })
            )
        )
    );

    getReadStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(readStatusActions.loadReadStatus),
            mergeMap(({ courseParticipationId, moduleId, sectionId }) =>
                this.http
                    .get<ReadStatus[]>(`${environment.apiHost}/read-status`, {
                        params: {
                            filter: [
                                `courseParticipationId||$eq||${courseParticipationId}`,
                                `moduleId||$eq||${moduleId}`,
                                `sectionId||$eq||${sectionId}`,
                            ],
                        },
                    })
                    .pipe(
                        map(readStatuses =>
                            readStatusActions.loadReadStatusSuccess({
                                readStatus: readStatuses[0],
                            })
                        ),
                        catchError(({ message }) =>
                            of(
                                readStatusActions.loadReadStatusFailure({
                                    message,
                                })
                            )
                        )
                    )
            )
        )
    );

    postCurrentReadStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(readStatusActions.createReadStatusForCurrent),
            withLatestFrom(
                this.store.select(selectCourseParticipationId),
                this.store.select(selectModuleId),
                this.store.select(selectReadingId)
            ),
            map(([{ read }, courseParticipationId, moduleId, sectionId]) =>
                readStatusActions.createReadStatus({
                    courseParticipationId,
                    moduleId,
                    sectionId,
                    read,
                })
            )
        )
    );

    postReadStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(readStatusActions.createReadStatus),
            mergeMap(({ courseParticipationId, moduleId, sectionId, read }) =>
                this.http
                    .post<ReadStatus>(`${environment.apiHost}/read-status`, {
                        courseParticipationId,
                        moduleId,
                        sectionId,
                        read,
                    })
                    .pipe(
                        map(readStatus =>
                            readStatusActions.createReadStatusSuccess({
                                readStatus,
                            })
                        ),
                        catchError(({ message }) =>
                            of(
                                readStatusActions.createReadStatusFailure({
                                    message,
                                })
                            )
                        )
                    )
            )
        )
    );

    postReadStatusSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(readStatusActions.createReadStatusSuccess),
                tap(() => {
                    this.toasterService.show(
                        'Status erfolgreich aktualisiert',
                        ToastType.success
                    );
                })
            ),
        { dispatch: false }
    );

    postReadStatusFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(readStatusActions.createReadStatusFailure),
                tap(() => {
                    this.toasterService.show(
                        'Der Status für das aktuelle Kapitel konnte nicht gespeichert werden, bitte versuchen Sie es später noch einmal!',
                        ToastType.error
                    );
                })
            ),
        { dispatch: false }
    );

    patchReadStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(readStatusActions.updateReadStatus),
            mergeMap(({ id, read }) =>
                this.http
                    .patch<ReadStatus>(
                        `${environment.apiHost}/read-status/${id}`,
                        {
                            read,
                        }
                    )
                    .pipe(
                        map(readStatus =>
                            readStatusActions.updateReadStatusSuccess({
                                readStatus,
                            })
                        ),
                        catchError(({ message }) =>
                            of(
                                readStatusActions.updateReadStatusFailure({
                                    message,
                                })
                            )
                        )
                    )
            )
        )
    );

    patchReadStatusSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(readStatusActions.updateReadStatusSuccess),
                tap(() => {
                    this.toasterService.show(
                        'Status erfolgreich aktualisiert',
                        ToastType.success
                    );
                })
            ),
        { dispatch: false }
    );

    patchReadStatusFailure$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(readStatusActions.updateReadStatusFailure),
                tap(() => {
                    this.toasterService.show(
                        'Der Status für das aktuelle Kapitel konnte nicht gespeichert werden, bitte versuchen Sie es später noch einmal!',
                        ToastType.error
                    );
                })
            ),
        { dispatch: false }
    );
}
