import { Component, OnDestroy, OnInit } from '@angular/core';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { Exam } from '../../../../../../shared/interfaces/src/lib/data-management/exam/exam';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { ExamHelperService } from '../../../../../shared-components/src/lib/services/exam-helper.service';
import { Store } from '@ngrx/store';
import { selectCustomer } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/customer/selectors';
import { CourseParticipation } from '@cna/projects/ak-jura/shared/interfaces';
import { setBreadcrumbs } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';
import { ToasterService } from '../../../../../shared-components/src/lib/services/toaster.service';
import { UUID } from '@cna/shared/generic-types';
import { selectCourseParticipationId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { validateAndSaveBookingExam } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/exam/actions';
import { ToastType } from '../../../../../../shared/interfaces/src/lib/toast-type.enum';
import { loadModuleInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/actions';
import { selectCustomersCourseParticipation } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';
import {
    CustomerBaseData,
    InvoiceAddress,
    isInvoiceAddress,
} from '@cna/projects/ak-jura/shared/interfaces';
import { loadCourseParticipationInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';
import { EditInvoiceAddressModalComponent } from '../../../../shared-components/src/lib/edit-invoice-address-modal/edit-invoice-address-modal.component';
import { environment } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/environments/environment';

@Component({
    selector: 'cna-exam-signin-view',
    templateUrl: './exam-signin-view.component.html',
    styleUrls: ['./exam-signin-view.component.css'],
})
export class ExamSigninViewComponent implements OnInit, OnDestroy {
    private _destroy$ = new Subject();
    private examService: EntityCollectionService<Exam>;
    public exam$: Observable<Exam>;
    private examId: UUID;
    customer$: Observable<CustomerBaseData> = this.store
        .select(selectCustomer)
        .pipe(takeUntil(this._destroy$));
    public selectedCourseParticipation$: Observable<
        CourseParticipation | undefined
    >;
    private legalAreaIdOfCourseParticipationId: UUID;

    public netPrice;
    private taxRate = 0.19;
    private taxExemption = true;
    public grossPrice;

    public websiteHost = environment.frontendHost;

    invoiceAddress: InvoiceAddress;

    checkExamForm = new FormGroup({
        checkReadAGB: new FormControl(false, Validators.requiredTrue),
    });

    constructor(
        private modalService: NgbModal,
        private entityServices: EntityServices,
        private route: ActivatedRoute,
        public examHelperService: ExamHelperService,
        private store: Store,
        private toasterService: ToasterService
    ) {
        this.examService =
            this.entityServices.getEntityCollectionService<Exam>('Exam');

        this.route.params
            .subscribe(params => {
                if ('examId' in params) {
                    this.examId = params['examId'];
                    this.exam$ = this.examService.getByKey(params['examId']);
                    this.exam$.subscribe(price => {
                        this.netPrice = price.price;
                        if (this.taxExemption === false) {
                            this.grossPrice =
                                this.netPrice + this.netPrice * this.taxRate;
                        } else if (this.taxExemption === true) {
                            this.grossPrice = this.netPrice;
                        }
                    });
                }
            })
            .unsubscribe();

        this.customer$.subscribe(
            customer =>
                (this.invoiceAddress = this.invoiceAddress ?? {
                    title: customer.title,
                    salutation: customer.salutation,
                    firstName: customer.firstName,
                    lastName: customer.lastName,
                    address: {
                        addressLine1: customer.address.addressLine1,
                        postCode: customer.address.postCode,
                        city: customer.address.city,
                    },
                    mail: customer.email,
                })
        );

        this.selectedCourseParticipation$ = this.store
            .select(selectCustomersCourseParticipation)
            .pipe(takeUntil(this._destroy$));

        this.selectedCourseParticipation$
            .pipe(takeUntil(this._destroy$))
            .subscribe(cp => {
                if (cp) {
                    if (
                        typeof cp.bookedCourse.associatedLegalArea !== 'string'
                    ) {
                        this.legalAreaIdOfCourseParticipationId =
                            cp.bookedCourse.associatedLegalArea.id;
                    }
                }
            });
    }

    async openInvoiceAddressModal(): Promise<void> {
        const modalRef = this.modalService.open(
            EditInvoiceAddressModalComponent,
            {
                backdrop: true,
                size: 'lg',
                keyboard: true,
                centered: true,
                windowClass: 'custom-modal',
            }
        );
        modalRef.componentInstance.invoiceAddress = this.invoiceAddress;
        try {
            const result = await modalRef.result;
            if (result && isInvoiceAddress(result)) {
                this.invoiceAddress = result;
            }
        } catch (err) {
            // do not update invoice address on modal dismiss
            // everything stays like before
        }
    }

    setBreadcrumbs() {
        this.store
            .select(selectCourseParticipationId)
            .subscribe(cpId => {
                if (cpId) {
                    this.store.dispatch(
                        setBreadcrumbs({
                            breadcrumbs: [
                                { label: 'Mein AK JURA', routerLink: cpId },
                                {
                                    label: 'Prüfung',
                                    routerLink: cpId + '/exams',
                                },
                                {
                                    label: 'Anmeldung zur Prüfung',
                                    routerLink: cpId + '/exams/' + this.examId,
                                },
                            ],
                        })
                    );
                }
            })
            .unsubscribe();
    }

    ngOnInit(): void {
        this.store.dispatch(loadModuleInfos());
        this.store.dispatch(loadCourseParticipationInfos());

        this.setBreadcrumbs();
    }

    ngOnDestroy() {
        this._destroy$.next();
        this._destroy$.complete();
    }

    registerToExam(exam: Exam, customer: CustomerBaseData) {
        if (this.checkExamForm.value.checkReadAGB) {
            this.store.dispatch(
                validateAndSaveBookingExam({
                    exam: exam,
                    customer: customer,
                    invoiceAddress: this.invoiceAddress,
                    legalAreaId: this.legalAreaIdOfCourseParticipationId,
                })
            );
        } else {
            this.toasterService.show(
                'Datenschutz & AGB akzeptieren',
                ToastType.error
            );
        }
    }
}
