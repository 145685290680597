import { createAction, props } from '@ngrx/store';
import { BreadCrumbInfo } from '../../../../../../../../libs/projects/ak-jura/angular/my-ak-jura/shared-components/src/lib/breadcrumbs/breadcrumbs.component';

/**
 * BreadCrumbs
 */
export const setBreadcrumbs = createAction(
    '[breadcrumb] set BreadCrumbs',
    props<{ breadcrumbs: BreadCrumbInfo[] }>()
);
export const addBreadcrumbsUnique = createAction(
    '[breadcrumb] add BreadCrumbs',
    props<{ breadcrumbs: BreadCrumbInfo[] }>()
);
