<ngb-accordion
    #acc="ngbAccordion"
    class="qanda"
    activeIds="{{ openSectionPanel }}"
>
    <!-- add activeIds="ngb-panel-0" to open first-->
    <ngb-panel id="p1">
        <ng-template ngbPanelTitle>
            <div class="row justify-content-between">
                <div class="col text-left">
                    <p class="blue-color adelle-sans-17 font-weight-600 mb-0">
                        Anleitung zur Nutzung der Lernplattform
                    </p>
                </div>
                <div class="col-2 text-right qa_arrow">
                    <img src="assets/accordion_down.svg" />
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-0"
                >Lernen</span
            >
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Sie starten Ihren Lehrgang, indem Sie auf den Button „Lernen“
                klicken. Wählen Sie in der Übersicht das Modul I aus. In der
                Modul Übersicht wird die Inhaltsstruktur der Lerninhalte
                angezeigt. Klicken Sie auf die jeweiligen Unterpunkte, um die
                Lehrinhalte zu laden. Starten Sie mit dem Studium des ersten
                Kapitels. Am Ende jedes Lerninhalts bestätigen, Sie, dass Sie
                die Lerneinheit gelesen und erarbeitet haben. Am Ende jedes
                Inhalts gelangen Sie zum nächsten Lehrinhalt oder in die
                Übersicht des Moduls.
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Menüleiste rechts oben
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Inhalt: Durch Auswählen blenden Sie die Inhalts-Struktur auf der
                rechten Seite ein.<br />
                Notizen: Hier können Sie eigene Notizen zu den Lerninhalten
                hinzufügen.<br />
                Anpassen: Durch Auswählen öffnet sich das Menü, in dem Sie die
                Darstellung der Inhalte auf Ihre Bedürfnisse anpassen können.<br />
                Dashboard: Hier gelangen Sie zu Ihrem Dashboard (Ihre
                Hauptverwaltungsseite).
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Lernstatus
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Gelernte Themen markieren Sie, indem Sie ein Häkchen setzen. Die
                gelernten Themen werden grafisch dargestellt und in %-Werten
                angegeben. Dadurch erhalten Sie jederzeit einen genauen
                Überblick über Ihren aktuellen Lernfortschritt. Wenn Ihr
                Lernfortschritt in einem Modul 100 % beträgt, können Sie die
                dazugehörige Lernkontrolle (Multiple-Choice-Test) durchführen.
                Nach Bestehen der Lernkontrolle wird Ihnen das nächste Modul
                automatisch freigeschaltet.
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Zeiterfassung
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Ihre Aktivität wird vom System dokumentiert. Wenn länger als 15
                Minuten keine Interaktion (Klick, Scroll, Mausbewegung etc.)
                erfolgt, stoppt die Zeiterfassung. Durch erneute Interaktion
                setzen Sie die Zeiterfassung fort.
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Lernkontrolle
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Die Lernkontrolle (Multiple-Choice-Test) steht Ihnen erst nach
                vollständiger Bearbeitung (100 %) des entsprechenden Moduls zur
                Verfügung. Nicht bestandene Lernkontrollen können beliebig oft
                wiederholt werden.
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Prüfungsrelevante Inhalte
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Sämtliche Prüfungsrelevante Inhalte sind mit einem *
                gekennzeichnet. Eine Zusammenfassung sämtlicher
                prüfungsrelevanter Inhalte finden Sie im Modul Repetitorium.
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Modul Repetitorium
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Das Modul Repetitorium dient Ihrer Vorbereitung auf die
                Abschlussprüfung. Hier sind nochmals sämtliche prüfungsrelevante
                Themen für Sie zusammengefasst. Das Modul Repetitorium steht
                Ihnen zur Verfügung, sobald Sie alle 4 Lern-Module erfolgreich
                bearbeitet haben. Die Bearbeitung des Moduls Repetitorium ist
                nicht Voraussetzung zur Prüfungsanmeldung, sondern dient
                lediglich Ihrer Prüfungsvorbereitung.
            </p>
        </ng-template>
    </ngb-panel>
    <ngb-panel id="p2">
        <ng-template ngbPanelTitle>
            <div class="row justify-content-between">
                <div class="col text-left">
                    <p class="blue-color adelle-sans-17 font-weight-600 mb-0">
                        Fortbildungspflicht ab Lehrgangsbeginn
                    </p>
                </div>
                <div class="col-2 text-right qa_arrow">
                    <img src="assets/accordion_down.svg" />
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Nach § 4 Abs. 2 FAO löst bereits der Lehrgangsbeginn eine
                Fortbildungspflicht aus. So heißt es in § 4 Abs. 2 FAO:
                <i
                    >„Wird der Antrag auf Verleihung der Fachanwaltschaft nicht
                    in dem Kalenderjahr gestellt, in dem der Lehrgang begonnen
                    hat, ist ab diesem Jahr Fortbildung in Art und Umfang von §
                    15 FAO nachzuweisen. Lehrgangszeiten sind anzurechnen.“</i
                >
                Nach § 15 Abs. 3 FAO darf die Gesamtdauer der Fortbildung je
                Fachgebiet 15 Zeitstunden nicht unterscheiten. <br /><br />
                <span class="font-weight-600">
                    Bitte achten Sie deshalb unbedingt darauf, dass Ihr
                    Zeitprotokoll in jedem Lehrgangsjahr mindestens 15 Stunden
                    aufweist!
                </span>
            </p>
        </ng-template>
    </ngb-panel>

    <ngb-panel id="p3">
        <ng-template ngbPanelTitle>
            <div class="row justify-content-between">
                <div class="col text-left">
                    <p class="blue-color adelle-sans-17 font-weight-600 mb-0">
                        Laufzeit
                    </p>
                </div>
                <div class="col-2 text-right qa_arrow">
                    <img src="assets/accordion_down.svg" />
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Ihr Lehrgang steht Ihnen für 12 Monate zur Verfügung. Sie können
                jederzeit verlängern. Ein Verlängerungsmonat kostet 30,- €.
            </p>
        </ng-template>
    </ngb-panel>

    <ngb-panel id="p4">
        <ng-template ngbPanelTitle>
            <div class="row justify-content-between">
                <div class="col text-left">
                    <p class="blue-color adelle-sans-17 font-weight-600 mb-0">
                        Abschlussprüfung
                    </p>
                </div>
                <div class="col-2 text-right qa_arrow">
                    <img src="assets/accordion_down.svg" />
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-0"
                >Informationen zur Abschlussprüfung</span
            >
            <div class="adelle-sans-17 font-weight-300 dark-gray">
                <p class="mb-0">Prüfungsvorbereitung</p>
                <ul>
                    <li>
                        Als Vorbereitung empfehlen wir die Bearbeitung des
                        Repetitoriums
                    </li>
                    <li>
                        Das Repetitorium bereitet Sie vollumfänglich auf die
                        Prüfung vor
                    </li>
                    <li>
                        Prüfungsrelevant sind ausschließlich die im Repetitorium
                        besprochenen Inhalte
                    </li>
                </ul>
                <p class="mb-0">Zugelassene Hilfsmittel</p>
                <ul>
                    <li>
                        Zugelassene Hilfsmittel sind sämtliche Gesetze und
                        Normen
                    </li>
                    <li>Markierungen und Kommentierungen sind zulässig</li>
                    <li>
                        Sie können die Normen auch in digitaler Form (offline,
                        z.B. Tablet) oder auch ausgedruckt mitbringen
                    </li>
                    <li>
                        Sie benötigen ausschließlich Normen welche Sie auch für
                        die Bearbeitung des Repetitoriums benötigen
                    </li>
                </ul>
                <p class="mb-0">Prüfungsablauf</p>
                <ul>
                    <li>
                        Jede Prüfung besteht aus drei jeweils 5-stündigen
                        Klausuren
                    </li>
                    <li>
                        Jede Klausur besteht aus etwa 30 offenen Fragen (s.
                        Repetitorium)
                    </li>
                    <li>
                        Es sind weder Fälle zu lösen noch sind Gutachten zu
                        fertigen
                    </li>
                </ul>
            </div>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-0"
                >Anmeldung zur Prüfung</span
            >
            <div class="adelle-sans-17 font-weight-300 dark-gray">
                <p class="mb-0">
                    Sie können sich jederzeit zur Abschlussprüfung anmelden.
                    Eine Teilnahme an der Prüfung ist jedoch erst möglich, wenn
                    folgende Voraussetzungen erfüllt sind:
                </p>
                <ul>
                    <li>alle 4 Lernmodule sind vollständig bearbeitet</li>
                    <li>alle 4 Lernkontrollen sind bestanden und</li>
                    <li>
                        die protokollierte Lernzeit beträgt mindestens 120 (bei
                        Insolvenzrecht 180) Zeitstunden
                    </li>
                </ul>
            </div>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-0"
                >Prüfungsgebühr</span
            >
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Die Prüfungsgebühr wird erst mit der Teilnahme an der Prüfung
                fällig.
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-0"
                >Teilnahme an der Prüfung nach Ablauf der Vertragslaufzeit</span
            >
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Sie können an der Prüfung teilnehmen, auch wenn Ihre
                Vertragszeit abgelaufen ist.
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-0"
                >Individuelle Prüfungstermine</span
            >
            <div class="adelle-sans-17 font-weight-300 dark-gray">
                <ul class="mb-0">
                    <li>
                        notarielle oder anwaltliche Prüfungsaufsicht bei Ihnen vor Ort
                    </li>
                    <li>
                        zusätzlicher Unkostenbeitrag zur Prüfungsgebühr für die Durchführung
                    </li>
                </ul>
                <p class="mb-0">
                    Für Fragen nehmen sie bitte Kontakt mit uns auf unter
                    <a
                        href="mailto:kontakt@ak-jura.de"
                        class="dark-gray"
                        style="text-decoration: underline"
                        >kontakt@ak-jura.de</a
                    >
                    oder telefonisch
                    <a
                        href="tel:+4973160319939"
                        class="dark-gray"
                        style="text-decoration: underline"
                    >+49&nbsp;731&nbsp;60319939</a
                    >.
                </p>
            </div>
        </ng-template>
    </ngb-panel>

    <ngb-panel id="p5">
        <ng-template ngbPanelTitle>
            <div class="row justify-content-between">
                <div class="col text-left">
                    <p class="blue-color adelle-sans-17 font-weight-600 mb-0">
                        Support
                    </p>
                </div>
                <div class="col-2 text-right qa_arrow">
                    <img src="assets/accordion_down.svg" />
                </div>
            </div>
        </ng-template>
        <ng-template ngbPanelContent>
            <span class="adelle-sans-17 font-weight-600 dark-gray">
                Allgemeine Fragen
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Bei allgemeinen Fragen wenden Sie sich bitte per Mail an:
                <a
                    class="dark-gray"
                    style="text-decoration: underline"
                    href="mailto:kontakt@ak-jura.de"
                    >kontakt@ak-jura.de</a
                >
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Technische Fragen
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Bei technischen Fragen oder Problemen wenden Sie sich bitte an:
                <a
                    class="dark-gray"
                    style="text-decoration: underline"
                    href="mailto:support@ak-jura.de"
                    >support@ak-jura.de</a
                >
            </p>
            <span class="adelle-sans-17 font-weight-600 dark-gray pt-2">
                Fachliche Fragen
            </span>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Soweit möglich wird der fachliche Support Ihre Frage
                beantworten. Bitte beachten Sie, dass Sie sich zu einem Lehrgang
                im Selbststudium entschieden haben und daher keine
                Individualbetreuung komplexer juristischer Fragen erfolgen kann.
                Strukturelle und inhaltliche Hinweise nehmen wir gerne entgegen
                und werden diese dann Wolters Kluwer weiterleiten, mit der Bitte
                dies im Zuge der nächsten Aktualisierung zu berücksichtigen. Sie
                stellen somit einen wertvollen Beitrag für die stete
                Verbesserung der Lehrinhalte dar.
            </p>
        </ng-template>
    </ngb-panel>
</ngb-accordion>
