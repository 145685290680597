import * as fromRouter from '@ngrx/router-store';
import { DEFAULT_ROUTER_FEATURENAME } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Observable } from 'rxjs';

export interface State {
    router: fromRouter.RouterReducerState<any>;
}

export const selectRouter = createFeatureSelector<
    State,
    fromRouter.RouterReducerState<any>
>(DEFAULT_ROUTER_FEATURENAME);

export const {
    selectCurrentRoute, // select the current route
    selectFragment, // select the current route fragment
    selectQueryParams, // select the current route query params
    selectQueryParam, // factory function to select a query param
    selectRouteParams, // select the current route params
    selectRouteParam, // factory function to select a route param
    selectRouteData, // select the current route data
    selectUrl, // select the current url
} = fromRouter.getSelectors(selectRouter);

export const selectCourseParticipationId = selectRouteParam(
    'courseParticipationId'
);

export const selectModuleId = selectRouteParam('moduleId');
export const selectReadingId = selectRouteParam('readingId');
export const selectFavoriteGroupId = selectRouteParam('favoriteGroupId');

export const selectRouteConfigPath = createSelector(
    selectCurrentRoute,
    currentRoute => {
        return currentRoute?.routeConfig?.path;
    }
);

export const selectRouteDataProp = <R>(prop: string | number) =>
    createSelector<State, unknown, R>(selectRouteData, data =>
        typeof data === 'object' && prop in data ? data[prop] : undefined
    );
