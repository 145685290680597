<div
    *ngLet="readingStyles$ | async as readingStyles"
    class="reading-content"
    [ngClass]="
        readingStyles
            ? [
                  readingStyles.fontStyle,
                  readingStyles.fontSize,
                  readingStyles.textColor
              ]
            : []
    "
    [innerHTML]="sanitizedContent"
></div>
