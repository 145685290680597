<div *ngIf="timeExpendituresByMonth$ | async as timeExpendituresByMonth">
    <div *ngIf="timeExpendituresByMonth.size > 0">
        <div class="d-flex flex-row learnstate-accordion-header">
            <p
                class="adelle-sans-12 font-weight-600 text-uppercase letterSpacing-08"
                style="margin-bottom: 12px; line-height: 1.417"
            >
                Zeitaufwand
            </p>
        </div>

        <ngb-accordion activeIds="ngb-panel-0" class="learnstate">
            <ngb-panel
                *ngFor="
                    let monthTimeExpenditures of timeExpendituresByMonth
                        | keyvalue: mapCompareFnc
                "
            >
                <ng-template ngbPanelTitle>
                    <div class="learntime-accordion-ebene1">
                        <div class="learntime-accordion-ebene1-t1">
                            <img
                                src="assets/Pfeil_Accordion-unten.svg"
                                alt="acc-arrow"
                                style="
                                    width: 20px;
                                    opacity: 50%;
                                    margin-right: 13px;
                                    margin-top: 24.6px;
                                    margin-bottom: 24.6px;
                                "
                                class="acc-arrow"
                            />
                            <span
                                class="learntime-accordion-ebene1-t1-text adelle-sans-17 dark-gray font-weight-600"
                            >
                                {{ monthTimeExpenditures.key }}
                            </span>
                        </div>
                        <div class="learntime-accordion-ebene1-t2">
                            <p class="adelle-sans-14 dark-gray font-weight-300">
                                {{
                                    getTotalTimeRead(
                                        monthTimeExpenditures.value
                                    ) | secondsToHours
                                }}
                            </p>
                        </div>
                    </div>
                </ng-template>
                <ng-template ngbPanelContent>
                    <!--  style="margin-left: 478px" -->
                    <div
                        class="d-flex flex-row learntime-accordion-ebene2-t1"
                        *ngFor="let learningTime of monthTimeExpenditures.value"
                    >
                        <div
                            class="col-auto text-left"
                            style="min-width: 10rem"
                        >
                            <p
                                class="adelle-sans-14 dark-gray font-weight-300"
                                style="margin-bottom: 6px"
                            >
                                {{ learningTime.date | date: 'dd.MM.YYYY' }}
                            </p>
                        </div>

                        <div class="text-left">
                            <p
                                class="adelle-sans-14 dark-gray font-weight-300"
                                style="margin-bottom: 6px"
                            >
                                {{ learningTime.duration | secondsToHours }}
                            </p>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
    <div *ngIf="timeExpendituresByMonth.size === 0">
        <p class="adelle-sans-17">Noch keine Lernzeiten aufgezeichnet.</p>
    </div>
</div>
