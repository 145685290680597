import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { NgForm } from '@angular/forms';
import { ControlQuestion } from '../../../../../../shared/interfaces/src/lib/data-management/control-test/control-question';

import { UUID } from '@cna/shared/generic-types';
import { validateAndSaveControlTestAttempt } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/controlTests/actions';

@Component({
    selector: 'cna-test-form',
    templateUrl: './test-form.component.html',
    styleUrls: ['./test-form.component.scss'],
})
export class TestFormComponent {
    SEPARATOR = '__';
    @Input() controlQuestions: ControlQuestion[];
    currentTab = 0;
    valid = false;

    answers: UUID[] = [];

    constructor(private store: Store) {
        // nix
    }

    /**
     * Saves the given answer or hands the result over to the API if last question
     * @param groupForm
     */
    validateAndNext(groupForm: NgForm) {
        // if (
        //     this.currentTab + 1 === this.controlQuestions.length &&
        //     this.answers.length !== 0
        // ) {
        //     this.store.dispatch(
        //         validateAndSaveControlTestAttempt({ answers: this.answers })
        //     );
        // } else {
        if (!groupForm.pristine) {
            this.answers.push(
                groupForm.value.radioValue.split(this.SEPARATOR)[1]
            );
            if (
                this.currentTab + 1 === this.controlQuestions.length &&
                this.answers.length !== 0
            ) {
                this.store.dispatch(
                    validateAndSaveControlTestAttempt({
                        answers: this.answers,
                    })
                );
            } else {
                this.currentTab += 1;
            }
        } else {
            this.valid = false;
        }
        // }
    }
}
