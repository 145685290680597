import { Injectable, OnInit } from '@angular/core';
import { OnScroll } from '../interfaces/on-scroll.interface';

@Injectable({ providedIn: 'root' })
export class ScrollService implements OnInit {
    private onScrollListener: OnScroll;

    constructor() {}

    ngOnInit() {
        this.onScrollListener = {
            onScroll: () => {
                throw new Error('No scroll listener implemented');
            },
        };
    }

    public setOnScrollListener(listener: OnScroll): void {
        this.onScrollListener = listener;
    }

    public async scrollTo(x = 0, y = 0): Promise<void> {
        await this.onScrollListener.onScroll(x, y);
    }

    public async scrollToTop(): Promise<void> {
        await this.scrollTo(0, 0);
    }
}
