import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HttpStatus } from '@cna/shared/generic-types';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
    NetworkStatus,
    NetworkStatusService,
} from '../../../network-status/src/lib/network-status.service';
import {
    TIME_TRACKING_CONFIG,
    TimeTrackingConfig,
} from './time-tracking.config';
import { TimeExpenditureData } from './time-expenditure-data';

@Injectable()
export class TimeTrackingClientService implements OnDestroy {
    private destroy$ = new Subject<void>();
    private readonly STORAGE_KEY = 'open_time_expenditure';

    constructor(
        private networkStatusService: NetworkStatusService,
        private http: HttpClient,
        @Inject(TIME_TRACKING_CONFIG)
        private timeTrackingConfig: TimeTrackingConfig,
        private store: Store
    ) {
        this.networkStatusService.status$
            .pipe(takeUntil(this.destroy$))
            .subscribe(async status => {
                if (
                    status === NetworkStatus.ONLINE &&
                    this.hasOpenTimeTrackings()
                ) {
                    await this.submitOpenTimeTrackingsFromStorage();
                }
            });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async submit(data: TimeExpenditureData, persist = false): Promise<void> {
        if (this.networkStatusService.isOffline || persist) {
            this.persistForLaterSubmit(data);
        } else {
            try {
                await this.sendTimeTracking(data);
            } catch (err: unknown) {
                this.persistForLaterSubmit(data);
            }
        }
    }

    private getAll(): TimeExpenditureData[] {
        return JSON.parse(localStorage.getItem(this.STORAGE_KEY)) ?? [];
    }

    private replaceAll(data: TimeExpenditureData[] = []) {
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(data));
    }

    private hasOpenTimeTrackings(): boolean {
        return this.getAll().length > 0;
    }

    private persistForLaterSubmit(data: TimeExpenditureData): void {
        this.replaceAll([...this.getAll(), data]);
    }

    private async submitOpenTimeTrackingsFromStorage(): Promise<void> {
        const openTimeTrackings = this.getAll();
        let timeTracking: TimeExpenditureData;
        while ((timeTracking = openTimeTrackings.shift())) {
            try {
                await this.sendTimeTracking(timeTracking);
            } catch (error) {
                switch (error.status) {
                    // catch error, session data is missing information and can't get submitted
                    case HttpStatus.BAD_REQUEST:
                        break;
                    default:
                        throw error;
                }
            }
            this.replaceAll(openTimeTrackings);
        }
    }

    private sendTimeTracking(data: TimeExpenditureData): Promise<void> {
        const url = `${this.timeTrackingConfig.apiHost}/time-expenditure`;
        return this.http.post<void>(url, data).toPromise();
    }
}
