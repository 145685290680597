<div *ngIf="unlocked" (click)="navigateToRepetitorium()">
    <div class="module-tile moduleTile">
        <div
            class="d-flex justify-content-between no-gutters px-4 pt-4 repetitorium-min-width"
        >
            <p
                class="adelle-sans-17 font-weight-400 white-color"
                style="letter-spacing: 0.6px"
            >
                REPETITORIUM
            </p>
        </div>
        <div
            class="d-flex flex-column px-4"
            style="padding-top: 16px; min-height: 120px"
        >
            <div>
                <p class="mb-0 adelle-sans-14 font-weight-300 white-color"></p>
                <p style="visibility: hidden"></p>
            </div>
        </div>
        <div class="d-flex justify-content-between p-3 pt-2">
            <p
                class="color-white mb-0 adelle-sans-11 font-weight-600 pr-2 align-self-center letterSpacing-08"
            >
                <cna-arrow
                    label="PDF"
                    [clickable]="true"
                    (click)="onPrintRepetitorium($event)"
                ></cna-arrow>
            </p>
            <div>
                <div>
                    <a class="d-flex button-continue-repeat">
                        <p
                            class="mb-0 mt-1 adelle-sans-11 font-weight-600 letterSpacing-08 pr-2 align-self-center"
                        >
                            FORTSETZEN
                        </p>

                        <img
                            alt="PLAY"
                            src="assets/play_white.svg"
                            width="30px"
                        />
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="!unlocked">
    <div class="module-tile-locked modulTile">
        <div class="d-flex justify-content-between no-gutters px-4 pt-4">
            <p class="adelle-sans-17 font-weight-400" style="color: #d9dbdc">
                REPETITORIUM
            </p>
        </div>
        <div style="min-height: 120px"></div>
        <div class="d-flex justify-content-end p-3">
            <div>
                <p
                    class="mb-0 adelle-sans-11 letterSpacing-08 font-weight-600 text-uppercase"
                    style="min-height: 30px; color: #d9dbdc"
                >
                    Noch nicht freigeschaltet
                </p>
            </div>
        </div>
    </div>
</div>
