import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as contentSectionActions from './actions';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import {
    selectCourseParticipationId,
    selectModuleId,
    selectReadingId,
} from '../router.selector';

@Injectable()
export class ContentSectionEffects {
    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private store: Store
    ) {}

    /**
     * Load content of section
     */
    loadContentSection = createEffect(() => {
        return this.actions$.pipe(
            ofType(contentSectionActions.loadContentSection),
            withLatestFrom(
                this.store.select(selectCourseParticipationId),
                this.store.select(selectModuleId),
                this.store.select(selectReadingId)
            ),
            mergeMap(([action, CourseParticipationId, ModuleId, ReadingId]) => {
                return this.http
                    .get<any>(
                        environment.apiHost + '/module-document-section',
                        {
                            params: {
                                moduleId: ModuleId,
                                courseParticipationId: CourseParticipationId,
                                sectionId: ReadingId,
                            },
                        }
                    )
                    .pipe(
                        map(response => {
                            return contentSectionActions.loadContentSectionSuccess(
                                {
                                    section: response,
                                }
                            );
                        }),
                        catchError(err =>
                            of(
                                contentSectionActions.loadContentSectionFailure(
                                    { error: err }
                                )
                            )
                        )
                    );
            })
        );
    });
}
