import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import {
    MsalInterceptor,
    MsalModule,
    MsalRedirectComponent,
    MsalService,
} from '@azure/msal-angular';
import {
    BrowserCacheLocation,
    PublicClientApplication,
} from '@azure/msal-browser';
import { ActiveTimeTrackingModule } from '@cna/projects/ak-jura/angular/my-ak-jura/active-time-tracking';
import { LearnModule } from '@cna/projects/ak-jura/angular/my-ak-jura/learning';
import { NetworkStatusModule } from '@cna/projects/ak-jura/angular/my-ak-jura/network-status';
import { PageViewsModule } from '@cna/projects/ak-jura/angular/my-ak-jura/page-views';
import { ScrollModule } from '@cna/projects/ak-jura/angular/my-ak-jura/scroll-module';
import { SharedComponentsModule } from '@cna/projects/ak-jura/angular/my-ak-jura/shared-components';
import { NgLetModule } from '@ngrx-utils/store';
import { DefaultDataServiceConfig, EntityDataModule } from '@ngrx/data';
import { EffectsModule } from '@ngrx/effects';
import {
    DEFAULT_ROUTER_FEATURENAME,
    NavigationActionTiming,
    routerReducer,
    StoreRouterConnectingModule,
} from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SidebarModule } from 'ng-sidebar';
import { SpinnerModule } from 'primeng/spinner';
import { CanDeactivateGuard } from '../../../../../../libs/angular/ui/confirm-before-leave/can-deactivate-guard.service';
import { NumberToNumberingPipe } from '../../../../../../libs/angular/ui/pipes/src/lib/number-to-numbering.pipe';
import { SecondsToHoursPipe } from '../../../../../../libs/angular/ui/pipes/src/lib/seconds-to-hours.pipe';
import { SectionNumberingPipe } from '../../../../../../libs/angular/ui/pipes/src/lib/section-numbering.pipe';
import { SectionTitlePipe } from '../../../../../../libs/angular/ui/pipes/src/lib/section-title.pipe';
import { environment } from '../environments/environment';
import {
    BREADCRUMB_STORE_NAME,
    breadcrumbReducer,
} from './+state/breadcrumb/reducer';
import { ControlTestsEffects } from './+state/controlTests/effects';
import {
    CONTROL_TESTS_STORE_NAME,
    controlTestsReducer,
} from './+state/controlTests/reducers';
import { CourseEffects } from './+state/course/effects';
import { COURSE_STORE_NAME, courseReducer } from './+state/course/reducer';
import { CourseParticipationEffects } from './+state/course_participation/effects';
import {
    COURSE_PARTICIPATION_STORE_NAME,
    courseParticipationReducer,
} from './+state/course_participation/reducers';
import { CustomerEffects } from './+state/customer/effects';
import {
    CUSTOMER_STORE_NAME,
    customerReducer,
} from './+state/customer/reducers';
import { GlobalEffects } from './+state/effects';
import { entityConfig } from './+state/entity-metadata';
import {
    ERROR_DESCRIBTION_STORE_NAME,
    errorDescribtionReducer,
} from './+state/error_describtion/reducers';
import { ExamEffects } from './+state/exam/effects';
import { examReducer, EXAMS_STORE_NAME } from './+state/exam/reducers';
import { ExaminablesEffects } from './+state/examinables/effects';
import {
    EXAMINABLES_STORE_NAME,
    examinablesReducer,
} from './+state/examinables/reducers';
import { ContentSectionEffects } from './+state/load_content/effects';
import {
    CONTENT_SECTION_INFO_STORE_NAME,
    contentSectionReducer,
} from './+state/load_content/reducer';
import { MetaReducerLocalStorage } from './+state/metaReducerLocalStorage';
import { ModuleInfoEffects } from './+state/module_infos/effects';
import {
    MODULE_INFO_STORE_NAME,
    moduleInfoReducer,
} from './+state/module_infos/reducer';
import { NotesEffects } from './+state/notes/effects';
import { NOTES_STORE_NAME, notesReducer } from './+state/notes/reducers';
import { ReadStatusEffects } from './+state/read_status/effects';
import {
    READ_STATUS_STORE_NAME,
    readStatusReducer,
} from './+state/read_status/reducers';
import {
    READINGSTYLE_STORE_NAME,
    readingstyleReducer,
} from './+state/readingstyles/reducers';
import { GLOBAL_STORE_NAME, globalReducer } from './+state/reducer';
import { RepetitoriumEffects } from './+state/repetitorium/effects';
import {
    REPETITORIUM_STORE_NAME,
    repetitoriumReducer,
} from './+state/repetitorium/reducers';

import { SIDENAV_STORE_NAME, sidenavReducer } from './+state/sidenav/reducers';
import { AppComponent } from './app.component';
import { routes } from './routes';

/** const metaReducers: MetaReducer[] = [hydrationMetaReducer]; */
registerLocaleData(localeDe, localeDeExtra);

const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;
const isIframe = window !== window.parent && !window.opener;

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        LearnModule,
        PageViewsModule,
        SharedComponentsModule,
        BrowserModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                ...environment.msalConfiguration,
                cache: {
                    cacheLocation: BrowserCacheLocation.SessionStorage,
                    storeAuthStateInCookie: isIE,
                },
            }),
            environment.msalGuardConfiguration,
            environment.msalInterceptorConfiguration
        ),
        HttpClientModule,
        StoreModule.forRoot(
            {
                [GLOBAL_STORE_NAME]: globalReducer,
                [DEFAULT_ROUTER_FEATURENAME]: routerReducer,
                [SIDENAV_STORE_NAME]: sidenavReducer,
                [BREADCRUMB_STORE_NAME]: breadcrumbReducer,
                [READINGSTYLE_STORE_NAME]: readingstyleReducer,
                [CUSTOMER_STORE_NAME]: customerReducer,
                [COURSE_STORE_NAME]: courseReducer,
                [NOTES_STORE_NAME]: notesReducer,
                [EXAMS_STORE_NAME]: examReducer,
                [EXAMINABLES_STORE_NAME]: examinablesReducer,
                [ERROR_DESCRIBTION_STORE_NAME]: errorDescribtionReducer,
                [REPETITORIUM_STORE_NAME]: repetitoriumReducer,
                [COURSE_PARTICIPATION_STORE_NAME]: courseParticipationReducer,
                [MODULE_INFO_STORE_NAME]: moduleInfoReducer,
                [CONTENT_SECTION_INFO_STORE_NAME]: contentSectionReducer,
                [CONTROL_TESTS_STORE_NAME]: controlTestsReducer,
                [READ_STATUS_STORE_NAME]: readStatusReducer,
            },
            {
                metaReducers: [MetaReducerLocalStorage],
            }
        ),
        RouterModule.forRoot(routes, {
            initialNavigation: !isIframe ? 'enabled' : 'disabled',
            paramsInheritanceStrategy: 'always',
        }),
        SidebarModule.forRoot(),
        SpinnerModule,
        StoreRouterConnectingModule.forRoot({
            navigationActionTiming: NavigationActionTiming.PostActivation,
        }),
        EffectsModule.forRoot([
            GlobalEffects,
            CustomerEffects,
            CourseEffects,
            NotesEffects,
            ExamEffects,
            ExaminablesEffects,
            RepetitoriumEffects,
            CourseParticipationEffects,
            ModuleInfoEffects,
            ContentSectionEffects,
            ControlTestsEffects,
            ReadStatusEffects,
        ]),
        EntityDataModule.forRoot(entityConfig),
        ScrollModule,
        NgLetModule,
        ActiveTimeTrackingModule.forRoot({
            maxInactiveTime: environment.timeTrackingConfig.maxInactiveTime,
            apiHost: environment.apiHost,
            dateTrackingIntervalSeconds:
                environment.timeTrackingConfig.dateTrackingIntervalSeconds,
            dateTrackingToleranceSeconds:
                environment.timeTrackingConfig.dateTrackingToleranceSeconds,
        }),
        /** TODO if we are going in production switch off */
        environment.devEnvironment
            ? StoreDevtoolsModule.instrument({
                  maxAge: 100,
                  name: 'My-AK-Jura', // Retains last 25 states
              })
            : [],
        /** */
        NetworkStatusModule,
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: 'de',
        },
        {
            provide: DefaultDataServiceConfig,
            useValue: {
                root: environment.apiHost,
            },
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        MsalService,
        CanDeactivateGuard,
        SectionNumberingPipe,
        SectionTitlePipe,
        SecondsToHoursPipe,
        NumberToNumberingPipe,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
