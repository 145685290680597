import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectCourseParticipationId } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { addBreadcrumbsUnique } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/breadcrumb/actions';

@Component({
    selector: 'cna-exam-signin-success-view',
    templateUrl: './exam-signin-success-view.component.html',
    styleUrls: ['./exam-signin-success-view.component.css'],
})
export class ExamSigninSuccessViewComponent implements OnInit {
    constructor(private store: Store) {}

    setBreadcrumbs() {
        this.store
            .select(selectCourseParticipationId)
            .subscribe(cpId => {
                if (cpId) {
                    this.store.dispatch(
                        addBreadcrumbsUnique({
                            breadcrumbs: [
                                {
                                    label: 'Anmeldungsergebnis',
                                    routerLink: cpId + '/examsignin/success',
                                },
                            ],
                        })
                    );
                }
            })
            .unsubscribe();
    }

    ngOnInit(): void {
        this.setBreadcrumbs();
    }
}
