import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'cna-gradient-button',
    templateUrl: './gradient-button.component.html',
    styleUrls: ['./gradient-button.component.css'],
})
export class GradientButtonComponent implements OnInit {
    @Output() akClick: EventEmitter<any> = new EventEmitter();
    @Input() buttonRouterLink?: string;
    @Input() buttonTitle: string;
    @Input() disabled: boolean;
    @Input() notgradient?: boolean;
    @Input() textAlign?: string;

    constructor() {}

    onButtonClicked() {
        this.akClick.emit();
    }

    ngOnInit(): void {}
}
