<div class="adelle-sans-17 font-weight-600 sans sidenavCustomizationHeader">
    Schriftgröße
</div>

<div class="gray-background sidenavCustomizationWrapper1">
    <div class="sidenavCustomizationWrapper2">
        <div
            class="col adelle-sans-14 pl-0 font-weight-600 pointer"
            style="font-size: 18.15px; color: #454f54"
            (click)="handleChange('fontSize', 'size-14')"
            [ngClass]="{
                activeText: this.currentStyle.fontSize === 'size-14'
            }"
        >
            A
        </div>
        <div
            class="col adelle-sans-17 font-weight-600 pointer"
            style="font-size: 21.78px; color: #454f54"
            (click)="handleChange('fontSize', 'size-17')"
            [ngClass]="{
                activeText: this.currentStyle.fontSize === 'size-17'
            }"
        >
            <span id="size-17">A</span>
        </div>
        <div
            class="col adelle-sans-20 font-weight-600 pointer"
            style="font-size: 26.62px; color: #454f54"
            (click)="handleChange('fontSize', 'size-20')"
            [ngClass]="{
                activeText: this.currentStyle.fontSize === 'size-20'
            }"
        >
            A
        </div>
        <div
            class="col adelle-sans-24 font-weight-600 pointer"
            style="font-size: 31.46px; color: #454f54"
            (click)="handleChange('fontSize', 'size-24')"
            [ngClass]="{
                activeText: this.currentStyle.fontSize === 'size-24'
            }"
        >
            A
        </div>
    </div>
</div>

<div class="adelle-sans-17 font-weight-600 sans sidenavCustomizationHeader">
    Schriftart
</div>

<div class="gray-background sidenavCustomizationWrapper1">
    <div class="sidenavCustomizationWrapper2">
        <div
            class="col-auto adelle-pe-17 font-weight-300 sans pl-0 pointer"
            (click)="handleChange('fontStyle', 'adelle-pe')"
            id="adelle-pe"
            style="color: #454f54"
            [ngClass]="{
                activeText: this.currentStyle.fontStyle === 'adelle-pe'
            }"
        >
            Klassisch
        </div>
        <div
            class="col-auto adelle-sans-17 font-weight-400 pointer"
            (click)="handleChange('fontStyle', 'adelle-sans')"
            style="color: #454f54"
            [ngClass]="{
                activeText: this.currentStyle.fontStyle === 'adelle-sans'
            }"
        >
            Minimalistisch
        </div>
    </div>
</div>

<div class="adelle-sans-17 font-weight-600 sans sidenavCustomizationHeader">
    Textbreite
</div>

<div class="gray-background sidenavCustomizationWrapper1">
    <div class="sidenavCustomizationWrapper2">
        <div
            class="slim pl-0 pointer"
            (click)="handleChange('textWidth', 'narrow-container')"
            [ngClass]="{
                activeElement:
                    this.currentStyle.textWidth === 'narrow-container'
            }"
        ></div>
        <div
            class="optimal pointer"
            (click)="handleChange('textWidth', 'medium-container')"
            [ngClass]="{
                activeElement:
                    this.currentStyle.textWidth === 'medium-container'
            }"
        ></div>
        <div
            class="fullWidth pointer"
            (click)="handleChange('textWidth', 'wide-container')"
            [ngClass]="{
                activeElement: this.currentStyle.textWidth === 'wide-container'
            }"
        ></div>
    </div>
</div>

<div class="adelle-sans-17 font-weight-600 sans sidenavCustomizationHeader">
    Schriftfarbe
</div>

<div class="gray-background sidenavCustomizationWrapper1">
    <div class="sidenavCustomizationWrapper2">
        <div
            class="circle one pl-0 pointer"
            (click)="handleChange('textColor', 'black-color')"
            [ngClass]="{
                activeElement: this.currentStyle.textColor === 'black-color'
            }"
        ></div>
        <div
            class="circle two pointer"
            (click)="handleChange('textColor', 'dark-gray')"
            [ngClass]="{
                activeElement: this.currentStyle.textColor === 'dark-gray'
            }"
        ></div>
        <div
            class="circle three pointer"
            (click)="handleChange('textColor', 'light-gray')"
            [ngClass]="{
                activeElement: this.currentStyle.textColor === 'light-gray'
            }"
        ></div>
    </div>
</div>

<div class="adelle-sans-17 font-weight-600 sans sidenavCustomizationHeader">
    Hintergrundfarbe
</div>

<div class="gray-background sidenavCustomizationWrapper1">
    <div class="sidenavCustomizationWrapper2">
        <div
            class="circle four pl-0 pointer"
            (click)="handleChange('backgroundColor', 'white-background')"
            [ngClass]="{
                activeElement:
                    this.currentStyle.backgroundColor === 'white-background'
            }"
        ></div>
        <div
            class="circle five pointer"
            (click)="handleChange('backgroundColor', 'egg-bg')"
            [ngClass]="{
                activeElement: this.currentStyle.backgroundColor === 'egg-bg'
            }"
        ></div>
        <div
            class="circle six pointer"
            (click)="handleChange('backgroundColor', 'dark-egg-bg')"
            [ngClass]="{
                activeElement:
                    this.currentStyle.backgroundColor === 'dark-egg-bg'
            }"
        ></div>
    </div>
</div>
