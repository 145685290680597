import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, skipWhile } from 'rxjs/operators';
import { selectCustomersCourseParticipation } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/selectors';

@Component({
    selector: 'cna-learn-time-with-progress-bar',
    templateUrl: './learn-time-with-progress-bar.component.html',
    styleUrls: ['./learn-time-with-progress-bar.component.scss'],
})
export class LearnTimeWithProgressBarComponent implements OnInit {
    @Input() targetReadingTimeInHours: number;
    @Input() currentLearnTimeInSeconds: number;

    constructor(private store: Store) {}

    ngOnInit(): void {}
}
