import { createAction, props } from '@ngrx/store';

/**
 * Error Description - TO DELETE !!
 */
export const setErrorDescription = createAction(
    '[global] set error description',
    props<{ errorDescription: string }>()
);

export const resetErrorDescription = createAction(
    '[global] reset error description'
);
