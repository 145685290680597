<div class="px-3 pt-3">
    <div class="modal-header">
        <h4 class="modal-title adelle-sans-17 font-weight-600">
            Notiz löschen
        </h4>
        <button
            type="button"
            class="closeButton"
            aria-label="Close"
            (click)="activeModal1.dismiss('Cross click')"
        >
            <img
                alt="close"
                src="assets/x-close.svg"
                height="20px"
                width="20px"
            />
        </button>
    </div>
    <div class="modal-body pt-3">
        <div class="formnote adelle-sans-17 font-weight-300" style="width: 92%">
            <p>
                Wollen Sie die Notiz mit dem Titel {{ title }} wirklich löschen?
            </p>
            <div class="form-el d-flex flex-row">
                <button
                    class="gradient-button normal-button"
                    (click)="deleteNote()"
                >
                    Löschen
                </button>
            </div>
        </div>
    </div>
</div>
