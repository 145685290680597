<div class="modal-header">
    <h4 class="modal-title adelle-sans-17 font-weight-600">
        Rechnungsadresse bearbeiten
    </h4>
    <button
        type="button"
        class="close"
        aria-label="Schließen"
        (click)="dismiss()"
    >
        <img
            alt="Schließen"
            src="assets/x-close.svg"
            height="20px"
            width="20px"
        />
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="invoiceAddressForm" (ngSubmit)="close()">
        <div class="form-group form-el">
            <label for="title" class="adelle-sans-17 font-weight-600"
                >Titel</label
            >
            <input
                id="title"
                type="text"
                name="title"
                class="form-control"
                formControlName="title"
            />
            <ng-container
                *ngLet="invoiceAddressForm.controls['title'] as control"
            >
                <div
                    *ngIf="
                        control.invalid && (control.dirty || control.touched)
                    "
                    class="error-list"
                >
                    <div
                        *ngIf="control.errors?.maxlength as error"
                        class="error"
                    >
                        Die Länge darf {{ error.requiredLength }} Zeichen nicht
                        übersteigen.
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="form-group form-el">
            <label for="salutation" class="adelle-sans-17 font-weight-600"
                >Anrede</label
            >
            <select
                id="salutation"
                name="salutation"
                class="form-control"
                formControlName="salutation"
            >
                <option
                    *ngFor="let salutation of salutations"
                    [value]="salutation"
                >
                    {{ salutation }}
                </option>
            </select>
            <ng-container
                *ngLet="invoiceAddressForm.controls['salutation'] as control"
            >
                <div
                    *ngIf="
                        control.invalid && (control.dirty || control.touched)
                    "
                    class="error-list"
                >
                    <div
                        *ngIf="control.errors?.forbiddenName as error"
                        class="error"
                    >
                        Der gewählte Wert ist ungültig.
                    </div>
                    <div
                        *ngIf="control.errors?.maxlength as error"
                        class="error"
                    >
                        Die Länge darf {{ error.requiredLength }} Zeichen nicht
                        übersteigen.
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="form-group form-el">
            <label for="first-name" class="adelle-sans-17 font-weight-600"
                >Vorname</label
            >
            <input
                id="first-name"
                name="first-name"
                class="form-control"
                formControlName="firstName"
                type="text"
            />
            <ng-container
                *ngLet="invoiceAddressForm.controls['firstName'] as control"
            >
                <div
                    *ngIf="
                        control.invalid && (control.dirty || control.touched)
                    "
                    class="error-list"
                >
                    <div
                        *ngIf="control.errors?.maxlength as error"
                        class="error"
                    >
                        Die Länge darf {{ error.requiredLength }} Zeichen nicht
                        übersteigen.
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="form-group form-el">
            <label for="last-name" class="adelle-sans-17 font-weight-600"
                >Nachname</label
            >
            <input
                id="last-name"
                name="last-name"
                class="form-control"
                formControlName="lastName"
                type="text"
            />
            <ng-container
                *ngLet="invoiceAddressForm.controls['lastName'] as control"
            >
                <div
                    *ngIf="
                        control.invalid && (control.dirty || control.touched)
                    "
                    class="error-list"
                >
                    <div
                        *ngIf="control.errors?.maxlength as error"
                        class="error"
                    >
                        Die Länge darf {{ error.requiredLength }} Zeichen nicht
                        übersteigen.
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="form-group form-el">
            <label for="company" class="adelle-sans-17 font-weight-600"
                >Unternehmen / Kanzlei</label
            >
            <input
                id="company"
                name="company"
                class="form-control"
                formControlName="company"
                type="text"
            />
            <ng-container
                *ngLet="invoiceAddressForm.controls['company'] as control"
            >
                <div
                    *ngIf="
                        control.invalid && (control.dirty || control.touched)
                    "
                    class="error-list"
                >
                    <div
                        *ngIf="control.errors?.maxlength as error"
                        class="error"
                    >
                        Die Länge darf {{ error.requiredLength }} Zeichen nicht
                        übersteigen.
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="form-group form-el">
            <label for="address-line-1" class="adelle-sans-17 font-weight-600"
                >Straße, Hausnummer*</label
            >
            <input
                id="address-line-1"
                name="address-line-1"
                class="form-control"
                formControlName="addressLine1"
                type="text"
                required
            />
            <ng-container
                *ngLet="invoiceAddressForm.controls['addressLine1'] as control"
            >
                <div
                    *ngIf="
                        control.invalid && (control.dirty || control.touched)
                    "
                    class="error-list"
                >
                    <div
                        *ngIf="control.errors?.maxlength as error"
                        class="error"
                    >
                        Die Länge darf {{ error.requiredLength }} Zeichen nicht
                        übersteigen.
                    </div>
                    <div
                        *ngIf="control.errors?.required as error"
                        class="error"
                    >
                        Dies ist ein Pflichtfeld.
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="form-group form-el">
            <label for="post-code" class="adelle-sans-17 font-weight-600"
                >Postleitzahl*</label
            >
            <input
                id="post-code"
                name="post-code"
                class="form-control"
                formControlName="postCode"
                type="text"
                required
            />
            <ng-container
                *ngLet="invoiceAddressForm.controls['postCode'] as control"
            >
                <div
                    *ngIf="
                        control.invalid && (control.dirty || control.touched)
                    "
                    class="error-list"
                >
                    <div
                        *ngIf="control.errors?.maxlength as error"
                        class="error"
                    >
                        Die Länge darf {{ error.requiredLength }} Zeichen nicht
                        übersteigen.
                    </div>
                    <div
                        *ngIf="control.errors?.required as error"
                        class="error"
                    >
                        Dies ist ein Pflichtfeld.
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="form-group form-el">
            <label for="city" class="adelle-sans-17 font-weight-600"
                >Stadt*</label
            >
            <input
                id="city"
                name="city"
                class="form-control"
                formControlName="city"
                type="text"
                required
            />
            <ng-container
                *ngLet="invoiceAddressForm.controls['city'] as control"
            >
                <div
                    *ngIf="
                        control.invalid && (control.dirty || control.touched)
                    "
                    class="error-list"
                >
                    <div
                        *ngIf="control.errors?.maxlength as error"
                        class="error"
                    >
                        Die Länge darf {{ error.requiredLength }} Zeichen nicht
                        übersteigen.
                    </div>
                    <div
                        *ngIf="control.errors?.required as error"
                        class="error"
                    >
                        Dies ist ein Pflichtfeld.
                    </div>
                </div>
            </ng-container>
        </div>
        <div
            *ngIf="
                invoiceAddressForm.invalid &&
                (invoiceAddressForm.dirty || invoiceAddressForm.touched)
            "
            class="error-list"
        >
            <div
                *ngIf="invoiceAddressForm.errors?.eitherNameOrCompany as error"
                class="error"
            >
                Es muss entweder Vor- und Nachname oder Unternehmen / Kanzlei
                angegeben werden.
            </div>
        </div>
        <div class="form-el" style="padding-top: 19px">
            <cna-gradient-button
                notgradient="true"
                buttonTitle="Speichern"
                [disabled]="invoiceAddressForm.invalid"
            ></cna-gradient-button>
        </div>
    </form>
</div>
