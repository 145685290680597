<!-- SideNav closed -->
<div
    *ngIf="sidenavType < 3"
    [ngClass]="[
        contentTile ? 'icon-tile-content' : 'icon-tile',
        noteTile ? 'noteTile' : ''
    ]"
    class="icon-tile"
    (click)="openSidenav()"
>
    <img [src]="tileImage" />
    <h5 class="adelle-sans-8 font-weight-600 letterSpacing-064 text-uppercase">
        {{ tileTitle }}
    </h5>
</div>

<!-- Sidenav opened -->
<div *ngIf="sidenavType == 3" class="icon-close-tile" (click)="closeSidenav()">
    <img [src]="tileImage" />
    <h5 class="adelle-sans-8 font-weight-600 letterSpacing-064 text-uppercase">
        {{ tileTitle }}
    </h5>
</div>

<!-- Dashboard active -->
<div
    *ngIf="sidenavType == 4"
    class="{{
        (dashboardActive$ | async)
            ? 'icon-tile icon-tile-is-dashboard'
            : 'icon-tile'
    }}"
    [routerLink]="[routerLinks]"
>
    <img [src]="tileImage" />
    <h5 class="adelle-sans-8 font-weight-600 letterSpacing-064 text-uppercase">
        {{ tileTitle }}
    </h5>
</div>
