import { Component, Input, OnInit } from '@angular/core';
import * as globalActions from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/actions';
import { Store } from '@ngrx/store';
import { loadCourseParticipationInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';
import {
    loadExamParticipations,
    loadExams,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/exam/actions';
import { CourseType } from '@cna/projects/ak-jura/shared/interfaces';

@Component({
    selector: 'cna-dashboard-tile-grid',
    templateUrl: './dashboard-tile-grid.component.html',
    styleUrls: ['./dashboard-tile-grid.component.scss'],
})
export class DashboardTileGridComponent implements OnInit {
    @Input() courseSelected: boolean;
    @Input() courseLocked: boolean;
    @Input() courseType: string;
    @Input() isTest: boolean;

    CourseType = CourseType;

    constructor(private store: Store) {}

    ngOnInit(): void {}

    loadCourseExam() {
        this.store.dispatch(loadCourseParticipationInfos());
        this.store.dispatch(loadExamParticipations());
        this.store.dispatch(loadExams());
    }
}
