import { createAction, props } from '@ngrx/store';
import { ReadingStyles } from '@cna/projects/ak-jura/shared/interfaces';

/**
 * Reading Styles
 */
export const setReadingStyles = createAction(
    '[ReadingStyles] set reading styles',
    props<{ readingStyles: ReadingStyles }>()
);
