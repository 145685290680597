// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { InteractionType } from '@azure/msal-browser';
import { MeinAkJuraEnvironment } from './environment.interface';
import { sharedEnvironment } from './environment.shared';

export const host = 'https://mein.ak-jura.de';
export const apiHost = 'https://api.ak-jura.de/api';
export const environment: MeinAkJuraEnvironment = {
    ...sharedEnvironment,
    production: true,
    devEnvironment: false,
    host,
    apiHost,
    frontendHost: 'https://www.ak-jura.de',
    passwordResetAuthority:
        'https://akjura.b2clogin.com/akjura.onmicrosoft.com/B2C_1_reset',
    msalGuardConfiguration: {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['https://akjura.onmicrosoft.com/webapi/User'],
        },
    },
    msalConfiguration: {
        auth: {
            clientId: 'c1d1aa7b-c251-415d-890e-552f25eae983',
            authority:
                'https://akjura.b2clogin.com/akjura.onmicrosoft.com/B2C_1_login',
            knownAuthorities: ['akjura.b2clogin.com'],
            navigateToLoginRequestUrl: false,
            postLogoutRedirectUri: 'https://www.ak-jura.de',
            redirectUri: host,
        },
    },
    msalInterceptorConfiguration: {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
            [`${apiHost}\*`, ['https://akjura.onmicrosoft.com/webapi/User']],
        ]),
    },
    timeTrackingConfig: {
        maxInactiveTime: 900,
        dateTrackingIntervalSeconds: 60,
        dateTrackingToleranceSeconds: 10,
    },
};
