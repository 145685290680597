import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as globalActions from './actions';
import * as errodDescribtionActions from '../error_describtion/actions';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { ExamParticipation } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam-participation';
import { environment } from '../../../environments/environment';
import { of } from 'rxjs';
import { selectCourseParticipationId } from '../router.selector';
import { Injectable } from '@angular/core';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { Exam } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleDocumentHelperService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/module-document-helper.service';
import { ToasterService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/toaster.service';
import { selectExamParticipations } from './selectors';

@Injectable()
export class ExamEffects {
    private examService: EntityCollectionService<Exam>;

    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private entityServices: EntityServices,
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private moduleDocumentHelperService: ModuleDocumentHelperService,
        private toasterService: ToasterService
    ) {
        this.examService =
            this.entityServices.getEntityCollectionService<Exam>('Exam');
    }

    /**
     * Load available Exams
     */
    loadExams$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.loadExams),
                tap(action => {
                    this.examService.getAll().subscribe(
                        exams => {
                            this.store.dispatch(
                                globalActions.loadExamsSuccess({
                                    exams: exams,
                                })
                            );
                        },
                        error =>
                            this.store.dispatch(
                                globalActions.loadExamsFailure({
                                    error: error,
                                })
                            )
                    );
                })
            );
        },
        { dispatch: false }
    );
    loadExamsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(globalActions.loadExamsFailure),
            map(error => {
                return errodDescribtionActions.setErrorDescription({
                    errorDescription:
                        'Verfügbare Prüfungstermine konnten nicht vom Server geladen werden. Bitte versuchen Sie es später noch einmal!',
                });
            })
        );
    });

    /**
     * Load available Exams
     */
    loadExamParticipations$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.loadExamParticipations),
                withLatestFrom(this.store.select(selectExamParticipations)),
                tap(action => {
                    this.http
                        .get(
                            environment.apiHost +
                                '/customer/me/exams-participation'
                        )
                        .subscribe(
                            (examParticipations: ExamParticipation[]) =>
                                this.store.dispatch(
                                    globalActions.loadExamParticipationsSuccess(
                                        {
                                            examParticipations:
                                                examParticipations,
                                        }
                                    )
                                ),
                            error =>
                                this.store.dispatch(
                                    globalActions.loadExamParticipationsFailure(
                                        { error: error }
                                    )
                                )
                        );
                })
            );
        },
        { dispatch: false }
    );

    loadExamParticipationsFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(globalActions.loadExamParticipationsFailure),
            map(error => {
                return errodDescribtionActions.setErrorDescription({
                    errorDescription:
                        'Informationen zu den absolvierten Prüfungen können nicht vom Server abgerufen werden.',
                });
            })
        );
    });

    /**
     * Booking Exam
     */
    validateAndSaveBookingExam$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(globalActions.validateAndSaveBookingExam),
            withLatestFrom(this.store.select(selectCourseParticipationId)),
            mergeMap(([action, courseParticipationId]) => {
                return this.http.post<ExamParticipation>(
                    environment.apiHost +
                        '/exam/' +
                        action.exam.id +
                        '/participant/' +
                        action.customer.id,
                    {
                        legalAreaId: action.legalAreaId,
                        invoiceAddress: action.invoiceAddress,
                        courseParticipationId: courseParticipationId,
                    }
                );
            }),
            map((ep: ExamParticipation) => {
                return globalActions.saveBookingExamSuccess({
                    examParticipation: ep,
                });
            }),
            catchError(error => {
                return of(globalActions.saveBookingExamFailure(error));
            })
        );
    });

    saveBookingExamSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.saveBookingExamSuccess),
                withLatestFrom(this.store.select(selectCourseParticipationId)),
                tap(([result, cpId]) => {
                    this.router.navigate([cpId, 'examsignin', 'success']);
                })
            );
        },
        { dispatch: false }
    );

    saveBookingExamFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.saveBookingExamFailure),
                tap(error => {
                    if (error['status'] === 409) {
                        this.store.dispatch(
                            errodDescribtionActions.setErrorDescription({
                                errorDescription:
                                    'Sie sind bereits für diese Prüfung angemeldet!',
                            })
                        );
                    } else {
                        this.store.dispatch(
                            errodDescribtionActions.setErrorDescription({
                                errorDescription: 'Anmeldung fehlgeschlagen!',
                            })
                        );
                    }
                })
            );
        },
        { dispatch: false }
    );
}
