<div *ngLet="customer$ | async as customer">
    <div class="dark-gray content-wrapper">
        <cna-page-heading
            pageHeadingTitle="Verlängerung buchen"
        ></cna-page-heading>
    </div>

    <div class="gray-background">
        <div class="content-wrapper">
            <div style="margin-bottom: 36px">
                <span class="adelle-pe-25 font-weight-400 dark-gray">Kurs</span>
            </div>
            <div *ngIf="course$ | async as course">
                <p class="adelle-sans-17 font-weight-300 dark-gray">
                    {{ course?.bookedCourse?.description }}
                </p>
            </div>
        </div>
    </div>

    <div class="gray-background">
        <div class="content-wrapper">
            <div
                class="adelle-pe-25 font-weight-400 dark-gray"
                style="margin-bottom: 36px"
            >
                Zeitraum
            </div>
            <p class="adelle-sans-17 font-weight-300 dark-gray mb-0">
                Die Laufzeit dieses Kurses endet<span *ngIf="courseHasEnded"
                    >e</span
                >
                am
                {{ (course$ | async)?.validUntil || 0 | date }}
            </p>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Sie können Ihre Laufzeit für {{ price | currency:'EUR':'symbol':'0.2-2' }} pro Monat verlängern.
            </p>

            <div class="custom-table book-course-extension">
                <div
                    class="hl extend adelle-sans-12 font-weight-600 dark-gray letterSpacing-08 no-border"
                    style="line-height: 1.417"
                >
                    VERLÄNGERUNG UM
                </div>
                <div
                    class="hl until adelle-sans-12 font-weight-600 dark-gray letterSpacing-08 no-border"
                    style="line-height: 1.417"
                >
                    NEUE LAUFZEIT BIS
                </div>
                <div
                    class="hl sum adelle-sans-12 font-weight-600 dark-gray letterSpacing-08 no-border"
                    style="line-height: 1.417"
                >
                    SUMME
                </div>
                <div class="data extend border-time-extension">
                    <div class="extend-input">
                        <form [formGroup]="ExtensionMonthsForm" class="time">
                            <input
                                type="number"
                                min="1"
                                max="24"
                                step="1"
                                value="{{ extensionMonths }}"
                                formControlName="em"
                                (ngModelChange)="this.changeMonthValue('input')"
                            />
                        </form>
                        <button
                            class="change-Amount-Button"
                            (click)="changeMonthValue('+')"
                        >
                            <img src="assets/Plus.svg" alt="plus" />
                        </button>
                        <button
                            class="change-Amount-Button"
                            (click)="changeMonthValue('-')"
                        >
                            <img src="assets/Minus.svg" alt="minus" />
                        </button>
                    </div>
                    <div class="adelle-sans-17 font-weight-300 dark-gray">
                        Monate je {{ price | currency:'EUR':'symbol':'1.2-2' }}
                    </div>
                </div>
                <div class="data until border-time-extension">
                    <div class="adelle-sans-17 font-weight-300 dark-gray">
                        {{ this.newOperationalTime | date }}
                    </div>
                </div>
                <div
                    class="data sum border-time-extension"
                    style="text-align: end"
                >
                    <div
                        class="adelle-sans-17 font-weight-300 dark-gray align-items-center"
                    >
                        {{ netPrice | currency: 'EUR':'symbol':'1.2-2' }}
                    </div>
                </div>
            </div>

            <div
                class="adelle-sans-17 font-weight-300 dark-gray"
                style="margin-top: 27px"
            >
                Die Zahlung erfolgt per Überweisung.
            </div>
        </div>
    </div>

    <div class="gray-background" *ngIf="customer">
        <div class="content-wrapper">
            <div class="d-flex flex-row" (click)="openInvoiceAddressModal()">
                <span
                    class="adelle-pe-25 font-weight-400 dark-gray"
                    style="margin-bottom: 36px"
                >
                    Rechnungsadresse
                    <img
                        class="ml-3"
                        style="transform: translateY(-4px); cursor: pointer"
                        src="assets/bearbeiten.svg"
                    />
                </span>
            </div>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                {{ invoiceAddress.salutation }}
                {{ invoiceAddress.title }}
                {{ invoiceAddress.firstName }}
                {{ invoiceAddress.lastName }}<br />
                <span *ngIf="invoiceAddress.company"
                    >{{ invoiceAddress.company }}<br
                /></span>
                {{ invoiceAddress.address.addressLine1 }}<br />
                {{ invoiceAddress.address.postCode }}
                {{ invoiceAddress.address.city }}
            </p>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                {{ invoiceAddress.mail }}
            </p>
            <p>
                <cna-gradient-button
                    textAlign="left"
                    notgradient="true"
                    buttonTitle="Bearbeiten"
                    (click)="openInvoiceAddressModal()"
                ></cna-gradient-button>
            </p>
        </div>
    </div>

    <div class="gray-background">
        <div class="content-wrapper">
            <p
                class="adelle-pe-25 font-weight-400 dark-gray"
                style="margin-bottom: 36px"
            >
                Kostenübersicht
            </p>

            <div class="custom-table price-overview">
                <div
                    class="hl position adelle-sans-12 font-weight-600 letterSpacing-08 dark-gray no-border"
                >
                    POSITION
                </div>
                <div
                    class="hl months adelle-sans-12 font-weight-600 dark-gray no-border letterSpacing-08"
                >
                    MONATE
                </div>
                <div
                    class="hl sum adelle-sans-12 font-weight-600 dark-gray letterSpacing-08 no-border"
                    style="line-height: 1.417"
                >
                    SUMME
                </div>
                <div
                    class="data position adelle-sans-17 font-weight-600 dark-gray border-cost-overview"
                >
                    Verlängerung
                </div>
                <div
                    class="data months adelle-sans-17 font-weight-300 dark-gray border-cost-overview"
                >
                    {{ this.extensionMonths }}
                </div>
                <div
                    class="data sum adelle-sans-17 font-weight-600 dark-gray border-cost-overview"
                >
                    {{ grossPrice | currency: 'EUR':'symbol':'1.2-2' }}
                </div>
                <div
                    class="data position no-border adelle-sans-17 font-weight-600 dark-gray"
                >
                    Gesamt
                </div>
                <div class="no-border"></div>
                <div
                    class="data sum no-border adelle-sans-17 font-weight-600 blue-color"
                >
                    <div>{{ grossPrice | currency: 'EUR':'symbol':'1.2-2' }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="gray-background">
        <div class="content-wrapper">
            <p
                class="adelle-pe-25 font-weight-400 dark-gray"
                style="margin-bottom: 35px"
            >
                Zahlungsart
            </p>
            <p
                class="adelle-sans-17 font-weight-600 dark-gray"
                style="margin-bottom: 10px"
            >
                Überweisung
            </p>
            <p class="adelle-sans-17 font-weight-300 dark-gray">
                Die Lehrgangskosten sind nach Rechnungsstellung innerhalb von
                zwei Wochen nach Buchung fällig.
            </p>
        </div>
    </div>

    <div class="gray-background">
        <div class="content-wrapper">
            <p class="adelle-sans-17 font-weight-600 dark-gray my-3">
                Hier finden Sie unsere
                <a
                    href="{{ websiteHost }}/datenschutz"
                    target="_blank"
                    class="dark-gray"
                    style="text-decoration: underline"
                    >Datenschutzbestimmungen</a
                >
            </p>

            <form [formGroup]="checkCourseExtensionForm">
                <div class="my-2 d-flex flex-row align-items-center">
                    <div class="radioContainer ml-0">
                        <label class="radio" for="radio_2">
                            <span
                                class="adelle-sans-17 font-weight-600 dark-gray"
                                >Ich stimme den
                                <a
                                    href="{{ websiteHost }}/agb"
                                    target="_blank"
                                    class="dark-gray"
                                    style="text-decoration: underline"
                                    >Vertragsbestimmungen</a
                                >
                                zu</span
                            >
                            <input
                                id="radio_2"
                                type="checkbox"
                                name="checkReadAGB"
                                formControlName="checkReadAGB"
                            />
                            <span class="checkround"></span>
                        </label>
                    </div>
                </div>
            </form>

            <div class="pl-0" style="margin-top: 36px; margin-bottom: 36px">
                <p
                    *ngIf="!(customerValid$ | async)"
                    class="adelle-sans-17 font-weight-600 dark-red-color"
                >
                    Ihre Kontaktdaten sind unvollständig, bitte vervollständigen
                    Sie Ihre Kontaktdaten
                    <a
                        (click)="openCustomerAddressModal($event)"
                        class="dark-red-color underlined c-cursor-pointer"
                        >hier</a
                    >.
                </p>
                <cna-loading-content [loading]="loading$ | async">
                    <div style="width: 100%; display: block">
                        <cna-gradient-button
                            buttonTitle="Kostenpflichtig buchen"
                            (click)="extendCourse()"
                            [disabled]="
                                !checkCourseExtensionForm.valid ||
                                !(customerValid$ | async) ||
                                !this.invoiceAddressValid()
                            "
                        ></cna-gradient-button>
                    </div>
                </cna-loading-content>
            </div>
        </div>
    </div>
</div>
