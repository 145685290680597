import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceAddress } from '@cna/projects/ak-jura/shared/interfaces';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Validators as CustomValidators } from '@cna/projects/ak-jura/angular/shared-components';

@Component({
    selector: 'cna-edit-invoice-address-modal',
    templateUrl: './edit-invoice-address-modal.component.html',
    styleUrls: ['./edit-invoice-address-modal.component.scss'],
})
export class EditInvoiceAddressModalComponent implements OnInit {
    @Input() invoiceAddress?: InvoiceAddress;

    salutations = ['', 'Frau', 'Herr'];

    invoiceAddressForm = new FormGroup(
        {
            title: new FormControl('', [Validators.maxLength(25)]),
            salutation: new FormControl(this.salutations[0], [
                CustomValidators.oneOf(this.salutations),
                Validators.maxLength(10),
            ]),
            firstName: new FormControl('', [Validators.maxLength(50)]),
            lastName: new FormControl('', [Validators.maxLength(50)]),
            company: new FormControl('', [Validators.maxLength(50)]),
            addressLine1: new FormControl('', [
                Validators.required,
                Validators.maxLength(50),
            ]),
            addressLine2: new FormControl('', [Validators.maxLength(50)]),
            postCode: new FormControl('', [
                Validators.required,
                Validators.maxLength(10),
            ]),
            city: new FormControl('', [
                Validators.required,
                Validators.maxLength(30),
            ]),
            stateOrProvince: new FormControl('', [Validators.maxLength(30)]),
            country: new FormControl('', [Validators.maxLength(60)]),
            mail: new FormControl('', [
                Validators.required,
                Validators.email,
                Validators.maxLength(255),
            ]),
        },
        {
            validators: [
                control => {
                    const firstName = control.get('firstName');
                    const lastName = control.get('lastName');
                    const company = control.get('company');

                    return (firstName.value &&
                        firstName.value.length > 0 &&
                        lastName.value &&
                        lastName.value.length > 0) ||
                        (company.value && company.value.length > 0)
                        ? null
                        : { eitherNameOrCompany: true };
                },
            ],
        }
    );

    constructor(private modal: NgbActiveModal) {}

    ngOnInit() {
        this.invoiceAddressForm.patchValue({
            title: this.invoiceAddress.title,
            salutation: this.invoiceAddress.salutation,
            firstName: this.invoiceAddress.firstName,
            lastName: this.invoiceAddress.lastName,
            company: this.invoiceAddress.company,
            mail: this.invoiceAddress.mail,
            addressLine1: this.invoiceAddress.address.addressLine1,
            addressLine2: this.invoiceAddress.address.addressLine2,
            postCode: this.invoiceAddress.address.postCode,
            city: this.invoiceAddress.address.city,
            provinceOrState: this.invoiceAddress.address.stateOrProvince,
            country: this.invoiceAddress.address.country,
        });
    }

    async close(): Promise<void> {
        if (this.invoiceAddressForm.valid) {
            const formData = this.invoiceAddressForm.getRawValue();
            this.modal.close(<InvoiceAddress>{
                title: formData.title || undefined,
                salutation: formData.salutation || undefined,
                firstName: formData.firstName || undefined,
                lastName: formData.lastName || undefined,
                company: formData.company || undefined,
                address: {
                    addressLine1: formData.addressLine1,
                    addressLine2: formData.addressLine2 || undefined,
                    postCode: formData.postCode,
                    city: formData.city,
                    country: formData.country || undefined,
                    stateOrProvince: formData.stateOrProvince || undefined,
                },
                mail: formData.mail,
            });
        }
    }

    async dismiss(): Promise<void> {
        this.modal.dismiss();
    }
}
