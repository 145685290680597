import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
    CONTENT_SECTION_INFO_STORE_NAME,
    ContentSectionState,
} from './reducer';

/**
 * Feature Selectors
 */
export const selectContentSectionState =
    createFeatureSelector<ContentSectionState>(CONTENT_SECTION_INFO_STORE_NAME);

export const selectContentSection = createSelector(
    selectContentSectionState,
    section => {
        return section.section[0];
    }
);

export const selectContentSectionLoading = createSelector(
    selectContentSectionState,
    section => section.loading
);
