import { Component, Input, OnInit } from '@angular/core';
import { filterNil } from '@cna/shared/helper';
import { Store } from '@ngrx/store';
import {
    setSidenavActiveState,
    setSidenavContentType,
} from 'apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/actions';
import { SidenavContentType } from 'libs/projects/ak-jura/angular/my-ak-jura/shared-components/src/lib/sidenav/sidenav.component';
import { selectRouteData } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { map } from 'rxjs/operators';

@Component({
    selector: 'cna-header-sidenav-icon-tile',
    templateUrl: './header-sidenav-icon-tile.component.html',
    styleUrls: ['./header-sidenav-icon-tile.component.scss'],
})
export class HeaderSidenavIconTileComponent implements OnInit {
    @Input() tileTitle: string;
    @Input() tileImage: string; // format: assets/test.png
    @Input() sidenavType?: SidenavContentType;
    @Input() routerLinks?: string;

    contentTile = false;
    noteTile = false;

    dashboardActive$ = this.store.select(selectRouteData).pipe(
        filterNil(),
        map(data => 'viewDashboard' in data && data.viewDashboard === true)
    );

    openSidenav() {
        this.store.dispatch(setSidenavActiveState({ activeState: true }));
        this.store.dispatch(
            setSidenavContentType({ contentType: this.sidenavType })
        );
    }

    closeSidenav() {
        this.store.dispatch(setSidenavActiveState({ activeState: false }));
    }

    constructor(private store: Store) {}

    ngOnInit(): void {
        if (this.tileTitle === 'Inhalt') {
            this.contentTile = true;
        }
        if (this.tileTitle === 'Notizen') {
            this.noteTile = true;
        }
        if (!this.routerLinks) {
            this.routerLinks = '';
        }
    }
}
