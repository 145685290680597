import { createReducer, on } from '@ngrx/store';
import * as fromActions from './actions';
import * as readStatusActions from '../read_status/actions';
import { LearningModuleDocumentInfo } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/learning-module-document-info';
import { saveTimeExpenditureToLocalStore } from '../actions';

export const MODULE_INFO_STORE_NAME = 'moduleInfo';

export interface ModuleInfoState {
    moduleInfo: LearningModuleDocumentInfo[];
    loading: boolean;
}

const initialState = {
    moduleInfo: [],
    loading: true,
};

const _moduleInfoReducer = createReducer<ModuleInfoState>(
    initialState,

    /**
     * load ModuleInfos //
     */
    on(fromActions.loadModuleInfosForModuleId, state => ({
        ...state,
        loading: true,
    })),

    on(fromActions.loadModuleInfosForModuleIdSuccess, (state, action) => ({
        ...state,
        moduleInfo: action.moduleInfo,
        loading: false,
    })),

    on(fromActions.loadModuleInfosForModuleIdFailure, state => ({
        ...state,
        loading: false,
    })),

    on(readStatusActions.createReadStatusSuccess, (state, action) => ({
        ...state,
        moduleInfo: state.moduleInfo.map(mI => ({
            ...mI,
            chapters:
                action.readStatus.moduleId === mI.moduleId
                    ? mI.chapters.map(chapter => ({
                          ...chapter,
                          sections: chapter.sections.map(section => ({
                              ...section,
                              readStatus:
                                  action.readStatus.sectionId === section.id
                                      ? {
                                            id: action.readStatus.id,
                                            read: action.readStatus.read,
                                        }
                                      : section.readStatus,
                              sections: section.sections
                                  ? section.sections.map(subSection => ({
                                        ...subSection,
                                        readStatus:
                                            action.readStatus.sectionId ===
                                            subSection.id
                                                ? {
                                                      id: action.readStatus.id,
                                                      read: action.readStatus
                                                          .read,
                                                  }
                                                : subSection.readStatus,
                                    }))
                                  : section.sections,
                          })),
                      }))
                    : mI.chapters,
        })),
    })),

    on(readStatusActions.updateReadStatusSuccess, (state, action) => ({
        ...state,
        moduleInfo: state.moduleInfo.map(mI => ({
            ...mI,
            chapters:
                action.readStatus.moduleId === mI.moduleId
                    ? mI.chapters.map(chapter => ({
                          ...chapter,
                          sections: chapter.sections.map(section => ({
                              ...section,
                              readStatus:
                                  action.readStatus.sectionId === section.id
                                      ? {
                                            id: action.readStatus.id,
                                            read: action.readStatus.read,
                                        }
                                      : section.readStatus,
                              sections: section.sections
                                  ? section.sections.map(subSection => ({
                                        ...subSection,
                                        readStatus:
                                            action.readStatus.sectionId ===
                                            subSection.id
                                                ? {
                                                      id: action.readStatus.id,
                                                      read: action.readStatus
                                                          .read,
                                                  }
                                                : subSection.readStatus,
                                    }))
                                  : undefined,
                          })),
                      }))
                    : mI.chapters,
        })),
    })),

    on(saveTimeExpenditureToLocalStore, (state, { timeExpenditure }) => ({
        ...state,
        moduleInfo: state.moduleInfo.map(mI =>
            timeExpenditure.moduleId === mI.moduleId &&
            mI.courseParticipationId === timeExpenditure.courseParticipationId
                ? {
                      ...mI,
                      totalTimeRead:
                          mI.totalTimeRead + timeExpenditure.duration,
                      chapters: mI.chapters.map(chapter => ({
                          ...chapter,
                          sections: chapter.sections.map(section => ({
                              ...section,
                              timeExpenditure:
                                  timeExpenditure.sectionId === section.id
                                      ? section.timeExpenditure
                                          ? {
                                                ...timeExpenditure,
                                                duration:
                                                    timeExpenditure.duration +
                                                    section.timeExpenditure
                                                        .duration,
                                            }
                                          : timeExpenditure
                                      : section.timeExpenditure,
                              sections: section.sections
                                  ? section.sections.map(innerSection => ({
                                        ...innerSection,
                                        timeExpenditure:
                                            timeExpenditure.sectionId ===
                                            innerSection.id
                                                ? innerSection.timeExpenditure
                                                    ? {
                                                          ...timeExpenditure,
                                                          duration:
                                                              timeExpenditure.duration +
                                                              innerSection
                                                                  .timeExpenditure
                                                                  .duration,
                                                      }
                                                    : timeExpenditure
                                                : innerSection.timeExpenditure,
                                    }))
                                  : section.sections,
                          })),
                      })),
                  }
                : mI
        ),
    }))
);

export function moduleInfoReducer(state, action) {
    return _moduleInfoReducer(state, action);
}
