import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as globalActions from './actions';
import * as errodDescribtionActions from '../error_describtion/actions';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { selectCourseParticipationId } from '../router.selector';
import { of } from 'rxjs';
import { ToastType } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/toast-type.enum';
import { ExamParticipation } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam-participation';
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { EntityServices } from '@ngrx/data';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ToasterService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/toaster.service';

@Injectable()
export class CourseEffects {
    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private entityServices: EntityServices,
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private toasterService: ToasterService
    ) {}

    /**
     * Set Course Participation in URL
     */
    setCourseParticipation$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(globalActions.setCourseParticipation),
            withLatestFrom(this.store.select(selectCourseParticipationId)),
            mergeMap(
                ([{ courseParticipationId }, actualCourseParticipationId]) => {
                    if (
                        courseParticipationId &&
                        actualCourseParticipationId !== courseParticipationId
                    ) {
                        return this.router.navigate([courseParticipationId], {
                            replaceUrl: true,
                        });
                    } else {
                        return of(
                            globalActions.setCourseParticipationSkipped()
                        );
                    }
                }
            ),
            map(navResult => {
                if (typeof navResult === 'boolean') {
                    if (navResult) {
                        return globalActions.setCourseParticipationSuccess();
                    } else {
                        return globalActions.setCourseParticipationFailure();
                    }
                } else if (navResult === null) {
                    return globalActions.setCourseParticipationSkipped();
                } else {
                    return navResult;
                }
            })
        );
    });

    setCourseParticipationFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.setCourseParticipationFailure),
                tap(action => {
                    this.toasterService.show(
                        'Fehler beim setzen des Kurses!',
                        ToastType.error
                    );
                })
            );
        },
        { dispatch: false }
    );

    /**
     * save CourseExtension
     */

    saveCourseExtension$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(globalActions.saveCourseExtension),
            mergeMap(action => {
                return this.http.post<ExamParticipation>(
                    environment.apiHost + '/course-participation/extension',
                    {
                        courseParticipationId: action.courseParticipationId,
                        extensionMonthsNumber: action.extensionMonthsNumber,
                        invoiceAddress: action.invoiceAddress,
                        costPerMonth: action.costPerMonth,
                        vat: action.vat,
                    }
                );
            }),
            map((a: any) => {
                return globalActions.saveCourseExtensionSuccess({
                    courseParticipationId: a.courseParticipationId,
                    newValidUntil: a.newValidUntil,
                });
            }),
            catchError(error => {
                return of(globalActions.saveCourseExtensionFailure(error));
            })
        );
    });

    saveCourseExtensionSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.saveCourseExtensionSuccess),
                tap(a => {
                    this.router.navigate([
                        a.courseParticipationId,
                        'bookextension',
                        'success',
                    ]);
                })
            );
        },
        { dispatch: false }
    );

    saveCourseExtensionFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.saveCourseExtensionFailure),
                tap(error => {
                    this.store.dispatch(
                        errodDescribtionActions.setErrorDescription({
                            errorDescription:
                                'Verlängerung des Kurses fehlgeschlagen!',
                        })
                    );
                })
            );
        },
        { dispatch: false }
    );
}
