import { createAction, props } from '@ngrx/store';
import { Examinable } from '@cna/projects/ak-jura/shared/interfaces';

/**
 * Load Examinables for Module
 */
export const loadModuleExaminables = createAction(
    '[global] load Module Examinables'
);
export const loadModuleExaminablesSuccess = createAction(
    '[global] load Module Examinables success',
    props<{ examinables: Examinable[] }>()
);
export const loadModuleExaminablesFailure = createAction(
    '[global] load Module Examinables failure',
    props<{ error: Error }>()
);
