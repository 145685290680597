<!--normal button-->
<button
    *ngIf="notgradient"
    [disabled]="disabled"
    class="normal-button dark-gray-background text-decoration-none"
    type="submit"
    (click)="onButtonClicked()"
    [routerLink]="buttonRouterLink || null"
    [ngClass]="{
        textAlignLeft: textAlign === 'left',
        textAlignCenter: textAlign === 'center',
        textAlignRight: textAlign === 'right'
    }"
>
    <span class="adelle-sans-17 font-weight-600 white-color">{{
        buttonTitle
    }}</span>
</button>

<!--disabled button-->
<button
    *ngIf="!notgradient && buttonRouterLink !== undefined"
    class="gradient-button mt-2"
    [disabled]="disabled"
    [routerLink]="[buttonRouterLink]"
    (click)="onButtonClicked()"
    [ngClass]="{
        textAlignLeft: textAlign === 'left',
        textAlignCenter: textAlign === 'center',
        textAlignRight: textAlign === 'right'
    }"
>
    <div class="row m-0 h-100 justify-content-center">
        <div class="col-auto d-flex h-100 align-items-center text-center">
            <p class="adelle-sans-17 font-weight-600 mb-0">{{ buttonTitle }}</p>
        </div>
    </div>
</button>

<button
    *ngIf="!notgradient && buttonRouterLink === undefined"
    class="gradient-button mt-2"
    [disabled]="disabled"
    (click)="onButtonClicked()"
    style="width: auto"
    [ngClass]="{
        textAlignLeft: textAlign === 'left',
        textAlignCenter: textAlign === 'center',
        textAlignRight: textAlign === 'right'
    }"
>
    <div class="row m-0 h-100 justify-content-center">
        <div class="col-auto d-flex h-100 align-items-center text-center">
            <p class="adelle-sans-17 font-weight-600 mb-0">{{ buttonTitle }}</p>
        </div>
    </div>
</button>
