import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { CourseParticipation } from '@cna/projects/ak-jura/shared/interfaces';
import type { UUID } from '@cna/shared/generic-types';

@Component({
    selector: 'cna-course-select',
    templateUrl: './course-select.component.html',
    styleUrls: ['./course-select.component.scss'],
})
export class CourseSelectComponent implements OnChanges {
    @Input() courses: CourseParticipation[] = [];
    @Input() selectedCourseId: UUID = undefined;
    @Output() selectCourse = new EventEmitter<{
        courseParticipationId: UUID;
    }>();
    selectedCourse: CourseParticipation;

    constructor() {}

    ngOnChanges(): void {
        this.selectedCourse = this.courses.find(
            cp => cp.id === this.selectedCourseId
        );
    }

    onSelectCourse(courseParticipationId: UUID): void {
        this.selectCourse.emit({ courseParticipationId });
    }
}
