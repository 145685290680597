import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectModuleNotesByModuleIdFromRouter } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/notes/selectors';
import { Note } from '@cna/projects/ak-jura/shared/interfaces';
import { ModalComponent } from '../modal/modal.component';
import { AskDeleteModalComponent } from '../ask-delete-modal/ask-delete-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { tap } from 'rxjs/operators';
import { loadNotes } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/notes/actions';

@Component({
    selector: 'cna-sidenav-notes-accordion',
    templateUrl: './sidenav-notes-accordion.component.html',
    styleUrls: ['./sidenav-notes-accordion.component.scss'],
})
export class SidenavNotesAccordionComponent implements OnInit {
    notes$ = this.store.select(selectModuleNotesByModuleIdFromRouter);
    Modal;

    constructor(private store: Store, private modalService: NgbModal) {}

    ngOnInit(): void {}

    open(event, clickedNote: Note) {
        event.preventDefault();
        event.stopPropagation();

        switch (event.target.id) {
            case 'edit':
                this.Modal = ModalComponent;
                break;
            case 'close':
                this.Modal = AskDeleteModalComponent;
                break;
            default:
                break;
        }

        const modalRef = this.modalService.open(this.Modal, {
            backdrop: true,
            size: 'lg',
            keyboard: true,
            centered: true,
            windowClass: 'custom-modal',
        });

        modalRef.componentInstance.id = clickedNote.id;
        modalRef.componentInstance.title = clickedNote.title;
        if (this.Modal === ModalComponent) {
            modalRef.componentInstance.content = clickedNote.description;
        }
    }
}
