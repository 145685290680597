import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'cna-learning-footer',
    templateUrl: './learning-footer.component.html',
    styleUrls: ['./learning-footer.component.scss'],
})
export class LearningFooterComponent {
    @Input() readStatus = true;
    @Input() isReadStatusDisabled: boolean;
    @Input() isRouteNextDisabled: boolean;
    @Input() isRouteOverviewDisabled = false;
    @Input() contactMailLink: string;
    @Output() changeReadStatus = new EventEmitter<{ readStatus: boolean }>();
    @Output() routeNext = new EventEmitter<void>();
    @Output() routeOverview = new EventEmitter<void>();
    @Output() print = new EventEmitter<void>();

    async onChange(event: { checked: boolean }) {
        if (!this.isReadStatusDisabled) {
            this.changeReadStatus.emit({
                readStatus: event.checked,
            });
        }
    }

    async onRouteNext() {
        if (!this.isRouteNextDisabled) {
            this.routeNext.emit();
        }
    }

    async onRouteOverview() {
        if (!this.isRouteOverviewDisabled) {
            this.routeOverview.emit();
        }
    }

    async onPrint() {
        this.print.emit();
    }
}
