<div class="table" *ngLet="courseType$ | async as courseType">
    <div class="table-header d-flex flex-row justify-content-start">
        <div class="header-left1"><p></p></div>
        <div class="header-left2">
            <p
                class="adelle-sans-12 letterSpacing-08 font-weight-600 header-learning-progress"
            >
                LERNFORTSCHRITT
            </p>
        </div>
        <div class="header-left3">
            <p
                class="adelle-sans-12 letterSpacing-08 font-weight-600 header-learning-time"
            >
                LERNZEIT
            </p>
        </div>
        <div class="header-left4">
            <p
                class="adelle-sans-12 letterSpacing-08 font-weight-600 header-learning-control"
            >
                LERNKONTROLLE
            </p>
        </div>
    </div>

    <div class="table-container">
        <ngb-accordion
            *ngIf="moduleInfos$ | async as modules"
            #acc="ngbAccordion"
            id="learnstate-accordion"
            activeIds="ngb-panel-0"
            class="learnstate"
        >
            <ngb-panel [disabled]="true">
                <ng-template ngbPanelTitle>
                    <div
                        class="d-flex flex-row justify-content-start align-items-baseline"
                    >
                        <p
                            class="adelle-sans-17 font-weight-600 dark-gray ebene1-left1"
                        >
                            Gesamt
                        </p>

                        <div class="pt-1 d-flex ebene1-left2">
                            <cna-progress-bar
                                [progressPercentage]="
                                    (totalProgress$ | async) * 100
                                "
                            ></cna-progress-bar>
                            <span
                                class="ml-2 adelle-sans-14 dark-gray font-weight-300 learnstate-accordion-percentage"
                                style="margin-left: 8px"
                            >
                                {{
                                    (totalProgress$ | async) * 100
                                        | number: '1.0-0'
                                }}
                                %
                            </span>
                        </div>
                        <div class="text-left ebene1-left3">
                            <p
                                class="adelle-sans-14 dark-gray font-weight-300"
                                style="margin-top: 4px"
                            >
                                {{ totalTimeSpent$ | async | secondsToHours }}
                            </p>
                        </div>
                        <div class="text-left"></div>
                    </div>
                </ng-template>
            </ngb-panel>

            <!-- second line -->
            <ngb-panel
                [disabled]="true"
                *ngFor="let moduleInfo of moduleInfos$ | async"
            >
                <ng-template ngbPanelHeader>
                    <div class="d-flex flex-row justify-content-between">
                        <div
                            class="d-flex flex-row justify-content-start align-items-baseline"
                        >
                            <p
                                class="adelle-sans-17 dark-gray font-weight-600 ebene2-left1"
                            >
                                <span
                                    *ngIf="
                                        courseType ===
                                        CourseType.EducationCourse
                                    "
                                    >MODUL
                                    {{
                                        moduleInfo.sequentialNumber
                                            | numberToNumbering
                                                : 'upperRomanNumerals'
                                    }}</span
                                >
                                <span
                                    *ngIf="
                                        courseType === CourseType.TrainingCourse
                                    "
                                >
                                    Kurs
                                </span>
                            </p>
                            <div class="d-flex flex-row ebene2-left2">
                                <cna-progress-bar
                                    [progressPercentage]="
                                        moduleInfo.percentageRead * 100
                                    "
                                ></cna-progress-bar>
                                <!-- [progressPercentage]="moduleDocumentHelperService.getPercentageReadForNode(moduleInfo)" -->
                                <span
                                    class="adelle-sans-14 dark-gray font-weight-300 learnstate-accordion-percentage"
                                    style="margin-left: 8px"
                                >
                                    {{
                                        moduleInfo.percentageRead * 100
                                            | number: '1.0-0'
                                    }}
                                    %
                                </span>
                            </div>
                            <div class="text-left ebene2-left3">
                                <p
                                    class="adelle-sans-14 dark-gray font-weight-300"
                                    style="margin-bottom: 0px"
                                >
                                    {{
                                        moduleInfo.totalTimeRead
                                            | secondsToHours
                                    }}
                                </p>
                            </div>
                            <div class="ebene2-left4">
                                <span
                                    *ngIf="
                                        moduleInfo.controlTestPassed === true
                                    "
                                    class="d-flex flex-row justify-content-between align-items-baseline"
                                >
                                    <span
                                        class="adelle-sans-14 dark-gray font-weight-300"
                                        style="
                                            width: 140px;
                                            margin-left: 56px;
                                            margin-bottom: 0px;
                                        "
                                        >bestanden</span
                                    >

                                    <button
                                        (click)="
                                            downloadLearnControlPdf(
                                                moduleInfo,
                                                courseType
                                            )
                                        "
                                        style="
                                            background-color: white;
                                            margin-left: 30px;
                                            width: 59px;
                                            padding-top: 0;
                                            padding-bottom: 0;
                                        "
                                        class="d-flex flex-row align-items-baseline"
                                    >
                                        <img
                                            style="
                                                margin-left: 6px;
                                                width: 25px;
                                                transform: translateY(2px);
                                            "
                                            src="assets/mehr_grey_2.svg"
                                        />
                                        <div
                                            class="font-weight-600 adelle-sans-11 dark-gray letterSpacing-073"
                                            style="
                                                width: 24px;
                                                margin-left: 4px;
                                            "
                                        >
                                            PDF
                                        </div>
                                    </button>
                                </span>

                                <p
                                    *ngIf="
                                        moduleInfo.controlTestPassed === false
                                    "
                                    class="adelle-sans-14 dark-gray font-weight-300"
                                    style="
                                        width: 140px;
                                        margin-left: 56px;
                                        margin-bottom: 0px;
                                    "
                                >
                                    nicht bestanden
                                </p>
                                <p
                                    *ngIf="
                                        moduleInfo.controlTestPassed === null
                                    "
                                    class="adelle-sans-14 dark-gray font-weight-300"
                                    style="
                                        width: 140px;
                                        margin-left: 56px;
                                        margin-bottom: 0px;
                                    "
                                >
                                    –
                                </p>
                            </div>

                            <div
                                class="d-flex flex-row justify-content-end text-right ebene2-left5"
                            >
                                <a
                                    *ngIf="moduleInfo?.unlocked"
                                    class="font-weight-600 adelle-sans-11 dark-gray letterSpacing-073"
                                    style="width: 203px"
                                    [routerLink]="[
                                        '../learning/moduleOverview/',
                                        moduleInfo.id
                                    ]"
                                >
                                    <span
                                        *ngIf="
                                            moduleInfo.percentageRead >= 1 &&
                                                moduleInfo.controlTestPassed !==
                                                    null;
                                            else continueReading
                                        "
                                    >
                                        MODUL WIEDERHOLEN
                                    </span>
                                    <ng-template #continueReading>
                                        <span
                                            *ngIf="
                                                moduleInfo.totalTimeRead != 0;
                                                else restartReading
                                            "
                                        >
                                            MODUL FORTSETZEN
                                        </span>
                                    </ng-template>
                                    <ng-template #restartReading>
                                        <span> MODUL STARTEN </span>
                                    </ng-template>
                                </a>

                                <div
                                    *ngIf="!moduleInfo?.unlocked"
                                    class="text-right"
                                    style="width: 238px"
                                >
                                    <a
                                        class="font-weight-600 adelle-sans-11 letterSpacing-073"
                                        style="color: #bec2c3"
                                    >
                                        NOCH NICHT FREIGESCHALTET
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div
                            *ngIf="moduleInfo?.unlocked"
                            style="margin-top: 15px; margin-bottom: 15px"
                        >
                            <!-- Modul wird erst noch gestartet timeRead == 0 -->
                            <span
                                *ngIf="moduleInfo.totalTimeRead == 0"
                                class="img-container"
                                [routerLink]="[
                                    '../learning/moduleOverview/',
                                    moduleInfo.moduleId
                                ]"
                            >
                                <img
                                    src="assets/start.svg"
                                    class="img-before"
                                />
                                <img
                                    src="assets/start-hover.svg"
                                    class="img-after"
                                />
                            </span>

                            <!-- Modul wird wiederholt -->
                            <span
                                *ngIf="
                                    moduleInfo.percentageRead >= 100 &&
                                    !(moduleInfo.controlTestPassed === null)
                                "
                                class="img-container"
                                [routerLink]="[
                                    '../learning/moduleOverview/',
                                    moduleInfo.moduleId
                                ]"
                            >
                                <img
                                    src="assets/start.svg"
                                    class="img-before"
                                />
                                <img
                                    src="assets/start-hover.svg"
                                    class="img-after"
                                />
                            </span>

                            <!-- Modul wird noch bearbeitet -->
                            <span
                                *ngIf="
                                    moduleInfo.totalTimeRead != 0 &&
                                    (moduleInfo.percentageRead < 100 ||
                                        moduleInfo.controlTestPassed === null)
                                "
                                class="img-container"
                                [routerLink]="[
                                    '../learning/moduleOverview/',
                                    moduleInfo.id
                                ]"
                            >
                                <img
                                    src="assets/start.svg"
                                    class="img-before"
                                />
                                <img
                                    src="assets/start-hover.svg"
                                    class="img-after"
                                />
                            </span>
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>

            <ngb-panel
                [disabled]="true"
                *ngIf="(courseType$ | async) === 'EDUCATION_COURSE'"
            >
                <ng-template ngbPanelHeader>
                    <div
                        class="d-flex flex-row justify-content-between align-items-baseline"
                    >
                        <p
                            class="learnstage-accordion-repetitorium-t1 adelle-sans-17 dark-gray font-weight-600"
                            [routerLink]="['../learning/repetitorium']"
                        >
                            REPETITORIUM
                        </p>
                        <div class="d-flex flex-row text-right">
                            <a
                                *ngIf="unlocked$ | async"
                                class="font-weight-600 adelle-sans-11 dark-gray letterSpacing-073"
                                [routerLink]="['../learning/repetitorium']"
                            >
                                <span style="margin-right: 12px">
                                    ZUM REPETITORIUM
                                </span>
                                <span class="img-container">
                                    <img
                                        src="assets/start.svg"
                                        class="img-before"
                                    />
                                    <img
                                        src="assets/start-hover.svg"
                                        class="img-after"
                                    />
                                </span>
                            </a>
                            <a
                                *ngIf="!(unlocked$ | async)"
                                class="font-weight-600 adelle-sans-11 letterSpacing-073"
                                style="color: #bec2c3; margin-right: 7px"
                                >NOCH NICHT FREIGESCHALTET</a
                            >
                        </div>
                    </div>
                </ng-template>
            </ngb-panel>
        </ngb-accordion>
    </div>
</div>
