import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ChaptersPercentageReadPipe } from './pipes/chapters-percentage-read.pipe';

@NgModule({
    imports: [CommonModule],
    declarations: [ChaptersPercentageReadPipe],
    exports: [ChaptersPercentageReadPipe],
    providers: [DatePipe],
})
export class AkJuraAngularSharedComponentsModule {}
