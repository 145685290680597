import { createReducer, on } from '@ngrx/store';
import * as fromActions from './actions';

export const ERROR_DESCRIBTION_STORE_NAME = 'error_describtion';

export interface ErrorDescribtionState {
    loading: boolean;
    errorDescription: string;
}

const initialState = {
    loading: false,
    errorDescription: undefined,
};

const _errorDescribtionReducer = createReducer<ErrorDescribtionState>(
    initialState,

    /**
     * Error
     */
    on(fromActions.setErrorDescription, (state, action) => ({
        ...state,
        errorDescription: action.errorDescription,
        loading: false,
    })),
    on(fromActions.resetErrorDescription, state => ({
        ...state,
        errorDescription: undefined,
        loading: false,
    }))
);

export function errorDescribtionReducer(state, action) {
    return _errorDescribtionReducer(state, action);
}
