import { Component, Input, OnInit } from '@angular/core';
import { loadCourseParticipationInfos } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/course_participation/actions';
import {
    loadExamParticipations,
    loadExams,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/exam/actions';
import { Store } from '@ngrx/store';

@Component({
    selector: 'cna-icon-tile',
    templateUrl: './icon-tile.component.html',
    styleUrls: ['./icon-tile.component.scss'],
})
export class IconTileComponent implements OnInit {
    @Input() tileTitle: string;
    @Input() tileImage: string; // format: assets/test.png
    @Input() routeLink: string;
    @Input() isDeactivated = false;
    constructor(private store: Store) {}

    test() {
        this.store.dispatch(loadCourseParticipationInfos());
        this.store.dispatch(loadExamParticipations());
        this.store.dispatch(loadExams());
    }

    ngOnInit(): void {}
}
