<ng-container *ngLet="readingStyles$ | async as readingStyles">
    <div
        class="text-container"
        [ngClass]="
            readingStyles
                ? [readingStyles.textWidth, readingStyles?.backgroundColor]
                : []
        "
    >
        <div class="content">
            <cna-loading-content [loading]="loading$ | async">
                <cna-reading-content
                    [content]="renderedText"
                    [readingStyles$]="readingStyles$"
                ></cna-reading-content>
            </cna-loading-content>
        </div>

        <cna-learning-footer
            *ngLet="readStatus$ | async as readStatus"
            [readStatus]="readStatus?.read"
            [isReadStatusDisabled]="
                (controlTestPassed$ | async) && readStatus?.read
            "
            [isRouteNextDisabled]="!nextReadingId"
            [contactMailLink]="contactMailLink$ | async"
            (changeReadStatus)="setReadStatus(readStatus, $event)"
            (routeNext)="routeToNextId()"
            (routeOverview)="routeToOverview()"
            (print)="printContent()"
        ></cna-learning-footer>
    </div>
</ng-container>

<ng-template #inactivityModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title adelle-sans-17 dark-gray">
            Zeitaufzeichnung wegen Inaktivität gestoppt
        </h5>
    </div>
    <div class="modal-body adelle-sans-17 dark-gray">
        <p>
            Sie scheinen inaktiv zu sein, deshalb wurde die Zeitaufzeichnung
            gestoppt und die letzten {{ maxInactiveTime / 60 | number }} Minuten
            werden nicht gewertet.
        </p>
    </div>
    <div class="modal-footer">
        <cna-gradient-button
            buttonTitle="Sitzung fortsetzen"
            (click)="restartSession()"
            class="pb-2"
        ></cna-gradient-button>
        <cna-gradient-button
            data-dismiss="modal"
            notgradient="true"
            buttonTitle="Zur Übersicht"
            (click)="routeToOverview()"
        ></cna-gradient-button>
    </div>
</ng-template>
