<div class="row">
    <div class="offset-sm-2 col-sm-8 col-16">
        <div
            class="adelle-pe-20 font-weight-600 blue-color"
            style="margin-left: 40px"
        >
            Frage {{ currentTab + 1 }} (von {{ controlQuestions.length }})
        </div>
        <div
            class="adelle-sans-17 font-weight-600 dark-gray my-4"
            style="margin-left: 40px"
        >
            {{ controlQuestions[currentTab].question }}
        </div>
    </div>
</div>

<div class="row gray-background">
    <div class="offset-sm-2 col-sm-8 col-16 mb-5">
        <div class="form-group">
            <form
                #controlForm="ngForm"
                id="controlForm"
                (ngSubmit)="validateAndNext(controlForm)"
            >
                <div
                    *ngFor="
                        let answer of controlQuestions[currentTab].answers;
                        let i = index
                    "
                    class="mt-5 d-flex"
                    style="margin-left: 40px"
                >
                    <div class="radioContainer">
                        <label class="radio" for="radio_{{ i }}">
                            <span
                                class="adelle-sans-17 font-weight-300 dark-gray"
                                >{{ answer.text }}</span
                            >
                            <input
                                type="radio"
                                name="radioValue"
                                id="radio_{{ i }}"
                                value="{{ i + SEPARATOR + answer.id }}"
                                ngModel
                                [required]="answer.id"
                            />
                            <span class="checkround"></span>
                        </label>
                    </div>
                    <!--
                    <div class="form-check">
                        <input
                            id="radio_{{ i }}"
                            type="radio"
                            class="form-check-input"
                            name="radioValue"
                            value="{{ i + SEPARATOR + answer.id }}"
                            ngModel
                            [required]="answer.id"
                        />
                        <label class="form-check-label" for="radio_{{ i }}">
                            <span
                                class="adelle-sans-17 font-weight-300 dark-gray"
                                >{{ answer.text }}</span
                            >
                        </label>
                    </div>
                    -->
                </div>

                <div
                    class="pt-3 mt-5 pl-0 form-check"
                    style="margin-left: 40px"
                >
                    <cna-gradient-button
                        *ngIf="currentTab < controlQuestions.length - 1"
                        notgradient="true"
                        buttonTitle="Bestätigen & zur nächsten Frage"
                        [disabled]="!valid && controlForm.invalid"
                    ></cna-gradient-button>
                    <cna-gradient-button
                        *ngIf="currentTab === controlQuestions.length - 1"
                        notgradient="true"
                        buttonTitle="Ergebnisse absenden"
                        [disabled]="!valid && controlForm.invalid"
                    ></cna-gradient-button>
                    <!-- <p
                        *ngIf="!valid && controlForm.invalid"
                        class="
                            adelle-sans-11
                            font-weight-300
                            letterSpacing-064
                            red-color
                            mb-0
                        "
                    >
                        Bitte mindestens eine Antwort auswählen!
                    </p> -->
                </div>
            </form>

            <div class="mt-5 pl-0 form-check"></div>
        </div>
    </div>
</div>

<!-- Step Indicator -->
<div class="row mt-5 mb-5">
    <div class="col d-flex justify-content-center">
        <div class="d-flex mx-3">
            <!-- Farbe von controlQuestion soll Farbe #8F9598; light sein-->
            <span class="adelle-sans-17 font-weight-600"
                >Frage {{ currentTab + 1 }}</span
            ><span class="mx-2 gray"> / </span
            ><span class="adelle-sans-17 font-weight-300 gray">{{
                controlQuestions.length
            }}</span>

            <!--
            <div
                *ngFor="let quest of controlQuestions; let i = index"
                class="text-center"
                style="padding-right: 0.25vw"
            >
                <div>
                    <div
                        class="{{ i === currentTab ? 'step active' : 'step' }}"
                    ></div>
                    <div class="adelle-sans-17 font-weight-600 stepCount">
                        {{ i + 1 }}
                    </div>
                </div>
            </div>

            -->
        </div>
    </div>
</div>
