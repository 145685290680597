export class StopWatch {
    private duration = 0;
    private startDate?: Date;

    private rounds: {
        start: Date;
        end: Date;
        ignore?: number;
        duration: number;
    }[] = [];
    private lastRead?: Date;

    get running() {
        return !!this.startDate;
    }

    start(): void {
        if (!this.running) {
            this.startDate = new Date();
        }
    }
    pause(ignoreSeconds?: number): void {
        if (this.running) {
            const endDate = new Date(Date.now() - (ignoreSeconds ?? 0) * 1000);
            this.lastRead = endDate;
            const round = {
                start: this.startDate,
                end: endDate,
                ignore: ignoreSeconds,
                duration: Math.max(
                    Math.floor(
                        (endDate.getTime() - this.startDate.getTime()) / 1000
                    ),
                    0
                ),
            };
            this.rounds.push(round);
            this.duration += round.duration;
            this.startDate = undefined;
        }
    }
    flush() {
        if (this.running) {
            this.pause();
        }
        const result = {
            duration: this.duration,
            rounds: this.rounds,
            lastRead: this.lastRead,
        };
        this.duration = 0;
        this.rounds = [];
        this.lastRead = undefined;
        return result;
    }
}
