import { Injectable } from '@angular/core';
import { CourseParticipation } from '@cna/projects/ak-jura/shared/interfaces';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import * as pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from './vfs_fonts';
import { ControlAnswerEvidence } from '../../../../shared/interfaces/src/lib/data-management/control-test/control-answer-evidence';
import { ControlTestEvidence } from '../../../../shared/interfaces/src/lib/data-management/control-test/control-test-evidence';
import { LearningModuleDocumentInfo } from '../../../../shared/interfaces/src/lib/learning-module-document-info';
import { CustomerBaseData } from '@cna/projects/ak-jura/shared/interfaces';
import { NumberToNumberingPipe } from '../../../../../../angular/ui/pipes/src/lib/number-to-numbering.pipe';
import htmlToPdfmake from 'html-to-pdfmake';

@Injectable({
    providedIn: 'root',
})
export class PdfCreatorService {
    public logo;
    public trainingCertificateBackground1;
    public trainingCertificateBackground2;
    public trainingCertificateBackground3;
    public trainingCertificateRight;
    public trainingCertificateWrong;
    public trainingCertificateCircle;
    public certificateFooter;

    constructor(
        private http: HttpClient,
        private datePipe: DatePipe,
        private numberToNumberingPipe: NumberToNumberingPipe
    ) {
        this.loadImage('assets/logo.png', 'logo');
        this.loadImage(
            'assets/training_certificate_s1.png',
            'trainingCertificateBackground1'
        );
        this.loadImage(
            'assets/training_certificate_s2.png',
            'trainingCertificateBackground2'
        );
        this.loadImage(
            'assets/training_certificate_s3.png',
            'trainingCertificateBackground3'
        );
        this.loadImage(
            'assets/training_certificate_right.png',
            'trainingCertificateRight'
        );
        this.loadImage(
            'assets/training_certificate_wrong.png',
            'trainingCertificateWrong'
        );
        this.loadImage(
            'assets/training_certificate_circle.png',
            'trainingCertificateCircle'
        );
        this.loadImage('assets/Footer.png', 'certificateFooter');
    }

    loadImage(url: string, destination: string) {
        this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
                this[destination] = reader.result;
            };
            reader.readAsDataURL(blob);
        });
    }

    createControlTestTableData(
        controlTestEvidence: ControlTestEvidence,
        markRightAnswer = false
    ): any[] {
        const answersTable = [];
        controlTestEvidence.questions.forEach((question, index) => {
            const questionTableRows: any[] = [];
            questionTableRows.push([
                {
                    text: index + 1 + '.',
                    bold: true,
                    fontSize: 8,
                },
                {
                    text: question.question.replace(/\n/g, ''),
                    bold: true,
                    fontSize: 8,
                },
            ]);
            question.answers.forEach(answer => {
                questionTableRows.push([
                    {
                        image: this.getPicNameForAnswer(
                            answer,
                            markRightAnswer
                        ),
                        width: 12,
                        height: 12,
                    },
                    {
                        text: answer.answer.replace(/\n/g, ''),
                        fontSize: 8,
                        margin: [0, 3, 0, 0],
                        color:
                            markRightAnswer && answer.selected
                                ? '#0A758F'
                                : undefined,
                    },
                ]);
            });
            questionTableRows.push([
                { text: ' ', fontSize: 8 },
                { text: ' ', fontSize: 8 },
            ]);
            answersTable.push({
                layout: 'noBorders',
                unbreakable: true,
                table: {
                    dontBreakRows: true,
                    body: questionTableRows,
                },
            });
        });
        return answersTable;
    }

    async createTrainingCourseCertificate(
        displayName: string,
        courseParticipation: CourseParticipation
    ) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.fonts = {
            AdelleSans: {
                normal: 'AdelleSans-Light.ttf',
                bold: 'AdelleSans-SemiBold.ttf',
                italics: 'AdelleSans-Light.ttf',
                bolditalics: 'AdelleSans-SemiBold.ttf',
            },
        };

        const pdfData = {
            pageSize: 'A4',
            // margin: [left, top, right, bottom]
            pageMargins: [80, 180, 80, 175],
            defaultStyle: {
                font: 'AdelleSans',
                lineHeight: 1.3,
            },
            background: function (currentPage, pageSize) {
                switch (currentPage) {
                    case 1:
                        return {
                            image: 'backgroundPage1',
                            width: 595.28,
                            height: 841.89,
                        };
                    case 2:
                        return {
                            image: 'backgroundPage2',
                            width: 595.28,
                            height: 841.89,
                        };
                    default:
                        return {
                            image: 'backgroundPage3',
                            width: 595.28,
                            height: 841.89,
                        };
                }
            },
            footer: function (currentPage, pageCount) {
                if (currentPage === 2) {
                    const datePipe = new DatePipe('de');
                    return [
                        {
                            text:
                                'Ulm, den ' +
                                datePipe.transform(Date.now(), 'shortDate'),
                            margin: [80, 5, 0, 0],
                            fontSize: 8,
                        },
                        {
                            text: 'Ralph Schweikert',
                            margin: [80, 35, 0, 0],
                            fontSize: 8,
                        },
                        {
                            text: 'Akademie für juristische Fachseminare',
                            margin: [80, 0, 0, 40],
                            fontSize: 8,
                        },
                    ];
                }
            },
            content: [
                { text: '', pageBreak: 'after' },
                //Page 2
                {
                    text: `Bestätigung der Teilnahme am ${courseParticipation.bookedCourse.targetReadingTime}-stündigen Online-Fortbildungslehrgang `,
                    fontSize: 20,
                    bold: true,
                    margin: [0, 30, 0, 0],
                },
                {
                    text: displayName,
                    fontSize: 14,
                    margin: [0, 30, 0, 0],
                    bold: true,
                },
                {
                    text:
                        'hat die ' +
                        courseParticipation.bookedCourse.targetReadingTime +
                        '-stündige Online-Fortbildung gem. FAO § 15 Abs. 4 im Fachgebiet ' +
                        courseParticipation.bookedCourse.associatedLegalArea[
                            'name'
                        ] +
                        ' erfolgreich absolviert. ',
                    fontSize: 14,
                    margin: [0, 15, 40, 30],
                },
                {
                    text:
                        'Das Online-Seminar umfasste mindestens ' +
                        courseParticipation.bookedCourse.targetReadingTime +
                        ' Zeitstunden und erfolgte im Wege des Selbststudiums mit abschließend erfolgreich bestandener Lernkontrolle (Nachweis s. Anhang). ',
                    fontSize: 10,
                    margin: [0, 0, 90, 0],
                },
                {
                    text: 'Die Lerninhalte umfassten',
                    fontSize: 10,
                    margin: [0, 15, 0, 0],
                },
                { text: '- Aktuelle Rechtssprechung', fontSize: 10 },
                {
                    text: '- Pressemitteilungen der Gerichte',
                    fontSize: 10,
                },
                { text: '- Gesetzgebung', fontSize: 10 },
                { text: '- Aktuelle Fachpresse ', fontSize: 10 },

                // Page 3
                {
                    pageBreak: 'before',
                    text: 'Nachweis der Lernerfolgskontrolle',
                    fontSize: 20,
                    margin: [0, 20, 0, 0],
                },
                {
                    text: displayName,
                    fontSize: 14,
                    margin: [0, 30, 0, 0],
                    bold: true,
                },
                {
                    text:
                        'hat folgende Lernkontrolle am ' +
                        this.datePipe.transform(
                            new Date(
                                courseParticipation.controlTestEvidence.createdAt
                            ),
                            'shortDate'
                        ) +
                        ' erfolgreich bestanden',
                    fontSize: 10,
                    margin: [0, 5, 0, 0],
                },
                ...this.getAnswerLegend(),
                ...this.createControlTestTableData(
                    courseParticipation.controlTestEvidence
                ),
            ],
            images: {
                rightAnswer: this.trainingCertificateRight,
                wrongAnswer: this.trainingCertificateWrong,
                emptyAnswer: this.trainingCertificateCircle,
                backgroundPage1: this.trainingCertificateBackground1,
                backgroundPage2: this.trainingCertificateBackground2,
                backgroundPage3: this.trainingCertificateBackground3,
            },
        };
        pdfMake
            .createPdf(pdfData)
            .download(
                `AK_JURA_Teilnamebestaetigung_${courseParticipation.bookedCourse.description}`
            );
    }

    getPicNameForAnswer(answer: ControlAnswerEvidence, markAllAnswers = false) {
        if (markAllAnswers) {
            return answer.isCorrect ? 'rightAnswer' : 'wrongAnswer';
        } else {
            if (answer.selected) {
                return answer.isCorrect ? 'rightAnswer' : 'wrongAnswer';
            } else {
                return 'emptyAnswer';
            }
        }
    }

    getAnswerLegend(withGivenAnswer = false) {
        const tableBodyData: any[] = [
            [
                {
                    image: 'rightAnswer',
                    width: 10,
                    height: 10,
                    margin: [0, 0, 0, 0],
                },
                {
                    text: 'richtige Antwort',
                    fontSize: 8,
                    margin: [0, 2, 0, 0],
                },
                {
                    image: 'wrongAnswer',
                    width: 10,
                    height: 10,
                    margin: [0, 0, 0, 0],
                },
                {
                    text: 'falsche Antwort',
                    fontSize: 8,
                    height: 10,
                    margin: [0, 2, 0, 0],
                },
            ],
        ];
        if (withGivenAnswer) {
            tableBodyData.push([
                {
                    image: 'emptyAnswer',
                    width: 10,
                    height: 10,
                    margin: [0, 0, 0, 0],
                },
                {
                    text: 'gegebene Antwort',
                    fontSize: 8,
                    margin: [0, 2, 0, 0],
                    color: '#0A758F',
                },
            ]);
        }

        return [
            {
                text: 'Legende',
                fontSize: 8,
                // margin: [left, top, right, bottom]
                margin: [0, 15, 0, 0],
            },
            {
                layout: 'noBorders',
                table: {
                    body: tableBodyData,
                },
                margin: [0, 0, 0, 20],
            },
        ];
    }

    createLearnControlPdf(
        customer: CustomerBaseData,
        courseParticipation: CourseParticipation,
        moduleInfo: LearningModuleDocumentInfo,
        controlTestEvidence: ControlTestEvidence
    ) {
        const tableInfo = this.createControlTestTableData(
            controlTestEvidence,
            false
        );
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.fonts = {
            AdelleSans: {
                normal: 'AdelleSans-Light.ttf',
                bold: 'AdelleSans-SemiBold.ttf',
                italics: 'AdelleSans-Light.ttf',
                bolditalics: 'AdelleSans-SemiBold.ttf',
            },
        };
        const pdfData = {
            pageSize: 'A4',
            // margin: [left, top, right, bottom]
            pageMargins: [80, 180, 80, 180],
            defaultStyle: {
                font: 'AdelleSans',
                lineHeight: 1.3,
            },
            background: function (currentPage, pageSize) {
                switch (currentPage) {
                    case 1:
                        return {
                            image: 'backgroundPage3',
                            width: 595.28,
                            height: 841.89,
                        };
                    case 2:
                        return {
                            image: 'backgroundPage3',
                            width: 595.28,
                            height: 841.89,
                        };
                    default:
                        return {
                            image: 'backgroundPage3',
                            width: 595.28,
                            height: 841.89,
                        };
                }
            },
            header: [],
            content: [
                {
                    text: `Nachweis der Lernerfolgskontrolle`,
                    fontSize: 20,
                    bold: true,
                    margin: [0, 20, 0, 20],
                },
                {
                    text: `${customer.firstName} ${customer.lastName}`,
                    fontSize: 12,
                    bold: true,
                },
                {
                    text: `hat folgende Lernkontrolle zum Modul ${moduleInfo.sequentialNumber} des Lehrgangs ${courseParticipation.bookedCourse.description}`,
                    fontSize: 12,
                },
                {
                    text: `am ${this.datePipe.transform(
                        controlTestEvidence.createdAt,
                        'dd.MM.YYYY'
                    )} um ${this.datePipe.transform(
                        controlTestEvidence.createdAt,
                        'HH:mm'
                    )} Uhr erfolgreich bestanden:`,
                    fontSize: 12,
                    margin: [0, 0, 0, 20],
                },
                /** */
                ...this.getAnswerLegend(),

                /** */
                ...tableInfo,
                /** ...this.getAnswerLegend(true), */
            ],
            images: {
                rightAnswer: this.trainingCertificateRight,
                wrongAnswer: this.trainingCertificateWrong,
                emptyAnswer: this.trainingCertificateCircle,
                backgroundPage1: this.trainingCertificateBackground1,
                backgroundPage2: this.trainingCertificateBackground2,
                backgroundPage3: this.trainingCertificateBackground3,
                footer: this.certificateFooter,
            },
        };
        pdfMake
            .createPdf(pdfData)
            .download(
                `AK_JURA_Lernkontrolle_MODUL_${this.numberToNumberingPipe.transform(
                    moduleInfo.sequentialNumber,
                    'upperRomanNumerals'
                )}`
            );
    }

    createExamDetailsPdf(description: string) {
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        pdfMake.fonts = {
            AdelleSans: {
                normal: 'AdelleSans-Light.ttf',
                bold: 'AdelleSans-SemiBold.ttf',
                italics: 'AdelleSans-Light.ttf',
                bolditalics: 'AdelleSans-SemiBold.ttf',
            },
        };
        const pdfData = {
            pageSize: 'A4',
            pageMargins: [80, 100, 80, 80],
            defaultStyle: {
                font: 'AdelleSans',
                lineHeight: 1.3,
            },
            header: [
                {
                    margin: [80, 20, 80, 20],
                    image: 'logo',
                    width: 244.5,
                    height: 39.5,
                },
            ],
            content: htmlToPdfmake(description),
            images: {
                logo: this.logo,
            },
        };
        pdfMake.createPdf(pdfData).download(`AK_JURA_Prüfung`);
    }
}
