import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { filterNil } from '@cna/shared/helper';
import { select, Store } from '@ngrx/store';
import { selectActualModuleDocumentInfo } from 'apps/ak-jura/frontends/my-ak-jura/src/app/+state/selectors';

import { selectSidenavContentType } from 'apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/selectors';

import { Observable } from 'rxjs/Observable';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { setSidenavActiveState } from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/sidenav/actions';
import {
    selectCourseParticipationId,
    selectModuleId,
    selectReadingId,
    selectRouter,
} from '../../../../../../../../../apps/ak-jura/frontends/my-ak-jura/src/app/+state/router.selector';
import { LearningModuleDocumentInfo } from '../../../../../../shared/interfaces/src/lib/learning-module-document-info';
import { NumberingService } from '../../../../../../../../angular/services/src/lib/numbering/numbering.service';

export enum SidenavContentType {
    CONTENT_OVERVIEW,
    CUSTOMIZATION,
    NOTES_OVERVIEW,
    NONE,
}

@Component({
    selector: 'cna-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {
    @Input() width;
    destroy$ = new Subject<void>();

    currentSidenavContentType: SidenavContentType =
        SidenavContentType.CONTENT_OVERVIEW;

    changedMode$ = this.store.pipe(
        select(selectRouter),
        takeUntil(this.destroy$)
    );

    value;
    currentMode: boolean;

    constructor(
        private store: Store,
        private router: Router,
        private route: ActivatedRoute,
        private numberingService: NumberingService
    ) {}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit(): void {
        this.store
            .pipe(select(selectSidenavContentType), takeUntil(this.destroy$))
            .subscribe(val => (this.currentSidenavContentType = val));

        this.changedMode$.pipe(filterNil()).subscribe(value => {
            this.value = value.state.url;
            this.currentMode = this.value.includes('repetitorium');
        });
    }

    getWidthForStyling() {
        return 'width: ' + this.width + 'vw';
    }

    routeToOverview() {
        if (!this.currentMode) {
            this.store
                .select(selectCourseParticipationId)
                .pipe(withLatestFrom(this.store.select(selectModuleId)))
                .subscribe(([courseParticipationId, moduleId]) => {
                    this.store.dispatch(
                        setSidenavActiveState({ activeState: false })
                    );
                    this.router.navigate([
                        courseParticipationId,
                        'learning',
                        'moduleOverview',
                        moduleId,
                    ]);
                })
                .unsubscribe();
        } else {
            this.store
                .select(selectCourseParticipationId)
                .subscribe(CourseParticipationId => {
                    this.router
                        .navigateByUrl('/', { skipLocationChange: true })
                        .then(() =>
                            this.router.navigate([
                                CourseParticipationId,
                                'learning',
                                'repetitorium',
                            ])
                        );
                })
                .unsubscribe();
        }
    }

    routeToNotes() {
        this.store
            .select(selectCourseParticipationId)
            .subscribe(courseParticipationId => {
                this.store.dispatch(
                    setSidenavActiveState({ activeState: false })
                );
                this.router.navigate([courseParticipationId, 'notes']);
            })
            .unsubscribe();
    }
}
