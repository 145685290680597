<div *ngLet="readingId$ | async as readingId">
    <div id="header" *ngIf="!sidebar">
        <div class="moduleOverview-Container">
            <div style="" class="moduleOverview-table-header">
                <div
                    class="adelle-sans-12 letterSpacing-08 font-weight-600 dark-gray text-learntime"
                >
                    LERNZEIT
                </div>
                <div
                    class="adelle-sans-12 letterSpacing-08 font-weight-600 dark-gray text-study_progress"
                >
                    LERNFORTSCHRITT
                </div>
            </div>
        </div>
    </div>

    <div class="background-grey">
        <div class="header-container" style="min-height: 69px">
            <div
                *ngIf="courseType === CourseType.EducationCourse"
                class="headerTitle dark-gray adelle-sans-17"
            >
                <span>{{ moduleName }}</span>
                <span class="text-header-viewable"> – gesamt </span>
            </div>
            <div
                *ngIf="courseType === CourseType.TrainingCourse"
                class="headerTitle dark-gray adelle-sans-17"
            >
                <span>INHALT</span>
            </div>
            <div class="header-info">
                <div
                    class="timeRead adelle-sans-14 dark-gray font-weight-300"
                    *ngIf="!sidebar"
                >
                    <!-- style="min-width: 120px" -->
                    {{ moduleDocumentInfo?.totalTimeRead | secondsToHours }}
                </div>

                <div class="progress-bar-learning">
                    <!-- <span *ngIf="!sidebar"> -->
                    <cna-progress-bar
                        [progressPercentage]="
                            moduleDocumentHelperService.getPercentageReadForNodeModul(
                                moduleDocumentInfo
                            )
                        "
                    ></cna-progress-bar>
                    <!-- </span> -->
                    <span
                        *ngIf="sidebar"
                        class="adelle-sans-14 dark-gray font-weight-300"
                    >
                        Lernfortschritt
                    </span>
                    <span class="header-text adelle-sans-14 dark-gray">
                        {{
                            moduleDocumentHelperService.getPercentageReadForNodeModul(
                                moduleDocumentInfo
                            ) | number: '1.0-0'
                        }}
                        %
                    </span>
                </div>
            </div>
        </div>
    </div>

    <!-- <hr style="margin-top: 0px; margin-bottom: 0px" *ngIf="sidebar"> -->
    <ngb-accordion
        id="chapter-accordion"
        class="chapter-accordion"
        animation="true"
        [activeIds]="openChapterPanelNames"
    >
        <!-- Ebene 1 -->
        <ngb-panel
            *ngFor="
                let chapter of moduleDocumentInfo?.chapters;
                let chapterIndex = index
            "
            id="{{ 'chapterPanel-' + chapterIndex }}"
        >
            <ng-template ngbPanelTitle>
                <div
                    class="justify-content-between align-items-baseline"
                    style="display: flex; flex-direction: row; min-height: 60px"
                >
                    <div
                        class="d-flex justify-content-start align-items-baseline"
                        style="
                            flex: auto;
                            margin-top: 17.5px;
                            margin-bottom: 17.5px;
                        "
                    >
                        <div class="ebene1-arrow">
                            <img
                                src="assets/Pfeil_Accordion-unten.svg"
                                style="width: 20px; opacity: 50%"
                                class="acc-arrow"
                            />
                        </div>

                        <div class="ebene1-title adelle-sans-17">
                            {{ chapter.title }}
                        </div>
                    </div>
                    <div class="header-info">
                        <div
                            class="timeRead adelle-sans-14 dark-gray font-weight-300"
                        >
                            <span *ngIf="!sidebar">
                                {{
                                    moduleDocumentHelperService.getTimeForNodeChapter(
                                        chapter
                                    ) | secondsToHours
                                }}
                            </span>
                        </div>
                        <div class="progress-bar-learning">
                            <!--                            {{moduleDocumentHelperService?.getPercentageReadForNode(chapter)}}-->
                            <cna-progress-bar
                                [progressPercentage]="
                                    moduleDocumentHelperService?.getPercentageReadForNodeChapter(
                                        chapter
                                    )
                                "
                            ></cna-progress-bar>
                            <span class="ebene1-text adelle-sans-14 dark-gray">
                                {{
                                    moduleDocumentHelperService.getPercentageReadForNodeChapter(
                                        chapter
                                    ) | number: '1.0-0'
                                }}
                                %
                            </span>
                        </div>
                    </div>
                </div>
            </ng-template>

            <!-- Ebene 2 -->
            <ng-template ngbPanelContent>
                <div
                    *ngFor="
                        let section of chapter.sections;
                        let sectionIndex = index
                    "
                >
                    <ngb-accordion
                        activeIds="{{ openSectionPanel }}"
                        class="section-accordion"
                        id="{{
                            'section-accordion-' +
                                chapterIndex +
                                '-' +
                                sectionIndex
                        }}"
                        animation="true"
                        [ngClass]="!sidebar ? 'main' : ''"
                    >
                        <ngb-panel
                            id="{{
                                'section-panel-' +
                                    chapterIndex +
                                    '-' +
                                    sectionIndex
                            }}"
                            *ngIf="
                                section.sections?.length > 0;
                                else noSubsections
                            "
                        >
                            <ng-template ngbPanelTitle>
                                <div
                                    class="justify-content-start"
                                    style="
                                        display: flex;
                                        flex-direction: row;
                                        min-height: 60px;
                                    "
                                    [ngClass]="{
                                        active: section.id == readingId
                                    }"
                                >
                                    <div class="ebene2-t1">
                                        <div class="ebene2-t1-arrow">
                                            <img
                                                src="assets/Pfeil_Accordion-unten.svg"
                                                style="
                                                    width: 20px;
                                                    opacity: 50%;
                                                "
                                                class="acc-arrow"
                                            />
                                        </div>
                                        <div
                                            class="align-items-baseline adelle-sans-17 font-weight-300 overflow-hidden d-flex flex-row"
                                        >
                                            <cna-chapter-circle
                                                chapter="{{
                                                    section.title
                                                        | sectionNumbering
                                                }}"
                                                [done]="
                                                    moduleDocumentHelperService?.getReadStatusForNode(
                                                        section
                                                    ) === 100
                                                "
                                            ></cna-chapter-circle>
                                        </div>
                                    </div>

                                    <div class="ebene2-t2">
                                        <div
                                            class="ebene2-t2-sectionTitle adelle-sans-17 font-weight-300"
                                        >
                                            <!-- (click)="navigate(section)" -->
                                            {{ section.title | sectionTitle }}
                                        </div>
                                        <div class="ebene2-t2-rightSection">
                                            <div
                                                class="timeRead dark-gray font-weight-300 dark-gray adelle-sans-14 font-weight-300"
                                            >
                                                {{
                                                    moduleDocumentHelperService.getTimeForNodeSection(
                                                        section
                                                    ) | secondsToHours
                                                }}
                                                <!--
                                                    {{
                                                        section?.timeRead
                                                            | secondsToHours
                                                    }} -->
                                            </div>

                                            <div
                                                [ngClass]="
                                                    moduleDocumentHelperService?.getNodeIncludesExaminables(
                                                        section,
                                                        examinables
                                                    )
                                                        ? 'ml-42'
                                                        : ''
                                                "
                                            >
                                                <!--
                                                <div
                                                    *ngIf="
                                                        !moduleDocumentHelperService?.getNodeIncludesExaminables(
                                                            section,
                                                            examinables
                                                        )
                                                    "
                                                    class="fake-element"
                                                ></div> -->
                                                <cna-exam-relevant
                                                    class="d-flex align-items-baseline"
                                                    *ngIf="
                                                        moduleDocumentHelperService?.getNodeIncludesExaminables(
                                                            section,
                                                            examinables
                                                        )
                                                    "
                                                ></cna-exam-relevant>
                                            </div>

                                            <div
                                                [ngClass]="
                                                    moduleDocumentHelperService?.getNodeIncludesExaminables(
                                                        section,
                                                        examinables
                                                    )
                                                        ? 'ml-41'
                                                        : 'ml-102'
                                                "
                                                class="progress-learning-bar-ebene2"
                                            >
                                                <!-- [progressPercentage]="
                                                            moduleDocumentHelperService?.getPercentageReadForNode(
                                                                section
                                                            )
                                                        "-->
                                                <!--                                                    {{moduleDocumentHelperService?.getPercentageReadForNode(section)}}-->
                                                <cna-progress-bar
                                                    [progressPercentage]="
                                                        moduleDocumentHelperService?.getPercentageReadForNodeSection(
                                                            section
                                                        )
                                                    "
                                                ></cna-progress-bar>
                                                <span
                                                    class="ebene2-text adelle-sans-14 dark-gray"
                                                >
                                                    {{
                                                        moduleDocumentHelperService.getPercentageReadForNodeSection(
                                                            section
                                                        ) | number: '1.0-0'
                                                    }}
                                                    %
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>

                            <!-- Ebene 3 -->
                            <ng-template ngbPanelContent>
                                <ngb-accordion
                                    id="{{
                                        'subsection-accordion-' +
                                            chapterIndex +
                                            '-' +
                                            sectionIndex
                                    }}"
                                    animation="true"
                                >
                                    <ngb-panel
                                        *ngFor="
                                            let subsection of section.sections;
                                            let subsectionIndex = index
                                        "
                                        id="{{
                                            'subsection-panel-' +
                                                chapterIndex +
                                                '-' +
                                                sectionIndex +
                                                '-' +
                                                subsectionIndex
                                        }}"
                                    >
                                        <ng-template ngbPanelHeader>
                                            <div
                                                class="justify-content-start"
                                                style="
                                                    display: flex;
                                                    flex-direction: row;
                                                    min-height: 60px;
                                                "
                                                [ngClass]="{
                                                    active:
                                                        subsection.id ==
                                                        readingId
                                                }"
                                            >
                                                <div class="ebene3-t1"></div>

                                                <div class="ebene3-w1">
                                                    <div
                                                        class="ebene3-t2-t1 adelle-sans-17 font-weight-300"
                                                        (click)="
                                                            navigate(subsection)
                                                        "
                                                    >
                                                        {{ subsection.title }}
                                                    </div>

                                                    <div class="ebene3-t2-t2">
                                                        <div
                                                            class="ebene3-t2-t2-right"
                                                        >
                                                            <div
                                                                class="timeRead d-flex align-items-baseline dark-gray adelle-sans-14 font-weight-300"
                                                            >
                                                                {{
                                                                    moduleDocumentHelperService.getTimeForNodeSubSection(
                                                                        subsection
                                                                    )
                                                                        | secondsToHours
                                                                }}

                                                                <!-- {{
                                                                    subsection?.timeRead
                                                                        | secondsToHours
                                                                }} -->
                                                            </div>
                                                            <div
                                                                [ngClass]="
                                                                    moduleDocumentHelperService?.getNodeIncludesExaminables(
                                                                        subsection,
                                                                        examinables
                                                                    )
                                                                        ? 'ml-42'
                                                                        : ''
                                                                "
                                                            >
                                                                <cna-exam-relevant
                                                                    *ngIf="
                                                                        moduleDocumentHelperService?.getNodeIncludesExaminables(
                                                                            subsection,
                                                                            examinables
                                                                        )
                                                                    "
                                                                ></cna-exam-relevant>
                                                            </div>

                                                            <div
                                                                [ngClass]="
                                                                    moduleDocumentHelperService?.getNodeIncludesExaminables(
                                                                        subsection,
                                                                        examinables
                                                                    )
                                                                        ? 'ml-41'
                                                                        : 'ml-102'
                                                                "
                                                                class="d-flex align-items-baseline"
                                                            >
                                                                <cna-progress-bar
                                                                    [progressPercentage]="
                                                                        subsection
                                                                            ?.readStatus
                                                                            ?.read
                                                                    "
                                                                ></cna-progress-bar>
                                                                <span
                                                                    class="ebene3-text adelle-sans-14 dark-gray font-weight-300"
                                                                >
                                                                    {{
                                                                        moduleDocumentHelperService.getPercentageReadForNode(
                                                                            subsection
                                                                        )
                                                                            | number
                                                                                : '1.0-0'
                                                                    }}
                                                                    %
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div
                                                    class="linkbutton"
                                                    (click)="
                                                        navigate(subsection)
                                                    "
                                                >
                                                    <span
                                                        class="img-container dark-gray"
                                                    >
                                                        <img
                                                            src="assets/start.svg"
                                                            class="img-before"
                                                        />
                                                        <img
                                                            src="assets/start-hover.svg"
                                                            class="img-after"
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </ng-template>
                        </ngb-panel>

                        <!-- Ebene 2 (ohne Subsektionen) -->
                        <ng-template #noSubsections>
                            <ngb-panel>
                                <ng-template ngbPanelHeader>
                                    <div
                                        class="d-flex flex-row justify-content-start"
                                        style="
                                            display: flex;
                                            flex-direction: row;
                                            min-height: 60px;
                                        "
                                        [ngClass]="{
                                            active: section.id == readingId
                                        }"
                                    >
                                        <div class="ebene2-t1">
                                            <div
                                                class="ebene2-t1-sub align-items-baseline adelle-sans-17 dark-gray font-weight-300 overflow-hidden d-flex flex-row"
                                            >
                                                <cna-chapter-circle
                                                    chapter="{{
                                                        section.title
                                                            | sectionNumbering
                                                    }}"
                                                    [done]="
                                                        moduleDocumentHelperService?.getReadStatusForNode(
                                                            section
                                                        ) === 100
                                                    "
                                                ></cna-chapter-circle>
                                            </div>
                                        </div>

                                        <!-- style="
                                                        margin-left: 11px;
                                                        line-height: 1.333;
                                                        margin-top: 19px;
                                                        margin-bottom: 19px;
                                                        width: 456px;
                                                        color: #454f54;
                                                    " -->

                                        <div class="ebene2-t2">
                                            <div
                                                class="ebene2-t2-sectionTitle adelle-sans-17 font-weight-300"
                                                (click)="navigate(section)"
                                            >
                                                {{
                                                    section.title | sectionTitle
                                                }}
                                            </div>

                                            <div class="ebene2-t2-rightSection">
                                                <div
                                                    class="timeRead dark-gray font-weight-300 dark-gray adelle-sans-14 font-weight-300"
                                                >
                                                    {{
                                                        moduleDocumentHelperService.getTimeForNodeSubSection(
                                                            section
                                                        ) | secondsToHours
                                                    }}
                                                    <!-- section?.timeRead | secondsToHours -->
                                                </div>

                                                <div
                                                    [ngClass]="
                                                        moduleDocumentHelperService?.getNodeIncludesExaminables(
                                                            section,
                                                            examinables
                                                        )
                                                            ? 'ml-42'
                                                            : ''
                                                    "
                                                >
                                                    <!--
                                                    <div
                                                        *ngIf="
                                                            !moduleDocumentHelperService?.getNodeIncludesExaminables(
                                                                section,
                                                                examinables
                                                            )
                                                        "
                                                        class="fake-element"
                                                    ></div> -->
                                                    <cna-exam-relevant
                                                        class="d-flex align-items-baseline"
                                                        *ngIf="
                                                            moduleDocumentHelperService?.getNodeIncludesExaminables(
                                                                section,
                                                                examinables
                                                            )
                                                        "
                                                    ></cna-exam-relevant>
                                                </div>

                                                <div
                                                    [ngClass]="
                                                        moduleDocumentHelperService?.getNodeIncludesExaminables(
                                                            section,
                                                            examinables
                                                        )
                                                            ? 'ml-41'
                                                            : 'ml-102'
                                                    "
                                                    class="progress-learning-bar-ebene2"
                                                >
                                                    <cna-progress-bar
                                                        [progressPercentage]="
                                                            moduleDocumentHelperService?.getPercentageReadForNode(
                                                                section
                                                            )
                                                        "
                                                    ></cna-progress-bar>
                                                    <span
                                                        class="ebene2-text adelle-sans-14 dark-gray"
                                                    >
                                                        {{
                                                            moduleDocumentHelperService.getPercentageReadForNode(
                                                                section
                                                            ) | number: '1.0-0'
                                                        }}
                                                        %
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            class="linkbutton"
                                            (click)="navigate(section)"
                                        >
                                            <!-- style="
                                                z-index: 1;
                                                padding-right: 0px;
                                                width: 30px;
                                                margin-left: 51px;
                                                margin-top: 15px;
                                            " -->
                                            <span class="img-container">
                                                <img
                                                    src="assets/start.svg"
                                                    class="img-before"
                                                />
                                                <img
                                                    src="assets/start-hover.svg"
                                                    class="img-after"
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </ng-template>
                            </ngb-panel>
                        </ng-template>
                    </ngb-accordion>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>

    <div
        *ngIf="courseType === CourseType.EducationCourse"
        class="d-flex flex-row justify-content-end align-items-start pt-4 border-top"
    >
        <img src="assets/stern.svg" />
        <div
            class="adelle-sans-12 font-weight-600 letterSpacing-08 pl-1"
            style="color: #b4b8ba"
        >
            PRÜFUNGSRELEVANT
        </div>
    </div>
</div>
