<div class="row justify-content-center">
    <div class="col-15 col-sm-13">
        <cna-page-heading pageHeadingTitle=""></cna-page-heading>
        <div class="row mt-5 pt-5">
            <div class="col-1 d-none d-md-block">
                <img src="assets/bestanden.svg" />
            </div>
            <div class="pl-3 col-13">
                <div class="col-16">
                    <p class="green-color adelle-pe-25 font-weight-400">
                        Die Verlängerung wurde erfolgreich gebucht
                    </p>
                </div>
                <div class="col-16">
                    <p class="adelle-sans-17 font-weight-300">
                        Die Verlängerung wurde gebucht. Ihr Kurs läuft nun bis
                        zum {{ validUntil | date }}.
                    </p>
                </div>
                <div class="col-16 pt-4">
                    <cna-gradient-button
                        notgradient="true"
                        buttonTitle="Zurück zur Kursübersicht"
                        buttonRouterLink="../../"
                    ></cna-gradient-button>
                </div>
            </div>
        </div>
    </div>
</div>
