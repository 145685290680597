import { ModuleDocumentHelperService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/module-document-helper.service';
import { SidenavContentType } from '../../../../../../../../libs/projects/ak-jura/angular/my-ak-jura/shared-components/src/lib/sidenav/sidenav.component';
import { ExamParticipation } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam-participation';
import { Exam } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam';
import { LearningModuleDocumentInfo } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/learning-module-document-info';
import {
    Examinable,
    ModuleDocument,
    Repetitorium,
    TimeExpenditure,
} from '@cna/projects/ak-jura/shared/interfaces';
import { ControlTest } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/control-test/control-test';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './actions';

export const REPETITORIUM_STORE_NAME = 'repetitorium';
const moduleDocumentHelperService = new ModuleDocumentHelperService();

export interface RepetitoriumState {
    repetitorium: Repetitorium;
    repetitoriumUnlocked: boolean;
    loading: boolean;
}

const initialState = {
    repetitorium: undefined,
    repetitoriumUnlocked: false,
    loading: true,
};

const _repetitoriumReducer = createReducer<RepetitoriumState>(
    initialState,

    /**
     * load Repetitorium for actual Course
     */
    on(fromActions.loadRepetitoriumForActualCourse, state => ({
        ...state,
        loading: true,
    })),
    on(fromActions.loadRepetitoriumForActualCourseSuccess, (state, action) => ({
        ...state,
        repetitorium: action.repetitorium,
        loading: false,
    })),
    on(fromActions.loadRepetitoriumForActualCourseFailure, state => ({
        ...state,
        loading: false,
    })),
    on(fromActions.setRepetitoriumUnlockedState, (state, action) => ({
        ...state,
        repetitoriumUnlocked: action.unlockedState,
    })),

    /** setReadStatusForRepetitoriumSuccess */
    on(
        fromActions.setReadStatusForRepetitoriumSuccess,
        (state: RepetitoriumState, action) => {
            return {
                ...state,
                repetitorium: {
                    ...state.repetitorium,
                    favoriteGroups: state.repetitorium.favoriteGroups.map(fg =>
                        fg.id === action.readStatus.favoriteGroupId ||
                        fg.workedOut?.id === action.readStatus.id
                            ? {
                                  ...fg,
                                  workedOut: action.readStatus,
                              }
                            : fg
                    ),
                },
            };
        }
    )
);

export function repetitoriumReducer(state, action) {
    return _repetitoriumReducer(state, action);
}
