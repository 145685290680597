import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as globalActions from './actions';
import * as errodDescribtionActions from '../error_describtion/actions';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import { selectModuleId } from '../router.selector';
import { environment } from '../../../environments/environment';
import { Examinable, Note } from '@cna/projects/ak-jura/shared/interfaces';
import { of } from 'rxjs';
import { Injectable } from '@angular/core';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { LearningModuleDocumentInfo } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/learning-module-document-info';
import { Exam } from '../../../../../../../../libs/projects/ak-jura/shared/interfaces/src/lib/data-management/exam/exam';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleDocumentHelperService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/module-document-helper.service';
import { ToasterService } from '../../../../../../../../libs/projects/ak-jura/angular/shared-components/src/lib/services/toaster.service';

@Injectable()
export class ExaminablesEffects {
    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private entityServices: EntityServices,
        private store: Store
    ) {}

    /**
     * Load Module Info
     */
    loadModuleExaminables$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(globalActions.loadModuleExaminables),
            withLatestFrom(this.store.select(selectModuleId)),
            mergeMap(([action, moduleId]) =>
                this.http.get(environment.apiHost + '/examinable', {
                    params: {
                        filter: `moduleId||$eq||${moduleId}`,
                    },
                })
            ),
            map((examinables: Examinable[]) => {
                return globalActions.loadModuleExaminablesSuccess({
                    examinables,
                });
            }),
            catchError(error =>
                of(globalActions.loadModuleExaminablesFailure({ error }))
            )
        );
    });

    loadModuleExaminablesFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(globalActions.loadModuleExaminablesFailure),
                tap(error => {
                    this.store.dispatch(
                        errodDescribtionActions.setErrorDescription({
                            errorDescription:
                                'Beim Laden der Favoriten ist ein Fehler aufgetreten: ' +
                                error,
                        })
                    );
                })
            );
        },
        { dispatch: false }
    );
}
